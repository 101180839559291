import { useQuery } from 'react-query';

import { MealPlans } from '@/api';
import { axios } from '@/lib/axios';
import { Dog } from '@/types/FunnelSession';

export interface UsePlanOptionsQueryData {
  pet?: Partial<Dog>;
  discounts?: string[];
  sessionId?: string;
  accountId?: string;
}

const getPlans = async ({
  pet,
  discounts,
  sessionId,
  accountId,
}: {
  pet: Partial<Dog>;
  discounts?: string[];
  sessionId?: string;
  accountId?: string;
}) => {
  try {
    const { weight, birth_year, birth_month, body_type, is_neutered, lifestyle } = pet;
    const response = await axios.get<MealPlans>('/calculator/plan_options', {
      params: {
        weight,
        birth_year,
        birth_month,
        body_type,
        neutered: is_neutered,
        lifestyle,
        discounts: discounts?.join(','),
        is_trial: true,
        session_id: sessionId || undefined,
        account_id: accountId || undefined,
      },
    });
    return response.data;
  } catch (err: unknown) {
    throw new Error(`Error fetching plan options: ${err}`);
  }
};

export const usePlans = ({ pet, discounts, sessionId, accountId }: UsePlanOptionsQueryData) => {
  const enabled =
    !!pet &&
    ![pet.name, pet.weight, pet.birth_year, pet.body_type, pet.is_neutered, pet.lifestyle].includes(
      undefined
    );
  return useQuery(
    [
      'Plans',
      pet?.name,
      pet?.weight,
      pet?.birth_year,
      pet?.birth_month,
      pet?.body_type,
      pet?.is_neutered,
      pet?.lifestyle,
      discounts,
      sessionId,
      accountId,
    ],
    () => getPlans({ pet: pet as Partial<Dog>, discounts, sessionId, accountId }),
    {
      enabled,
      cacheTime: 1000 * 60 * 60, // cache pet plan data for these specific params for 1 hour
    }
  );
};
