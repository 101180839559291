import { Box, Stack } from 'native-base';

import { LabelledPicto } from '@/components/Elements';

export const PictosSection = () => {
  return (
    <Stack
      flexDirection={{ base: 'column', lg: 'row' }}
      p={{ base: '48px', lg: '75px' }}
      bg="sntBlue.primary"
      w={{ base: '100%', lg: '1160px' }}
      justifyContent="center"
      alignItems="center"
    >
      <LabelledPicto
        src="Icons/carrot.png"
        heading="100% real, fresh ingredients"
        caption="There is a growing body of evidence supporting the numerous health benefits of freshly prepared, minimally processed foods for dogs. We use only real whole grains, meat, fruits, and vegetables. Never anything artificial."
        imageProps={{ size: '80px' }}
        w="280px"
        headingProps={{ color: 'black', size: 'bodyMl' }}
        captionProps={{ color: 'black', size: 'bodyMl', fontFamily: 'secondary' }}
        space="24px"
      />

      {/* Workaround for missing space prop on Stacks */}
      <Box w={{ base: '68px', lg: '50px' }} h={{ base: '68px', lg: '50px' }} />

      <LabelledPicto
        src="Icons/vet-developed.png"
        heading="Vet-Developed Recipes:"
        caption="Our recipes are formulated to meet the AAFCO nutrient requirements for All Life Stages — even large breed puppies (over 70 lbs as an adult)."
        imageProps={{ size: '80px' }}
        w="280px"
        headingProps={{ color: 'black', size: 'bodyMl' }}
        captionProps={{ color: 'black', size: 'bodyMl', fontFamily: 'secondary' }}
        space="24px"
      />

      <Box w={{ base: '68px', lg: '50px' }} h={{ base: '68px', lg: '50px' }} />

      <LabelledPicto
        src="Icons/happy-pup.png"
        heading="Happy Pup Guarantee:"
        caption="We’re so confident your dog will love UnKibble, we offer a money-back guarantee. There's nothing to lose — if your dog doesn't love it, we'll refund you."
        imageProps={{ size: '80px' }}
        w="280px"
        headingProps={{ color: 'black', size: 'bodyMl' }}
        captionProps={{ color: 'black', size: 'bodyMl', fontFamily: 'secondary' }}
        space="24px"
      />
    </Stack>
  );
};
