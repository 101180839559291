import {
  OrderProduct,
  OrderProductType,
  PetPlanStatus,
  PricingModelStatus,
  ProductType,
  RecipeType,
} from '../../api';

export const mealOrderProductMock: OrderProduct = {
  id: '37a881f1-3566-401e-8302-fcbd8b2ea2fd',
  product: '222881f1-3566-401e-8302-fcbd8b2ea222',
  product_type: ProductType.MEAL,
  recipes: [
    {
      id: 'F-B',
      name: 'Beef + Millet',
      description:
        'Our farm-to-bowl Beef & Millet recipe is a great option for pups with wheat sensitivities. Beef is also an excellent source of Vitamin E, a super antioxidant. ',
      type: RecipeType.FRESH,
      seq_no: 1,
      aafco_statement: '',
    },
  ],
  image_uri: null,
  unit_price: '42.0',
  name: 'Meal Plan - Fresh - Beef - Plan Size 300 Calories - Frequency 4W',
  code: 'MEAL-F-B-300C-4W',
  quantity: 1,
  type: OrderProductType.SUBSCRIPTION,
  price: {
    id: 'b45a3e06-bd79-4ae9-a065-d9428e58cd20',
    price: '150.23',
    frequency: 4,
    price_per_meal: '2.68',
    price_per_day: '5.37',
    price_per_week: '37.56',
    discounted_price_per_pack: null,
    discounted_price_per_meal: null,
    discounted_price_per_day: null,
    discounted_price_per_week: null,
    discounted_price: null,
    pricing_model: {
      id: 'e77f5274-9027-495b-abf2-7b3b900fba1d',
      created: '2023-05-08T13:11:17.026380-04:00',
      last_updated: '2023-05-08T13:11:17.026392-04:00',
      name: 'v2',
      status: PricingModelStatus.ACTIVE,
      is_default: true,
    },
    created: '2023-05-08T13:11:18.343188-04:00',
    last_updated: '2023-05-08T13:11:18.343201-04:00',
  },
};

export const snackOrderProductMock: OrderProduct = {
  id: '1',
  name: 'Snack Chicken Munchies R',
  code: 'SNACK-CM-1CT-R',
  quantity: 1,
  unit_price: '14.00',
  image_uri: null,
  price: {
    id: 'da1e6466-d19a-49dd-9a38-5b68c444f383',
    price: '14.00',
    price_per_meal: null,
    price_per_day: null,
    price_per_week: null,
    discounted_price_per_pack: null,
    discounted_price_per_meal: null,
    discounted_price_per_day: null,
    discounted_price_per_week: null,
    pricing_model: {
      id: 'd9234380-b13c-418d-b59e-7c1902b63a0b',
      created: '2022-12-13T13:33:36.717507-05:00',
      last_updated: '2022-12-13T13:33:36.717516-05:00',
      name: 'v2',
      status: PricingModelStatus.ACTIVE,
      is_default: true,
    },
    created: '2022-12-13T13:33:38.263362-05:00',
    last_updated: '2022-12-13T13:33:38.263378-05:00',
    discounted_price: '14.00',
    frequency: 2,
  },
  type: OrderProductType.SUBSCRIPTION,
  product: 'f8eaba54-3b31-4ef1-a0a3-42a4dc8ed658',
  recipes: [
    {
      id: 'SN-CHICKENBITES',
      seq_no: 1,
      name: 'Chicken Munchies',
      description:
        'Meet Chicken Munchies: 100% chicken breast snacks, freeze dried for the perfect crunch. Our dog snacks are grain-free, gluten free fan favorites!',
      aafco_statement: '',
      type: RecipeType.SNACK,
    },
  ],
  product_type: ProductType.SNACK,
  pet_plan: {
    id: '197797fe-e19f-46b9-b82a-7bb48145fe35',
    pet_name: 'Spot',
    pet_id: '36430520-e27c-4904-bac4-fd84c5374812',
    plan_size_code: '150',
    recipes: ['U-B'],
    start_date: '2023-01-24',
    status: PetPlanStatus.ACTIVE,
  },
};

export const totebagOrderProductMock: OrderProduct = {
  id: '1',
  name: 'Promo Tote Bag',
  code: 'TOTEBAG',
  quantity: 1,
  unit_price: '7.00',
  image_uri: null,
  price: {
    id: 'da1e6466-d19a-49dd-9a38-5b68c444f383',
    price: '7.00',
    price_per_meal: null,
    price_per_day: null,
    price_per_week: null,
    discounted_price_per_pack: null,
    discounted_price_per_meal: null,
    discounted_price_per_day: null,
    discounted_price_per_week: null,
    pricing_model: {
      id: 'd9234380-b13c-418d-b59e-7c1902b63a0b',
      created: '2022-12-13T13:33:36.717507-05:00',
      last_updated: '2022-12-13T13:33:36.717516-05:00',
      name: 'v2',
      status: PricingModelStatus.ACTIVE,
      is_default: true,
    },
    created: '2022-12-13T13:33:38.263362-05:00',
    last_updated: '2022-12-13T13:33:38.263378-05:00',
    discounted_price: '7.00',
    frequency: 2,
  },
  type: OrderProductType.ONETIME,
  product: 'f8eaba54-3b31-4ef1-a0a3-42a4dc8ed658',
  recipes: [
    {
      id: 'MT-TOTE',
      seq_no: 1,
      name: 'Promo Tote Bag',
      description: 'bag!',
      aafco_statement: '',
      type: RecipeType.MATERIAL,
    },
  ],
  product_type: ProductType.MATERIAL,
  pet_plan: {
    id: '197797fe-e19f-46b9-b82a-7bb48145fe35',
    pet_name: 'Spot',
    pet_id: '36430520-e27c-4904-bac4-fd84c5374812',
    plan_size_code: '150',
    recipes: ['U-B'],
    start_date: '2023-01-24',
    status: PetPlanStatus.ACTIVE,
  },
};
