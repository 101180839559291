import { HStack, Text } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { useEffect, useRef, useState } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { GroupBase, SelectInstance } from 'react-select';

import { Select, SelectProps } from './Select';

type SelectRef = React.MutableRefObject<SelectInstance<
  unknown,
  boolean,
  GroupBase<unknown>
> | null>;
interface SelectFloatLabelProps extends SelectProps {
  innerRef?: SelectRef;
  _stackProps?: IHStackProps;
}

export const SelectFloatLabel = ({
  placeholder,
  innerRef,
  _stackProps,
  ...props
}: SelectFloatLabelProps) => {
  const [labelPosition, setLabelPosition] = useState<'up' | 'down'>('down');

  const internalRef = useRef() as SelectRef;
  const selectRef = innerRef || internalRef;

  const textOffset = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: textOffset.value }],
    };
  });

  const slideUp = () => {
    textOffset.value = withTiming(-21, {
      duration: 100,
      easing: Easing.out(Easing.exp),
    });
    setLabelPosition('up');
  };

  const slideDown = () => {
    textOffset.value = withTiming(0, {
      duration: 150,
      easing: Easing.out(Easing.exp),
    });
    setLabelPosition('down');
  };

  useEffect(() => {
    if (labelPosition === 'down' && selectRef.current?.state.selectValue?.length) slideUp();
  }, [selectRef.current?.state.selectValue]);

  return (
    <HStack w="100%" {..._stackProps}>
      <Select
        {...props}
        placeholder={labelPosition === 'down' ? '' : undefined} // hides "Select..." while label is over it
        innerRef={selectRef}
        alignment="left"
        onFocus={(e) => {
          if (labelPosition === 'down') {
            slideUp();
          }
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          if (labelPosition === 'up' && !selectRef.current?.state.selectValue?.length) {
            slideDown();
          }
          props.onBlur?.(e);
        }}
      />
      {placeholder ? (
        <Animated.View
          pointerEvents="none"
          style={[
            {
              position: 'absolute',
              left: '16px',
              backgroundColor: 'white',
              padding: '4px',
              top: 12,
            },
            animatedStyles,
          ]}
        >
          <Text
            size={labelPosition === 'up' ? 'captionToBodySm' : 'bodySmToMd'}
            color={labelPosition === 'up' ? 'black' : 'sntGrey.primary'}
            fontWeight={500}
          >
            {placeholder}
          </Text>
        </Animated.View>
      ) : null}
    </HStack>
  );
};
