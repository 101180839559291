import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { Account } from './api';
import { API_URL, HAS_DATADOG } from './config';

const DD_SERVICE = 'native-apps';

const isDatadogEnabled = () => {
  if (HAS_DATADOG === undefined) {
    return false;
  }
  return HAS_DATADOG === 'true';
};

export const initDatadog = () => {
  if (!isDatadogEnabled()) {
    return;
  }
  datadogLogs.init({
    clientToken: 'pub64c33a3d4a33e41bd82ab2d86437d459',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: DD_SERVICE,
    env: process.env.ENVIRONMENT,
    version: 'v1',
  });

  datadogLogs.setGlobalContextProperty('fe_gh_commit_hash', process.env.FE_GH_COMMIT_HASH);
  datadogLogs.setGlobalContextProperty('fe_build_date', process.env.FE_BUILD_DATE);

  datadogRum.init({
    applicationId: '6296ca09-cf79-4814-9cd6-1d389d7cff7f',
    clientToken: 'pub3ac62f34bcb7fd735d0c48a2bffe836b',
    site: 'datadoghq.com',
    service: DD_SERVICE,
    env: process.env.ENVIRONMENT,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 80,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: API_URL ? [API_URL] : undefined,
    version: 'v1',
  });
};

export const startDatadogRecording = () => {
  if (!isDatadogEnabled()) {
    return;
  }
  try {
    datadogRum.startSessionReplayRecording();
  } catch (e) {
    console.error('Error starting Datadog Session Replay Recording', e);
  }
};

export const identifyDatadogFunnelUser = (ddUserData: {
  name?: string;
  email?: string;
  session_id?: string;
}) => {
  datadogLogs.setUser(ddUserData);
  datadogRum.setUser(ddUserData);
};

export const updateDatadogFunnelUser = (key: string, property: any) => {
  datadogLogs.setUserProperty(key, property);
  datadogRum.setUserProperty(key, property);
};

export const identifyDatadogPortalUser = (user: Account) => {
  if (user.email) {
    const ddUserData = {
      id: user.id,
      name: [user.first_name, user.last_name].filter(Boolean).join(' '),
      email: user.email,
    };
    datadogLogs.setUser(ddUserData);
    datadogRum.setUser(ddUserData);
  }
};
