import { useFocusEffect } from '@react-navigation/native';
import dayjs from 'dayjs';
import { Button, HStack, Radio, ScrollView, Stack, Text, VStack } from 'native-base';
import { useCallback, useMemo, useState } from 'react';
import { Pressable } from 'react-native';

import { RetentionDiscountModal } from '..';
import { AdjustPortionSizeModal, SuggestionMode } from '../AdjustPortionSizeModal';
import {
  useAnswerSurveyAndCancelPlan,
  useInterventionEligibility,
  usePetsNextOrder,
} from '../hooks';
import { StopPlanSuccessModal } from './StopPlanSuccessModal';
import { Intervention } from './types';

import { DiscountExclusiveType, useUpdatePlanDates } from '@/api';
import { Image, ToastType, displayToast } from '@/components/Elements';
import { DogRescheduleModal } from '@/components/Portal/Orders/RescheduleDelivery';
import { ASSETS_URL } from '@/constants';
import { useAccount } from '@/hooks';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';
import { toIsoDate } from '@/utils';
import { addDays } from '@/utils/calcShippingDates';

export const SnoozePlan = ({ navigation, route }: ProtectedScreenProps<'SnoozePlan'>) => {
  useFocusEffect(
    useCallback(() => {
      if (route?.params?.title) {
        navigation.setOptions({ title: route?.params?.title });
      }
    }, [route?.params?.title, navigation])
  );

  const account = useAccount();
  const { isLoading: isSubmittingCancel, answerSurveyAndCancelPlan } =
    useAnswerSurveyAndCancelPlan();
  const { mutateAsync: updatePlanDates, isLoading: isLoadingUpdatePlanDate } = useUpdatePlanDates();
  const { pet, nextOrderDate, petPlanIdsInNextOrder, nextOrderIsMultiDog } = usePetsNextOrder(
    route.params.petId
  );
  const [retentionDiscountModalOpen, setRetentionDiscountModalOpen] = useState(false);
  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);
  const [adjustPortionSizeModalOpen, setAdjustPortionSizeModalOpen] = useState(false);
  const [isDogModalOpen, setIsDogModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>('2');

  const { interventions, isLoading: isLoadingInterventionEligiblity } = useInterventionEligibility(
    pet?.id
  );

  const today = useMemo(() => new Date(), []);
  const SNOOZE_OPTIONS_WEEKS = [2, 4, 6];

  const navigateTo = (screen: 'Orders' | 'Account' = 'Account') => {
    setStopPlanSuccessModalOpen(false);
    setRetentionDiscountModalOpen(false);
    setIsDogModalOpen(false);
    const params = screen === 'Orders' ? { petId: pet?.id } : undefined;
    navigation.navigate(screen as any, params);
  };

  const doSnooze = async (petIds: string[]) => {
    const pets = account.pets.filter((pet) => petIds.includes(pet.id));
    const petName = petIds.length > 1 ? "Your dogs'" : `${pets[0]?.name}'s`;
    const petPlanIds = pets.map((pet) => pet.pet_plan.id);

    if (!account || !petPlanIds || petPlanIds.length === 0 || !selectedValue) return;

    const previousOrderDate = nextOrderDate ? toIsoDate(new Date(nextOrderDate)) : undefined;
    const selectedDate = toIsoDate(addDays(today, Number(selectedValue) * 7));

    await updatePlanDates({ petPlanIds, date: selectedDate });

    segment.trackEvent('Cancellation Flow - Snooze Plan', {
      account_id: account.id,
      email: account.email,
      cancellation_reason: route.params?.primaryAnswer,
      cancellation_reason_secondary: route.params?.secondaryAnswer,
      cancellation_reason_comment: route.params?.customerComment,
      pet_id: petIds,
      previous_order_date: previousOrderDate,
      snooze_duration_days: Number(selectedValue) * 7,
    });
    displayToast({
      message: `${petName} order date has been rescheduled.`,
      type: ToastType.Success,
    });
    navigateTo('Orders');
  };

  const onPressSnooze = async () => {
    if (!pet?.pet_plan?.id || !selectedValue) return;

    if (nextOrderIsMultiDog) {
      setIsDogModalOpen(true);
    } else {
      await doSnooze([pet.id]);
    }
  };

  const onPressCancel = async () => {
    if (
      route?.params?.withAdjustPortionModal === true &&
      interventions.includes(Intervention.AdjustPortionSizeDown)
    ) {
      setAdjustPortionSizeModalOpen(true);
    } else if (interventions.includes(Intervention.TenPercentDiscount)) {
      setRetentionDiscountModalOpen(true);
    } else {
      doCancelPlan();
    }
  };

  const doCancelPlan = async () => {
    if (!pet) {
      return;
    }
    await answerSurveyAndCancelPlan({
      pet,
      primaryAnswer: route.params.primaryAnswer,
      secondaryAnswer: route.params.secondaryAnswer,
      customerComment: route.params.customerComment,
    });

    setRetentionDiscountModalOpen(false);
    setIsDogModalOpen(false);
    setStopPlanSuccessModalOpen(true);
  };

  if (!pet) {
    return null;
  }

  return (
    <Stack alignItems="center" w="100%" h="100%">
      <ScrollView style={{ width: '100%' }}>
        <Stack alignItems="center" w="100%" h="100%" px={4} space={{ base: 6, md: 12 }} flex={1}>
          <Text fontFamily="secondary" size="bodySmToMd" textAlign="center" maxW={{ md: '1000px' }}>
            {route?.params?.description ??
              'We want you to be in complete control of your orders. Ready to take a breather? Snooze your subscription and take the time you need.'}
          </Text>
          <Stack
            borderWidth={{ base: '1px', md: '2px' }}
            borderColor="black"
            w="100%"
            h="fit-content"
            p={{ base: 4, md: 6 }}
            maxW={{ md: '492px' }}
            space={4}
            marginBottom={{ base: '142px', md: '160px' }}
          >
            <Stack
              w="100%"
              position="relative"
              alignSelf="center"
              justifyContent="center"
              borderWidth={{ base: '1px', md: '2px' }}
              borderColor="black"
            >
              <Image
                source={{
                  uri: `${ASSETS_URL}/pictos/CancellationIntervention/snooze.png`,
                }}
                w="492px"
                h={{ base: '337px', md: '400px' }}
                resizeMode="contain"
              />
            </Stack>

            <VStack w="100%">
              <Radio.Group
                m={0}
                w="100%"
                name="selectedValue"
                accessibilityLabel="Survey answer"
                value={selectedValue}
                onChange={(value) => setSelectedValue(value)}
              >
                {SNOOZE_OPTIONS_WEEKS.map((numWeeks, index) => {
                  const isSelected = selectedValue === numWeeks.toString();
                  const selectedStyle = isSelected
                    ? {
                        bg: 'sntBlue.primary20',
                        borderWidth: { base: '2px', md: '3px' },
                        borderColor: 'black',
                      }
                    : undefined;
                  return (
                    <Pressable
                      key={`${numWeeks}Weeks`}
                      style={{ width: '100%' }}
                      onPress={() => setSelectedValue(numWeeks.toString())}
                    >
                      <HStack
                        borderWidth="1px"
                        borderColor="sntGrey.outline"
                        alignItems="start"
                        w="100%"
                        mt={index > 0 ? 4 : undefined}
                        p={4}
                        {...selectedStyle}
                      >
                        <Radio value={numWeeks.toString()} />
                        <Stack space={2} ml={2}>
                          <Text
                            size="bodyMdToLg"
                            fontWeight="bold"
                          >{`Snooze for ${numWeeks} Weeks`}</Text>
                          <Text size="bodySmToMd" fontFamily="secondary">{`Next Order Date: ${dayjs(
                            addDays(today, numWeeks * 7)
                          )
                            .local()
                            .format('MMMM D')}`}</Text>
                        </Stack>
                      </HStack>
                    </Pressable>
                  );
                })}
              </Radio.Group>
            </VStack>
          </Stack>
        </Stack>
      </ScrollView>
      <Stack
        justifyContent="center"
        alignItems="center"
        space={6}
        w="100%"
        bg="white"
        style={{
          shadowColor: 'rgba(0,0,0,0.15)',
          shadowOffset: { width: -4, height: -4 },
          shadowRadius: 4,
        }}
        px={4}
        py={6}
        position="fixed"
        bottom={0}
      >
        <Button
          variant="hugPrimary"
          w="100%"
          maxW={{ md: '290px' }}
          onPress={onPressSnooze}
          isLoading={isLoadingUpdatePlanDate}
          isDisabled={isSubmittingCancel || isLoadingInterventionEligiblity || !selectedValue}
        >
          SNOOZE MY SUBSCRIPTION
        </Button>
        <Button
          variant="underlineMini"
          onPress={onPressCancel}
          isDisabled={
            isSubmittingCancel || isLoadingInterventionEligiblity || isLoadingUpdatePlanDate
          }
        >
          Continue to cancellation
        </Button>
      </Stack>
      <RetentionDiscountModal
        isOpen={retentionDiscountModalOpen}
        onClose={() => setRetentionDiscountModalOpen(false)}
        petId={pet.id}
        onSecondaryPress={doCancelPlan}
        discountType={DiscountExclusiveType.WINBACK_10_PERCENT}
        cancellationReason={route?.params?.primaryAnswer}
        cancellationReasonSecondary={route?.params?.secondaryAnswer}
        cancellationReasonComment={route?.params?.customerComment}
      />
      {pet ? (
        <AdjustPortionSizeModal
          isOpen={adjustPortionSizeModalOpen}
          petId={pet.id}
          petName={pet.name}
          mode={SuggestionMode.DECREASE}
          onClose={() => {
            setAdjustPortionSizeModalOpen(false);
            if (interventions.includes(Intervention.TenPercentDiscount)) {
              setRetentionDiscountModalOpen(true);
            } else {
              doCancelPlan();
            }
          }}
          onPressX={() => setAdjustPortionSizeModalOpen(false)}
          cancellationReason={route?.params?.primaryAnswer}
          cancellationReasonSecondary={route?.params?.secondaryAnswer}
          cancellationReasonComment={route?.params?.customerComment}
        />
      ) : null}
      {selectedValue && pet && petPlanIdsInNextOrder ? (
        <DogRescheduleModal
          heading="You have other dogs on this order. Would you like to snooze their plans as well?"
          description={null}
          showDateEstimateFooter={false}
          initialPetPlanIds={petPlanIdsInNextOrder}
          isOpen={isDogModalOpen}
          onConfirm={doSnooze}
          isConfirmButtonLoading={isLoadingUpdatePlanDate}
          isBackButtonDisabled={isLoadingUpdatePlanDate}
          chargeDate={new Date(selectedValue)}
          onClose={() => setIsDogModalOpen(false)}
          modalContentProps={{ w: '100%', maxW: '440px' }}
        />
      ) : null}
      <StopPlanSuccessModal
        isOpen={stopPlanSuccessModalOpen}
        onPressX={() => navigateTo('Account')}
        onConfirm={() => navigateTo('Account')}
      />
    </Stack>
  );
};
