import dayjs from 'dayjs';
import { Text } from 'native-base';

import { OrderStatus } from '@/api';
import { EditableCard } from '@/components/Elements';
import { API_URL } from '@/config';
import { useAccount } from '@/hooks';
import { sortOrderByCharged } from '@/utils';

export const FeedingGuidelinesCard = ({ petId }: { petId: string }) => {
  const account = useAccount();
  const pet = account.pets.find((pet) => petId === pet.id);

  if (!pet) {
    return null;
  }

  const petPlan = account.petplans.find(({ pet: petPlanPetId }) => petId === petPlanPetId);
  const openFeedingGuidelines = () => {
    window.open(`${API_URL}/feeding_guidelines?pet_id=${petId}`, '_blank');
  };

  const latestOrder = [...account.orders.processing, ...account.orders.past]
    .filter(
      (order) =>
        order.products.some((product) => product.pet_plan && product.pet_plan.id === petPlan?.id) &&
        ![
          OrderStatus.CANCELLED,
          OrderStatus.MAX_RETRY_REACHED,
          OrderStatus.HOLD,
          OrderStatus.ERROR,
        ].includes(order.status)
    )
    .sort((a, b) => sortOrderByCharged(a, b, 'desc'))[0];

  return (
    <EditableCard
      heading="Feeding Guide"
      onPress={openFeedingGuidelines}
      stackProps={{ direction: 'column', space: { base: 2, lg: 4 } }}
    >
      {latestOrder ? (
        <Text size="bodyMdToLg">
          {`For ${pet.name}'s order on ${dayjs(latestOrder.charged).local().format('MMMM D')}`}
        </Text>
      ) : null}
    </EditableCard>
  );
};
