import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 17"
    fill="currentColor"
    {...props}
  >
    <Path d="M11.917 16.331a1.5 1.5 0 1 0 .167-2.995h-.002a2.404 2.404 0 0 1-.17-.028 8.826 8.826 0 0 1-.467-.106 22.735 22.735 0 0 1-1.418-.414 29.73 29.73 0 0 1-3.6-1.423c-.73-.35-1.361-.706-1.856-1.046l17.464-.4a1.5 1.5 0 0 0-.07-3L4.79 7.313c.131-.254.255-.502.372-.737.153-.308.294-.592.427-.838.577-1.071 1.135-1.796 2.003-2.229A1.5 1.5 0 0 0 6.252.825c-1.702.849-2.63 2.24-3.304 3.489-.235.436-.432.834-.616 1.202-.388.783-.713 1.436-1.201 2.062-.528.677-.744 1.485-.574 2.317.153.756.592 1.37 1.037 1.835.884.925 2.233 1.714 3.532 2.339a32.723 32.723 0 0 0 3.977 1.573c.601.195 1.153.354 1.611.47.422.105.866.2 1.203.22Z" />
  </Svg>
);
function SvgLeftArrow({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgLeftArrow;
