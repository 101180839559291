import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 12 14"
    {...props}
  >
    <Path
      fill={undefined}
      d="M1.5 12.25a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-9h-9v9ZM3 4.75h6v7.5H3v-7.5ZM8.625 1l-.75-.75h-3.75l-.75.75H.75v1.5h10.5V1H8.625Z"
    />
  </Svg>
);
function SvgDelete({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgDelete;
