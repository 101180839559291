import { useBreakpointValue } from 'native-base';

/**
 * Returns if the user is on a device that is under the `lg` breakpoint
 */
export const useIsMobile = (): boolean =>
  useBreakpointValue({
    base: true,
    lg: false,
  });
