import { HStack, Heading, IconButton, InfoOutlineIcon, Link, Stack, Text } from 'native-base';
import { Control, Controller } from 'react-hook-form';

import { NotificationPreferencesFieldValues } from '../NotificationPreferences';

import { Checkbox, LoadingSpinner, Tooltip } from '@/components/Elements';

interface NotificationPreferencesBlockProps {
  isLoading?: boolean;
  errorLoading?: boolean;
  accountHasPhone: boolean;
  emailEnabled: boolean;
  control: Control<NotificationPreferencesFieldValues, any>;
  controllerType: string;
  heading: string;
  text: string;
}

type controllerName = 'news_offers_email' | 'news_offers_text';

export const NotificationPreferencesBlock = ({
  isLoading,
  errorLoading,
  accountHasPhone,
  emailEnabled,
  control,
  controllerType,
  heading,
  text,
}: NotificationPreferencesBlockProps) => {
  const emailController = emailEnabled ? (
    <Controller
      control={control}
      name={`${controllerType}_email` as controllerName}
      render={({ field: { onChange, value } }) => (
        <Checkbox value="" isChecked={value} onChange={onChange}>
          Email
        </Checkbox>
      )}
    />
  ) : (
    <Checkbox
      value=""
      isChecked
      isDisabled
      _disabled={{
        bg: 'transparent',
        _text: { color: 'sntGrey.primary' },
      }}
      _hover={{ bg: 'transparent', borderColor: 'sntGrey.primary' }}
    >
      Email
    </Checkbox>
  );

  return (
    <Stack variant="card" space={{ base: '8px', md: '16px' }}>
      <Heading size="bodyMlToTitleSm" w="100%" fontWeight="bold" textAlign="start">
        {heading}
      </Heading>
      <Stack space={{ base: '8px', md: '16px' }}>
        <Text size="bodyMdToLg" color="grey.dark">
          {text}
        </Text>
        {isLoading ? (
          <Stack alignItems="flex-start">
            <LoadingSpinner
              accessLabel="Marketing Preferences"
              spinnerSize={{ base: 18, lg: 20 }}
              alignSelf="flex-start"
            />
          </Stack>
        ) : errorLoading ? (
          <Text color="error.default" size="bodySmToMd">
            Error loading marketing preferences. Please try again later.
          </Text>
        ) : (
          <Stack direction="row" alignItems="center" space={{ base: '16px', md: '24px' }}>
            {emailController}
            {controllerType === 'news_offers' ? (
              <HStack alignItems="center" space={2}>
                <Controller
                  control={control}
                  name={`${controllerType}_text` as controllerName}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      value=""
                      isChecked={value}
                      onChange={onChange}
                      isDisabled={!accountHasPhone}
                    >
                      Text
                    </Checkbox>
                  )}
                />
                <Tooltip
                  label={
                    <Text size="bodySmToMd" fontWeight="medium">
                      By checking this box, you agree to receive recurring automated promotional and
                      personalized marketing messages by call or text at this number from Spot &
                      Tango. Consent is not a condition of any purchase. Reply HELP for help and
                      STOP to cancel. Msg & data rates may apply. View{' '}
                      <Link
                        color="black"
                        href="https://spotandtango.com/terms-of-use"
                        textDecoration="underline"
                        isExternal
                      >
                        Company Terms
                      </Link>
                      ,{' '}
                      <Link
                        color="black"
                        href="https://breuer-premium-pet-food.myklpages.com/p/mobile-terms-of-service"
                        textDecoration="underline"
                        isExternal
                      >
                        Mobile Terms
                      </Link>{' '}
                      &{' '}
                      <Link
                        color="black"
                        href="https://spotandtango.com/privacy-policy"
                        textDecoration="underline"
                        isExternal
                      >
                        Privacy
                      </Link>
                      .
                    </Text>
                  }
                  placement="bottom"
                  closeDelay={2000}
                  w="100%"
                  maxW={{ base: '296px', md: '494px' }}
                >
                  <IconButton
                    p={0}
                    icon={<InfoOutlineIcon color="black" size={{ base: '18px', md: '24px' }} />}
                  />
                </Tooltip>
              </HStack>
            ) : null}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
