import { NavigationProp, useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import { Heading, Stack, Text, HStack, VStack, Pressable, IStackProps } from 'native-base';

import { Order, OrderStatus, Pet, PetStatus } from '@/api/types/accountServices';
import { Button, Avatar } from '@/components/Elements';
import { TrackingLinks } from '@/components/Portal/Orders/TrackingLinks';
import { useIsMobile } from '@/hooks';
import { ProtectedStackParamList } from '@/types';
import { getEstimatedArrivalRangeString, getModifyByDate, getOrderPets } from '@/utils';

export interface UpcomingOrderCardProps extends IStackProps {
  order: Order;
  pets: Pet[];
}

const AVATAR_DISABLE_STATUSES = [PetStatus.DECEASED, PetStatus.DECEASED_TO_NOT_LIST];

export const UpcomingOrderCard = ({ order, pets, ...props }: UpcomingOrderCardProps) => {
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  let orderPets = getOrderPets(pets, order);
  const orderShipments = order.fulfillments.flatMap((fulfillment) => fulfillment.shipments);
  const isTrackingNumber = orderShipments.some((shipment) => !!shipment.tracking_number);
  const orderDate = order.scheduled ?? order.charged;

  const isMobile = useIsMobile();

  const isMobileAndMoreThenThreePets = isMobile && orderPets.length > 3;
  const orderPetsCount = orderPets.length;

  if (isMobileAndMoreThenThreePets) {
    orderPets = orderPets.slice(0, 2);
  }

  let orderStatusLabel = '';
  if (order.status === OrderStatus.COMPLETE) {
    orderStatusLabel = 'Delivered';
  } else if ([OrderStatus.FULFILLED, OrderStatus.PARTIALLY_FULFILLED].includes(order.status)) {
    orderStatusLabel = 'Shipped';
  } else if (order.status === OrderStatus.PREVIEW) {
    orderStatusLabel = `Edit until ${getModifyByDate(orderDate)}, 5pm ET`;
  } else if (order.status !== OrderStatus.HOLD) {
    orderStatusLabel = 'Preparing order';
  }

  let orderProgressLabel = '';
  let orderProgressColor = 'black';
  if (order.status === OrderStatus.HOLD) {
    orderProgressLabel = 'Payment error';
    orderProgressColor = 'error.default';
  } else if (order.status === OrderStatus.PREVIEW) {
    orderProgressLabel = `Est Arrival ${getEstimatedArrivalRangeString(orderDate)}`;
  } else {
    orderProgressLabel = 'Tracking Available Soon';
  }

  return (
    <Stack
      borderColor="sntGrey.outline"
      borderWidth="1px"
      p={{ base: 4, lg: 6 }}
      justifyContent="center"
      w="100%"
      {...props}
    >
      <VStack w="100%" h="100%" justifyContent="center" space={{ base: '8px', lg: '16px' }}>
        <HStack justifyContent="space-between" w="100%">
          <Heading size="bodyMlToLg" fontWeight="bold">
            {dayjs(orderDate).local().format('MMM D')}
          </Heading>
          <Button
            variant="underlineMini"
            size="bodySmToMd"
            onPress={() =>
              navigation.navigate('Orders', {
                orderId: order.id,
              })
            }
          >
            View Details
          </Button>
        </HStack>
        <HStack justifyContent="space-between" w="100%">
          {isTrackingNumber ? (
            <TrackingLinks
              shipments={order.fulfillments.flatMap((fulfillment) => fulfillment.shipments)}
            />
          ) : (
            <Text
              size="bodySmToMd"
              fontFamily="secondary"
              fontWeight="bold"
              color={orderProgressColor}
            >
              {orderProgressLabel}
            </Text>
          )}
        </HStack>

        <HStack justifyContent="space-between" w="100%">
          <Stack direction="row" w="fit-content" space={{ base: 5, lg: 7 }} mr={{ base: 2, lg: 0 }}>
            {orderPets.map((pet, i) => (
              <Pressable
                onPress={() =>
                  navigation.navigate('Dogs', {
                    petId: pet.id,
                    scrollToTop: true,
                  })
                }
                key={i}
                disabled={AVATAR_DISABLE_STATUSES.includes(pet.status)}
              >
                <Avatar
                  nameProps={{
                    size: { base: 'bodyMd', md: 'bodyLg' },
                    fontWeight: 'bold',
                  }}
                  imageSize={{ base: 16, lg: '84px' }}
                  name={pet.name}
                  petId={pet.id}
                  petGender={pet.gender}
                />
              </Pressable>
            ))}
            {isMobileAndMoreThenThreePets && (
              <Avatar
                nameProps={{
                  size: { base: 'bodyMd', md: 'bodyLg' },
                  fontWeight: 'bold',
                }}
                imageSize={{ base: 16, lg: '84px' }}
                noImgVariant="digit"
                petsCount={orderPetsCount - 2}
                name={' '}
              />
            )}
          </Stack>
          <Text
            size="bodySmToMd"
            alignSelf="flex-end"
            fontWeight="book"
            fontFamily="secondary"
            color="sntGrey.primary"
          >
            {orderStatusLabel}
          </Text>
        </HStack>
      </VStack>
    </Stack>
  );
};
