import {
  Flex,
  HStack,
  Heading,
  IButtonProps,
  IHeadingProps,
  IStackProps,
  ITextProps,
  IFlexProps,
  Stack,
  Text,
} from 'native-base';
import * as React from 'react';

import { SocialIconButton } from '../IconButton';

import { Image, Button } from '@/components/Elements';
import { socials } from '@/constants';

export interface MarketingCardProps extends Omit<IStackProps, 'children'> {
  source: string;
  header: IHeadingProps['children'];
  content: ITextProps['children'];
  variant: 'cta' | 'social';
  ctaText?: IButtonProps['children'];
  ctaOnPress?: (args?: any) => void;
  contentTextProps?: ITextProps;
  imageProps?: IFlexProps;
  buttonProps?: IButtonProps;
}

export const MarketingCard = ({
  source,
  header,
  content,
  variant = 'social',
  ctaText,
  ctaOnPress,
  contentTextProps,
  imageProps = {},
  buttonProps = {},
  ...props
}: MarketingCardProps) => {
  return (
    <Stack
      alignItems="center"
      bgColor="white"
      borderColor="black"
      borderWidth="2px"
      p={{ base: 4, md: 6 }}
      space={4}
      {...props}
    >
      <Stack space="8px" w="100%" justifyContent="center" alignItems="center">
        {header ? (
          <Heading size="bodyMlToTitleSm" fontWeight="bold">
            {header}
          </Heading>
        ) : null}
        {content ? (
          <Text
            fontFamily="secondary"
            fontWeight="medium"
            size="bodySmToMd"
            textAlign="center"
            {...contentTextProps}
          >
            {content}
          </Text>
        ) : null}
      </Stack>
      <Flex
        w="100%"
        h="337px"
        overflow="hidden"
        justifyContent="center"
        alignItems="center"
        {...imageProps}
      >
        <Image alt="" w="100%" h="100%" source={{ uri: `${source}` }} resizeMode="cover" />
      </Flex>
      {variant === 'cta' ? (
        <Button variant="hugSecondary" onPress={ctaOnPress} size="bodyMl" {...buttonProps}>
          {ctaText}
        </Button>
      ) : (
        <HStack py="12px" space={{ base: '16px', lg: '24px' }}>
          {socials.map((site, index) => (
            <SocialIconButton key={index} imgSrc={site.src} alt={site.alt} url={site.url} />
          ))}
        </HStack>
      )}
    </Stack>
  );
};
