import dayjs from 'dayjs';

const ESTIMATED_DELIVERY_START_DELTA = 3;
const ESTIMATED_DELIVERY_MIDDLE_DELTA = 4;
const ESTIMATED_DELIVERY_END_DELTA = 5;

/** @description FireFox issue: if the date is in a format other than 'YYYY-MM-DD' function will return Invalid Date */
export const getEstimatedArrivalDate = (date: string, formatString?: string) => {
  if (!dayjs(date, 'YYYY-MM-DD', true).isValid()) {
    throw new Error('Validation error: date string format not parseable by dayjs');
  }
  // 3 days in future, whether they're weekdays or weekends
  return dayjs(date)
    .add(ESTIMATED_DELIVERY_START_DELTA, 'day')
    .local()
    .format(formatString || 'MMMM D');
};

/** @description FireFox issue: date format must be 'YYYY-MM-DD' or getEstimatedArrivalRange return Error */
export const getEstimatedArrivalRange = (date: string | Date) => {
  if (!dayjs(date, 'YYYY-MM-DD', true).isValid()) {
    throw new Error('Validation error: date string format not parseable by dayjs');
  }
  const deliverStartDay = dayjs(date).add(ESTIMATED_DELIVERY_START_DELTA, 'day').local();
  const deliverMiddleDay = dayjs(date).add(ESTIMATED_DELIVERY_MIDDLE_DELTA, 'day').local();
  const deliverEndDay = dayjs(date).add(ESTIMATED_DELIVERY_END_DELTA, 'day').local();
  return {
    deliverStartDay,
    deliverMiddleDay,
    deliverEndDay,
  };
};

/** @description FireFox issue: date format must be 'YYYY-MM-DD' or getEstimatedArrivalRange return Error */
export const getEstimatedArrivalRangeString = (date: string | Date) => {
  const { deliverStartDay, deliverEndDay } = getEstimatedArrivalRange(date);
  const deliverStartMonth = deliverStartDay.format('MMM');
  const deliverEndMonth = deliverEndDay.format('MMM');

  if (deliverStartMonth === deliverEndMonth) {
    return `${deliverStartDay.format('MMM D')}-${deliverEndDay.format('D')}`;
  }
  return `${deliverStartDay.format('MMM D')}-${deliverEndDay.format('MMM D')}`;
};

export const getModifyByDate = (date: string, formatString?: string) => {
  if (!dayjs(date, 'YYYY-MM-DD', true).isValid()) {
    throw new Error('Validation error: date string format not parseable by dayjs');
  }
  // 3 days in future, whether they're weekdays or weekends
  return dayjs(date)
    .subtract(1, 'day')
    .local()
    .format(formatString || 'MMMM D, YYYY');
};

export const addDays = (startDate: Date | string, num: number) => {
  const result = new Date(startDate);
  result.setDate(result.getDate() + num);
  return result;
};
