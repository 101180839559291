import { Stack } from 'native-base';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';

export const Logo = () => {
  return (
    <Stack
      position="absolute"
      left={0}
      right={{ base: 'auto', lg: 0 }}
      top={3}
      justifyContent="center"
      alignItems={{ base: 'flex-start', lg: 'center' }}
      flex={{ base: 1, lg: 2 }}
      zIndex={-1}
    >
      <Image
        width={{ base: '175px', lg: '275px' }}
        height={{ base: '40px', lg: '50px' }}
        source={{ uri: `${ASSETS_URL}/logo.svg` }}
        alt="Text Logo: Spot & Tango"
        resizeMode="contain"
      />
    </Stack>
  );
};
