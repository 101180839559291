import { Pressable, Radio, Box, Text, Stack, HStack, VStack } from 'native-base';
import { useState } from 'react';

import { PortalPopUp } from '../PortalPopUp';

import { InfoCta, Subscribe } from '@/components/Icons';

interface RadioButtonCardProps {
  title: string;
  subtitle?: string;
  price: string;
  retailPrice: string;
  cancelLabel?: string;
  onCardPress?: () => void;
  isSubscriptionCard: boolean;
  selected: boolean;
}

const RadioButtonCard = ({
  title,
  subtitle,
  price,
  retailPrice,
  cancelLabel,
  onCardPress,
  isSubscriptionCard,
  selected,
}: RadioButtonCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleInfoCtaClick = () => {
    setIsModalOpen(true);
  };

  const value = isSubscriptionCard ? 'subscribe' : 'oneTime';

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Stack>
      <Pressable
        variant="card"
        w="100%"
        h="fit-content"
        p={4}
        isPressed={selected}
        onPress={onCardPress}
      >
        <VStack mb="8px">
          <HStack mb="5px" alignItems="center">
            {isSubscriptionCard && <Subscribe size="lg" mr="5px" />}
            <Text size="bodyMdToLg" fontWeight="bold">
              {title}
            </Text>
            <Stack ml="auto">
              <Radio.Group
                name="selectedValue"
                accessibilityLabel="Order Type"
                value={selected ? value : ''}
              >
                <Radio value={value} marginRight={0} />
              </Radio.Group>
            </Stack>
          </HStack>
          {isSubscriptionCard && (
            <HStack alignItems="center">
              <Text size="tinyToCaption">{subtitle}</Text>
              <Box>
                <InfoCta color="black" size="xs" onClick={handleInfoCtaClick} />
              </Box>
            </HStack>
          )}
        </VStack>
        <VStack>
          <HStack alignItems="center">
            <Text
              size="bodyMdToLg"
              fontWeight="bold"
              color={isSubscriptionCard ? 'sntSubscriptionPricing' : 'black'}
            >
              {price}
            </Text>
            <Text size="bodyMdToLg" color="sntGrey.primary">
              {' '}
              |{' '}
            </Text>
            <Text size="bodySmToMd" color="sntGrey.primary">
              <Text textDecorationLine="line-through">{retailPrice}</Text> retail price
            </Text>
          </HStack>
          {isSubscriptionCard && (
            <Text size="tinyToCaption" color="sntGrey.primary">
              {cancelLabel}
            </Text>
          )}
        </VStack>
      </Pressable>
      {isModalOpen && (
        <PortalPopUp
          isOpen={isModalOpen}
          onClose={() => closeModal()}
          heading="What is Subscribe & Save?"
          description="The Subscribe & Save option gives you exclusive discounted pricing on your favorite Snacks, Supplements, and more! These products will be delivered with your dog’s recurring meal plan."
          children={null}
          onConfirm={() => closeModal()}
          confirmBtnText="GOT IT!"
          showCloseButton={false}
          size="xl"
        />
      )}
    </Stack>
  );
};

export { RadioButtonCard };
