import { useFocusEffect } from '@react-navigation/native';
import { Heading, Text, VStack, HStack, Progress } from 'native-base';
import { useCallback, useEffect, useState } from 'react';

import { DogFactsGrid } from './DogFactsGrid';
import { useProgressBar } from './useProgressBar';

import { useCallBuildPlansRegularPlan, useCallBuildTopperPlan, useSessionPlans } from '@/api';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useSessionQuery } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { FunnelScreenProps, Dog, FunnelSession } from '@/types';
import { getCurrentPet } from '@/utils/getCurrentPet';

export default function Calculating({ navigation }: FunnelScreenProps<'Calculating'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.CALCULATING);
  const { mutateAsync: callBuildPlansRegularPlan } = useCallBuildPlansRegularPlan();
  const { mutateAsync: callBuildPlansTopperPlan } = useCallBuildTopperPlan();
  const { session: funnelSession } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();

  const [dogs, setDogs] = useState<Dog[]>([]);
  const [dogIndex, setDogIndex] = useState<number>(0);

  const dog = getCurrentPet(funnelSession);
  const discounts = funnelSession?.discounts?.map((discount) => discount.code);

  const {
    query: { data: petData },
  } = useSessionPlans({ pet: dog, discounts, sessionId: funnelSession?.id });
  const { progress } = useProgressBar();
  const progressDisplay = Math.min(Math.floor(progress), 100);

  useEffect(() => {
    if (progress >= 101) {
      navigation.navigate(nextStep);
    }
  }, [progress]);

  const foodType = dog?.food_type === 'FRESH' ? petData?.prices.fresh : petData?.prices.unkibble;

  useEffect(() => {
    try {
      const { pets, current_pet_index } = funnelSession as FunnelSession;

      setDogs(pets as Dog[]);
      setDogIndex(current_pet_index as number);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const preloadPlanData = async () => {
    if (!petData || !foodType) {
      return;
    }
    try {
      await Promise.all([
        callBuildPlansRegularPlan({
          session_id: funnelSession?.id,
          plan_size: petData?.plan_size.id,
          recipes: foodType
            ?.map((unkibbleOptions) => unkibbleOptions.trial?.product?.recipes.map((r) => r.id))
            .flat()
            .filter((recipeID) => dog?.recipes?.some((id) => id === recipeID)),
          discounts,
        }),
        callBuildPlansTopperPlan({
          session_id: funnelSession?.id,
          plan_size: petData?.topper_plan_size.id as number,
          recipes: petData?.prices?.topper?.[0].trial?.product?.recipes.map((r) => r.id),
          discounts,
        }),
      ]);

      return () => {};
    } catch (error) {
      funnelErrorHandler(error, 'Preloading recipes', () => {
        navigation.goBack();
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      preloadPlanData();
    }, [])
  );

  return (
    <FunnelWrapper>
      <VStack w={{ base: '100%', lg: '360px' }}>
        <VStack
          justifyContent="center"
          alignItems="center"
          w="100%"
          alignSelf="center"
          space="16px"
          mb={{ base: '36px', md: '48px' }}
        >
          <Heading size="titleSmToMd" fontWeight="bold">
            Calculating
          </Heading>
          <Heading variant="secondary" size={{ base: 'bodySm', lg: 'bodyMd' }} fontWeight={450}>
            Building {dogs[dogIndex]?.name}&apos;s personal plan...
          </Heading>
        </VStack>

        <Text size="bodyMl" lineHeight="40px" textAlign="center" fontWeight="bold">
          {`${progressDisplay}%`}
        </Text>
        <Progress
          h="12px"
          my={{ base: '24px', md: '36px' }}
          bg="gallery"
          borderRadius="25px"
          _filledTrack={{
            bg: 'sntBlue.primary',
            borderRadius: '25px',
          }}
          value={progress}
        />

        {dogs[dogIndex] ? (
          <HStack px={{ base: '48px', lg: '13px' }} justifyContent="center">
            <DogFactsGrid dog={dogs[dogIndex] as Dog} progress={progress} />
          </HStack>
        ) : null}
      </VStack>
    </FunnelWrapper>
  );
}
