import {
  Button,
  Center,
  Divider,
  FormControl,
  InfoOutlineIcon,
  ScrollView,
  Stack,
  Switch,
  Text,
  Toast,
  Tooltip,
} from 'native-base';
import { useEffect, useState } from 'react';
import { LockClosedIcon } from 'react-native-heroicons/outline';

import { FormSubmitButton } from '../../FormSubmitButton';
import { useConfirmSetPrimaryCardModal } from './ConfirmSetPrimaryCardModal';
import { CreditCardDetailsCard } from './CreditCardDetailsCard';

import {
  OrderStatus,
  useChargeHoldOrders,
  useDeletePaymentMethod,
  usePaymentMethods,
  useSetDefaultPaymentMethod,
} from '@/api';
import {
  EditableCard,
  PortalPopUp,
  displayToast,
  RetryErrorToastMessage,
} from '@/components/Elements';
import { useAuth, useOrdersOnHold } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import { capitalize, paymentFailedErrorHandler } from '@/utils';

export const EditCreditCard = ({
  navigation,
  route: {
    params: { cardId, successRedirect = 'Account', successRedirectParams = {} },
  },
}: ProtectedScreenProps<'EditCreditCard'>) => {
  const { refetchUser } = useAuth();
  const { mutateAsync: modifyDefaultPaymentMethod, isLoading: isSetPrimaryLoading } =
    useSetDefaultPaymentMethod();
  const { mutateAsync: deletePaymentMethod, isLoading: isDeleteLoading } = useDeletePaymentMethod();
  const { mutateAsync: chargeHoldOrders } = useChargeHoldOrders();
  const [isDefault, setIsDefault] = useState(false);
  const [isPaymentRetrying, setIsPaymentRetrying] = useState(false);
  const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false);
  const [title, setTitle] = useState('');
  const { setShowModal: setShowPrimaryModal, Modal: ConfirmSetPrimaryCardModal } =
    useConfirmSetPrimaryCardModal();

  const { data: paymentMethods } = usePaymentMethods();
  const ordersOnHold = useOrdersOnHold();
  const paymentMethod = paymentMethods?.find(({ id }) => id === cardId);

  useEffect(() => {
    if (paymentMethod?.card) {
      const newTitle = `${capitalize(paymentMethod.card.brand)} ${paymentMethod.card.last4}`;
      setTitle(newTitle);
      navigation.setOptions({ title: newTitle });
    }
  }, [navigation, paymentMethod?.card]);

  const onPressToast = () => {
    navigation.navigate('PaymentMethodList');
    Toast.close('payment_retry_error_toast');
  };

  const onRemoveConfirm = async () => {
    if (!paymentMethod) {
      return;
    }
    setShowRemoveConfirmModal(false);
    try {
      await deletePaymentMethod({ paymentMethodId: paymentMethod.id });
      navigation.navigate(successRedirect, successRedirectParams);
      displayToast({
        title: 'Success!',
        message: `${title} has been removed from your payment methods.`,
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (!paymentMethod?.card) {
    return null;
  }

  const onSubmit = async () => {
    setShowPrimaryModal(false);
    if (isDefault) {
      await modifyDefaultPaymentMethod({ paymentMethodId: paymentMethod.id });
      displayToast({
        title: 'Success!',
        message: `Your primary payment method is now ${title}.`,
      });
    }
    if (!ordersOnHold.length) {
      navigation.navigate(successRedirect, successRedirectParams);
      return;
    }
    setIsPaymentRetrying(true);
    try {
      await chargeHoldOrders();
      const refetchUserResponse = await refetchUser();

      if (
        refetchUserResponse.data?.orders?.processing.some(
          (order) => order.status === OrderStatus.HOLD
        )
      ) {
        displayToast({
          title: 'Success!',
          message:
            'Your payment retry was successful. You may temporarily see that your order is still in an "error" state, but rest assured your order was charged and is getting ready to be fulfilled. Please refresh this page in a few minutes to see the updated order status.',
        });
      } else {
        displayToast({
          title: 'Success!',
          message:
            "Your payment retry was successful. We'll get started on your order and send you tracking information as soon as it's officially on its way.",
        });
      }
    } catch (err) {
      paymentFailedErrorHandler(RetryErrorToastMessage(err), onPressToast, true);
    }
    setIsPaymentRetrying(false);
    navigation.navigate('Orders');
  };

  return (
    <Stack alignItems="center" w="100%" h="100%" px="16px" pb="24px">
      <Stack
        w={{ base: '100%', lg: '450px' }}
        h="100%"
        justifyContent="space-between"
        space={{ base: '16px', lg: '24px' }}
      >
        <Center>
          <Text
            variant="helperText"
            fontSize={{ base: 'tiny', lg: 'body.sm' }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LockClosedIcon size="14px" /> All transactions are secure and encrypted through Stripe.
          </Text>
        </Center>
        <EditableCard stackProps={{ flexDirection: 'column' }} chevron={false}>
          <CreditCardDetailsCard stripeCard={paymentMethod?.card} />
        </EditableCard>
        <Divider bg="gallery" />
        <Stack alignItems="start" w="100%">
          <Stack direction="row" w="100%" justifyContent="space-between">
            <FormControl.Label variant="portal" size={{ base: 'bodyMd' }}>
              Set as primary{' '}
              <Tooltip
                label="By setting a new payment method as primary, all future orders will be updated to your new payment method."
                placement="top"
                openDelay={200}
                maxW="75vw"
                ml="8px"
              >
                <InfoOutlineIcon />
              </Tooltip>
            </FormControl.Label>
            <Switch
              onToggle={() => {
                setIsDefault(!isDefault);
              }}
              value={isDefault}
            />
          </Stack>
        </Stack>
        <Divider bg="gallery" />
        <Stack>
          <Button
            justifyContent="left"
            variant="underlineMini"
            onPress={() => setShowRemoveConfirmModal(true)}
            size={{ base: 'bodyMd' }}
          >
            Remove payment method
          </Button>
        </Stack>
        <ScrollView>
          <PortalPopUp
            isOpen={showRemoveConfirmModal}
            size="xl"
            onConfirm={onRemoveConfirm}
            onClose={() => setShowRemoveConfirmModal(false)}
            heading="Are you sure you want to remove this payment method?"
            contentStackProps={{ px: 8 }}
          />
        </ScrollView>
        <Center>
          <FormSubmitButton
            onPress={() => setShowPrimaryModal(true)}
            isDisabled={!isDefault}
            isLoading={isSetPrimaryLoading || isDeleteLoading || isPaymentRetrying}
          >
            Save Changes
          </FormSubmitButton>
          {isDefault && (
            <Text
              size="bodySmToMd"
              fontFamily="secondary"
              fontWeight="medium"
              textAlign={{ base: 'left', lg: 'center' }}
              alignItems="center"
              w={{ base: '100%', lg: '850px' }}
              pt={{ base: '10px', lg: '24px' }}
            >
              By proceeding with setting this card as your primary payment method, you are setting
              this card as your default payment method for all future orders. We will immediately
              retry any previously failed charges with this new primary card.
            </Text>
          )}
        </Center>
      </Stack>
      <ConfirmSetPrimaryCardModal onClose={() => setShowPrimaryModal(false)} onConfirm={onSubmit} />
    </Stack>
  );
};
