import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <Path d="M17 13h-4v4h-2v-4H7v-2h4V7h2v4h4v2ZM12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z" />
  </Svg>
);
function SvgPlusCircleFill({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgPlusCircleFill;
