import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  shadow: 'none',
  borderRadius: 0,
  _light: {
    p: 4,
    bg: 'sntBlue.primary',
    _text: {
      color: 'black',
      fontWeight: 'medium',
    },
  },
};
export const Tooltip = {
  baseStyle,
  sizes: responsiveSizes,
};
