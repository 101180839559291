import { Pet } from '@/api/types/accountServices';
import { PictoGroup } from '@/components/Elements';
import { capitalize, dogAge } from '@/utils';

export interface DogPictoGroupProps {
  selectedPet: Partial<Pet>;
  isDisabled?: boolean;
}

export const DogPictoGroup = ({ selectedPet, isDisabled }: DogPictoGroupProps) => {
  const petLifestyle = selectedPet?.properties?.lifestyle;
  const selectedPetAge = dogAge(
    selectedPet?.birth_year || undefined,
    selectedPet?.birth_month || undefined
  );
  const dogPictos = [
    {
      src: 'CardIcons/breed.png',
      caption: selectedPet
        ? capitalize(
            selectedPet?.breed_details && selectedPet.breed_details.length > 0
              ? selectedPet?.breed_details.length > 1
                ? 'Mixed Breed'
                : selectedPet.breed_details[0].name
              : 'Unknown Breed'
          )
        : undefined,
    },
    {
      src: 'CardIcons/birthday.png',
      caption: selectedPetAge ? capitalize(selectedPetAge) + ' Old' : 'Unknown age',
    },
    {
      src: 'CardIcons/scale.png',
      caption: selectedPet?.properties?.weight + ' Pounds',
    },
    {
      src: 'CardIcons/lifestyle.png',
      caption: selectedPet
        ? capitalize(petLifestyle ? petLifestyle.replaceAll('-', ' ') : '') + ' Lifestyle'
        : undefined,
    },
  ];

  return (
    <PictoGroup
      pictos={dogPictos}
      pictoStackProps={{
        w: { base: '52px', lg: '68px' },
        space: 1,
      }}
      captionProps={{
        size: 'tinyToCaption',
        fontWeight: 'bold',
        color: isDisabled ? 'sntGrey.primary' : 'black',
      }}
      space={{ base: 4, lg: 6 }}
      w={{ base: '100%', lg: '344px' }}
    />
  );
};
