import { useMutation } from 'react-query';

import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

const postApplyDiscount = ({ orderId, discountCode }: { orderId: string; discountCode: string }) =>
  axios
    .post(
      `/orders/${orderId}/apply_discount`,
      { discount_code: discountCode },
      { noErrorToast: true }
    )
    .then((response) => response.data);

export const useApplyDiscount = () => {
  const { refetchUser } = useAuth();

  return useMutation(
    ({ orderId, discountCode }: { orderId: string; discountCode: string }) =>
      postApplyDiscount({ orderId, discountCode }),
    {
      onSuccess: ({ data }) => {
        refetchUser();
        return data;
      }, // refresh with new user data
    }
  );
};
