import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={3}
      d="M7.948 15.597c0-3.943.086-7.519 1.4-11.286.286-.819 1.001-1.624 1.059-2.511.098-1.49.283 2.976.307 4.468.065 3.936.104 8.11.905 11.938.284 1.354-1.538-2.346-2.441-3.425-1.192-1.423-2.787-2.72-4.303-3.816-1.06-.766-2.005-1.514-3.21-1.843-.008-.002 3.028-.617 3.722-.717 3.906-.565 8.204-.193 12.089-.44.9-.058-1.667.744-2.305 1.353-1.512 1.444-3.073 3.11-4.286 4.795-.824 1.144-2.788 4.125-4.473 4.125"
    />
  </Svg>
);
function SvgIllustratedStar({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgIllustratedStar;
