import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 17"
    fill="currentColor"
    {...props}
  >
    <Path d="M12.083.669a1.5 1.5 0 1 0-.167 2.995h.002c.012.002.064.008.17.028.12.023.276.058.467.106.382.096.868.236 1.418.414a29.74 29.74 0 0 1 3.6 1.423A14.3 14.3 0 0 1 19.43 6.68l-17.464.4a1.5 1.5 0 0 0 .07 3l17.175-.394c-.131.254-.255.502-.372.737-.153.308-.294.592-.427.838-.578 1.071-1.135 1.796-2.003 2.229a1.5 1.5 0 0 0 1.34 2.684c1.702-.849 2.63-2.24 3.303-3.489.236-.436.434-.834.617-1.202.388-.783.713-1.436 1.201-2.062.528-.677.744-1.485.574-2.317-.153-.756-.592-1.37-1.037-1.835-.884-.925-2.233-1.714-3.532-2.339a32.725 32.725 0 0 0-3.977-1.574c-.601-.194-1.153-.353-1.611-.468-.422-.106-.866-.202-1.203-.22Z" />
  </Svg>
);
function SvgRightArrow({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgRightArrow;
