import { useMutation } from 'react-query';

import { SurveyResponse } from '@/api/types';
import { axios } from '@/lib/axios';

const postSurveyResponse = ({ accountId, data }: { accountId: string; data: SurveyResponse }) =>
  axios.post(`accounts/${accountId}/add_survey_response/`, data).then((response) => response.data);

interface SurveyResponseData {
  accountId: string;
  data: SurveyResponse;
}

export const useCreateSurveyResponse = () => {
  return useMutation<SurveyResponse, Error, SurveyResponseData>(
    ({ accountId, data }: SurveyResponseData) => postSurveyResponse({ accountId, data })
  );
};
