import { AddNewDogAdjustPortionSize } from './AddNewDogAdjustPortionSize';
import { AddNewDogBirthday } from './AddNewDogBirthday';
import { AddNewDogBodyType } from './AddNewDogBodyType';
import { AddNewDogBreed } from './AddNewDogBreed';
import { AddNewDogCalculating } from './AddNewDogCalculating';
import { AddNewDogFoodTypes } from './AddNewDogFoodTypes';
import { AddNewDogGender } from './AddNewDogGender';
import { AddNewDogHealthConditions } from './AddNewDogHealthConditions';
import { AddNewDogHealthConditionsList } from './AddNewDogHealthConditionsList';
import { AddNewDogLifestyle } from './AddNewDogLifestyle';
import { AddNewDogMealPlan } from './AddNewDogMealPlan';
import { AddNewDogName } from './AddNewDogName';
import { AddNewDogNeutered } from './AddNewDogNeutered';
import { AddNewDogPlanSummaryPage } from './AddNewDogPlanSummaryPage';
import { AddNewDogRecipes } from './AddNewDogRecipes';
import { AddNewDogRescheduleDelivery } from './AddNewDogRescheduleDelivery';
import { AddNewDogSnacks } from './AddNewDogSnacks';
import { AddNewDogSupplements } from './AddNewDogSupplements';
import { AddNewDogWeight } from './AddNewDogWeight';

export const AddNewDogComponents = {
  DogName: AddNewDogName,
  Gender: AddNewDogGender,
  Neutered: AddNewDogNeutered,
  Breed: AddNewDogBreed,
  Birthday: AddNewDogBirthday,
  BodyType: AddNewDogBodyType,
  Weight: AddNewDogWeight,
  Lifestyle: AddNewDogLifestyle,
  HealthConditions: AddNewDogHealthConditions,
  HealthConditionsList: AddNewDogHealthConditionsList,
  FoodTypes: AddNewDogFoodTypes,
  Recipes: AddNewDogRecipes,
  Calculating: AddNewDogCalculating,
  MealPlan: AddNewDogMealPlan,
  AdjustPortionSize: AddNewDogAdjustPortionSize,
  Snacks: AddNewDogSnacks,
  Supplements: AddNewDogSupplements,
  PlanSummary: AddNewDogPlanSummaryPage,
  RescheduleDelivery: AddNewDogRescheduleDelivery,
};
