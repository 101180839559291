import { Divider, Stack, IStackProps, Text } from 'native-base';

import { Pill, PillFill, PillSize, PillStyle } from '../Pill';

interface PillBlockItem<TValue> {
  description?: string;
  id?: string;
  isDisabled?: boolean;
  label?: string;
  type?: string;
  value?: TValue;
}

interface PillBlockProps<TValue> extends IStackProps {
  items: PillBlockItem<TValue>[];
  onPress: (id?: string) => void;
  rightPart?: boolean;
}

const getTextColor = (type?: string) => {
  if (type === 'inactive') {
    return 'sntGrey.primary';
  }
  if (type === 'error') {
    return 'black';
  }
  return 'black';
};

const getPillProps = (type?: string) => {
  if (type === 'error') {
    return {
      isError: true,
      fill: PillFill.Filled,
    };
  }
  return {
    fill: PillFill.Outline,
  };
};

export function PillBlock<TValue>({ items, onPress, rightPart, ...props }: PillBlockProps<TValue>) {
  const activeItems = items.filter((item) => item.type !== 'placeholder');
  return (
    <Stack flexDir="row" justifyContent="center" alignItems="center" {...props}>
      {items.map(({ description, label, type, id, isDisabled = false }, i) => {
        return (
          <Stack key={id} flexDir="row" justifyContent="center" alignItems="center">
            <Pill
              isDisabled={isDisabled}
              onPress={() => id && onPress(id)}
              size={PillSize.Tall}
              style={type === 'error' ? PillStyle.Error : undefined}
              {...getPillProps(type)}
            >
              <Stack justifyContent="center" alignItems="center">
                <Text size="bodyLg" fontWeight="bold" color={getTextColor(type)}>
                  {label}
                </Text>
                {Boolean(description) && (
                  <Text size="captionToBodySm" color={getTextColor(type)}>
                    {description}
                  </Text>
                )}
              </Stack>
            </Pill>
            <Divider
              w="8px"
              h="2px"
              backgroundColor={rightPart && activeItems.length === i + 1 ? 'transparent' : 'black'}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}
