import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  letterSpacing: 'none',
  fontFamily: 'primary',
  fontWeight: 'normal',
};

const variants = {
  helperText: {
    //Doesn't seem to work
    _light: {
      color: 'grey.secondary',
    },
    size: { base: 'bodySm', lg: 'bodyMd' },
    textAlign: 'center',
    _text: {
      fontFamily: 'primary',
      fontWeight: 450,
    },
  },
  errorMessage: {
    fontFamily: 'secondary',
    textAlign: 'left',
    alignSelf: 'flex-start',
    color: 'error.default',
    ...responsiveSizes.bodySmToMd,
  },
};

const defaultProps = {
  size: 'bodyLg',
};

export const Text = {
  baseStyle,
  variants,
  sizes: responsiveSizes,
  defaultProps,
};
