import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { BuildPlan, PlansDetails } from '@/api/types';
import { axios } from '@/lib/axios';

export interface PlanSizesForRecipesResponse {
  [size: number]: BuildPlan;
}

export const getPlanSizesForRecipes = (data: PlansDetails) => {
  return axios
    .post<PlansDetails, AxiosResponse<PlanSizesForRecipesResponse>>(
      `/calculator/build_plans_for_recipes`,
      data
    )
    .then(({ data }) => data);
};

export const usePlanSizesForRecipes = (data?: PlansDetails) => {
  return useQuery(['MultiRecipePlan'], {
    queryFn: () => getPlanSizesForRecipes(data as PlansDetails),
    enabled: !!data,
  });
};
