import { useState } from 'react';

import { PopUpProps, PortalPopUp } from '@/components/Elements';

export const useRemoveAddressModal = () => {
  const [showModal, setShowModal] = useState(false);

  return {
    setShowModal,
    Modal: (props: PopUpProps) => (
      <PortalPopUp
        isOpen={showModal}
        size="xl"
        useRNModal
        heading="Are you sure you want to remove this address?"
        {...props}
      />
    ),
  };
};
