import { Badge, Button, FormControl, Heading, HStack, Input, Stack } from 'native-base';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useAddNewDogContext } from '@/context';

export const AddNewDogPromoCard = () => {
  const { discountCode, saveDiscountCode } = useAddNewDogContext();
  const [showPromoInput, setShowPromoInput] = useState(false);

  const defaultValues = {
    discountCode: discountCode ?? '',
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const handleApplyDiscount = ({ discountCode }: { discountCode: string }) => {
    setShowPromoInput(false);
    reset();
    saveDiscountCode(discountCode);
  };

  return (
    <Stack variant="card" w="100%">
      <Stack space="4px" w="100%" alignItems="start">
        <HStack w="100%" justifyContent="space-between">
          <Heading size="bodyMlToTitleSm" fontWeight="bold">
            Promo Code
          </Heading>
          <Stack space="4px" justifyContent="flex-end" alignItems="center">
            {!!discountCode && (
              <Badge variant="smallInfoPillSecondary" _text={{ size: 'tinyToBodyMd' }}>
                {discountCode}
              </Badge>
            )}
          </Stack>
        </HStack>
        {showPromoInput ? null : (
          <Button
            variant="underlineMini"
            size="bodySmToMd"
            display={showPromoInput ? 'none' : 'flex'}
            onPress={() => setShowPromoInput(true)}
          >
            Add a Code
          </Button>
        )}
        {showPromoInput && (
          <FormControl isRequired isInvalid={'discountCode' in errors}>
            <Controller
              control={control}
              name="discountCode"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  w="100%"
                  m="0px"
                  size={{ base: 'bodySm', lg: 'bodyLg' }}
                  onBlur={() => {
                    if (!watch('discountCode')) setShowPromoInput(false);
                    onBlur();
                  }}
                  onChangeText={(val) => {
                    onChange(val.toUpperCase());
                  }}
                  value={value}
                  placeholder="Enter Promo Code"
                  textAlign="start"
                  InputRightElement={
                    <Button
                      variant="inline"
                      w="fit-content"
                      px="16px"
                      isDisabled={!watch('discountCode')}
                      onPress={handleSubmit(handleApplyDiscount)}
                    >
                      Apply
                    </Button>
                  }
                />
              )}
            />
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};
