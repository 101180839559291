import { FormControl, Heading, Flex, Stack, Text, CheckIcon, Box } from 'native-base';
import { useState } from 'react';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { createPasswordResolver } from '../Portal/resolvers';

import { Button, InputFloatLabel } from '@/components/Elements';

export interface CreatePasswordFormValues {
  password: string;
  password_confirm: string;
}
interface CreatePasswordFormProps {
  email?: string;
  heading?: string;
  subHeading?: string;
  buttonText: string;
  onSubmit: SubmitHandler<CreatePasswordFormValues>;
  onError: SubmitErrorHandler<CreatePasswordFormValues>;
  isLoading: boolean;
}

export type PasswordError = {
  response: {
    data: {
      password: string[];
      old_password: string[];
    };
  };
};

export const CreatePasswordForm = ({
  email,
  heading,
  subHeading,
  buttonText,
  onSubmit,
  onError,
  isLoading,
}: CreatePasswordFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [focusedField, setFocusedField] = useState('');

  const defaultValues = {
    password: '',
    password_confirm: '',
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
  } = useForm<CreatePasswordFormValues>({
    resolver: createPasswordResolver,
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" space="16px" w="100%">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="start"
        w="100%"
        h="fit-content"
        space="24px"
      >
        <Stack w="100%" space={2}>
          <Heading fontWeight="bold" size="bodyMlToTitleSm">
            {heading}
          </Heading>
          {subHeading && (
            <Heading fontWeight="medium" fontFamily="secondary" size="bodySmToMd">
              {subHeading}
            </Heading>
          )}
        </Stack>
        {email && (
          <FormControl isInvalid={'email' in errors}>
            <InputFloatLabel isDisabled placeholder="Email" value={email} w="100%" m="0px" />
          </FormControl>
        )}
        <FormControl isRequired isInvalid={'password' in errors}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="start"
                  w="100%"
                  space="8px"
                >
                  <InputFloatLabel
                    w="100%"
                    m="0px"
                    nativeID="pcheckout-input-password"
                    variant={'password' in errors ? 'error' : 'base'}
                    externalRef={ref}
                    onFocus={() => setFocusedField('password')}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="Password"
                    secureTextEntry={!showPassword}
                    type="password"
                    textAlign="start"
                    InputRightElement={
                      <Button
                        variant="inline"
                        p={0}
                        w="fit-content"
                        isDisabled={!value}
                        onPress={(e) => {
                          setShowPassword(!showPassword);
                          setFocusedField('password');
                          e.preventDefault();
                        }}
                      >
                        {showPassword ? 'Hide' : 'Show'}
                      </Button>
                    }
                  />

                  <Stack
                    display={focusedField === 'password' ? 'flex' : 'none'}
                    justifyContent="center"
                    alignItems="start"
                    flex={1}
                    space={{ base: '4px', lg: '8px' }}
                  >
                    <FormControl.HelperText flexDir="row" alignItems="center" w="100%" m="0px">
                      {errors?.password?.types?.min !== 'min8Chars' && watch('password') && (
                        <>
                          <CheckIcon color="successGreen" size="14px" />
                          <Box w="4px" h="4px" />
                        </>
                      )}
                      <Text
                        size={{ base: 'bodySm' }}
                        color={
                          errors?.password?.types?.min !== 'min8Chars' && watch('password')
                            ? 'successGreen'
                            : 'black'
                        }
                      >
                        Include at least 8 characters
                      </Text>
                    </FormControl.HelperText>
                    <FormControl.HelperText flexDir="row" alignItems="center" w="100%" m="0px">
                      {!(errors?.password?.types?.matches as string)?.includes('min1Number') &&
                        watch('password') && (
                          <>
                            <CheckIcon color="successGreen" size="14px" />
                            <Box w="4px" h="4px" />
                          </>
                        )}

                      <Text
                        size={{ base: 'bodySm' }}
                        color={
                          !(errors?.password?.types?.matches as string)?.includes('min1Number') &&
                          watch('password')
                            ? 'successGreen'
                            : 'black'
                        }
                      >
                        Include at least 1 number
                      </Text>
                    </FormControl.HelperText>
                    <FormControl.HelperText flexDir="row" alignItems="center" w="100%" m="0px">
                      {errors?.password?.types?.matches !== 'min1SpecialChar' &&
                        !(errors?.password?.types?.matches as string)?.includes(
                          'min1SpecialChar'
                        ) &&
                        watch('password') && (
                          <>
                            <CheckIcon color="successGreen" size="14px" />
                            <Box w="4px" h="4px" />
                          </>
                        )}
                      <Text
                        size={{ base: 'bodySm' }}
                        color={
                          errors?.password?.types?.matches !== 'min1SpecialChar' &&
                          !(errors?.password?.types?.matches as string)?.includes(
                            'min1SpecialChar'
                          ) &&
                          watch('password')
                            ? 'successGreen'
                            : 'black'
                        }
                      >
                        Include at least 1 special character
                      </Text>
                    </FormControl.HelperText>
                  </Stack>
                </Stack>
              );
            }}
          />
        </FormControl>
        <FormControl isRequired isInvalid={'password_confirm' in errors}>
          <Controller
            control={control}
            name="password_confirm"
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="start"
                  w="100%"
                  space="8px"
                >
                  <InputFloatLabel
                    w="100%"
                    m="0px"
                    nativeID="pcheckout-input-password-confirm"
                    variant={'password_confirm' in errors ? 'error' : 'base'}
                    externalRef={ref}
                    onBlur={onBlur}
                    onFocus={() => setFocusedField('password_confirm')}
                    onChange={onChange}
                    value={value}
                    placeholder="Confirm Password"
                    secureTextEntry={!showPasswordConfirm}
                    type="password"
                    textAlign="start"
                    InputRightElement={
                      <Button
                        variant="inline"
                        w="fit-content"
                        p={0}
                        isDisabled={!value}
                        onPress={(e) => {
                          setShowPasswordConfirm((showPasswordConfirm) => !showPasswordConfirm);
                          setFocus('password_confirm');
                          e.preventDefault();
                        }}
                      >
                        {showPasswordConfirm ? 'Hide' : 'Show'}
                      </Button>
                    }
                  />
                  <FormControl.HelperText
                    flexDir="row"
                    alignItems="center"
                    w="100%"
                    display={focusedField === 'password_confirm' ? 'flex' : 'none'}
                  >
                    {watch('password_confirm') &&
                      errors?.password_confirm?.types?.oneOf !== 'match' && (
                        <>
                          <CheckIcon color="successGreen" size="14px" />
                          <Box w="4px" h="4px" />
                        </>
                      )}
                    <Text
                      size={{ base: 'bodySm' }}
                      color={
                        watch('password_confirm') &&
                        errors?.password_confirm?.types?.oneOf !== 'match'
                          ? 'successGreen'
                          : 'black'
                      }
                    >
                      Match Password
                    </Text>
                  </FormControl.HelperText>
                </Stack>
              );
            }}
          />
        </FormControl>
      </Stack>
      <Flex w="100%" direction="column" justify="center" align="center" alignSelf="center">
        <Button
          w="100%"
          size="baseMlToLg"
          px={{ base: '8px', lg: '124px' }}
          onPress={handleSubmit(onSubmit, onError)}
          isDisabled={
            !watch().password || !watch().password_confirm || Object.keys(errors).length !== 0
          }
          isLoading={isLoading}
        >
          {buttonText}
        </Button>
      </Flex>
    </Stack>
  );
};
