import { useNavigation, NavigationProp, useFocusEffect } from '@react-navigation/native';
import { HStack, PresenceTransition, VStack } from 'native-base';
import { useState, useEffect, useCallback } from 'react';

import { MealPlanFooter } from '../MealPlanFooter';
import { DogFactsSection } from './DogFactsSection';
import { HeresDogPlanSection } from './HeresDogPlanSection';
import { PictosSection } from './PictosSection';
import { ReviewSection } from './ReviewSection';
import { ScoopSection } from './ScoopSection';
import { WhatsInTheBoxSection } from './WhatsInTheBoxSection';

import { useCallBuildPlansRegularPlan, useSessionPlans } from '@/api';
import { Image, LoadingSpinner } from '@/components/Elements';
import { FunnelScreenNames, ASSETS_URL } from '@/constants';
import { useSessionQuery } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import segment from '@/segment';
import { Dog, FunnelStackParamList } from '@/types';

export const UnKibbleMealPlan = ({ dog }: { dog: Partial<Dog> }) => {
  const nextStep = useNextFunnelStep(FunnelScreenNames.MEAL_PLAN);
  const navigation = useNavigation<NavigationProp<FunnelStackParamList>>();
  const { isLoading: isLoadingSession, mutateUpdatePetAnswer, session } = useSessionQuery();
  const [isLoading, setIsLoading] = useState(false);
  const funnelErrorHandler = useFunnelErrorHandler();
  const discounts = session?.discounts?.map((discount) => discount.code);
  const {
    query: { data },
  } = useSessionPlans({ pet: dog, discounts, sessionId: session?.id });

  const { mutateAsync: callBuildPlansRegularPlan, data: unkibbleData } =
    useCallBuildPlansRegularPlan();

  useFocusEffect(
    useCallback(() => {
      if (data) {
        // load meal plan details
        callBuildPlansRegularPlan({
          plan_size: data?.plan_size.id,
          recipes: dog.recipes,
          discounts: session?.discounts?.map((discount) => discount.code),
          session_id: session?.id,
        });
      }
    }, [data, dog.recipes, session?.discounts])
  );

  useEffect(() => {
    if (unkibbleData) {
      const price = Number(
        unkibbleData?.trial.price.discounted_price ?? unkibbleData?.trial.price.price
      );
      const recipes = unkibbleData?.trial.product.recipes.map((recipe) => recipe.id) || [];
      segment.trackEvent('AddToCart', {
        dogName: dog?.name,
        price,
        recipes,
        account_id: null,
        session_id: session?.id,
      });
    }
  }, [unkibbleData]);

  const items = unkibbleData?.trial?.product?.items.map((item) => {
    const recipe = unkibbleData.trial.product.recipes.find(
      (recipe) => recipe.id === item.recipe_id
    )?.name;
    return { quantity: item.quantity, recipe };
  });

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await mutateUpdatePetAnswer({
        calories_per_day: unkibbleData?.calories_per_day,
        plan_size: unkibbleData?.plan_size,
        plan_type: 'full',
        trial_pricing_id: unkibbleData?.trial.price.id,
        subscription_pricing_id: unkibbleData?.subscription.price.id,
      });
      navigation.navigate(nextStep);
    } catch (error) {
      funnelErrorHandler(error, 'Update unkibble meal plan');
    } finally {
      setIsLoading(false);
    }
  };

  const dataIsReady = Boolean(!isLoadingSession && dog && data && unkibbleData);

  return dataIsReady ? (
    <PresenceTransition
      visible={dataIsReady}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 250,
        },
      }}
      style={{
        width: '100%',
      }}
    >
      <VStack w="100%" alignItems="center">
        <HeresDogPlanSection dog={dog} data={unkibbleData} onSubmit={onSubmit} />
        <DogFactsSection dog={dog} />
        <WhatsInTheBoxSection dog={dog} items={items} />
        <ReviewSection />
        <ScoopSection
          dogName={dog.name || 'Your Dog'}
          scoopCalories={data?.plan_size.scoop_calories}
          scoopsPerDay={data?.plan_size.scoops_per_day}
        />
        <PictosSection />
        <CenteredLogo />
        <MealPlanFooter onSubmit={onSubmit} isLoading={isLoading} data={unkibbleData} />
      </VStack>
    </PresenceTransition>
  ) : (
    <LoadingSpinner />
  );
};

const CenteredLogo = () => (
  <HStack w="100%" justifyContent="center" alignItems="center" py={{ base: '25px', lg: '40px' }}>
    <Image
      source={{ uri: `${ASSETS_URL}/logo.svg` }}
      w={{ base: '151px', lg: '242px' }}
      h={{ base: '31px', lg: '50px' }}
      alt="logo"
    />
  </HStack>
);
