import { HStack, IStackProps, VStack } from 'native-base';
import { forwardRef } from 'react';

import { useIsMobile } from '@/hooks';

export const VToHStack = forwardRef<HTMLDivElement, IStackProps>(({ ...props }, ref) => {
  const isMobile = useIsMobile();
  // Allows the space prop to be used in either a
  // vertical or horizontal flex direction
  return isMobile ? <VStack ref={ref} {...props} /> : <HStack ref={ref} {...props} />;
});
