import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import dayjs from 'dayjs';
import { memo } from 'react';

import { OrderStatusPill } from '../OrderStatusPill';

import { Order } from '@/api/types';
import { EditableCard, EditableCardProps } from '@/components/Elements';
import { ProtectedStackParamList } from '@/types';

export interface PastOrderCardProps extends EditableCardProps {
  order: Order;
}

export const PastOrderCardComponent = ({ order, ...props }: PastOrderCardProps) => {
  const heading = order.charged ? dayjs(order.charged).local().format('MMM D') : null;
  const navigation = useNavigation<NativeStackNavigationProp<ProtectedStackParamList>>();

  return (
    <EditableCard
      heading={heading}
      headingProps={{
        size: 'bodyMlToTitleSm',
        fontWeight: 'bold',
      }}
      stackProps={{ alignItems: 'center' }}
      p={{ base: '16px', lg: '24px' }}
      onPress={() => {
        if (navigation) navigation.navigate('OrderDetailPage', { order });
      }}
      {...props}
    >
      <OrderStatusPill order={order} />
    </EditableCard>
  );
};

export const PastOrderCard = memo(
  ({ order, ...props }: PastOrderCardProps) => <PastOrderCardComponent order={order} {...props} />,
  (prevP, nextP) => prevP?.order.id === nextP?.order.id
);
