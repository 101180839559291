import { VStack, View, Image } from 'native-base';

import { FunnelWrapper } from '../../../components/layouts';

import { ASSETS_URL } from '@/constants';

export const PreparingCheckoutAnimation = () => (
  <View
    position="absolute"
    top="0"
    left="0"
    width="100%"
    height="100%"
    backgroundColor="white"
    zIndex="9999"
  >
    <FunnelWrapper>
      <VStack justifyContent="center" alignItems="center" safeAreaTop h="85%">
        <Image
          source={{ uri: `${ASSETS_URL}/PreparingCheckout.gif` }}
          resizeMode="cover"
          w={{ base: '300px', '2xl': '500px' }}
          h={{ base: '300px', '2xl': '500px' }}
          size="4xl"
          alt="Processing your Order"
        />
      </VStack>
    </FunnelWrapper>
  </View>
);
