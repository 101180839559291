import { useAccount } from './useAuth';

export const useProductQuantityByCode = (productCodes: string[]) => {
  const account = useAccount();
  if (!account || !productCodes?.length) {
    return;
  }

  const quantitiesObj: { [key: string]: number } = {};

  const previewOrders = account.orders.upcoming;
  productCodes?.forEach((productCode) => {
    quantitiesObj[productCode] = 0;
    const orderProducts = previewOrders
      ?.flatMap((order) => order.products)
      ?.filter((orderProduct) => orderProduct.code === productCode);
    orderProducts?.forEach(
      (orderProduct) => (quantitiesObj[orderProduct.code] += orderProduct.quantity)
    );
  });

  return quantitiesObj;
};
