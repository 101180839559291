import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  bg: 'sntBlue.primary',
  w: 'fit-content',
  borderWidth: '0',
  borderStyle: 'solid',
  borderColor: 'black',
  borderRadius: '25px',
  py: { base: '6px', lg: '8px' },
  px: { base: '8px', lg: '12px' },
  ...responsiveSizes.tinyToBodyMd,
  _light: {
    _text: {
      color: 'black',
    },
    _icon: {
      color: 'black',
    },
  },
};

const variants = {
  smallInfoPill: {
    _light: {
      _text: {
        fontWeight: 'bold',
      },
    },
  },
  smallInfoPillSecondary: {
    bg: 'white',
    borderWidth: '1px',
    _light: {
      _text: {
        fontWeight: 'bold',
      },
    },
  },
  largeInfoPill: {
    py: { base: '8px', lg: '12px' },
    paddingLeft: '12px',
    paddingRight: '12px',
    _light: {
      _text: {
        fontWeight: 'bold',
      },
    },
    ...responsiveSizes.bodySmToMd,
  },
  status: {
    px: { base: '16px', lg: '24px' },
    py: '10px',
    borderRadius: { base: '25px', lg: '30px' },
    ...responsiveSizes.bodyMdToLg,
    _light: {
      _hover: {
        bg: 'sntBlue.hover',
      },
    },
  },
  statusBold: {
    px: { base: '16px', lg: '24px' },
    py: '10px',
    borderRadius: { base: '25px', lg: '30px' },
    ...responsiveSizes.bodyMdToLg,
  },
  statusBoldOutline: {
    bg: 'white',
    borderWidth: '1px',
    borderColor: 'sntBlue.primary',
    borderRadius: { base: '25px', lg: '30px' },
    px: { base: '16px', lg: '24px' },
    py: '10px',
    ...responsiveSizes.bodyMdToLg,
  },
  statusBoldError: {
    bg: 'error.default',
    borderRadius: { base: '25px', lg: '30px' },
    px: { base: '16px', lg: '24px' },
    py: '10px',
    ...responsiveSizes.bodyMdToLg,
    _light: {
      _text: {
        color: 'white',
      },
      _icon: {
        color: 'white',
      },
    },
  },
  statusSmall: {
    px: { base: '16px', lg: '24px' },
    py: { base: '7px', lg: '10px' },
    ...responsiveSizes.bodySmToMd,
  },
  statusSmallOutline: {
    bg: 'white',
    borderColor: 'sntBlue.primary',
    borderWidth: '1px',
    px: { base: '16px', lg: '24px' },
    py: { base: '7px', lg: '10px' },
    ...responsiveSizes.bodySmToMd,
  },
  smallTabActive: {
    borderRadius: { base: '4px', lg: '8px' },
    py: { base: '2px', lg: '4px' },
    px: { base: '4px', lg: '8px' },
    ...responsiveSizes.tinyToCaption,
    _light: {
      _text: {
        color: 'black',
      },
    },
  },
  smallTabInactive: {
    bg: 'sntGrey.outline',
    borderRadius: { base: '4px', lg: '8px' },
    py: { base: '2px', lg: '4px' },
    px: { base: '4px', lg: '8px' },
    ...responsiveSizes.tinyToCaption,
    _light: {
      _text: {
        color: 'black',
      },
    },
  },
  largeTabActive: {
    borderRadius: { base: '4px', lg: '8px' },
    py: { base: '2px', lg: '4px' },
    px: { base: '4px', lg: '8px' },
    ...responsiveSizes.bodySmToMd,
    _light: {
      _text: {
        color: 'black',
      },
    },
  },
  largeTabInactive: {
    bg: 'sntGrey.outline',
    borderRadius: { base: '4px', lg: '8px' },
    py: { base: '2px', lg: '4px' },
    px: { base: '4px', lg: '8px' },
    ...responsiveSizes.bodySmToMd,
    _light: {
      _text: {
        color: 'black',
      },
    },
  },
  floatingPill: {
    bg: 'rgba(77, 77, 77, 0.9)',
    py: '10px',
    paddingLeft: { base: '16px', md: '24px' },
    paddingRight: { base: '16px', md: '24px' },
    ...responsiveSizes.bodyMdToLg,
    _light: {
      _text: {
        color: 'white',
      },
    },
  },
};

const defaultProps = {
  variant: 'smallInfoPill',
  size: 'NativeBaseSizeHack',
};

export const Badge = {
  baseStyle,
  variants,
  sizes: responsiveSizes,
  defaultProps,
};
