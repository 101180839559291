import { FieldValues, Path } from 'react-hook-form';

import { states } from '../../constants';

import { LabeledSelectProps, LabeledSelect } from '@/components/Elements';

interface StateOption {
  value: string;
  label: string;
}

interface ShippingStateSelectProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> extends Omit<LabeledSelectProps<StateOption, TFieldValues, TName>, 'options'> {
  value?: string;
}

export const ShippingStateSelect = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>(
  selectProps: ShippingStateSelectProps<TFieldValues, TName>
) => (
  <LabeledSelect<StateOption, TFieldValues, TName>
    options={states.map((state) => ({
      value: state.short,
      label: state.name,
    }))}
    label="State"
    placeholder="State"
    value={selectProps.value} // Pass the value prop here
    {...selectProps}
  />
);
