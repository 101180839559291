import { Elements } from '@stripe/react-stripe-js';
import { Stack, Toast } from 'native-base';
import { useEffect, useState } from 'react';

import { AddCreditCardForm } from './AddCreditCardForm';
import { AddCreditCardSkeleton } from './AddCreditCardSkeleton';

import { OrderStatus, useChargeHoldOrders, usePrepareCardSetup } from '@/api';
import { RetryErrorToastMessage, displayToast } from '@/components/Elements';
import { useAuth, useOrdersOnHold } from '@/hooks';
import { useStripeElementsOptionsBase, stripePromise } from '@/stripe';
import { ProtectedScreenProps } from '@/types';
import { paymentFailedErrorHandler } from '@/utils';

export const AddCreditCard = ({ navigation, route }: ProtectedScreenProps<'AddCreditCard'>) => {
  const { params } = route;
  const { refetchUser } = useAuth();
  const { mutateAsync: prepareCardSetup } = usePrepareCardSetup();
  const { mutateAsync: chargeHoldOrders } = useChargeHoldOrders();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const ordersOnHold = useOrdersOnHold();
  const elementsOptionsBase = useStripeElementsOptionsBase();
  const elementsOptions = {
    ...elementsOptionsBase,
    clientSecret,
  };

  const onPressToast = () => {
    navigation.navigate('PaymentMethodList');
    Toast.close('payment_retry_error_toast');
  };

  const onSuccess = async () => {
    displayToast({ message: 'A new payment method has been added.' });
    if (!ordersOnHold.length) {
      if (params?.successRedirect) {
        navigation.navigate(params?.successRedirect, params?.successRedirectParams || {});
      } else {
        navigation.navigate('Account');
      }
      return;
    }

    try {
      await chargeHoldOrders();
      const refetchUserResponse = await refetchUser();

      if (
        refetchUserResponse.data?.orders?.processing.some(
          (order) => order.status === OrderStatus.HOLD
        )
      ) {
        displayToast({
          title: 'Success!',
          message:
            'Your payment retry was successful. You may temporarily see that your order is still in an "error" state, but rest assured your order was charged and is getting ready to be fulfilled. Please refresh this page in a few minutes to see the updated order status.',
        });
      } else {
        displayToast({
          title: 'Success!',
          message:
            "Your payment retry was successful. We'll get started on your order and send you tracking information as soon as it's officially on its way.",
        });
      }
    } catch (err) {
      paymentFailedErrorHandler(RetryErrorToastMessage(err), onPressToast, true);
    }

    navigation.navigate('Orders');
  };

  useEffect(() => {
    prepareCardSetup(undefined, {
      onSuccess: (res) => {
        setClientSecret(res.client_secret);
      },
    });
  }, [prepareCardSetup]);

  return (
    <Stack alignItems="center" w="100%" h="100%">
      <Stack
        w="100%"
        h="100%"
        px={{ base: '16px', lg: '0px' }}
        pb="24px"
        justifyContent="space-between"
      >
        {clientSecret ? (
          <Elements stripe={stripePromise} options={elementsOptions}>
            <AddCreditCardForm
              clientSecret={clientSecret}
              onSuccess={onSuccess}
              initialIsDefault={route.params?.setDefault || route.params?.forceDefault}
              forceDefault={route.params?.forceDefault}
            />
          </Elements>
        ) : (
          <AddCreditCardSkeleton />
        )}
      </Stack>
    </Stack>
  );
};
