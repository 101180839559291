export type SurveyQuestion = {
  label: string;
  showCommentField?: boolean;
  commentRequired?: boolean;
  isOtherOption?: boolean;
};

export interface SurveyOption {
  primary: SurveyQuestion;
  secondary?: SurveyQuestion[];
}

export const SURVEY_OPTIONS: SurveyOption[] = [
  {
    primary: {
      label: `It's too expensive`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `I can't afford it within my budget`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `It didn't meet my expectations for the price`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      { label: `Other`, showCommentField: true, commentRequired: true, isOtherOption: true },
    ],
  },
  {
    primary: {
      label: `My dog doesn't like it`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `My dog won't eat it (e.g., sniffs and walks away)`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `My dog will eat it, but just doesn't love it`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `My dog has trouble chewing the food (e.g., issues with size or texture)`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      { label: `Other`, showCommentField: true, commentRequired: true, isOtherOption: true },
    ],
  },
  {
    primary: {
      label: `My dog had a negative reaction (digestive, stool, vomiting, or allergy issues)`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `My dog is vomiting`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `My dog is having stool or digestive issues`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `My dog had an allergic reaction`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      { label: `Other`, showCommentField: true, commentRequired: true, isOtherOption: true },
    ],
  },
  {
    primary: {
      label: `I haven't noticed any benefit`,
      showCommentField: true,
      commentRequired: false,
      isOtherOption: false,
    },
  },
  {
    primary: {
      label: `I'm unhappy with the quality of the supplements`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `I'm concerned about ingredients in or not in the supplements`,
        showCommentField: true,
        commentRequired: true,
        isOtherOption: false,
      },
      {
        label: `It has a strong or unpleasant smell`,
        showCommentField: true,
        commentRequired: true,
        isOtherOption: false,
      },
      {
        label: `It looks different than advertised`,
        showCommentField: true,
        commentRequired: true,
        isOtherOption: false,
      },
      {
        label: `The quality varies order to order`,
        showCommentField: true,
        commentRequired: true,
        isOtherOption: false,
      },
      { label: `Other`, showCommentField: true, commentRequired: true, isOtherOption: true },
    ],
  },
  {
    primary: {
      label: `I don't like the packaging`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `It's hard to open`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `It's not recyclable`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `It's messy`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `Other`,
        showCommentField: true,
        commentRequired: true,
        isOtherOption: true,
      },
    ],
  },
  {
    primary: {
      label: `I don't want a subscription`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `I prefer to order one-off, but I'll re-order when I'm ready`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `I dislike subscriptions in general, so I'll buy another product that I can purchase one-off`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `Other`,
        showCommentField: true,
        commentRequired: true,
        isOtherOption: true,
      },
    ],
  },
  {
    primary: {
      label: `I'm running out or have too much leftover between orders`,
      showCommentField: false,
      commentRequired: false,
      isOtherOption: false,
    },
    secondary: [
      {
        label: `I'm running out`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      {
        label: `I have too much`,
        showCommentField: true,
        commentRequired: false,
        isOtherOption: false,
      },
      { label: `Other`, showCommentField: true, commentRequired: false, isOtherOption: true },
    ],
  },
  {
    primary: {
      label: `I have a different issue not listed above`,
      showCommentField: true,
      commentRequired: true,
      isOtherOption: true,
    },
  },
];
