import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),

  password: Yup.string().trim().required('Please enter your password'),
});

export const LoginResolver = yupResolver(LoginSchema);
