import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect } from '@react-navigation/native';
import { VStack, Heading, Progress, HStack, Text } from 'native-base';
import { useEffect, useCallback, useMemo } from 'react';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { useCallBuildPlansRegularPlan, useCallBuildTopperPlan, usePlans } from '@/api';
import { displayToast, ToastType } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';
import { DogFactsGrid } from '@/screens/Funnel/Calculating/DogFactsGrid';
import { useProgressBar } from '@/screens/Funnel/Calculating/useProgressBar';
import { Dog } from '@/types';

const { logger } = datadogLogs;

export const AddNewDogCalculating = ({ navigation }: AddNewDogScreenProps<'Calculating'>) => {
  const account = useAccount();
  const { petData, discountCode } = useAddNewDogContext();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.CALCULATING);
  const { mutateAsync: callBuildPlansRegularPlan } = useCallBuildPlansRegularPlan();
  const { mutateAsync: callBuildPlansTopperPlan } = useCallBuildTopperPlan();

  const { data: planData } = usePlans({
    pet: petData,
    accountId: account.id,
  });
  const { progress } = useProgressBar();
  const progressDisplay = Math.min(Math.floor(progress), 100);

  useEffect(() => {
    if (progress >= 101) {
      navigation.navigate(nextStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const foodType = useMemo(
    () => (petData.food_type === 'FRESH' ? planData?.prices.fresh : planData?.prices.unkibble),
    [petData.food_type, planData?.prices.fresh, planData?.prices.unkibble]
  );

  useFocusEffect(
    useCallback(() => {
      const preloadPlanData = async () => {
        if (!planData || !foodType) {
          return;
        }
        try {
          await Promise.all([
            callBuildPlansRegularPlan({
              account_id: account.id,
              plan_size: planData?.plan_size.id,
              recipes: foodType
                ?.map((mealOptions) => mealOptions.trial?.product?.recipes.map((r) => r.id))
                .flat()
                .filter((recipeID) => petData.recipes?.some((id) => id === recipeID)),
              discounts: discountCode ? [discountCode] : [],
            }),
            callBuildPlansTopperPlan({
              account_id: account.id,
              plan_size: planData?.topper_plan_size.id as number,
              recipes: planData?.prices?.topper?.[0].trial?.product?.recipes.map((r) => r.id),
              discounts: discountCode ? [discountCode] : [],
            }),
          ]);
          return () => {};
        } catch (error) {
          displayToast({
            message: `There was a problem calculating your dog's plan`,
            type: ToastType.Error,
          });
          logger.error(`Error calculating dog's plan, account_id=${account.id}`, { error });
        }
      };

      preloadPlanData();
    }, [
      account.id,
      callBuildPlansRegularPlan,
      callBuildPlansTopperPlan,
      discountCode,
      foodType,
      petData.recipes,
      planData,
    ])
  );

  return (
    <AddNewDogWrapper>
      <VStack w={{ base: '100%', lg: '360px' }}>
        <VStack
          justifyContent="center"
          alignItems="center"
          w="100%"
          alignSelf="center"
          space="16px"
          mb={{ base: '36px', md: '48px' }}
        >
          <Heading size="titleSmToMd" fontWeight="bold">
            Calculating
          </Heading>
          <Heading variant="secondary" size={{ base: 'bodySm', lg: 'bodyMd' }} fontWeight={450}>
            Building {petData.name}&apos;s personal plan...
          </Heading>
        </VStack>

        <Text size="bodyMl" lineHeight="40px" textAlign="center" fontWeight="bold">
          {`${progressDisplay}%`}
        </Text>
        <Progress
          h="12px"
          my={{ base: '24px', md: '36px' }}
          bg="gallery"
          borderRadius="25px"
          _filledTrack={{
            bg: 'sntBlue.primary',
            borderRadius: '25px',
          }}
          value={progress}
        />

        {petData ? (
          <HStack px={{ base: '48px', lg: '13px' }} justifyContent="center">
            <DogFactsGrid dog={petData as Dog} progress={progress} />
          </HStack>
        ) : null}
      </VStack>
    </AddNewDogWrapper>
  );
};
