import dayjs from 'dayjs';

import { round } from './math';
import { sortPetsByPetPlanID } from './pets';
import { sortOrderByCharged } from './sort';

import {
  Account,
  FulfillmentStatus,
  Order,
  OrderProduct,
  OrderStatus,
  Pet,
  ProductType,
} from '@/api';
import { NON_CUSTOMER_FACING_PRODUCT_CODES } from '@/constants';

export const MAX_PAST_ORDERS = 3;
const deliveredCategoryStatuses = [OrderStatus.COMPLETE];
const shippedCategoryStatuses = [OrderStatus.FULFILLED, OrderStatus.PARTIALLY_FULFILLED];
const preparingCategoryStatuses = [
  OrderStatus.FULFILLING,
  OrderStatus.READY_FOR_FULFILLMENT,
  OrderStatus.CHARGING,
  OrderStatus.ERROR,
];
const errorCategoryStatuses = [OrderStatus.HOLD];
const upcomingCategoryStatuses = [OrderStatus.PREVIEW];
const ordersToExcludeStatuses = [OrderStatus.CANCELLED];

export interface OrdersCategory {
  description?: string;
  id: string;
  label: string;
  type?: string;
  value: Order;
}

type PetPlanProductData = {
  pet: Pet;
  meals: OrderProduct[];
  snacks: OrderProduct[];
  supplements: OrderProduct[];
  oneTimeProducts: OrderProduct[];
};

export const getOrderPets = (pets: Pet[], order: Order) =>
  pets.filter((pet) => order.products.some((product) => product.pet_plan?.id === pet.pet_plan.id));

export function mealPriceFormat(
  mealPrice: string | number | undefined,
  frequency: number | undefined,
  unit = 'wk'
) {
  if (mealPrice === undefined) {
    return '$ --';
  } else if (frequency !== undefined) {
    return `$${round(Number(mealPrice) / frequency, 2)}/${unit}`;
  } else {
    return `$${round(mealPrice, 2)}`;
  }
}

export const sortOrdersByCategory = (orders: Order[]) => {
  const deliveredCategory: OrdersCategory[] = [];
  const shippedCategory: OrdersCategory[] = [];
  const preparingCategory: OrdersCategory[] = [];
  const errorCategory: OrdersCategory[] = [];
  const upcomingCategory: OrdersCategory[] = [];
  const pastOrders: Order[] = [];

  orders.forEach((order) => {
    const label = order?.charged
      ? dayjs(order.charged).local().format('MMM D')
      : dayjs(order.scheduled, 'YYYY-MM-DD').local().format('MMM D');
    const { id } = order;
    if (deliveredCategoryStatuses.includes(order.status)) {
      if (isRecentPastOrder(order)) {
        deliveredCategory.push({ id, label, value: order, description: 'Delivered' });
      } else {
        pastOrders.push(order);
      }
    }
    if (shippedCategoryStatuses.includes(order.status)) {
      shippedCategory.push({ id, label, value: order, description: 'Shipped' });
    }
    if (preparingCategoryStatuses.includes(order.status)) {
      preparingCategory.push({ id, label, value: order, description: 'Preparing order' });
    }
    if (errorCategoryStatuses.includes(order.status)) {
      errorCategory.push({ id, label, value: order, description: 'Error', type: 'error' });
    }
    if (upcomingCategoryStatuses.includes(order.status)) {
      upcomingCategory.push({ id, label, value: order, description: 'Upcoming order' });
    }
    if (ordersToExcludeStatuses.includes(order.status) && order?.charged) {
      pastOrders.push(order);
    }
  });
  return {
    categories: deliveredCategory
      .concat(shippedCategory)
      .concat(preparingCategory)
      .concat(errorCategory)
      .concat(upcomingCategory),
    pastOrders: pastOrders.sort((a, b) => sortOrderByCharged(a, b, 'desc')),
  };
};

export const getOrderType = (order: Order) => {
  if (deliveredCategoryStatuses.includes(order.status)) {
    return 'delivered';
  }
  if (shippedCategoryStatuses.includes(order.status)) {
    return 'shipped';
  }
  if (preparingCategoryStatuses.includes(order.status)) {
    return 'preparing';
  }
  if (errorCategoryStatuses.includes(order.status)) {
    return 'error';
  }
  if (upcomingCategoryStatuses.includes(order.status)) {
    return 'upcoming';
  }
};

export const isRecentPastOrder = (order: Order) => {
  const shipments = order?.fulfillments.flatMap((fulfillment) => fulfillment.shipments);

  const passedOneDayForAllShipments = shipments
    .map((shipment) => {
      const isOldShipment = dayjs(shipment?.delivered ? shipment.delivered : undefined)
        .local()
        .add(1, 'day')
        .isBefore(dayjs().local());

      const isDeliveryFailed =
        order?.status === OrderStatus.COMPLETE &&
        order?.fulfillments?.some(
          (fulfillment) => fulfillment.status === FulfillmentStatus.DELIVERY_FAILED
        );

      return isOldShipment || isDeliveryFailed;
    })
    .every((shipment) => shipment);

  return !passedOneDayForAllShipments;
};

export const getWeeksOfFood = (code: string) => {
  const codeArr = code.split('-');
  const weeksCode = codeArr[codeArr.length - 1];
  switch (weeksCode) {
    case 'TRIAL':
      return '(2 weeks of food)';
    case '1W':
      return '(1 week of food)';
    case '2W':
      return '(2 weeks of food)';
    case '4W':
      return '(4 weeks of food)';
    case '8W':
      return '(8 weeks of food)';
    case 'SAMPLE':
      return '(Sample)';
    case 'REPLACE':
      return '(Replacement)';
    default:
      return '';
  }
};

export const getProductCategoriesByOrder = (order: Order, pets: Pet[]) => {
  const productsByPlanId: PetPlanProductData[] = [];
  const sortedPets = sortPetsByPetPlanID(pets);
  order.products.forEach((product) => {
    const petPlanId = product.pet_plan?.id;
    if (petPlanId) {
      const pet = sortedPets[petPlanId];
      let petData = productsByPlanId.find(
        (plan: PetPlanProductData) => plan.pet.pet_plan.id === petPlanId
      );
      if (!petData) {
        petData = {
          pet,
          meals: [],
          snacks: [],
          supplements: [],
          oneTimeProducts: [],
        };
        productsByPlanId.push(petData);
      }
      switch (product.product_type) {
        case ProductType.MEAL: {
          petData.meals.push(product);
          return;
        }
        case ProductType.SNACK: {
          if (product.recurring) {
            petData.snacks.push(product);
          } else {
            petData.oneTimeProducts.push(product);
          }
          return;
        }
        case ProductType.SUPPLEMENT: {
          if (product.recurring) {
            petData.supplements.push(product);
          } else {
            petData.oneTimeProducts.push(product);
          }
          return;
        }
        case ProductType.MATERIAL:
        case ProductType.SCOOP:
        case ProductType.MERCH: {
          if (!NON_CUSTOMER_FACING_PRODUCT_CODES.includes(product.code)) {
            petData.oneTimeProducts.push(product);
          }
          return;
        }
        case ProductType.SAMPLE:
        case ProductType.REPLACE: {
          petData.oneTimeProducts.push(product);
        }
      }
    }
  });
  return productsByPlanId.sort((planA, planB) => planA.pet.name.localeCompare(planB.pet.name));
};

export const getPetsFulfillments = (orders: Order[], pet?: Pet) => {
  const activeOrders = orders.filter(
    (order) =>
      !(
        order.status === OrderStatus.ERROR ||
        order.status === OrderStatus.HOLD ||
        order.status === OrderStatus.MAX_RETRY_REACHED ||
        order.status === OrderStatus.CANCELLED
      )
  );
  const pastFulfillments = activeOrders.flatMap((order) => order.fulfillments);
  return pastFulfillments.filter((fulfillment) => fulfillment.pet_ids.includes(pet?.id ?? ''));
};

export function findGeneratedOrder(
  account: Account,
  scheduled: string | undefined,
  petPlanCount: number | undefined
): Order | undefined {
  const orderToSelect = account.orders.upcoming.filter((order) => order.scheduled === scheduled)[0];

  if (orderToSelect) {
    const uniquePetPlanIds = [...new Set(orderToSelect.products.map((op) => op.pet_plan?.id))];

    if (petPlanCount) {
      if (uniquePetPlanIds.length > petPlanCount) {
        return account.orders.processing.sort(sortOrderByCharged)[0];
      } else {
        return orderToSelect;
      }
    }
  }
}

/**
 * Allows sorting of addon OrderProducts and PetPlanProducts
 */
export function addonSeqNoCompare(
  addon1: { recipes: { seq_no: number }[] },
  addon2: { recipes: { seq_no: number }[] }
) {
  return addon1.recipes[0].seq_no - addon2.recipes[0].seq_no;
}

/**
 * Allows sorting of 'extras' OrderProducts
 */
export function extrasOrderProductCompare(a: OrderProduct, b: OrderProduct) {
  return [0, NaN].includes(Number(b.unit_price) - Number(a.unit_price))
    ? a.code.localeCompare(b.code)
    : Number(b.unit_price) - Number(a.unit_price);
}

export function extrasOrderProductCategorySort(a: OrderProduct, b: OrderProduct) {
  // Define a priority object with only known product types
  const priority: { [key in ProductType]?: number } = {
    SCOOP: 1,
    MATERIAL: 2,
    MERCH: 3,
  };

  // Helper function to get priority for each product type, defaulting to 4 if unknown
  const getProductPriority = (productType: ProductType): number => {
    return priority[productType] ?? 4; // Use 4 as fallback for unrecognized types
  };

  return getProductPriority(a.product_type) - getProductPriority(b.product_type);
}

export function mealOrderProductCategorySort(a: OrderProduct, b: OrderProduct) {
  // Define a priority object with only known product types
  const priority: { [key in ProductType]?: number } = {
    MEAL: 1,
    REPLACE: 2,
    SAMPLE: 3,
  };

  // Helper function to get priority for each product type, defaulting to 4 if unknown
  const getProductPriority = (productType: ProductType): number => {
    return priority[productType] ?? 4; // Use 4 as fallback for unrecognized types
  };

  return getProductPriority(a.product_type) - getProductPriority(b.product_type);
}
/**
 * Allows sorting of 'one time products':
 * one-time meal products (UnKibble samples, replacements, etc),
 * and then snacks (in their seq_no order),
 * and then supplements (also in their seq_no order),
 * and then lastly merch (in the same order as shown on the Shop tab)
 */
export function sortByProductType(a: OrderProduct, b: OrderProduct) {
  // Define a priority object with only known product types
  const priority: { [key in ProductType]?: number } = {
    SCOOP: 1,
    MATERIAL: 2,
    MERCH: 3,
    SNACK: 4,
    SUPPLEMENT: 5,
    REPLACE: 6,
    SAMPLE: 7,
  };

  // Helper function to get priority for each product type, defaulting to 8 if unknown
  const getProductPriority = (productType: ProductType): number => {
    return priority[productType] ?? 8; // Use 8 as fallback for unrecognized types
  };

  return getProductPriority(a.product_type) - getProductPriority(b.product_type);
}
