import { HStack, Skeleton, VStack } from 'native-base';

import { CardBrandSkeleton } from './CardBrandLogo';

import { InputSkeleton } from '@/components/Elements';

export const AddCreditCardSkeleton = () => {
  return (
    <VStack
      alignSelf="center"
      alignItems="center"
      w={{ base: '100%', md: '540px' }}
      space={{ base: '24px', lg: '48px' }}
    >
      <Skeleton.Text w={{ base: '70%', lg: '60%' }} lines={1} />
      <VStack space={{ base: '16px', lg: '18px' }} w="100%">
        <VStack space="16px" w="100%">
          <HStack w="100%" justifyContent="flex-start" space={2}>
            {[...Array(4)].map((_, i) => (
              <CardBrandSkeleton key={i} />
            ))}
          </HStack>
          <InputSkeleton variant="portal" h={{ base: '18px', lg: '24px' }} />
        </VStack>
        <Skeleton w="100%" h="1px" />
        <HStack w="100%" justifyContent="space-between">
          <Skeleton.Text lines={1} w="100px" />
          <Skeleton
            w={{ base: '42px', lg: '48px' }}
            h={{ base: '28px', lg: '32px' }}
            borderRadius="28px"
          />
        </HStack>
      </VStack>
    </VStack>
  );
};
