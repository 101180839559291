import { Order } from '@/api/types/accountServices';

export const sortOrderByLastUpdated = (
  orderA: Order,
  orderB: Order,
  direction?: 'asc' | 'desc'
) => {
  const aDate = new Date(orderA.last_updated).getTime();
  const bDate = new Date(orderB.last_updated).getTime();
  const dir = direction && direction === 'asc' ? 1 : -1;
  if (aDate > bDate) {
    return 1 * dir;
  }
  if (aDate < bDate) {
    return -1 * dir;
  }
  return 0;
};
