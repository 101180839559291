import { PricingModelStatus, ProductStatus, RecipeFull, RecipeType } from '@/api/types';

export const mockRecipeSnack1: RecipeFull = {
  price: '42.00',
  id: 'SN-CHICKENBITES',
  seq_no: 1,
  name: 'Chicken Bites',
  discounted_price: '21.00',
  description:
    'Meet Chicken Bites: 100% chicken breast snacks, freeze dried for the perfect crunch. Our dog snacks are grain-free, gluten free fan favorites!',
  type: RecipeType.SNACK,
  status: ProductStatus.ACTIVE,
  ingredients: [
    'Chicken',
    'Crude Protein: 82% (Min)',
    'Crude Fat: 4% (Min)',
    'Crude Fiber: 2% (Max)',
    'Moisture: 5% (Max)',
  ],
  vitamins: [
    'Dicalcium Phosphate',
    'Calcium Carbonate',
    'Potassium Chloride',
    'Safflower Oil',
    'Fish Oil',
    'Choline Chloride',
    'Threonine',
    'Tryptophan-L',
    'Zinc Proteinate',
    'Iron Amino Acid Chelate',
    'Selenium Yeast',
    'Vitamin E',
    'Copper Amino Acid Chelate',
    'Manganese Amino Acid Chelate',
    'Riboflavin',
    'Thiamine Mononitrate',
    'Calcium Pantothenate',
    'Vitamin A',
    'Apples',
    'Blueberries',
    'Broccoli',
    'Cherries',
    'Oranges',
    'Pumpkins',
    'Shiitake Mushrooms',
    'Spinach',
    'Strawberries',
    'Tomatoes',
    'Pyridoxine Hydrochloride',
    'Folic Acid',
    'Vitamin D',
  ],
  analysis: [
    'Crude Protein: 82% (Min)',
    'Crude Fat: 4% (Min)',
    'Crude Fiber: 2% (Max)',
    'Moisture: 5% (Max)',
  ],
  aafco_statement:
    "Spot & Tango's Beef + Barley recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (>70 pounds or more as an adult)",
  recipe_info: [],
  info_pills: [
    {
      slug: 'best-seller',
      text: 'Best Seller',
      seq_no: 1,
    },
    {
      slug: 'protein-packed',
      text: 'Protein Packed',
      seq_no: 2,
    },
    {
      slug: 'antibiotic-free',
      text: 'Antibiotic Free',
      seq_no: 3,
    },
  ],
  inactive_ingredients: [],
  product_contents: [],
  cautions: [],
  for_use_in_dogs_only: [],
  directions_for_use: [],
  warnings: [],
  mini_pictos: [],
  reviews: [
    {
      reviewer_name: 'G B.',
      rating: 5,
      review:
        'We tried so many fresh meals and so much went to waste. After 6 months our dog was sick of it. Our dog loves Spot & Tango fresh! Plus no mess and easy serving. The chicken treats sprinkled on top make our pup extra happy. Big fan!',
      seq_no: 1,
    },
    {
      reviewer_name: 'Darcie',
      rating: 5,
      review:
        'She loves meal time! We can’t thank you enough for the peace of mind! Oh, she also wants to tell you that her chicken bites are the best thing she’s ever had! She’s an absolute fiend for them. Thank you!!!!',
      seq_no: 2,
    },
    {
      reviewer_name: 'Patrice',
      rating: 5,
      review:
        'My dogs both love Spot and Tango, especially the fresh beef and freeze dried chicken snacks.',
      seq_no: 3,
    },
  ],
  default_product: {
    id: '2fd0f692-a7da-4bcb-9bc1-3ed11b63d267',
    name: 'Snack Chicken Bites Recurring',
    code: 'SNACK-CHICKENBITES-1CT-R',
    price: {
      id: '51992b2b-cdc9-4599-8678-df57d8877c1c',
      price: '14.00',
      price_per_meal: null,
      price_per_day: null,
      price_per_week: null,
      discounted_price_per_pack: null,
      discounted_price_per_meal: null,
      discounted_price_per_day: null,
      discounted_price_per_week: null,
      discounted_price: null,
      frequency: 2,
      pricing_model: {
        id: 'ef9c8380-c8c4-4aeb-a99c-f7920b92cab7',
        created: '2023-04-11T09:42:16.378613-04:00',
        last_updated: '2023-04-11T09:42:16.378621-04:00',
        name: 'v2',
        status: PricingModelStatus.ACTIVE,
        is_default: true,
      },
      created: '2023-04-11T09:42:17.169768-04:00',
      last_updated: '2023-04-11T09:42:17.169777-04:00',
    },
  },
};

export const mockRecipeSnack2: RecipeFull = {
  id: 'SN-SPBITES',
  seq_no: 2,
  name: 'Sweet Potato Bites',
  price: '144.00',
  discounted_price: '77.00',
  description:
    'Meet Sweet Potato Bites: 100% sweet potato, freeze dried to cruncy perfection. Our snacks are upcycled from local farms in Midwestern US, perfect for pups who love sweet potatoes and a nice crunch.',
  type: RecipeType.SNACK,
  ingredients: [
    'Yam',
    'Crude Protein: 8% (Min)',
    'Crude Fat: 1.5% (Min)',
    'Crude Fiber: 6% (Max)',
    'Moisture: 16% (Max)',
    '2941 kcal/kg; approx. 13 kcal/treat',
  ],
  vitamins: [],
  analysis: [
    'Crude Protein: 8% (Min)',
    'Crude Fat: 1.5% (Min)',
    'Crude Fiber: 6% (Max)',
    'Moisture: 16% (Max)',
    '2941 kcal/kg; approx. 13 kcal/treat',
  ],
  aafco_statement: '',
  recipe_info: [],
  info_pills: [
    {
      slug: 'low-calorie',
      text: 'Low Calorie',
      seq_no: 1,
    },
    {
      slug: 'non-gmo',
      text: 'Non-GMO',
      seq_no: 2,
    },
    {
      slug: 'uncycled-produce',
      text: 'Uncycled Produce',
      seq_no: 3,
    },
  ],
  inactive_ingredients: [],
  product_contents: [],
  cautions: [],
  for_use_in_dogs_only: [],
  directions_for_use: [],
  warnings: [],
  mini_pictos: [],
  reviews: [],
  default_product: {
    id: '4b44bf07-a075-47da-8dd8-e04b9785f596',
    name: 'Snack Yam Yums R',
    code: 'SNACK-YY-1CT-R',
    price: {
      id: 'da7be3b4-11ce-4e2d-b69e-c6f1106cd578',
      price: '12.00',
      price_per_meal: null,
      price_per_day: null,
      price_per_week: null,
      discounted_price_per_pack: null,
      discounted_price_per_meal: null,
      discounted_price_per_day: null,
      discounted_price_per_week: null,
      discounted_price: null,
      frequency: 2,
      pricing_model: {
        id: 'd9234380-b13c-418d-b59e-7c1902b63a0b',
        name: 'v2',
        created: '2022-12-13T13:33:36.717507-05:00',
        last_updated: '2022-12-13T13:33:36.717516-05:00',
        status: PricingModelStatus.ACTIVE,
        is_default: true,
      },
      created: '2022-12-13T13:33:38.282749-05:00',
      last_updated: '2022-12-13T13:33:38.282763-05:00',
    },
  },
};
