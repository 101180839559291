import { useMemo } from 'react';

import { OrderProduct, ProductType } from '@/api';
import { useAccount } from '@/hooks';
import { sortOrderByScheduled } from '@/utils';

export const usePetsNextOrder = (petId?: string) => {
  const account = useAccount();

  const pet = useMemo(() => account.pets.find((pet) => pet.id === petId), [account.pets, petId]);

  const petsNextOrder = useMemo(() => {
    return account?.orders.upcoming
      .sort(sortOrderByScheduled)
      .find((order) => order.products.some((product) => product.pet_plan?.pet_id === pet?.id));
  }, [account?.orders.upcoming, pet?.id]);
  const nextOrderDate = petsNextOrder?.scheduled;

  const petPlanIdsInNextOrder = useMemo(() => {
    return petsNextOrder?.products.reduce(
      (distinctPets: string[], currentProduct: OrderProduct) => {
        if (currentProduct.pet_plan?.id && !distinctPets.includes(currentProduct.pet_plan.id)) {
          distinctPets.push(currentProduct.pet_plan.id);
        }
        return distinctPets;
      },
      []
    );
  }, [petsNextOrder?.products]);

  const nextOrderIsMultiDog = useMemo(
    () => petPlanIdsInNextOrder && petPlanIdsInNextOrder.length > 1,
    [petPlanIdsInNextOrder]
  );

  const productsByPet = useMemo(() => {
    return petsNextOrder?.products.reduce(
      (productsByPetPlan: Record<string, OrderProduct[]>, currentProduct: OrderProduct) => {
        if (
          !currentProduct.pet_plan ||
          ![ProductType.MEAL, ProductType.SNACK, ProductType.SUPPLEMENT].includes(
            currentProduct.product_type
          )
        )
          return productsByPetPlan;

        if (!productsByPetPlan.hasOwnProperty(currentProduct.pet_plan.pet_id)) {
          productsByPetPlan[currentProduct.pet_plan.pet_id] = [currentProduct];
        } else {
          productsByPetPlan[currentProduct.pet_plan.pet_id].push(currentProduct);
        }
        return productsByPetPlan;
      },
      {}
    );
  }, [petsNextOrder?.products]);

  return {
    pet,
    petsNextOrder,
    nextOrderDate,
    petPlanIdsInNextOrder,
    nextOrderIsMultiDog,
    productsByPet,
  };
};
