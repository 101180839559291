import { useMemo } from 'react';

import { ChargeStatus } from '@/api';
import { useAccount, useCardsExpiryStatus } from '@/hooks';

export const usePaymentError = () => {
  const account = useAccount();
  const { isPrimaryCardExpired, isPrimaryCardExpiringSoon } = useCardsExpiryStatus();

  const paymentMethodError = useMemo(() => {
    if (!account?.orders) return undefined;

    if (isPrimaryCardExpired) return 'expired';
    else if (isPrimaryCardExpiringSoon) return 'expiringSoon';
    else {
      const hasOrderWithPaymentError = account.orders?.processing.some((order) =>
        [ChargeStatus.ERROR, ChargeStatus.FAILED].includes(order?.charge?.status)
      );
      return hasOrderWithPaymentError ? 'error' : undefined;
    }
  }, [account?.orders, isPrimaryCardExpired, isPrimaryCardExpiringSoon]);
  return paymentMethodError;
};
