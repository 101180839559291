import { useNavigation } from '@react-navigation/native';
import { FlatList, Heading, VStack } from 'native-base';

import { PetAvatarButton } from './PetAvatarButton';

import { Pet, PetStatus } from '@/api';
import { useAccount } from '@/hooks';
import { ProtectedScreenProps } from '@/types';

function sortByOrderAndStatus(pets: Pet[]) {
  const petsObject = pets.reduce(
    (accum: { active: Pet[]; inactive: Pet[] }, dog: Pet) => {
      if (dog.status === PetStatus.ACTIVE) {
        accum.active.push(dog);
      }
      if (dog.status === PetStatus.PAUSED || dog.status === PetStatus.CANCELLED) {
        accum.inactive.push(dog);
      }
      return accum;
    },
    { active: [], inactive: [] }
  );

  return {
    activeDogs: petsObject.active.sort((a: Pet, b: Pet) => (a.name < b.name ? -1 : 1)),
    inactiveDogs: petsObject.inactive.sort((a: Pet, b: Pet) => (a.name < b.name ? -1 : 1)),
  };
}

export const PetsAvatarPressableList = () => {
  const account = useAccount();
  const pets = account.pets;
  const navigation = useNavigation<ProtectedScreenProps<'Home'>['navigation']>();
  const { activeDogs, inactiveDogs } = sortByOrderAndStatus(pets);

  return (
    <VStack space={{ base: '8px', lg: '24px' }} pl={{ base: '16px', lg: '0px' }} w="100%">
      <Heading
        size={{ base: 'bodyMl', md: 'titleSm' }}
        fontWeight="bold"
        textAlign={{
          base: 'left',
        }}
      >
        {`My Dog${pets?.length > 1 ? 's' : ''}`}
      </Heading>
      <FlatList
        horizontal
        width="100%"
        showsHorizontalScrollIndicator={false}
        data={[...activeDogs, ...inactiveDogs]}
        renderItem={({ item }) => (
          <PetAvatarButton
            pet={item}
            mr="16px"
            onPress={() => {
              if (item.status === PetStatus.ACTIVE) {
                navigation.navigate('Dogs', { petId: item.id, scrollToTop: true });
              } else {
                navigation.navigate('ReactivatePlan', { petId: item.id });
              }
            }}
          />
        )}
        keyExtractor={(item, index) => `${item.id}_${index}`}
      />
    </VStack>
  );
};
