import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  mx: 'auto',
  w: '100%',
  display: 'flex',
  flexDir: 'row',
  justifyContent: 'flex-start',
  alignSelf: 'flex-start',
  textAlign: 'left',
  _text: {
    color: 'error.default',
    ...responsiveSizes.bodySm,
  },
};

const variants = {};

const defaultProps = { size: 'bodySm' };

export const ErrorMessage = {
  baseStyle,
  variants,
  sizes: responsiveSizes,
  defaultProps,
};
