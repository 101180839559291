import { Stack, ISkeletonProps, Skeleton } from 'native-base';

export const FoodTypeCardSkeleton = (props: ISkeletonProps) => (
  <Stack alignSelf="center" w="100%" mb={3}>
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      space={8}
      alignItems="center"
      justifyContent="center"
    >
      {[...Array(2)].map((_, i) => (
        <Skeleton
          key={i}
          w={{ base: '100%', md: '484px' }}
          minH={{ base: '162px', md: '175px', lg: '378px' }}
          {...props}
        />
      ))}
    </Stack>
  </Stack>
);
