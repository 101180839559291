import { Box, Button, Stack } from 'native-base';
import { useState } from 'react';

import { ReschedulingCalendar } from '../../../ReschedulingCalendar';

import { ProtectedScreenProps } from '@/types';

export const ReactivatePlanRescheduleDelivery = ({
  navigation,
  route,
}: ProtectedScreenProps<'ReactivatePlanRescheduleDelivery'>) => {
  const [selectedDate, setSelectedDate] = useState<Date>(route.params.date || new Date());

  const handleDayPress = ({ day, month, year }: { day: number; month: number; year: number }) => {
    setSelectedDate(new Date(year, month - 1, day));
  };

  const onPressSubmit = () => {
    navigation.navigate('ReactivatePlan', { petId: route.params.petId, date: selectedDate });
  };

  return (
    <Stack
      w="100%"
      h="100%"
      paddingBottom="24px"
      px="16px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack w={{ base: '100%', lg: '396px' }} space="8px">
        <ReschedulingCalendar selectedDate={selectedDate} onDayPress={handleDayPress} />
      </Stack>
      <Box w={{ base: '100%', md: '357px' }}>
        <Button variant="primary" onPress={onPressSubmit}>
          Reschedule
        </Button>
      </Box>
    </Stack>
  );
};
