import { MealPlans } from '@/api';
import { axios } from '@/lib/axios';

export const getPlanOptions = async (sessionId: string) => {
  try {
    const response = await axios.get<MealPlans>(`/sessions/${sessionId}/plan_options`);
    return response.data;
  } catch (err: unknown) {
    throw new Error(`Error fetching plan options: ${err}`);
  }
};
