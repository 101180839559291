import { useNavigation, NavigationProp } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Button, IButtonProps, IStackProps, Image, HStack, Stack } from 'native-base';

import { Pill, PillProps, PillSize, PillStyle } from '@/components/Elements/Pill';
import { ASSETS_URL } from '@/constants';
import { PortalStackParamList } from '@/types';

interface NavItemProps extends IButtonProps {
  navScreen: string;
  type: 'internal' | 'external';
  isCurrentRoute?: boolean;
  stackProps?: IStackProps;
  pillText?: PillProps['children'];
}

export const navToScreen = (
  navScreen: string,
  navigation: NavigationProp<PortalStackParamList>,
  type: 'internal' | 'external'
) => {
  const sntUrl = `https://spotandtango.com/${navScreen.toLowerCase()}`;
  if (type === 'internal') {
    navigation.navigate(navScreen as never);
  } else {
    Linking.canOpenURL(sntUrl).then(() => Linking.openURL(sntUrl));
  }
};

export const NavItem = ({
  children,
  navScreen,
  type,
  isCurrentRoute = false,
  stackProps,
  pillText,
  ...props
}: NavItemProps) => {
  const navigation = useNavigation<NavigationProp<PortalStackParamList>>();

  return (
    <HStack justifyContent="flex-start" alignItems="center" space={1} {...stackProps}>
      {pillText ? (
        <Pill
          size={PillSize.Tiny}
          style={PillStyle.Tiny}
          onPress={() => navToScreen(navScreen, navigation, type)}
        >
          {pillText}
        </Pill>
      ) : null}
      <Button
        variant="portalNav"
        onPress={() => navToScreen(navScreen, navigation, type)}
        testID="navigation"
        textAlign="center"
        _text={{ color: 'black' }}
        {...props}
      >
        {children}
      </Button>
      <Stack
        w="100%"
        alignItems="center"
        position="absolute"
        bottom="-8px"
        display={isCurrentRoute ? 'block' : 'none'}
      >
        <Image
          w="100%"
          h="4px"
          resizeMode="stretch"
          alt=""
          source={{ uri: `${ASSETS_URL}/FAQ/Underline.png` }}
        />
      </Stack>
    </HStack>
  );
};
