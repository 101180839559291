import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { HStack, Skeleton, Stack, View, Text, Box, Heading, useBreakpointValue } from 'native-base';

interface ExpressCheckoutProps {
  paymentRequest?: PaymentRequest;
  isLoading?: boolean;
}

export const ExpressCheckout = ({ paymentRequest, isLoading }: ExpressCheckoutProps) => {
  const fontWeight = useBreakpointValue({ base: 'medium', lg: 'bold' });
  return (
    <Stack mb={{ base: 6, lg: 12 }}>
      <Stack flexDirection="row" alignItems="center" py={{ base: 4, lg: 6 }}>
        <Heading
          size="bodyMd"
          textAlign="center"
          color="sntGrey.primary"
          fontWeight={fontWeight}
          w="100%"
        >
          Express Checkout
        </Heading>
      </Stack>
      <Stack
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="center"
        pt={1}
        space={4}
        alignItems="center"
        w="100%"
        mb={{ base: 6, lg: 12 }}
      >
        {!isLoading && paymentRequest ? (
          <View width="100%">
            <PaymentRequestButtonElement
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    type: 'default',
                    theme: 'dark',
                    height: '50px',
                  },
                },
              }}
            />
          </View>
        ) : (
          <Skeleton h="50px" w="100%" />
        )}
      </Stack>
      <HStack w="100%" alignItems="center">
        <Box bg="sntGrey.outline" flex={1} h="1px" />
        <Text color="sntGrey.primary" size="bodyMd" fontWeight={fontWeight} px={8}>
          or
        </Text>
        <Box bg="sntGrey.outline" flex={1} h="1px" />
      </HStack>
    </Stack>
  );
};
