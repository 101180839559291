import { Box, HStack, Heading, Stack, VStack, Text, useBreakpointValue } from 'native-base';

import { CheckMark } from './CheckMark';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { Dog } from '@/types';
import { capitalize, dogAge, getBreed } from '@/utils';

export const DogFactsSection = ({ dog }: { dog: Partial<Dog> }) => {
  const rotateOnMobile = useBreakpointValue({
    base: [{ rotate: '90deg' }, { rotateX: '180deg' }],
    lg: 'none',
  });
  const lifestyle = dog.lifestyle?.replaceAll('-', ' ');
  const bodyType = dog.body_type?.replaceAll('-', ' ');
  const petAge = dogAge(dog.birth_year, dog.birth_month);
  return (
    <VStack
      w="100%"
      pt={{ base: '48px', lg: '86px' }}
      px={{ base: '16px', lg: '180px' }}
      pb={{ base: 0, lg: '264px' }}
      bg="sntBlue.primary"
      overflow="hidden"
      alignItems="center"
    >
      <Stack flexDirection={{ base: 'column', lg: 'row' }} w="100%" maxW={1079}>
        <Stack
          flexDirection={{ base: 'column', lg: 'row-reverse' }}
          alignItems={{ base: 'flex-start', lg: 'flex-end' }}
          justifyContent={{ base: 'flex-start', lg: 'space-between' }}
          w="100%"
        >
          <HStack alignItems="center">
            <VStack
              borderRadius="100%"
              borderWidth="4px"
              borderColor="sntBlue.primary75"
              justifyContent="center"
              alignItems="center"
              w="68px"
              h="68px"
            >
              <Image
                w="52px"
                h="52px"
                source={{ uri: `${ASSETS_URL}/pictos/Avatars/unkibble-speech-bubble.png` }}
                resizeMode="contain"
                alt="vet"
              />
            </VStack>
            <SpeechBox text={`${dog.name}'s plan is vet-developed.`} />
          </HStack>
          <Heading
            mt={{ base: '24px', md: 0 }}
            fontWeight="bold"
            variant="extraLargeTitle"
          >{`${dog.name} is...`}</Heading>
        </Stack>
      </Stack>
      <VStack w="100%" maxW={1079} alignItems="flex-start" zIndex={2}>
        <VStack
          w="fit-content"
          alignItems="flex-start"
          space={{ base: '25px', lg: '35px' }}
          mt="36px"
        >
          <HStack>
            <HStack space="8px">
              <CheckMark />
              <Text size="bodyMlToLg" fontWeight="medium">
                {petAge ? `${petAge} old` : 'Age unknown'}
              </Text>
            </HStack>
            <Stack
              position="absolute"
              left={{ base: '-75px', lg: 165 }}
              top={{ base: '110px', lg: 0 }}
              style={{ transform: rotateOnMobile }}
            >
              <Image
                source={{ uri: `${ASSETS_URL}/pictos/Illustrations/DashedLineTop.png` }}
                w={{ base: '222px', lg: '492px' }}
                h={{ base: '75px', lg: '165px' }}
                alt="dashed line"
              />
            </Stack>
          </HStack>
          <HStack pl="48px">
            <HStack space="8px">
              <CheckMark />
              <Text size="bodyMlToLg" fontWeight="medium">{`"${capitalize(
                bodyType
              )}" Body Type`}</Text>
            </HStack>
            <Stack
              position="absolute"
              left={{ base: '-56px', lg: 295 }}
              top={{ base: '110px', lg: '-36px' }}
              style={{ transform: rotateOnMobile }}
            >
              <Image
                source={{ uri: `${ASSETS_URL}/pictos/Illustrations/DashedLineMiddle.png` }}
                w={{ base: '222px', lg: '420px' }}
                h={{ base: '75px', lg: '104px' }}
                alt="dashed line"
              />
            </Stack>
          </HStack>
          <HStack pl="96px">
            <HStack space="8px">
              <CheckMark />
              <Text size="bodyMlToLg" fontWeight="medium">{`${capitalize(
                lifestyle
              )} Lifestyle`}</Text>
            </HStack>
            <Stack
              position="absolute"
              left={{ base: '24px', lg: 290 }}
              top={{ base: '110px', lg: '-36px' }}
              style={{ transform: rotateOnMobile }}
            >
              <Image
                source={{ uri: `${ASSETS_URL}/pictos/Illustrations/DashedLineBottom.png` }}
                w={{ base: '222px', lg: '548px' }}
                h={{ base: '75px', lg: '218px' }}
                alt="dashed line"
              />
            </Stack>
          </HStack>
          <HStack pl="144px" space="8px">
            <CheckMark />
            <Text size="bodyMlToLg" fontWeight="medium">
              {getBreed(dog.breeds)}
            </Text>
          </HStack>
        </VStack>
      </VStack>
      <Stack
        position={{ base: 'relative', lg: 'absolute' }}
        bottom={{ base: -37, lg: -177 }}
        right={{ base: 0, lg: 187 }}
        zIndex={1}
      >
        <Image
          source={{ uri: `${ASSETS_URL}/pictos/Illustrations/dog-profile.png` }}
          w={{ base: '343px', lg: '589px' }}
          h={{ base: '378px', lg: '589px' }}
          alt="dog drawing"
        />
      </Stack>
    </VStack>
  );
};

const SpeechBox = ({ text }: { text: string }) => (
  <HStack alignItems="center">
    <Box
      w="0"
      h="0"
      borderStyle="solid"
      borderTopWidth="10px"
      borderTopColor="transparent"
      borderBottomWidth="10px"
      borderBottomColor="transparent"
      borderRightWidth="10px"
      borderRightColor="white"
    />
    <VStack bg="white" w="fit-content" h="fit-content" py="12px" px="16px">
      <Text fontSize="body.md" lineHeight="body.md" fontWeight="medium">
        {text}
      </Text>
    </VStack>
  </HStack>
);
