import { useMutation } from 'react-query';

import { PetStatus } from '@/api/types';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

const cancelPet = async ({
  petId,
  petStatus,
  primaryCancellationReason,
  secondaryCancellationReason,
  cancellationReasonComments,
}: {
  petId: string;
  petStatus?: PetStatus;
  primaryCancellationReason?: string;
  secondaryCancellationReason?: string;
  cancellationReasonComments?: string;
}) =>
  axios
    .post(`/pets/${petId}/cancel`, {
      pet_status: petStatus,
      primary_cancellation_reason: primaryCancellationReason,
      secondary_cancellation_reason: secondaryCancellationReason,
      cancellation_reason_comments: cancellationReasonComments,
    })
    .then((response) => response.data);

export const useCancelPet = () => {
  const { refetchUser } = useAuth();

  return useMutation(
    ({
      petId,
      petStatus,
      primaryCancellationReason,
      secondaryCancellationReason,
      cancellationReasonComments,
    }: {
      petId: string;
      petStatus?: PetStatus;
      primaryCancellationReason?: string;
      secondaryCancellationReason?: string;
      cancellationReasonComments?: string;
    }) =>
      cancelPet({
        petId,
        petStatus,
        primaryCancellationReason,
        secondaryCancellationReason,
        cancellationReasonComments,
      }),
    {
      onSuccess: () => refetchUser(),
    }
  );
};
