import * as Linking from 'expo-linking';

import { Banner, BannerProps } from './Banner';

export const GetStartedBanner = ({ ...props }: BannerProps) => {
  const handleGetStarted = () => {
    const redirect_uri = Linking.createURL('/');
    Linking.openURL(redirect_uri);
  };

  return (
    <>
      <Banner
        onPress={handleGetStarted}
        borderWidth={{ base: '2px', lg: '3px' }}
        textAlign="center"
        headingProps={{
          fontWeight: 'bold',
        }}
        {...props}
      >
        NEW CUSTOMERS GET 20% OFF!
      </Banner>
    </>
  );
};
