import { Input as NBInput } from 'native-base';
import { useState } from 'react';
import { Merge } from 'type-fest';

type NBInputTypes = typeof NBInput;

export type InputProps = {
  type: 'text' | 'password';
  className?: string;
  error?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  onBlur?: () => void;
  $$typeof?: symbol;
  keyboardType?:
    | 'default'
    | 'number-pad'
    | 'decimal-pad'
    | 'numeric'
    | 'email-address'
    | 'phone-pad'
    | 'url';
  autoCompleteType?:
    | 'username'
    | 'password'
    | 'cc-number'
    | 'cc-cvc'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'off';
};

type NBInputProps = Merge<NBInputTypes, InputProps>;
export const Input = ({
  type = 'text',
  placeholder = `Input`,
  value = '',
  keyboardType = 'default',
  ...props
}: NBInputProps) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (text: string) => {
    setInputValue(text);
    props.onChangeText && props.onChangeText(text);
  };

  return (
    <NBInput
      type={type}
      placeholder={placeholder}
      value={inputValue}
      variant="unstyled"
      onChange={(e) => handleChange(e.nativeEvent.text)}
      keyboardType={keyboardType}
      {...props}
    />
  );
};

Input.displayName = 'Input';
