import { Badge } from 'native-base';
import { useMemo } from 'react';

import { Order, OrderStatus } from '@/api/types';

enum PillStatus {
  'REFUNDED' = 'Refunded',
  'PARTIALLY_REFUNDED' = 'Partially Refunded',
  'CANCELLED' = 'Cancelled',
}

export const OrderStatusPill = ({ order }: { order: Order }) => {
  const pillText = useMemo(() => {
    if (order.total_price && [OrderStatus.COMPLETE, OrderStatus.CANCELLED].includes(order.status)) {
      if (order.total_refunds && Number(order.total_refunds) > 0) {
        if (Number(order.total_refunds) >= Number(order.total_price)) {
          return PillStatus.REFUNDED;
        }
        return PillStatus.PARTIALLY_REFUNDED;
      }
      if (order.status === OrderStatus.CANCELLED) {
        return PillStatus.CANCELLED;
      }
    }

    return null;
  }, [order.status, order.total_price, order.total_refunds]);

  return pillText ? <Badge variant="smallTabActive">{pillText}</Badge> : null;
};
