import { axios } from '@/lib/axios';

export interface CreatePasswordData {
  password: string;
  password_confirm: string;
  old_password: string;
}

/**
 * Updates user's password
 */
export const update_password = ({
  password,
  password_confirm,
  old_password,
}: CreatePasswordData) => {
  return axios.post(
    `/update_password`,
    {
      password,
      password_confirm,
      old_password,
    },
    { noErrorToast: true }
  );
};
