import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect } from '@react-navigation/native';
import { Stack } from 'native-base';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FormSubmitButton } from '../../FormSubmitButton';
import { NotificationPreferencesBlock } from './components/NotificationPreferencesBlock';

import { useUpdateAccount, getMarketingSubscriptions } from '@/api';
import { ContactPreference } from '@/api/types/accountServices';
import { ToastType, displayToast } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { sendErrorReport } from '@/utils/analytics';

const { logger } = datadogLogs;
export interface NotificationPreferencesFieldValues {
  news_offers_email: boolean;
  news_offers_text: boolean;
}

export const NotificationPreferences = ({ navigation }: any) => {
  const account = useAccount();
  const { mutateAsync: updateAccount } = useUpdateAccount();
  const [isLoading, setIsLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const { control, handleSubmit, formState, reset } = useForm<NotificationPreferencesFieldValues>();

  useFocusEffect(
    useCallback(() => {
      let isActive = true;
      (async () => {
        setIsLoading(true);
        setErrorLoading(false);
        try {
          const subscriptions = await getMarketingSubscriptions(account?.id);
          if (isActive) {
            reset({
              news_offers_email: subscriptions?.email,
              news_offers_text: subscriptions?.sms,
            });
          }
        } catch (error) {
          logger.error('Error fetching subscriptions: ', { error });
          if (isActive) {
            setErrorLoading(true);
          }
        }
        if (isActive) {
          setIsLoading(false);
        }
      })();
      return () => {
        isActive = false;
      };
    }, [account?.id, reset])
  );

  const accountHasPhone = !!account?.phone_number;

  const onSubmit: SubmitHandler<NotificationPreferencesFieldValues> = async (data) => {
    try {
      let contact_preference_marketing = ContactPreference.NONE;
      if (data.news_offers_email && data.news_offers_text) {
        contact_preference_marketing = ContactPreference.ANY;
      } else if (data.news_offers_email) {
        contact_preference_marketing = ContactPreference.EMAIL;
      } else if (data.news_offers_text) {
        contact_preference_marketing = ContactPreference.TEXT;
      }

      await updateAccount({
        contact_preference_marketing,
      });

      navigation.navigate('Account');
      displayToast({
        message: 'Your notification preferences have been updated.',
        type: ToastType.Success,
        duration: 3000,
      });

      if (data.news_offers_text) {
        displayToast({
          title: 'Thank you for signing up for texts from Spot & Tango! ',
          message:
            'In a few moments, you should receive a text from us asking ' +
            'you to confirm your consent to receive text messages. ' +
            'After you respond to this text, your communication preferences ' +
            'will be saved here in your account.',
          type: ToastType.Success,
          duration: 3000,
        });
      }
    } catch (error) {
      sendErrorReport(error);
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      alignSelf="center"
      h="100%"
      w={{ base: '100%', md: '492px' }}
      px={{ base: '16px', md: 0 }}
      pb="24px"
      pt={{ base: '8px', md: '24px' }}
      space={{ base: 0, md: '24px' }}
    >
      <Stack space={{ base: '16px', lg: '24px' }}>
        <NotificationPreferencesBlock
          accountHasPhone={accountHasPhone}
          control={control}
          controllerType="order"
          emailEnabled={false}
          heading="Order Reminders"
          text="We'll remind you when your next box is close to shipping so you can make any desired changes."
        />
        <NotificationPreferencesBlock
          accountHasPhone={accountHasPhone}
          control={control}
          controllerType="delivery"
          emailEnabled={false}
          heading="Delivery Updates"
          text="We'll update you as your order travels from our kitchen to your door."
        />
        <NotificationPreferencesBlock
          isLoading={isLoading}
          errorLoading={errorLoading}
          accountHasPhone={accountHasPhone}
          control={control}
          controllerType="news_offers"
          emailEnabled
          heading="News and Offers"
          text="We'll keep you in the loop on new products, limited-time promotions, and exclusive events in your city, among other things!"
        />
      </Stack>
      <Stack
        variant="stickyFooter"
        px={4}
        style={{ shadowOffset: { width: 0, height: 0 } }}
        shadow="none"
      >
        <FormSubmitButton
          isDisabled={formState.isSubmitting}
          onPress={handleSubmit(onSubmit)}
          w="100%"
        >
          Save Changes
        </FormSubmitButton>
      </Stack>
    </Stack>
  );
};
