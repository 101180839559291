import { NativeStackScreenProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { useMemo } from 'react';

import { FormScreenHeader } from '../Home/FormScreenHeader';
import { AddNewDogComponents } from './AddNewDog';

import { AddNewDogScreens } from '@/constants';
import { AddNewDogContextProvider } from '@/context';

export type AddNewDogStackParamList = {
  DogName: undefined;
  Gender: undefined;
  Neutered: undefined;
  Breed: undefined;
  Birthday: undefined;
  BodyType: undefined;
  Weight: undefined;
  Lifestyle: undefined;
  HealthConditions: undefined;
  HealthConditionsList: undefined;
  FoodTypes: undefined;
  Recipes: undefined;
  Calculating: undefined;
  MealPlan: undefined;
  AdjustPortionSize: undefined;
  Snacks: undefined;
  Supplements: undefined;
  PlanSummary: undefined;
  RescheduleDelivery: undefined;
};

export type AddNewDogScreenProps<T extends keyof AddNewDogStackParamList> = NativeStackScreenProps<
  AddNewDogStackParamList,
  T
>;

export const AddNewDogQuestionnaire = () => {
  const Stack = createNativeStackNavigator<AddNewDogStackParamList>();
  const AddNewDogStack = useMemo(
    () =>
      Object.entries(AddNewDogComponents).map(([name, screen]) => (
        <Stack.Screen
          key={name}
          name={name as keyof AddNewDogStackParamList}
          component={
            screen as React.ComponentType<AddNewDogScreenProps<keyof AddNewDogStackParamList>>
          }
          options={
            name === 'AdjustPortionSize'
              ? {
                  header: FormScreenHeader,
                }
              : { headerShown: false }
          }
        />
      )),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AddNewDogScreens.length]
  );

  return (
    <AddNewDogContextProvider>
      <Stack.Navigator initialRouteName="DogName">{AddNewDogStack}</Stack.Navigator>
    </AddNewDogContextProvider>
  );
};
