import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="currentColor"
    {...props}
  >
    <Path d="M2.25 4.5h13.5V6H2.25V4.5Zm0 3.75h13.5v1.5H2.25v-1.5Zm0 3.75h13.5v1.5H2.25V12Z" />
  </Svg>
);
function SvgHamburgerMenu({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgHamburgerMenu;
