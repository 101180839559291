export const lineHeights = {
  largeTitle: '60px',
  title: {
    lg: '48px',
    ml: '44px',
    md: '40px',
    sm: '30px',
  },
  body: {
    lg: '24px',
    md: '20px',
    sm: '18px',
  },
  caption: '16px',
  tiny: '12px',
};
