import { Box, Stack, Text } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
const images = `${ASSETS_URL}/pictos/Icons/`;

interface CheckoutFormImageProps extends IHStackProps {
  source: string;
}

export const CheckoutFormImage = ({ source, children, ...props }: CheckoutFormImageProps) => {
  return (
    <Stack
      flexDirection={{ base: 'column', lg: 'row' }}
      alignItems="center"
      mx={{ base: 2, md: 6 }}
      w={{ base: '20%', lg: 'auto' }}
      {...props}
    >
      <Box mr={{ lg: 3 }} mb={{ base: 3, lg: 0 }}>
        <Image
          source={{
            uri: `${images}${source}.png`,
          }}
          alt={source}
          resizeMode="contain"
          w={{ base: 9, lg: 7 }}
          h={{ base: 9, lg: 7 }}
        />
      </Box>

      <Text size="bodySmToMd" fontWeight="medium" textAlign="center">
        {children}
      </Text>
    </Stack>
  );
};
