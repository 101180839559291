export const round = (value: string | number, decimals: string | number) => {
  const val = value.toString();
  const dec = decimals.toString();
  return Number(Math.round(Number(val + 'e' + dec)) + 'e-' + dec).toFixed(Number(decimals));
};

export const floor = (value: string | number, decimals: string | number) => {
  const val = value.toString();
  const dec = decimals.toString();
  return Number(Math.floor(Number(val + 'e' + dec)) + 'e-' + dec).toFixed(Number(decimals));
};

export const range = (start: number, end: number) => {
  return Array.from({ length: end }, (_, i) => i).slice(start);
};
