import { useMutation, useQuery } from 'react-query';

import { BuildPlan } from '@/api/types';
import { axios } from '@/lib/axios';

type PlanDetails = {
  plan_size?: number;
  recipes?: string[];
  discounts?: string[];
  session_id?: string;
  account_id?: string;
};

export const getBuildPlans = (plan: PlanDetails, noErrorToast?: boolean) => {
  return axios
    .post<BuildPlan>(`/calculator/build_plan`, plan, { noErrorToast })
    .then((res) => res.data);
};

export const useBuildPlansRegularPlan = (plan: PlanDetails) => {
  return useQuery<BuildPlan>(['RegularPlan', plan.recipes?.join()], {
    queryFn: () => getBuildPlans(plan),
    enabled: !!plan,
  });
};

export const useCallBuildPlansRegularPlan = () => {
  return useMutation<BuildPlan, Error, PlanDetails>((plan: PlanDetails) => getBuildPlans(plan));
};

export const useBuildTopperPlan = (plan: PlanDetails) => {
  return useQuery<BuildPlan>(['TopperPlan', plan.recipes?.join()], {
    queryFn: () => getBuildPlans(plan),
    enabled: !!plan,
  });
};

export const useCallBuildTopperPlan = () => {
  return useMutation<BuildPlan, Error, PlanDetails>((plan: PlanDetails) => getBuildPlans(plan));
};
