import { ISkeletonProps, Skeleton } from 'native-base';

export const CardBrandSkeleton = (props: ISkeletonProps) => (
  <Skeleton
    w={{ base: '40px', lg: '40px' }}
    h={{ base: '30px', lg: '30px' }}
    borderRadius="4px"
    {...props}
  />
);
