import { HStack, Skeleton, VStack } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';

export const PictoGroupSkeleton = ({
  numPictos,
  ...props
}: { numPictos: number } & IHStackProps) => {
  return (
    <HStack space={{ base: 7, lg: 9 }} {...props}>
      {[...Array(numPictos)].map((_, i) => (
        <VStack key={i} w={{ base: '36px', lg: '48px' }} space={{ base: 4 }}>
          <Skeleton
            w="100%"
            borderRadius={{ base: '8px', lg: '16px' }}
            h={{ base: '36px', lg: '48px' }}
          />
          <Skeleton.Text lines={1} w="100%" />
        </VStack>
      ))}
    </HStack>
  );
};
