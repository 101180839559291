import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

import { FunnelScreenNames } from '@/constants';
import { useCountdownTimerContext, useDogsLastVisitedScreenContext } from '@/context';
import { queryClient } from '@/lib/react-query';
import { Dog, FunnelSession, FunnelStackParamList } from '@/types';

type LatestQuestionData = {
  dogNumber: number;
  latestQuestion: FunnelScreenNames;
};

export const useNavigateToLatestQuestion = () => {
  const navigation = useNavigation<NavigationProp<FunnelStackParamList>>();
  const { start, visibilityOfTimer } = useCountdownTimerContext();
  const { setDogsVisitedScreens } = useDogsLastVisitedScreenContext();

  return useCallback((session: FunnelSession | undefined) => {
    if (!session) return;

    const { dogNumber, latestQuestion } = getLatestQuestion(session);
    session.current_pet_index = dogNumber;
    queryClient.setQueryData<FunnelSession>(['session'], {
      ...session,
    });

    if (visibilityOfTimer(latestQuestion)) {
      start();
    }

    const dogsVisitedScreens = { [dogNumber]: latestQuestion };

    for (let dogIndex = dogNumber - 1; dogIndex >= 0; dogIndex--) {
      dogsVisitedScreens[dogIndex] = FunnelScreenNames.SUPPLEMENTS;
    }

    setDogsVisitedScreens(dogsVisitedScreens);

    navigation.navigate(latestQuestion as any);
  }, []);
};

const getLatestQuestion = (session: FunnelSession): LatestQuestionData => {
  const { number_of_pets: number_of_dogs, zip_code, pets } = session;

  if (!number_of_dogs || !pets) {
    return { dogNumber: 0, latestQuestion: FunnelScreenNames.HOW_MANY_DOGS };
  }
  if (pets?.length && pets.some((dog) => !dog.name)) {
    return { dogNumber: 0, latestQuestion: FunnelScreenNames.DOG_NAME };
  }
  if (!zip_code) {
    return { dogNumber: 0, latestQuestion: FunnelScreenNames.LOCATION };
  }

  const result = {
    dogNumber: number_of_dogs - 1,
    latestQuestion: FunnelScreenNames.CHECKOUT,
  };
  for (const [index, dog] of pets.entries()) {
    result.dogNumber = index;

    const missingAnswer = (answerName: keyof Partial<Dog>) => {
      if (dog.hasOwnProperty(answerName) && Array.isArray(dog[answerName])) {
        const parsed_array = dog[answerName] as any[];
        return parsed_array.length === 0;
      }
      return (
        !dog.hasOwnProperty(answerName) || dog[answerName] === undefined || dog[answerName] === null
      );
    };

    if (missingAnswer('gender')) {
      result.latestQuestion = FunnelScreenNames.GENDER;
      break;
    }
    if (missingAnswer('is_neutered')) {
      result.latestQuestion = FunnelScreenNames.NEUTERED;
      break;
    }
    if (missingAnswer('breeds')) {
      result.latestQuestion = FunnelScreenNames.BREED;
      break;
    }
    if (missingAnswer('birth_year')) {
      result.latestQuestion = FunnelScreenNames.BIRTHDAY;
      break;
    }
    if (missingAnswer('body_type')) {
      result.latestQuestion = FunnelScreenNames.BODY_TYPE;
      break;
    }
    if (missingAnswer('weight')) {
      result.latestQuestion = FunnelScreenNames.WEIGHT;
      break;
    }
    if (missingAnswer('lifestyle')) {
      result.latestQuestion = FunnelScreenNames.LIFESTYLE;
      break;
    }
    if (!dog?.hasOwnProperty('health_conditions')) {
      result.latestQuestion = FunnelScreenNames.HEALTH_CONDITIONS;
      break;
    }
    if (missingAnswer('food_type')) {
      result.latestQuestion = FunnelScreenNames.FOOD_TYPES;
      break;
    }
    if (missingAnswer('recipes')) {
      result.latestQuestion = FunnelScreenNames.RECIPES;
      break;
    }
    if (missingAnswer('plan_type')) {
      result.latestQuestion = FunnelScreenNames.MEAL_PLAN;
      break;
    }
    if (!dog?.hasOwnProperty('snacks')) {
      result.latestQuestion = FunnelScreenNames.SNACKS;
      break;
    }
    if (!dog?.hasOwnProperty('supplements')) {
      result.latestQuestion = FunnelScreenNames.SUPPLEMENTS;
      break;
    }
  }

  return result;
};
