import { Badge, HStack } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';

import { CarrierLogo } from '../CarrierLogo/CarrierLogo';

import { CarrierCode } from '@/api';

export enum TabSize {
  Large,
  Small,
}

export enum TabStatus {
  Active,
  ActiveSecondary,
  Inactive,
  Tracking,
}

export enum TabStyle {
  Filled,
  Outline,
}

interface TabProps {
  children: IHStackProps['children'];
  status?: TabStatus;
  isDisabled?: boolean;
  style?: TabStyle;
  size?: TabSize;
  carrierCode?: CarrierCode | null;
}

const getBackgroundColor = (status: TabStatus, style: TabStyle) => {
  if (status === TabStatus.Inactive && style === TabStyle.Filled) {
    return 'sntGrey.secondary';
  }
  if (status === TabStatus.Active && style === TabStyle.Outline) {
    return 'white';
  }
  if (status === TabStatus.ActiveSecondary) {
    return 'sntBlue.primary25';
  }
  return 'sntBlue.primary';
};

const getBorderColor = (style: TabStyle) => {
  if (style === TabStyle.Outline) {
    return 'black';
  }
  return '';
};

export const Tab = ({
  children,
  status = TabStatus.Active,
  style = TabStyle.Filled,
  carrierCode = null,
}: TabProps) => {
  return (
    <Badge
      borderRadius={{ base: '4px', lg: '8px' }}
      px={{ base: '4px', lg: '8px' }}
      py={{ base: '2px', lg: '4px' }}
      bg={getBackgroundColor(status, style)}
      borderWidth={style === TabStyle.Outline ? '1px' : '0px'}
      borderColor={getBorderColor(style)}
    >
      <HStack space="xs">
        {status === TabStatus.Tracking && (
          <CarrierLogo
            mt={{ base: '1px', lg: '0px' }}
            w={{ base: '12.6px', lg: '18px' }}
            h={{ base: '14px', lg: '20px' }}
            carrierCode={carrierCode}
          />
        )}
        {children}
      </HStack>
    </Badge>
  );
};
