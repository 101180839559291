import { useMutation } from 'react-query';

import { useAuth } from '@/hooks';
import { axios } from '@/lib/axios';

const patchDeliveryDate = async ({
  orderId,
  newDate,
}: {
  orderId: string;
  newDate: string | Date;
}) => {
  axios.patch(`/orders/${orderId}`, { scheduled: newDate }).then((response) => response.data);
};
export const useUpdateDeliveryDate = () => {
  const { refetchUser } = useAuth();

  return useMutation(
    ({ orderId, newDate }: { orderId: string; newDate: string | Date }) =>
      patchDeliveryDate({ orderId, newDate }),
    { onSuccess: () => refetchUser() }
  );
};
