import { Stack, HStack, Divider, Text } from 'native-base';
import { useMemo } from 'react';

import { AddonSection } from './AddonSection';
import { MealSection } from './MealSection';

import { Avatar } from '@/components/Elements';
import { useAddNewDogContext } from '@/context';

export const AddNewDogPlanSummary = () => {
  const { petData, petPlan, snacks, supplements } = useAddNewDogContext();
  const hasAddons = useMemo(
    () => Object.keys(snacks).length + Object.keys(supplements).length > 0,
    [snacks, supplements]
  );
  if (!petPlan) {
    return null;
  }

  return (
    <Stack w="100%" variant="card" space={4}>
      <Stack flex={1} alignItems="start" space={{ base: '8px', lg: '16px' }}>
        <HStack w="100%" justifyContent="space-between">
          <Avatar
            name={`${petData.name}'s Plan`}
            nameProps={{
              size: 'bodyMlToTitleSm',
              fontWeight: 'bold',
            }}
            imageSize={{ base: '24px', lg: '36px' }}
            containerProps={{ direction: 'row', space: '8px' }}
          />
        </HStack>
        <Text size="bodySmToMd" color="sntGrey.primary">
          Delivered every {petPlan.subscription.product.frequency} weeks
        </Text>
      </Stack>
      <Divider bg="gallery" />
      <MealSection petData={petData} petPlan={petPlan} />
      {hasAddons ? (
        <>
          <Divider bg="gallery" />
          <AddonSection />
        </>
      ) : null}
    </Stack>
  );
};
