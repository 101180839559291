import { Heading, InfoOutlineIcon, Text, Tooltip, VStack } from 'native-base';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';

export const ScoopSection = ({
  dogName,
  scoopsPerDay,
}: {
  dogName: string;
  scoopsPerDay: number | undefined;
  scoopCalories: number | undefined;
}) => {
  return (
    <VStack w={{ base: '100%', lg: '1160px' }} alignItems="center">
      <VStack
        bg="sntGrey.athens"
        pt="55px"
        pb={{ base: '335px', lg: '296px' }}
        w="100%"
        justifyContent="center"
        alignItems="center"
        zIndex={1}
      >
        <Image
          source={{ uri: `${ASSETS_URL}/heroes/Unkibble/scoop-in-hand.png` }}
          alt="scoop in hand"
          resizeMode="cover"
          position="absolute"
          w="100%"
          h="575px"
          top={0}
          right={0}
          zIndex={2}
        />
        <VStack
          space="16px"
          w={{ base: '100%', lg: '400px' }}
          alignItems="center"
          px={{ base: '16px', lg: 0 }}
          zIndex={3}
          height="250px"
        >
          <VStack>
            <Heading
              size="titleLg"
              lineHeight="titleLg"
              textAlign="center"
              position="absolute"
              top={-20}
              left={0}
              right={0}
              margin="auto"
              fontWeight="medium"
            >{`${dogName}'s`}</Heading>
            <Heading fontSize="77px" lineHeight="106px" textAlign="center" fontWeight="medium">
              Scoop
            </Heading>
          </VStack>
          <Text color="black" size="bodyMl" fontWeight="medium" textAlign="center">
            {`Your trial comes with a free scoop molded just for ${dogName}. We recommend feeding ${dogName} ${scoopsPerDay} scoops a day.`}
          </Text>
          <Tooltip
            label={`All this info will be included in ${dogName}'s feeding guide, included in your trial box.`}
            placement="bottom"
            openDelay={200}
            maxW="250px"
            bg="white"
            rounded={0}
            shadow={1}
            _text={{
              color: 'black',
              fontFamily: 'secondary',
              fontSize: 'body.sm',
              lineHeight: 'body.sm',
              textAlign: 'center',
            }}
          >
            <InfoOutlineIcon color="black" size="24px" />
          </Tooltip>
        </VStack>
      </VStack>
    </VStack>
  );
};
