import { ISkeletonProps, Skeleton } from 'native-base';

const variants = {
  base: {
    w: { base: '100%', md: '360px' },
    h: { base: '52px', lg: '56px' },
  },
  portal: {
    w: '100%',
    h: { base: '52px', lg: '56px' },
  },
};

interface InputSkeletonProps extends ISkeletonProps {
  variant: keyof typeof variants;
}

export const InputSkeleton = ({ variant = 'base', ...props }: InputSkeletonProps) => (
  <Skeleton {...variants[variant]} {...props} />
);
