import { Banner } from '.';

import { useAuth } from '@/hooks';

export const ImpersonateBanner = () => {
  const { user } = useAuth();
  return (
    <Banner bg="error.default">
      You are currently logged in as {`${user?.first_name} ${user?.last_name} – ${user?.email}`}
    </Banner>
  );
};
