import {
  Heading,
  IHeadingProps,
  IModalProps,
  IStackProps,
  ITextProps,
  Modal,
  Stack,
  Text,
} from 'native-base';
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box';

export interface ModalHeaderProps extends InterfaceBoxProps<IModalProps> {
  heading: IHeadingProps['children'];
  subHeading?: ITextProps['children'];
  contentStackProps?: IStackProps;
  headingProps?: IHeadingProps;
  subHeadingProps?: ITextProps;
}

export const ModalHeader = ({
  heading,
  subHeading,
  contentStackProps,
  headingProps,
  subHeadingProps,
  ...props
}: ModalHeaderProps) => (
  <Modal.Header p={0} bgColor="transparent" borderBottomStyle="none" w="100%" {...props}>
    <Stack
      space="8px"
      flex={1}
      justifyContent="center"
      alignItems="center"
      px={{ base: 4, md: 10 }}
      {...contentStackProps}
    >
      <Heading textAlign="center" size="bodyMlToTitleSm" fontWeight="bold" {...headingProps}>
        {heading}
      </Heading>
      {subHeading ? (
        <Text
          textAlign="center"
          size="bodySmToMd"
          fontWeight="medium"
          fontFamily="secondary"
          {...subHeadingProps}
        >
          {subHeading}
        </Text>
      ) : null}
    </Stack>
  </Modal.Header>
);
