import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import {
  StripeError,
  StripeElementsOptions,
  PaymentRequest,
  PaymentRequestShippingAddressEvent,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js';
import axios from 'axios';
import { Spacer, View, Heading, Text } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';

import { PreparingCheckoutAnimation } from './PreparingCheckoutAnimation';
import {
  OrderSummary,
  CheckoutFormBilling,
  ExpressCheckout,
  CheckoutFormShipping,
} from './components';
import { trackCheckoutStarted } from './trackCheckoutStarted';
import { trackOrderCompleted } from './trackOrderCompleted';

import { Cart, ContactPreference } from '@/api';
import { trackEvent } from '@/api/services/track/track_event';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useTaxRates, useFriendbuy, useUTT } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FALLBACK_TAX_RATE } from '@/hooks/useTaxRates';
import { queryClient } from '@/lib/react-query';
import { CheckoutFormResolver } from '@/screens';
import { useStripeElementsOptionsBase, stripePromise } from '@/stripe';
import { FunnelScreenProps } from '@/types';
import { invalidAddressErrorHandler } from '@/utils';
import { sanitizeState } from '@/utils/format/formatState';

const { logger } = datadogLogs;

const DEFAULT_CHECKOUT_ERROR_MESSAGE =
  'There was a problem with your request. Please try again or contact care@spotandtango.com for further assistance.';

const calculateCartTotal = (cartPreview: Cart['cart_preview']) =>
  Math.round(Number(cartPreview?.prices?.total_to_be_charged) * 100);

export interface CheckoutDetails {
  first_name: string;
  last_name: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  readonly country: 'US';
  zip_code: string;
  phone_number: string;
  to_receive_text_messages: boolean;
}

export default function Checkout({ navigation }: FunnelScreenProps<'Checkout'>) {
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
  const isFocused = useIsFocused();
  useFocusEffect(
    useCallback(() => {
      return () => setShowLoadingAnimation(true);
    }, [])
  );

  if (!isFocused) return null;

  return (
    <>
      {showLoadingAnimation ? <PreparingCheckoutAnimation /> : null}
      <CheckoutContent navigation={navigation} onReady={() => setShowLoadingAnimation(false)} />
    </>
  );
}

const CheckoutContent = ({
  navigation,
  onReady,
}: {
  navigation: FunnelScreenProps<'Checkout'>['navigation'];
  onReady: () => void;
}) => {
  const { prepareCheckout, session } = useSessionQuery();
  const [clientSecret, setClientSecret] = useState<string>('');
  const [intentObjectType, setIntentObjectType] =
    useState<Cart['stripe_payment_intent_object']>('payment_intent');
  const [cartPreview, setCartPreview] = useState<Cart['cart_preview']>();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { showBoundary } = useErrorBoundary();

  useFocusEffect(
    useCallback(() => {
      const doPrepareCheckout = async () => {
        try {
          await prepareCheckout(true);
        } catch (error) {
          if (axios.isAxiosError(error) && error?.response?.status === 400) {
            showBoundary(error);
          } else {
            funnelErrorHandler(error, 'Prepare Checkout');
          }
          return;
        }
        const { stripe_payment_intent_secret, stripe_payment_intent_object, cart_preview } =
          queryClient.getQueryData(['cart']) as Cart;
        setClientSecret(stripe_payment_intent_secret);
        setIntentObjectType(stripe_payment_intent_object);

        setCartPreview(cart_preview);
        onReady();
      };
      // wait for queryClient to be ready
      doPrepareCheckout();
    }, [])
  );

  useEffect(() => {
    if (session && cartPreview) {
      try {
        trackCheckoutStarted({ cartPreview, session });
      } catch (e) {
        console.error('Error sending Segment call', e);
      }
    }
  }, [cartPreview]);

  const methods = useForm<CheckoutDetails>({
    resolver: CheckoutFormResolver,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip_code: '',
      to_receive_text_messages: false,
    },
    mode: 'onSubmit',
  });

  const elementsOptionsBase = useStripeElementsOptionsBase();

  if (cartPreview?.prices?.total_to_be_charged === undefined || !clientSecret) {
    return null;
  }

  const elementsOptions: StripeElementsOptions = {
    ...elementsOptionsBase,
    clientSecret,
  };

  const onCartUpdate = () => {
    const { stripe_payment_intent_secret, stripe_payment_intent_object, cart_preview } =
      queryClient.getQueryData(['cart']) as Cart;
    setClientSecret(stripe_payment_intent_secret);
    setIntentObjectType(stripe_payment_intent_object);
    setCartPreview(cart_preview);
    queryClient.invalidateQueries('cart');
  };

  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      <CheckoutFormInner
        methods={methods}
        cartPreview={cartPreview}
        intentObjectType={intentObjectType}
        navigation={navigation}
        onReady={onReady}
        onCartUpdate={onCartUpdate}
      />
    </Elements>
  );
};

const CheckoutFormInner = ({
  navigation,
  methods,
  cartPreview,
  intentObjectType,
  onReady,
  onCartUpdate,
}: {
  navigation: FunnelScreenProps<'Checkout'>['navigation'];
  methods: UseFormReturn<CheckoutDetails, any>;
  cartPreview: Cart['cart_preview'];
  intentObjectType: Cart['stripe_payment_intent_object'];
  onReady: () => void;
  onCartUpdate: () => void;
}) => {
  const nextStep = useNextFunnelStep(FunnelScreenNames.CHECKOUT);
  const { session, removeDiscount, applyDiscounts, validateCheckout, prepareCheckout, finalize } =
    useSessionQuery();

  const stripe = useStripe();
  const elements = useElements();
  const { isLoading: isLoadingTaxRate, getTaxRateByZipCode } = useTaxRates();
  const [checkoutSubmitError, setCheckoutSubmitError] = useState<string | undefined>();
  const [expressPaymentSubmitError, setExpressPaymentSubmitError] = useState<string | undefined>();
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string | undefined>();
  const [discountError, setDiscountError] = useState<string | undefined>();
  const [isLoadingCanMakeExpressPayment, setIsLoadingCanMakeExpressPayment] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expressPaymentRequest, setExpressPaymentRequest] = useState<PaymentRequest>();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { trackFunnelCheckoutOrder } = useFriendbuy();
  const { trackImpactConversion } = useUTT();
  const showCheckoutSubmitError = (message?: any) =>
    setCheckoutSubmitError((prev) => message || prev || DEFAULT_CHECKOUT_ERROR_MESSAGE);

  const onApplyDiscount = async (discountCode: string) => {
    setIsLoading(true);
    setDiscountError(undefined);
    try {
      await applyDiscounts([discountCode]);
      await prepareCheckout();
    } catch (error) {
      funnelErrorHandler(error, 'Apply discounts', () => {
        setDiscountError('Discount code is invalid.');
      });
    }
    onCartUpdate();
    setIsLoading(false);
  };

  const onRemoveDiscount = async (discountCode: string) => {
    setIsLoading(true);
    try {
      await removeDiscount(discountCode);
      await prepareCheckout();
    } catch (error) {
      funnelErrorHandler(error, 'Remove discounts');
      return;
    }
    onCartUpdate();
    setIsLoading(false);
  };

  const onPaymentRequestShippingAddressChange = async (
    event: PaymentRequestShippingAddressEvent
  ) => {
    // When the payment request popup first appears or when the user selects a
    // different address, we should update the estimated total with the correct
    // tax multiplier for their zip code.
    let taxRateStatus: string;
    let taxRate = FALLBACK_TAX_RATE;
    if (event.shippingAddress.postalCode) {
      const taxRateRecord = getTaxRateByZipCode(event.shippingAddress.postalCode);
      taxRateStatus = taxRateRecord.result;
      taxRate = taxRateRecord.taxRate;
    } else {
      taxRateStatus = 'zip-code-not-provided';
    }

    if (taxRateStatus !== 'estimated') {
      logger.warn('Tax estimation failed in express checkout', {
        shippingAddress: event.shippingAddress,
        taxRate,
        taxRateStatus,
      });
    }

    event.updateWith({
      status: 'success',
      total: {
        label: "Today's Total",
        amount: Math.round(
          Number(cartPreview?.prices?.subtotal_to_be_charged) * 100 * (1 + taxRate)
        ),
      },
      displayItems: [
        {
          label: `Est. Tax (${event.shippingAddress.postalCode})`,
          amount: Math.round(Number(cartPreview?.prices?.subtotal_to_be_charged) * 100 * taxRate),
        },
      ],
    });
  };

  const onExpressCheckoutSubmit = async (event: PaymentRequestPaymentMethodEvent) => {
    try {
      trackEvent({
        event_name: 'Customer Checkout Intent',
        account_id: session?.account ?? '',
        session_id: session?.id ?? '',
        email: session?.email ?? '',
        full_name: `${session?.first} ${session?.last}`,
        phone: '',
        payment_type: 'EXPRESS_PAYMENT',
      });
    } catch (e) {
      logger.error(`Failed to call track_event Customer Checkout Intent ${session?.id}`);
    }
    if (!stripe) {
      return;
    }
    setCheckoutSubmitError(undefined);
    setExpressPaymentSubmitError(undefined);
    const paymentShippingAddress = event.shippingAddress;
    const paymentShippingState = paymentShippingAddress?.region?.toUpperCase();
    const sanitizedState = sanitizeState(paymentShippingState, session?.id);

    const fullName = event.payerName;
    if (!fullName) {
      event.complete('fail');
      setExpressPaymentSubmitError('Full name is required.');
      return;
    }
    const [firstName, ...lastNameParts] = fullName.split(' ');
    const lastName = lastNameParts.join(' ');
    if (!firstName || !lastName) {
      event.complete('fail');
      setExpressPaymentSubmitError('Full name is required.');
      return;
    }

    try {
      await validateCheckout({
        first_name: firstName,
        last_name: lastName,
        email: session?.email ?? '',
        address1: paymentShippingAddress?.addressLine?.[0] ?? '',
        address2: paymentShippingAddress?.addressLine?.[1] ?? '',
        city: paymentShippingAddress?.city ?? '',
        state: sanitizedState ?? '',
        zip_code: paymentShippingAddress?.postalCode ?? '',
        contact_number: '',
        contact_preference_marketing: ContactPreference.EMAIL,
        validation_level: 'prepare_checkout',
        payment_method: 'express',
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data?.error_key === 'VALIDATE_ADDRESS') {
          funnelErrorHandler(error, 'Validate address', () => {
            const validationError = error as any;
            const response = validationError.response as any;
            const errors = response?.data?.error_message?.errors;
            // Handles validateAddress fail case
            if (Array.isArray(errors)) {
              if (errors.find((error) => ['zip_code', 'state'].includes(error.name))) {
                event.complete('invalid_shipping_address');
                return;
              }
              const addressErrors = errors.find((error) =>
                error?.message?.includes('Address.resolve_address')
              );
              if (addressErrors) {
                invalidAddressErrorHandler();
              }
            }
          });
        } else if (error.response?.data?.error_key === 'UPDATE_SESSION_DATA') {
          funnelErrorHandler(error, 'Update session (payment)');
        } else if (error.response?.data?.error_key === 'PREPARE_CHECKOUT') {
          if (error.response?.status === 400) {
            setExpressPaymentSubmitError(
              error.response?.data?.error_message || DEFAULT_CHECKOUT_ERROR_MESSAGE
            );
          }
          funnelErrorHandler(error, 'Prepare checkout');
        } else {
          funnelErrorHandler(error, 'Validate checkout');
        }
      } else {
        funnelErrorHandler(error, 'Validate checkout - non axios error');
      }
      event.complete('fail');
      return;
    }

    try {
      const finalizeResponse = await finalize({ paymentMethodId: event.paymentMethod.id });
      event.complete('success');
      const cartTotal = cartPreview?.prices?.total_to_be_charged;
      const initialOrderID = finalizeResponse.order.id;
      const userName =
        session?.last && session.first ? `${session.first} ${session.last}` : `${session?.first}`;

      trackFunnelCheckoutOrder(
        session?.id,
        initialOrderID,
        cartTotal,
        session?.email,
        session?.discounts?.[0]?.code ?? '',
        userName
      );
      if (session) {
        try {
          trackOrderCompleted({ cartPreview, session, finalizeResponse });
        } catch (e) {
          console.error('Error sending Segment call', e);
        }
        try {
          if (initialOrderID && session.account && session.email) {
            trackImpactConversion(initialOrderID, session.account, session.email, cartPreview);
          } else {
            logger.error('Failed to track UTT user conversion', { session });
          }
        } catch (e) {
          logger.error('Caught error trying to track UTT user conversion', { e });
        }
      }
      navigation.navigate(nextStep, {
        chargeDate: finalizeResponse.order.charged,
      });
    } catch (error) {
      event.complete('fail');
      funnelErrorHandler(error, 'Charge and finalize checkout');
      logger.error('Charge and finalize checkout failed', {
        error: error instanceof Error ? error.message : 'Unknown error',
        funnel_session_id: session?.id,
        email: session?.email,
      });
    }
  };

  useEffect(() => {
    if (stripe && elements && !isLoadingTaxRate) {
      const cartTotal = calculateCartTotal(cartPreview);
      const cartTax = Math.round(Number(cartPreview?.prices?.tax_amount) * 100);
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: "Today's Total",
          amount: cartTotal,
        },
        displayItems: [{ label: `Est. Tax ${session?.zip_code}`, amount: cartTax }],
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true,
        shippingOptions: [
          { amount: 0, id: 'free-shipping', label: 'Free Shipping', detail: 'Free Shipping' },
        ],
      });

      // Check the availability of the Payment Request API
      paymentRequest.canMakePayment().then((result) => {
        if (result?.googlePay || result?.applePay) {
          setExpressPaymentRequest(paymentRequest);
        }
        paymentRequest.on('paymentmethod', onExpressCheckoutSubmit);
        setIsLoadingCanMakeExpressPayment(false);
      });
    }
  }, [stripe, elements, isLoadingTaxRate]);

  useEffect(() => {
    // Updates the event listener for the 'paymentmethod' event to ensure that it uses the new
    // handleExpressCheckoutConfirm function that has the updated intentObjectType
    // This ensures that we make the correct stripe call to 'payment_intent' or 'setup_intent'
    // depending on the updated coupon code
    // Also updates the charge amount on express payment pop up to reflect the updated
    // total whenever the customer changes the coupon code
    if (expressPaymentRequest) {
      const cartTotal = calculateCartTotal(cartPreview);
      const cartTax = Math.round(Number(cartPreview?.prices?.tax_amount) * 100);
      expressPaymentRequest.update({
        total: {
          label: "Today's Total",
          amount: cartTotal,
        },
        displayItems: [{ label: `Est. Tax ${session?.zip_code}`, amount: cartTax }],
      });
      expressPaymentRequest.off('paymentmethod').on('paymentmethod', onExpressCheckoutSubmit);
    }
  }, [cartPreview, intentObjectType, expressPaymentRequest]);

  useEffect(() => {
    // Whenever the cart changes, we need to re-listen with the newest price data
    // e.g. if a discount code is added/removed
    if (expressPaymentRequest) {
      expressPaymentRequest
        .off('shippingaddresschange')
        .on('shippingaddresschange', onPaymentRequestShippingAddressChange);
    }
  }, [expressPaymentRequest, cartPreview]);

  useEffect(() => {
    if (stripe && elements && !isLoadingCanMakeExpressPayment) {
      onReady();
    }
  }, [stripe, elements, isLoadingCanMakeExpressPayment]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      if (!isLoading) {
        return;
      }
      e.preventDefault();
    });
    return () => unsubscribe();
  }, [navigation, isLoading]);

  if (!stripe || !elements || isLoadingCanMakeExpressPayment) {
    return null;
  }

  const onSubmit = async ({
    first_name,
    last_name,
    email,
    address1,
    address2,
    city,
    state,
    country = 'US',
    zip_code,
    phone_number,
    to_receive_text_messages,
  }: CheckoutDetails) => {
    setCheckoutSubmitError(undefined);
    setExpressPaymentSubmitError(undefined);
    setStripeErrorMessage(undefined);
    setIsLoading(true);

    const contact_preference_marketing =
      to_receive_text_messages.toString() === 'true'
        ? ContactPreference.ANY
        : ContactPreference.EMAIL;

    try {
      trackEvent({
        event_name: 'Customer Checkout Intent',
        account_id: session?.account ?? '',
        session_id: session?.id ?? '',
        email,
        full_name: `${first_name} ${last_name}`,
        phone: phone_number,
        payment_type: 'CREDIT_CARD',
      });
    } catch (e) {
      logger.error(`Failed to call track_event Customer Checkout Intent ${session?.id}`);
    }

    const { error: elementsSubmitError } = await elements.submit();
    if (elementsSubmitError) {
      showCheckoutSubmitError(elementsSubmitError.message);
      setIsLoading(false);
      return;
    }

    try {
      await validateCheckout({
        first_name,
        last_name,
        email,
        address1,
        address2,
        city,
        state,
        zip_code,
        contact_number: phone_number,
        contact_preference_marketing,
        validation_level: 'prepare_checkout',
        payment_method: 'credit_card',
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data?.error_key === 'CHECK_VALID_EMAIL') {
          const resData = e.response?.data?.error_message;
          if (resData === 'ACTIVE_ACCOUNT') {
            methods.setError('email', {
              type: 'string',
              message: 'This email is already associated with an existing account.',
            });
          } else {
            showCheckoutSubmitError(resData);
          }
        } else if (e.response?.data?.error_key === 'VALIDATE_ADDRESS') {
          const resData = e.response?.data?.error_message;
          const errors = resData?.errors;
          if (Array.isArray(errors)) {
            // handle cases like mismatching state + zipcode
            const fullAddressErrors = errors.filter((error) => error?.name === 'full_address');
            if (fullAddressErrors.length > 0) {
              methods.setError('address1', {
                type: 'string',
                message: 'There was a problem with the address you submitted.',
              });
            }
            for (const error of errors) {
              methods.setError(error.name, { type: 'string', message: error.message });
            }

            const addressErrors = errors.find((error) =>
              error?.message?.includes('Address.resolve_address')
            );
            if (addressErrors) {
              logger.error(`[ERROR] ${email} hit address validation error on checkout`, {
                error: addressErrors,
                fullAddress: `${address1} ${address2} ${city} ${state} ${zip_code}`,
                email,
              });
              invalidAddressErrorHandler();
            }
          } else {
            showCheckoutSubmitError(resData);
          }
        } else if (e.response?.data?.error_key === 'UPDATE_SESSION_DATA') {
          funnelErrorHandler(e, 'Update session (payment)');
          showCheckoutSubmitError();
        } else if (e.response?.data?.error_key === 'UPDATE_EMAIL') {
          if (e.response?.status === 409) {
            methods.setError('email', {
              type: 'string',
              message: 'This email is already associated with an existing account.',
            });
          } else {
            showCheckoutSubmitError(
              'There was an error with your email. Please try again or contact care@spotandtango.com for further assistance.'
            );
          }
        } else if (e.response?.data?.error_key === 'PREPARE_CHECKOUT') {
          let errorMessage: any;
          // handle address already exists in prepare_checkout
          if (axios.isAxiosError(e) && e.response?.status === 400) {
            errorMessage = e.response?.data?.error_message;
          }
          showCheckoutSubmitError(errorMessage);
          setIsLoading(false);
          return;
        } else {
          showCheckoutSubmitError(e.response?.data);
        }
      } else {
        showCheckoutSubmitError();
      }
      setIsLoading(false);
      return;
    }

    // Charge the payment method
    const card = elements.getElement('card');
    if (!card) {
      logger.error('Card element not found');
      showCheckoutSubmitError();
      setIsLoading(false);
      return;
    }
    let paymentMethodId: string;
    try {
      const { error: createPaymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: `${first_name} ${last_name}`,
          email,
          address: {
            line1: address1,
            line2: address2 ? address2 : '',
            city,
            state,
            country,
            postal_code: zip_code,
          },
          phone: phone_number ? phone_number : '',
        },
      });

      if (createPaymentMethodError) {
        throw createPaymentMethodError;
      } else {
        paymentMethodId = paymentMethod.id;
        logger.info('Stripe payment method confirmed', {
          funnel_session_id: session?.id,
          email: session?.email,
        });
      }

      const finalizeResponse = await finalize({ paymentMethodId });
      const cartTotal = cartPreview?.prices?.total_to_be_charged;
      const initialOrderID = finalizeResponse.order.id;
      const userName =
        session?.last && session.first ? `${session.first} ${session.last}` : `${session?.first}`;

      trackFunnelCheckoutOrder(
        session?.id,
        initialOrderID,
        cartTotal,
        session?.email,
        session?.discounts?.[0]?.code ?? '',
        userName
      );
      if (session) {
        try {
          trackOrderCompleted({ cartPreview, session, finalizeResponse });
        } catch (e) {
          console.error('Error sending Segment call', e);
        }
        try {
          if (initialOrderID && session.account && session.email) {
            trackImpactConversion(initialOrderID, session.account, session.email, cartPreview);
          } else {
            logger.error('Failed to track UTT user conversion', { session });
          }
        } catch (e) {
          logger.error('Caught error trying to track UTT user conversion', { e });
        }
      }
      navigation.navigate(nextStep, {
        chargeDate: finalizeResponse.order.charged,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 500) {
        funnelErrorHandler(error, 'Charge and finalize checkout');
        const resData = error.response?.data;
        showCheckoutSubmitError(resData);
      } else {
        let errorMessage: string;
        if (axios.isAxiosError(error)) {
          errorMessage = error.response?.data ?? 'Unexpected error';
        } else {
          const stripeError = error as StripeError;
          errorMessage = stripeError.message ?? 'Unexpected error';
        }
        funnelErrorHandler(error, 'Stripe confirm card payment', () => {
          logger.error('Card checkout Stripe error: ', { error: errorMessage });
          setStripeErrorMessage(errorMessage);
        });
        setIsLoading(false);

        logger.error('Stripe confirm credit card payment failed', {
          error: error instanceof Error ? error.message : 'Unknown error',
          funnel_session_id: session?.id,
          email: session?.email,
        });
      }
    }
  };

  return (
    <FunnelWrapper showDiscountBanner showPromotionBanner>
      <Heading size="titleSmToMd" fontWeight="bold" pb={4} textAlign="center">
        You're one step away from a healthier dog!
      </Heading>
      <Heading
        size="bodyMdToTitleSm"
        fontFamily="secondary"
        textAlign="center"
        maxW={{ base: '100%', md: '960px' }}
        pb={{ base: 6, lg: 12 }}
      >
        Any questions? Call or text us at (718) 514-6292. Our team is here to help.
      </Heading>
      <View w="100%" flexDirection={{ base: 'column', lg: 'row' }} my="0" mx="auto" pb={10}>
        {cartPreview && (
          <FormProvider {...methods}>
            <View
              px={{ base: 0, md: 6 }}
              flex={{ base: 1, md: 5 }}
              width="100%" // Forces the width to appear as expected in Safari
              flexBasis="auto"
            >
              {expressPaymentRequest ? (
                <ExpressCheckout
                  isLoading={isLoadingTaxRate || isLoading}
                  paymentRequest={expressPaymentRequest}
                />
              ) : null}
              {expressPaymentSubmitError ? (
                <Text size="bodySm" mt="4px" color="error.default">
                  {expressPaymentSubmitError}
                </Text>
              ) : null}
              <CheckoutFormShipping />
              <CheckoutFormBilling isLoading={isLoading} stripeErrorMessage={stripeErrorMessage} />
            </View>
            <Spacer size={{ base: 0, xl: 4 }} />
            <View
              flex={{ base: 1, md: 3 }}
              px={{ base: 0, md: 6 }}
              flexBasis="auto"
              width="100%" // Forces the width to appear as expected in Safari
              maxW={{ base: 'auto', lg: '550px' }}
            >
              <OrderSummary
                isLoading={isLoading}
                stripe={stripe}
                elements={elements}
                cartPreview={cartPreview}
                onApplyDiscount={onApplyDiscount}
                onRemoveDiscount={onRemoveDiscount}
                submitError={checkoutSubmitError}
                discountError={discountError}
                onSubmit={onSubmit}
              />
            </View>
          </FormProvider>
        )}
      </View>
    </FunnelWrapper>
  );
};
