import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { Heading, VStack, Text } from 'native-base';
import { useCallback, useMemo, useState } from 'react';
import { MultiValue, createFilter } from 'react-select';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { usePetFormOptions } from '@/api';
import {
  Button,
  Select,
  SelectedPills,
  AnimatedBlock,
  ToastType,
  displayToast,
} from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useEnterPressEffect, useNextAddNewDogStep } from '@/hooks';
import { BreedOption, BREEDS_LIMIT, getBreedOptions } from '@/utils';

const { logger } = datadogLogs;

export const AddNewDogBreed = ({ navigation }: AddNewDogScreenProps<'Breed'>) => {
  const account = useAccount();
  const isFocused = useIsFocused();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.BREED);
  const { petData, savePetData } = useAddNewDogContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<MultiValue<BreedOption>>([]);
  const [showBreedsLimitWarning, setShowBreedsLimitWarning] = useState(false);
  const breedsOverlimited = selectedOptions.length >= BREEDS_LIMIT;

  const { data: petFormData } = usePetFormOptions();
  const breedOptions = useMemo(() => getBreedOptions(petFormData?.breeds), [petFormData?.breeds]);

  const onSubmit = () => {
    if (!selectedOptions?.length) {
      return;
    }
    setIsSubmitting(true);
    const breeds = selectedOptions.map((breed: BreedOption) => breed.value) || [];
    try {
      savePetData({ breeds });
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's breed(s)`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's breed(s), account_id=${account.id}`, { error });
    }
    navigation.navigate(nextStep);
    setIsSubmitting(false);
  };

  const onSelectChange = (newOptions: any) => {
    setSelectedOptions(newOptions as MultiValue<BreedOption>);
  };

  const removeBreed = (value: string) => {
    const newValues = selectedOptions.filter(
      (option) => (option as BreedOption).value.toLowerCase() !== value.toLowerCase()
    );

    setSelectedOptions(newValues);
  };

  useEnterPressEffect(() => {
    onSubmit();
  });

  // When navigating back to a page, restore any previously submitted data
  useFocusEffect(
    useCallback(() => {
      setSelectedOptions(() => {
        return breedOptions.filter((breedOption) =>
          petData?.breeds?.find((breed) => breedOption?.value === breed)
        );
      });
    }, [breedOptions, petData?.breeds])
  );

  useFocusEffect(() => {
    if (breedsOverlimited) {
      setShowBreedsLimitWarning(true);
    } else {
      setShowBreedsLimitWarning(false);
    }
  });

  if (!isFocused || !petData || !Object.keys(petData).length) {
    return null;
  }

  return (
    <AddNewDogWrapper containerProps={{ space: '62px' }}>
      <VStack
        justifyContent={{ base: 'center', md: 'flex-start' }}
        alignItems="center"
        w={{ base: '100%', md: '472px' }}
        space={3}
      >
        <VStack space="8px" w="100%">
          <VStack alignItems="center" w="100%">
            <Heading size="titleSmToMd" fontWeight="bold">
              What breed is {petData.name}?
            </Heading>
            <Heading
              size="bodyMdToTitleSm"
              fontWeight="bold"
              mt={{ base: '36px', md: '48px' }}
              mb={{ base: '16px', md: '24px' }}
            >
              Select all that apply
            </Heading>
            <Select
              isMulti
              closeMenuOnSelect
              isDisabled={breedsOverlimited}
              options={breedOptions}
              onChange={onSelectChange}
              placeholder="Breed"
              value={selectedOptions}
              blurInputOnSelect
              autoFocus
              extraStyles={{ valueContainer: { padding: '16px 20px' } }}
              filterOption={createFilter({ ignoreAccents: false })} // helps performance
            />
            {breedsOverlimited ? (
              <AnimatedBlock showBlock={showBreedsLimitWarning}>
                <Text
                  fontFamily="secondary"
                  fontWeight="medium"
                  color="black"
                  size="bodySmToMd"
                  pt={{ base: '16px', md: '24px' }}
                >
                  {`If ${petData.name} is more than five breeds, choose Mixed Breed`}
                </Text>
              </AnimatedBlock>
            ) : null}
          </VStack>
          <SelectedPills
            w="100%"
            mt="16px"
            selected={selectedOptions}
            onPillPress={(breed) => removeBreed(breed)}
            justifyContent="center"
          />
        </VStack>
      </VStack>
      <VStack
        w={{ base: '100%', lg: '336px' }}
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        space={{ base: '8px', lg: '16px' }}
      >
        <Button
          variant="primary"
          w="100%"
          maxW={{ base: '100%', md: '290px' }}
          h={{ base: '52px', md: '56px' }}
          size="bodyMdToLg"
          onPress={onSubmit}
          isDisabled={selectedOptions.length === 0}
          isLoading={isSubmitting}
        >
          CONTINUE
        </Button>
      </VStack>
    </AddNewDogWrapper>
  );
};
