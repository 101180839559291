import * as Linking from 'expo-linking';
import {
  Divider,
  IconButton,
  Stack,
  Text,
  Pressable,
  VStack,
  HStack,
  IStackProps,
} from 'native-base';

import { Image } from '@/components/Elements';
import { socials, ASSETS_URL } from '@/constants';

const LinkColumn = ({ links }: { links: { text: string; href: string }[] }) => {
  return (
    <Stack
      w="fit-content"
      h="fit-content"
      justifyContent="start"
      alignItems="flex-start"
      flexWrap="wrap"
      space={{ base: 2, md: 6 }}
    >
      {links.map((item) => {
        return (
          <Pressable key={item.href} onPress={() => Linking.openURL(item.href)}>
            <Text fontWeight="bold" size="bodySmToTitleMd">
              {item.text}
            </Text>
          </Pressable>
        );
      })}
    </Stack>
  );
};

const ContactSupport = () => {
  const contactTextStyle = {
    fontFamily: 'secondary',
    size: 'captionToBodyMd',
    fontWeight: 'medium',
  };
  return (
    <VStack space={2} maxW={{ base: '180px', md: undefined }}>
      <Text fontWeight="700" size="bodySmToTitleMd">
        Contact Support
      </Text>
      <Pressable onPress={() => Linking.openURL('https://spotandtango.com/faq')}>
        <Text {...contactTextStyle}>FAQ</Text>
      </Pressable>
      <Pressable onPress={() => Linking.openURL('mailto: care@spotandtango.com')}>
        <Text {...contactTextStyle}>care@spotandtango.com</Text>
      </Pressable>
      <Text {...contactTextStyle}>
        (718) 514-6292 - <Text fontStyle="italic">call or text</Text>
      </Text>
      <Text {...contactTextStyle}>
        {/* Duplicate text component allows italic style in Bradford */}
        <Text fontStyle="italic">Available every day from 9am to 5pm ET</Text>
      </Text>
    </VStack>
  );
};

export const Footer = ({ ...props }: IStackProps) => {
  const links = [
    { text: 'Shop', href: 'https://plan.spotandtango.com/' },
    { text: 'Recipes', href: 'https://spotandtango.com/recipes' },
    { text: 'UnKibble', href: 'https://spotandtango.com/unkibble' },
    { text: 'Why S&T', href: 'https://learn.spotandtango.com/' },
    { text: 'What The Pup', href: 'https://whatthepup.spotandtango.com/' },
    { text: 'Careers', href: 'https://spotandtango.com/careers' },
    { text: 'Media Inquiries', href: 'mailto: press@spotandtango.com' },
    { text: 'Affiliate Inquiries', href: 'mailto: partners@spotandtango.com' },
  ];

  return (
    <Stack
      bgColor="blueBayoux.primary"
      py={{ base: '72px', md: '48px' }}
      px={{ base: 4, lg: 6 }}
      w="100%"
      space="24px"
      justifyContent="flex-start"
      alignItems="center"
      {...props}
    >
      <VStack w={{ base: '100%', md: '840px', lg: '100%' }} maxW={{ lg: '1300px' }}>
        <Stack
          w="100%"
          direction={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          space={{ base: '24px', lg: '48px' }}
        >
          <HStack justifyContent="start" alignContent="start" space={{ base: 12, md: '120px' }}>
            <Stack direction={{ base: 'column', md: 'row' }} space={{ base: 2, md: '120px' }}>
              <LinkColumn links={links.slice(0, 4)} />
              <LinkColumn links={links.slice(4)} />
            </Stack>
            <ContactSupport />
          </HStack>
          <Divider display={{ base: 'flex', md: 'none' }} />
          {/* Socials */}
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems="center"
          >
            {socials.map((social, i) => (
              <IconButton
                accessibilityLabel={social.alt}
                px={{ base: '24px', md: 0 }}
                py={0}
                onPress={() => Linking.openURL(social.url)}
                key={social + i.toString()}
                icon={
                  <Image
                    size={{ base: '24px', lg: '36px' }}
                    source={{ uri: `${social.src}` }}
                    alt=""
                    tintColor="black"
                  />
                }
              />
            ))}
          </Stack>
        </Stack>
        <Divider mt={{ base: 6, md: 9 }} mb={{ base: 4, md: 9 }} w="100%" />
        <HStack w="100%" justifyContent="space-between" alignItems="center">
          <HStack w="fit-content" justifyContent="start" alignItems="center">
            <Image
              width={{ base: '130px', lg: '340px' }}
              height={{ base: '26px', lg: '69px' }}
              source={{
                uri: `${ASSETS_URL}/logo.svg`,
              }}
              alt="Text Logo: Spot & Tango"
              resizeMode="cover"
              tintColor="black"
            />
          </HStack>
          <HStack space={4} alignItems="center">
            <Pressable onPress={() => Linking.openURL('https://spotandtango.com/privacy-policy')}>
              <Text size="captionToBodyMd">Privacy Policy</Text>
            </Pressable>
            <Pressable onPress={() => Linking.openURL('https://spotandtango.com/terms-of-use')}>
              <Text size="captionToBodyMd">Terms of Use</Text>
            </Pressable>
          </HStack>
        </HStack>
      </VStack>
    </Stack>
  );
};
