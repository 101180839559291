import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  mx: 'auto',
  fontFamily: 'primary',
  _text: {
    color: 'black',
    letterSpacing: 'none',
    fontWeight: 'light',
    fontFamily: 'secondary',
    textAlign: 'center',
  },
};

const variants = {
  portal: {
    _text: {
      textAlign: 'start',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    w: '100%',
    mt: '0px',
  },
};

const defaultProps = { size: 'bodySm', fontFamily: 'secondary' };

export const HelperText = {
  baseStyle,
  variants,
  sizes: responsiveSizes,
  defaultProps,
};
