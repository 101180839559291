import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .trim()
    .max(40, 'Email must be less than 40 characters')
    .min(3, 'Email must be at least 3 characters')
    .lowercase()
    .email(),
});

export const EmailResolver = yupResolver(EmailSchema);
