import { HStack, Text, VStack } from 'native-base';

import { Order } from '@/api/types';
import { formatPrice } from '@/utils';

export const TotalSection = ({ order, showFull = true }: { order: Order; showFull?: boolean }) => {
  const hasRefunds = Number(order?.total_refunds) > 0;

  return (
    <VStack space={{ base: '8px', lg: '16px' }}>
      {showFull || (!showFull && !hasRefunds) ? (
        <HStack justifyContent="space-between">
          <Text size="bodyMdToLg" fontWeight={showFull ? 'bold' : 'medium'}>
            Total
          </Text>
          <Text size="bodyMdToLg" fontWeight={showFull ? 'bold' : 'medium'}>
            {formatPrice(order.total_price, 'none')}
          </Text>
        </HStack>
      ) : null}
      {showFull && hasRefunds ? (
        <HStack justifyContent="space-between">
          <Text size="bodyMdToLg" fontWeight="medium">
            Total Refund
          </Text>
          <Text size="bodyMdToLg">{`-${formatPrice(order.total_refunds)}`}</Text>
        </HStack>
      ) : null}
      {hasRefunds ? (
        <HStack justifyContent="space-between">
          <Text size="bodyMdToLg" fontWeight="bold">
            Net Payment
          </Text>
          <Text size="bodyMdToLg" fontWeight="bold">
            {formatPrice(Number(order.total_price) - Number(order.total_refunds), 'none')}
          </Text>
        </HStack>
      ) : null}
    </VStack>
  );
};
