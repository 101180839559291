import { IStackProps, Stack } from 'native-base';

import { Footer } from '@/components/Portal/Footer';

export interface WrapperProps extends IStackProps {
  containerProps?: IStackProps;
}

export const PortalWrapper = ({ containerProps, ...props }: WrapperProps) => {
  return (
    <Stack w="100%" h="100%" justifyContent="space-between" alignItems="center" {...props}>
      <Stack
        bgColor="white"
        w="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          w="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
          py={{ base: '16px', md: '24px', lg: '48px' }}
          px={{ base: '16px' }}
          maxW={{
            base: '100%',
            md: '840px',
          }}
          {...containerProps}
        >
          {props.children}
        </Stack>
      </Stack>
      <Footer />
    </Stack>
  );
};
