import { Box, Divider, Text } from 'native-base';

import { ProductType, RecipeType } from '@/api/types';
import { round, roundPriceShort } from '@/utils';

interface PricingProps {
  itemType: ProductType | RecipeType;
  pricing: number;
  pricingUnit?: string | null;
  isTrial?: boolean;
  pricingPrefix?: string;
  discountedPrice?: number | null;
  strikethroughPrice?: number | null;
}

export const Pricing = ({
  itemType,
  pricing,
  pricingUnit = 'meal',
  isTrial = true,
  pricingPrefix = '',
  discountedPrice = null,
  strikethroughPrice = null,
}: PricingProps) => {
  const isAddon = itemType === RecipeType.SNACK || itemType === RecipeType.SUPPLEMENT;
  const priceRounded = isAddon ? roundPriceShort(pricing) : round(pricing, 2);

  return (
    <Box w="fit-content" display="flex" flexDir="row" justifyContent="center" alignItems="center">
      {!isAddon && isTrial && (
        <Text size="bodySmToMd" fontFamily="primary" fontWeight="medium" mr="6px">
          {pricingPrefix || 'Try for'}
        </Text>
      )}
      {typeof discountedPrice === 'number' ? (
        <>
          <Text size="bodyMdToLg" fontWeight="bold">
            ${isAddon ? roundPriceShort(discountedPrice) : round(discountedPrice, 2)}
          </Text>
          <Divider
            orientation="vertical"
            h={{ base: '12px', md: '14px' }}
            mx={1}
            backgroundColor="sntGrey.primary"
          />
          <Text
            size="captionToBodySm"
            fontFamily="primary"
            fontWeight="medium"
            color="sntGrey.primary"
          >{`$${priceRounded} after trial`}</Text>
        </>
      ) : null}

      {typeof strikethroughPrice === 'number' ? (
        <>
          <Text
            size="bodySmToMd"
            mx={1}
            color="sntGrey.primary"
            fontWeight="medium"
            strikeThrough
            style={{ textDecorationThickness: '1px' } as any}
          >
            {`$${roundPriceShort(strikethroughPrice)}`}
          </Text>
          <Text size="bodyMdToLg" fontWeight="bold">
            ${priceRounded}
          </Text>
          <Text
            size="bodySmToMd"
            fontFamily="primary"
            fontWeight="medium"
          >{` /${pricingUnit} `}</Text>
        </>
      ) : null}

      {typeof discountedPrice !== 'number' && typeof strikethroughPrice !== 'number' ? (
        <>
          <Text size="bodyMdToLg" fontWeight="bold">
            ${priceRounded}
          </Text>
          {pricingUnit ? (
            <Text
              size="bodySmToMd"
              fontFamily="primary"
              fontWeight="medium"
            >{` /${pricingUnit} `}</Text>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};
