import { Box, Pressable, Heading, Text, Spacer } from 'native-base';
import { GestureResponderEvent } from 'react-native';
import { Merge } from 'type-fest';

import { Button, Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
const images = `${ASSETS_URL}/pictos/`;

export type DataProps = {
  picto: string;
  pictoAlt?: string;
  heading?: string;
  content?: string;
  contentProps?: object;
  pricing?: string | number;
};

type NBPressableProps = React.ComponentProps<typeof Pressable>;
type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type TextAlign = 'start' | 'end' | 'center' | 'justify';

export type MyCardProps = {
  textData: DataProps;
  // my?: string;
  // mx?: string;
  onPress?: any;
  onClick?: () => void;
  $$typeof?: symbol;
  type?: string;
  picArr?: { picto: string; caption: string }[];
  infoPillArr?: string[];
  pictoWidth?: string | { base: string; md?: string; lg?: string };
  pictoHeight?: string | { base: string; md?: string; lg?: string };
  underlineButton?: {
    text: string;
    onPress: (e?: GestureResponderEvent | undefined, name?: string) => void;
  };
  responsiveDir?:
    | FlexDirection
    | {
        base?: FlexDirection | undefined;
        sm?: FlexDirection | undefined;
        md?: FlexDirection | undefined;
        lg?: FlexDirection | undefined;
        xl?: FlexDirection | undefined;
        '2xl'?: FlexDirection | undefined;
      };
  responsiveJustify?: string | { base: string; md?: string; lg?: string };
  responsiveAlign?: string | { base: string; md?: string; lg?: string };
  textContainerAlign?:
    | TextAlign
    | {
        base?: TextAlign | undefined;
        sm?: TextAlign | undefined;
        md?: TextAlign | undefined;
        lg?: TextAlign | undefined;
        xl?: TextAlign | undefined;
        '2xl'?: TextAlign | undefined;
      };
  headingProps?: React.ComponentProps<typeof Heading>;
};

type CardProps = Merge<NBPressableProps, MyCardProps>;

export const Card = ({
  textData: { picto, pictoAlt, heading, content, pricing, contentProps = {} },
  responsiveDir = { base: 'row', lg: 'column' },
  responsiveJustify = { base: 'start', lg: 'center' },
  responsiveAlign = 'center',
  textContainerAlign = { base: 'start', lg: 'center' },
  pictoWidth = { base: '84px', lg: '140px' },
  pictoHeight = { base: '84px', lg: '140px' },
  headingProps = {},
  ...props
}: CardProps) => {
  const imageSource = images + picto;

  return (
    <Pressable
      variant="card"
      w={{ base: '100%', md: '400px', lg: '324px' }}
      h="fit-content"
      p={{ base: 4, lg: 6 }}
      {...props}
    >
      {/* ~~~~~~~~~~~~~~~ Picto + Text Content Flex Box ~~~~~~~~~~~~~~~~~~~*/}
      <Box
        display="flex"
        flexDir={responsiveDir}
        justifyContent={responsiveJustify}
        alignItems={responsiveAlign}
      >
        {/* ~~~~~~~~~~~~~~~~~~ Main Picto ~~~~~~~~~~~~~~*/}
        {picto && (
          <>
            <Box display="flex" justifyContent="center" w="fit-content">
              <Image
                source={{ uri: imageSource }}
                alt={pictoAlt ?? heading}
                w={pictoWidth}
                h={pictoHeight}
                resizeMode="contain"
              />
            </Box>
            <Box width={{ base: '8px', lg: '24px' }} height={{ base: '8px', lg: '24px' }} />
          </>
        )}

        {/* ~~~~~~~~~~~ Text Content Container ~~~~~~~~~~~~ */}
        <Box
          flex={{ base: 2, md: 1 }}
          flexDir="column"
          justifyContent="center"
          alignItems={{ base: 'start', lg: 'center' }}
          w="100%"
        >
          {heading && (
            <Heading
              textAlign={textContainerAlign}
              size="bodyMlToTitleSm"
              fontWeight="bold"
              mb="4px"
              w="100%"
              {...headingProps}
            >
              {heading}
            </Heading>
          )}
          {props.infoPillArr && <Spacer size={{ base: 0.5, lg: 2 }} />}
          {/* ~~~~~~~~~~~ Info Pills Section ~~~~~~~~~~~~ */}
          {props.infoPillArr && (
            <Box
              display="flex"
              flexDir="row"
              justifyContent={responsiveJustify}
              alignItems={responsiveAlign}
              w="100%"
            >
              {props.infoPillArr.map((content, i, arr) => {
                return (
                  <Box
                    display="flex"
                    flexDir="row"
                    justifyContent="center"
                    alignItems="center"
                    key={content + 'box' + i.toString()}
                  >
                    <Button
                      _text={{
                        fontSize: { base: '12px', md: '16px' },
                        lineHeight: { base: '16px', md: '20px' },
                      }}
                      p={{ base: '6px', md: '8px' }}
                      key={content + i.toString()}
                      variant="mintPill"
                    >
                      {content}
                    </Button>
                    {i < arr.length - 1 ? <Spacer size={{ base: '4px', lg: '8px' }} /> : <></>}
                  </Box>
                );
              })}
            </Box>
          )}
          {content && <Spacer size={{ base: 1, lg: 4 }} />}
          {/* ~~~~~~~~~~~ Text Description ~~~~~~~~~~~~ */}
          {content && (
            <Text
              fontWeight="bold"
              textAlign={textContainerAlign}
              size={{ base: 'bodyMd', lg: 'bodyLg' }}
              mb="4px"
              {...contentProps}
            >
              {content}
            </Text>
          )}
          {/* ~~~~~~~~~~~ Pricing Info Container ~~~~~~~~~~~~ */}
          {pricing && <Spacer size={{ base: 1, lg: 4 }} />}
          {pricing && (
            <Box
              w="100%"
              display="flex"
              flexDir="row"
              justifyContent={textContainerAlign}
              alignItems="center"
            >
              <Text size="body-2" fontWeight="light">
                Try for{' '}
                <Text size="body-1" fontWeight="bold">
                  ${pricing}
                </Text>{' '}
                / meal
              </Text>
            </Box>
          )}
          {props.underlineButton && <Spacer size={{ base: 1, lg: 4 }} />}
          {props.underlineButton && (
            <Box display="flex" flexDir="row" justifyContent={textContainerAlign}>
              <Button onPress={props.underlineButton.onPress} variant="underlineMini">
                {props.underlineButton.text}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Pressable>
  );
};
