import { datadogLogs } from '@datadog/browser-logs';
import { Box, Heading, Pressable, Stack, VStack, Text } from 'native-base';
import { useState } from 'react';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { ToastType, displayToast } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';

const { logger } = datadogLogs;

export const AddNewDogHealthConditions = ({
  navigation,
}: AddNewDogScreenProps<'HealthConditions'>) => {
  const account = useAccount();
  const { petData, savePetData } = useAddNewDogContext();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.HEALTH_CONDITIONS);
  const [hasHealthConditions, setHasHealthConditions] = useState<string>('');
  const onNo = () => {
    setHasHealthConditions('no');
    try {
      savePetData({ health_conditions: [] });
    } catch (error) {
      displayToast({
        message: `There was a problem saving your answer`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's health conditions (yes/no), account_id=${account.id}`, {
        error,
      });
    }
    navigation.navigate(nextStep);
  };

  const onYes = () => {
    setHasHealthConditions('yes');
    navigation.navigate(AddNewDogScreenNames.HEALTH_CONDITIONS_LIST);
  };

  return (
    <AddNewDogWrapper
      containerProps={{
        justifyContent: 'flex-start',
        space: { base: '36px', lg: '48px' },
      }}
    >
      <Heading size="titleSmToMd" textAlign="center" fontWeight="bold">
        Does {petData?.name} have any special health conditions?
      </Heading>
      <VStack alignItems="center" w="100%" space={{ base: '16px', md: '24px' }}>
        <Stack
          w="100%"
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent="center"
          alignItems="center"
        >
          <Pressable
            variant="card"
            _hover={{ bg: 'white' }}
            w={{ base: '100%', md: '324px' }}
            onPress={onYes}
            py={{ base: '42px', md: '36px' }}
            px="16px"
            isPressed={hasHealthConditions === 'yes'}
            alignItems="center"
          >
            <Text size="titleSmToMd" fontWeight="bold">
              Yes
            </Text>
          </Pressable>
          <Box w={{ base: '16px', lg: '24px' }} h={{ base: '16px', lg: '24px' }} />
          <Pressable
            variant="card"
            _hover={{ bg: 'white' }}
            w={{ base: '100%', md: '324px' }}
            onPress={onNo}
            py={{ base: '42px', md: '36px' }}
            px="16px"
            isPressed={hasHealthConditions === 'no'}
            alignItems="center"
          >
            <Text size="titleSmToMd" fontWeight="bold">
              No
            </Text>
          </Pressable>
        </Stack>
        <Text size="bodySmToMd" fontFamily="secondary" textAlign="center" fontWeight="medium">
          This includes things like obesity, arthritis, and digestive issues
        </Text>
      </VStack>
    </AddNewDogWrapper>
  );
};
