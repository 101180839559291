import { fontSizes } from './fontSizes';
import { fontConfig, fonts, fontWeights } from './fonts';
import { letterSpacings } from './letterSpacings';
import { lineHeights } from './lineHeights';

export const typography = {
  letterSpacings,
  lineHeights,
  fontConfig,
  fonts,
  fontSizes,
  fontWeights,
};
