import { Button, Divider, FlatList, Heading, HStack, Stack } from 'native-base';
import { useMemo, useState } from 'react';

import { PastOrderCard } from './PastOrderCard';

import { Order } from '@/api/types/accountServices';
import { MAX_PAST_ORDERS } from '@/utils';

interface PastOrdersListProps {
  pastOrders: Order[];
}

export const PastOrdersList = ({ pastOrders }: PastOrdersListProps) => {
  const ordersCount = pastOrders.length;

  const [visibleOrdersCount, setVisibleOrdersCount] = useState(MAX_PAST_ORDERS);

  const showViewMoreCta = useMemo(
    () => visibleOrdersCount < ordersCount,
    [ordersCount, visibleOrdersCount]
  );

  const pastOrdersToDisplay = useMemo(
    () => pastOrders.slice(0, visibleOrdersCount),
    [pastOrders, visibleOrdersCount]
  );

  if (pastOrders.length === 0) {
    return null;
  }

  return (
    <Stack justifyContent="center" alignItems="start" w="100%" space={{ base: '16px', lg: '24px' }}>
      <HStack w="100%" alignItems="center" space={4}>
        <Divider flex={1} />
        <Heading size="bodyMlToTitleSm" fontWeight="bold">
          Past Orders
        </Heading>
        <Divider flex={1} />
      </HStack>
      <FlatList
        bg="sntGrey.athens"
        p={4}
        data={pastOrdersToDisplay}
        width="100%"
        keyExtractor={(item: Order) => item.id}
        renderItem={({ item, index }) => (
          <PastOrderCard
            order={item}
            mb={showViewMoreCta || index < pastOrdersToDisplay.length - 1 ? { base: 4, lg: 6 } : 0}
          />
        )}
        ListFooterComponent={
          showViewMoreCta ? (
            <Button
              alignSelf="center"
              variant="hugSecondary"
              borderWidth={{ base: '2px', lg: '3px' }}
              py={{ base: '14px', lg: 4 }}
              px={4}
              w="100%"
              h="fit-content"
              maxW={{ base: '240px', lg: '340px' }}
              onPress={() => setVisibleOrdersCount((prev) => prev + MAX_PAST_ORDERS)}
            >
              VIEW MORE
            </Button>
          ) : null
        }
      />
    </Stack>
  );
};
