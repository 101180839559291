import { useIsFocused } from '@react-navigation/native';
import { AxiosError } from 'axios';
import { Heading, Box, Stack, VStack } from 'native-base';
import { useState, useEffect } from 'react';

import { PetGender } from '@/api';
import { Card } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FunnelScreenProps } from '@/types';
import { getCurrentPet } from '@/utils/getCurrentPet';

export default function Gender({ navigation }: FunnelScreenProps<'Gender'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.GENDER);
  const { session, mutateUpdatePetAnswer } = useSessionQuery();
  const dog = getCurrentPet(session);
  const [gender, setGender] = useState<PetGender | undefined>();
  const isFocused = useIsFocused();
  const funnelErrorHandler = useFunnelErrorHandler();

  useEffect(() => {
    if (isFocused && dog?.gender) {
      setGender(dog.gender);
    }
  }, [isFocused]);

  const handlePress = async (gender: PetGender) => {
    try {
      setGender(gender);
      await mutateUpdatePetAnswer({ gender });
      navigation.navigate(nextStep);
    } catch (error) {
      funnelErrorHandler(error as AxiosError, 'Update gender');
    }
  };

  return (
    <FunnelWrapper
      containerProps={{
        justifyContent: 'flex-start',
        space: { base: '16px', lg: '24px' },
      }}
    >
      <VStack alignItems="center" space={{ base: '36px', md: '48px' }}>
        <Heading size="titleSmToMd" fontWeight="bold">
          Let's talk about {dog?.name}
        </Heading>
        <Heading size="bodyMdToTitleSm" fontWeight="bold">
          Is {dog?.name} a boy or a girl?
        </Heading>
      </VStack>
      <Stack
        w="100%"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Card
          w={{ base: '100%', md: '324px' }}
          pictoWidth={{ base: '84px', lg: '140px' }}
          pictoHeight={{ base: '84px', lg: '140px' }}
          onPress={() => handlePress(PetGender.MALE)}
          textData={{
            picto: 'Gender/BoyDog.png',
            pictoAlt: '',
            heading: 'Boy',
          }}
          isPressed={gender === PetGender.MALE}
        />
        <Box w={{ base: '16px', lg: '24px' }} h={{ base: '16px', lg: '24px' }} />
        <Card
          w={{ base: '100%', md: '324px' }}
          pictoWidth={{ base: '84px', lg: '140px' }}
          pictoHeight={{ base: '84px', lg: '140px' }}
          onPress={() => handlePress(PetGender.FEMALE)}
          textData={{
            picto: 'Gender/GirlDog.png',
            pictoAlt: '',
            heading: 'Girl',
          }}
          isPressed={gender === PetGender.FEMALE}
        />
      </Stack>
    </FunnelWrapper>
  );
}
