import { SelectOption } from '@/components/Elements';

export const getBirthdayMonths = () => {
  const months: SelectOption[] = [...Array(12)].map((_, i) => {
    const date = new Date(0, i + 1, 0);
    const month = date.toLocaleString('en-US', { month: 'long' });
    return {
      value: (i + 1).toString(),
      label: month,
    };
  });
  return months;
};

export const getBirthdayYears = () => {
  const years: SelectOption[] = [];
  const thisYear = new Date().getFullYear();

  for (let i = 0; i < 30; i++) {
    const pastYear = thisYear - i;
    years.push({ value: pastYear.toString(), label: pastYear.toString() });
  }
  return years;
};
