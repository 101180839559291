import { Account } from '@/api/types';
import { axios } from '@/lib/axios';

/**
 *
 * Gets the current data for the user from the API.
 *
 * @description
 * This function fetches the currently authenticated user's acount data from the API.
 */

export const getAccounts = async () => {
  try {
    return axios.get<Account[]>('/accounts');
  } catch (error) {
    console.log(error);
  }
};
