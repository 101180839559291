import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 11 14"
    {...props}
  >
    <Path
      fill={undefined}
      d="M5.333 10.667C4.6 10.667 4 10.067 4 9.333 4 8.6 4.6 8 5.333 8s1.333.6 1.333 1.333c0 .734-.6 1.334-1.333 1.334Zm4 2V6h-8v6.667h8Zm0-8c.733 0 1.333.6 1.333 1.333v6.667c0 .733-.6 1.333-1.333 1.333h-8C.6 14 0 13.4 0 12.667V6c0-.733.6-1.333 1.333-1.333H2V3.333A3.301 3.301 0 0 1 5.333 0a3.301 3.301 0 0 1 3.333 3.333v1.334h.667Zm-4-3.334c-1.133 0-2 .867-2 2v1.334h4V3.333c0-1.133-.867-2-2-2Z"
    />
    <Path
      fill={undefined}
      fillOpacity={0.2}
      d="M5.333 10.667C4.6 10.667 4 10.067 4 9.333 4 8.6 4.6 8 5.333 8s1.333.6 1.333 1.333c0 .734-.6 1.334-1.333 1.334Zm4 2V6h-8v6.667h8Zm0-8c.733 0 1.333.6 1.333 1.333v6.667c0 .733-.6 1.333-1.333 1.333h-8C.6 14 0 13.4 0 12.667V6c0-.733.6-1.333 1.333-1.333H2V3.333A3.301 3.301 0 0 1 5.333 0a3.301 3.301 0 0 1 3.333 3.333v1.334h.667Zm-4-3.334c-1.133 0-2 .867-2 2v1.334h4V3.333c0-1.133-.867-2-2-2Z"
    />
  </Svg>
);
function SvgLockAlertOutline({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgLockAlertOutline;
