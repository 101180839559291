import {
  Box,
  Stack,
  Heading,
  Text,
  ChevronRightIcon,
  Toast as NativeBaseToast,
  Pressable,
  ITextProps,
} from 'native-base';

export enum ToastType {
  Success = 'Success!',
  Error = 'Error',
  Warning = 'Warning',
}

interface ToastProps {
  title?: string;
  message: string;
  styledMessage?: ITextProps['children'];
  type?: ToastType;
  redirectUrl?: string;
  duration?: number;
  id?: any;
  onPress?: () => void;
}

const bgBasedOnType: Record<ToastType, string> = {
  [ToastType.Success]: 'sntBlue.primary25',
  [ToastType.Error]: 'error.bg',
  [ToastType.Warning]: 'sntBlue.primary25',
};

export const Toast = ({
  title,
  message,
  styledMessage,
  type = ToastType.Success,
  redirectUrl,
  onPress,
}: ToastProps) => {
  const toastContent = (
    <Box w={{ base: '100vw', md: '50vw', lg: '560px' }} px="16px" ml={{ base: '0px', lg: '48px' }}>
      <Stack
        space={{ base: '8px', lg: '16px' }}
        justifyContent="center"
        alignItems="start"
        bgColor={bgBasedOnType[type]}
        p={{ base: '16px', lg: '24px' }}
      >
        <Heading size="titleSm" fontWeight="bold" color="black">
          {title || type}
        </Heading>
        <Text
          color="black"
          size="bodySmToMd"
          fontWeight="medium"
          marginRight={onPress || redirectUrl ? 9 : 0}
        >
          {styledMessage ?? message}
        </Text>
        {(onPress || redirectUrl) && (
          <Stack alignItems="center" justifyContent="center" position="absolute" right={5}>
            <ChevronRightIcon size={3} />
          </Stack>
        )}
      </Stack>
    </Box>
  );

  // Wrap the content in a <Pressable>/anchor tag if onPress or redirectUrl are provided respectively
  if (onPress) {
    return <Pressable onPress={onPress}>{toastContent}</Pressable>;
  } else if (redirectUrl) {
    return (
      <a href={redirectUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
        {toastContent}
      </a>
    );
  }
  return toastContent;
};

export const displayToast = ({
  title,
  message,
  styledMessage,
  type,
  redirectUrl,
  duration = 4200,
  id,
  onPress,
}: ToastProps) => {
  NativeBaseToast.show({
    id,
    duration,
    render: () => (
      <Toast
        title={title}
        message={message}
        styledMessage={styledMessage}
        type={type}
        redirectUrl={redirectUrl}
        onPress={onPress}
      />
    ),
    accessibilityAnnouncement: message,
    placement: 'bottom-left',
  });
};

export const toastIsActive = (id: any) => NativeBaseToast.isActive(id);
