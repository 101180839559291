import { HStack, ISpinnerProps, Spinner, Text } from 'native-base';

type LoadingProps = {
  accessLabel?: string;
  spinnerSize?: ISpinnerProps['size'];
  alignSelf?: 'center' | 'flex-start';
};

export const LoadingSpinner = ({
  accessLabel,
  spinnerSize = { base: 28, lg: 32 },
  alignSelf = 'center',
}: LoadingProps) => {
  return (
    <HStack
      space="8px"
      alignSelf={alignSelf}
      w="fit-content"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size={spinnerSize} accessibilityLabel={`Loading ${accessLabel}`} color="black" />
      <Text fontSize={{ base: 'body.ml', lg: 'body.lg' }} color="black">
        Loading...
      </Text>
    </HStack>
  );
};
