import { useFocusEffect } from '@react-navigation/native';
import { IImageProps, HStack } from 'native-base';
import { useCallback, useMemo, useState } from 'react';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';

export const MobileProductImages = ({
  setBannerImage,
}: {
  setBannerImage: (uri: string) => void;
}) => {
  const uriBase = `${ASSETS_URL}/pictos/Portal/Shop/`;
  const content = useMemo(
    () => [
      {
        imageUri: `${uriBase}chicken-bites-diagonal.png`,
        textUri: `${uriBase}snacks-bubble-text.png`,
        imageStyle: {
          w: '215px',
          h: '145px',
          top: 3,
          style: { transform: [{ rotate: '-5deg' }] },
          resizeMode: 'cover' as IImageProps['resizeMode'],
        },
        textStyle: {
          w: '135px',
          h: '75px',
          left: -60,
          top: -20,
          style: { transform: [{ rotate: '-17deg' }] },
        },
      },
      {
        imageUri: `${uriBase}hip-joint-transparent.png`,
        textUri: `${uriBase}supplements-block-text.png`,
        imageStyle: {
          style: { transform: [{ rotate: '12deg' }] },
          w: '130px',
          alignSelf: 'start',
          left: 6,
        },
        textStyle: {
          w: '180px',
          h: '55px',
          left: -30,
          top: -20,
        },
      },
      {
        imageUri: `${uriBase}tote-diagonal.png`,
        textUri: `${uriBase}merch-serif-text.png`,
        imageStyle: { w: '160px', h: '130px' },
        textStyle: { w: '130px', h: '50px', left: -40, top: -16 },
      },
    ],
    [uriBase]
  );

  const [imageUri, setImageUri] = useState<string>(content[0].imageUri);
  const [textUri, setTextUri] = useState<string>(content[0].textUri);
  const [contentIndex, setContentIndex] = useState(0);

  const nextUriIndex = useCallback(() => {
    return (contentIndex + 1) % content.length;
  }, [contentIndex, content.length]);

  useFocusEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        const nextUri = nextUriIndex();
        setImageUri(content[nextUri].imageUri);
        // For Segment tracking
        setBannerImage(content[nextUri].imageUri);
        setTextUri(content[nextUri].textUri);
        setContentIndex(nextUri);
      }, 2000);
      return () => clearInterval(interval);
    }, [contentIndex, content])
  );

  const imageStyle = content[contentIndex].imageStyle;
  const textStyle = content[contentIndex].textStyle;
  return (
    <HStack pl={8} flex={1} h="100%" justifyContent="center" alignItems="center">
      <Image h="100%" resizeMode="contain" source={{ uri: imageUri }} {...imageStyle} />
      <Image source={{ uri: textUri }} {...textStyle} />
    </HStack>
  );
};
