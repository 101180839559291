import { useMutation } from 'react-query';

import { PetPlan } from '@/api/types/accountServices';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

interface PetPlanRequest {
  petPlanId: string;
  data: Partial<PetPlan>;
}

const patchPetPlan = async ({ petPlanId, data }: PetPlanRequest) =>
  axios.patch(`/pet_plans/${petPlanId}`, data).then(({ data }) => data);

export const useUpdatePetPlan = () => {
  const { refetchUser } = useAuth();

  return useMutation<PetPlan, Error, PetPlanRequest>(patchPetPlan, {
    onSuccess: () => refetchUser(),
  });
};
