export const SliderTrack = {
  baseStyle: {
    bg: 'powderBlue',
    _pressable: {
      borderWidth: 0,
      _hover: {
        borderWidth: 0,
      },
    },
    borderRadius: 0,
  },
};

export const SliderThumb = {
  baseStyle: {
    bg: 'sntBlue.primary',
    borderWidth: 0,
    _interactionBox: {
      borderWidth: '2px',
      borderColor: 'black',
    },
    _hover: {
      _web: {
        outlineWidth: 0,
      },
    },
    _focus: {
      _web: {
        outlineWidth: 0,
      },
    },
    _pressed: {
      _interactionBox: {
        borderWidth: '2px',
        borderColor: 'black',
      },
    },
  },
};

export const SliderFilledTrack = {
  baseStyle: {
    bg: 'sntBlue.primary',
  },
};

export const Slider = {
  sizes: {
    sm: {
      thumbSize: 4,
      sliderTrackHeight: 8,
      _interactionBox: { p: '2' },
    }, // larger default track height size
  },
};
