import {
  Badge,
  Button,
  CloseIcon,
  Divider,
  Heading,
  IModalProps,
  IconButton,
  Modal,
  Stack,
  Text,
} from 'native-base';

import { CreditCardDetailsCard } from '../Portal/Home/Billing/CreditCardDetailsCard';

import { usePaymentMethods } from '@/api';

interface IBaseModal extends Omit<IModalProps, 'children' | 'onExit'> {
  isLoading: boolean;
  handleAddNewCard: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmPaymentRetryModal = ({
  isLoading,
  handleAddNewCard,
  onClose,
  onConfirm,
  ...props
}: IBaseModal) => {
  const { data: paymentMethods } = usePaymentMethods();

  const defaultPaymentMethod = paymentMethods?.find(({ is_default }) => is_default);

  return (
    <Modal {...props}>
      <Modal.Content maxW="440px">
        <Stack w="100%" alignItems="flex-end" position="absolute" top={0} padding={5} zIndex={9999}>
          <IconButton size="24px" icon={<CloseIcon color="black" />} onPress={onClose} />
        </Stack>
        <Modal.Body p={{ base: '16px', md: '24px' }}>
          <Heading size="bodyMlToTitleSm" pb="16px" fontWeight="bold" textAlign="center">
            Confirm payment retry
          </Heading>

          <Text size="bodySm" fontWeight="medium" textAlign="center">
            By proceeding, you are authorizing us to retry previously failed charges with the
            payment method below.
          </Text>
          {defaultPaymentMethod && (
            <>
              <Divider bg="sntGrey.outline" my={6} />
              <CreditCardDetailsCard stripeCard={defaultPaymentMethod.card} />
              <Badge mt={2} variant="smallTabActive" size="xs">
                Primary
              </Badge>
            </>
          )}
        </Modal.Body>
        <Modal.Footer
          borderColor="sntGrey.outline"
          mx={{ base: '16px', md: '24px' }}
          py={{ base: '16px', md: '24px' }}
          px="0"
        >
          <Stack flexDirection="row" justifyContent="space-between" w="100%">
            <Button
              variant="tertiary"
              size="bodyMlToLg"
              px={4}
              py={2}
              pl="2px"
              pr="2px"
              w="fit-content"
              onPress={handleAddNewCard}
            >
              ADD NEW CARD
            </Button>
            <Button
              bg="blueBayoux.primary"
              size="bodyMlToLg"
              px={{ base: '16px', md: '36px' }}
              py={{ base: '12px', md: '14px' }}
              w="45%"
              isLoading={isLoading}
              onPress={onConfirm}
            >
              CONFIRM
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
