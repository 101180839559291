import { useForm, UseFormProps } from 'react-hook-form';

import { addressFormResolver } from './addressFormResolver';
import { AddressFormValues } from './types';

const defaultValues: AddressFormValues = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  is_primary: false,
};

export const useAddressForm = (props: UseFormProps<AddressFormValues> = {}) => {
  return useForm<AddressFormValues>({
    resolver: addressFormResolver,
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
    defaultValues,
    ...props,
  });
};
