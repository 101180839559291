import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { AddressFormValues } from './types';

import { zip_codes } from '@/constants';

const noPOBoxTest =
  /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i;

const addressFormSchema: Yup.SchemaOf<AddressFormValues> = Yup.object().shape({
  address1: Yup.string()
    .required('Address is required')
    .trim()
    .test('pobox', 'PO Boxes are not supported', (value) =>
      value ? noPOBoxTest.test(value) : true
    ),
  address2: Yup.string()
    .trim()
    .test('pobox', 'PO Boxes are not supported', (value) =>
      value ? noPOBoxTest.test(value) : true
    )
    .optional(),
  city: Yup.string().required('City is required').trim().min(1),
  state: Yup.string().required('State is required').trim(),
  zip: Yup.string()
    .required('Zip code is required')
    .trim()
    .length(5, 'Invalid zip code')
    .notOneOf(zip_codes, 'This zip code is not supported'),
  is_primary: Yup.bool().required(),
});

export const addressFormResolver = yupResolver(addressFormSchema);
