import { Box, Stack } from 'native-base';

import { LabelledPicto } from '@/components/Elements';

export const PictosSection = () => (
  <Stack
    bg="sntBlue.primary25"
    flexDirection={{ base: 'column', lg: 'row' }}
    py={{ base: '72px', lg: '86px' }}
    px="70px"
    w="100%"
    justifyContent="center"
    alignItems="center"
  >
    <StyledPicto
      src="Icons/carrot.png"
      heading="Healthy Ingredients"
      caption="Cooked fresh, and ready to serve"
    />

    {/* Workaround for missing space prop on Stacks */}
    <Box w={{ base: '70px', lg: '92px' }} h={{ base: '70px', lg: '92px' }} />

    <StyledPicto
      src="Icons/shipping.png"
      heading="Convenient Deliveries"
      caption="At your door, right when you need it"
    />

    <Box w={{ base: '70px', lg: '92px' }} h={{ base: '70px', lg: '92px' }} />

    <StyledPicto
      src="Icons/refundable.png"
      heading="Risk-Free Trial"
      caption="Dog doesn't like it? We'll give your money back"
    />
  </Stack>
);

const StyledPicto = ({
  src,
  heading,
  caption,
}: {
  src: string;
  heading: string;
  caption: string;
}) => (
  <LabelledPicto
    src={src}
    heading={heading}
    caption={caption}
    imageProps={{ size: '80px' }}
    w="100%"
    maxW="200px"
    headingProps={{ size: 'bodyMlToLg' }}
    captionProps={{ size: 'bodyMl', fontFamily: 'secondary' }}
    space="24px"
  />
);
