import { Cart, CheckoutPlan, RecipeType } from '@/api';
import segment from '@/segment';
import { FunnelSession } from '@/types';

export const trackCheckoutStarted = ({
  cartPreview,
  session,
}: {
  cartPreview: Cart['cart_preview'];
  session: FunnelSession;
}) => {
  const planSets = session.pets?.map((pet, i) => {
    const cartPlan: CheckoutPlan | undefined = cartPreview?.plans.find(
      (plan) => plan.pet_name === pet.name
    );
    if (!cartPlan) {
      return [];
    }

    const snackPlans = cartPlan.snacks.map((cartSnack) => cartSnack.product.code);
    const supplementPlans = cartPlan.supplements.map(
      (cartSupplement) => cartSupplement.product.code
    );
    const mealPlanData = {
      birth_month: pet.birth_month,
      birth_year: pet.birth_year,
      birthday: pet.birth_day,
      body_type: pet.body_type,
      breeds: pet.breeds,
      cals: pet.plan_size,
      category: 'subscription',
      conditions: pet.health_conditions,
      conditions_yn: pet.health_conditions && pet.health_conditions?.length > 0,
      pet_name: pet.name,
      food_type: pet.food_type,
      gender: pet.gender,
      position: i,
      id: `${session.email}_${pet.name}`,
      neutered: pet.is_neutered,
      plan_type:
        pet.food_type === RecipeType.UNKIBBLE
          ? null
          : pet.plan_type === 'full'
          ? 'Recommended'
          : 'Topper',
      recipes: pet.recipes,
      sku: cartPlan?.plan.trial.product.code,
      weekly_price: Number(cartPlan?.plan.trial.price.price_per_week),
      weight: pet.weight,
      snacks: snackPlans,
      supplements: supplementPlans,
      cartTotal: cartPreview.prices.total_to_be_charged,
    };

    return [mealPlanData];
  });

  segment.trackEvent('Checkout Started', {
    email: session.email,
    plans: planSets?.flat(),
    account_id: null,
    session_id: session.id,
  });
};
