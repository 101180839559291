import { useFocusEffect } from '@react-navigation/native';
import { Button, Pressable, Stack } from 'native-base';
import { useCallback } from 'react';

import { SectionContent } from './ShopTab';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useAccount, useIsMobile, useIsOnScreen } from '@/hooks';
import segment from '@/segment';

type ShopAnchorLinkProps = {
  title: string;
  iconUri: string;
  sectionHeaderRef: React.RefObject<HTMLDivElement>;
  itemsRef: React.RefObject<HTMLDivElement>;
  selectedLink: SectionContent['title'];
  setSelectedLink: (title: string) => void;
};

export const ShopAnchorLink = ({
  title,
  iconUri,
  sectionHeaderRef,
  itemsRef,
  selectedLink,
  setSelectedLink,
}: ShopAnchorLinkProps) => {
  const isMobile = useIsMobile();
  const account = useAccount();

  const isSelected = selectedLink === title;

  const sectionContentOnScreen = useIsOnScreen(itemsRef as React.RefObject<HTMLDivElement>, 1);
  const headerOnScreen = useIsOnScreen(sectionHeaderRef as React.RefObject<HTMLDivElement>, 1);
  const haveEnteredSection = isMobile
    ? sectionContentOnScreen || headerOnScreen
    : sectionContentOnScreen;
  useFocusEffect(
    useCallback(() => {
      if (haveEnteredSection && !isSelected) {
        setSelectedLink(title);
      }
    }, [haveEnteredSection])
  );

  const handlePress = (headerRef: React.RefObject<HTMLDivElement>, title: string) => {
    if (headerRef?.current) {
      headerRef.current.style.scrollMargin = isMobile ? '150px' : '0';
      headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (account && title) {
      segment.trackEvent('Clicked Shop Page Navigation', {
        account_id: account.id,
        email: account.email,
        section_clicked: title,
      });
    }
  };
  return (
    <Stack
      space={{ base: 1, lg: 0 }}
      alignItems="center"
      justifyContent="end"
      py={{ base: 2, lg: 0 }}
      borderBottomWidth="2px"
      borderBottomColor={isSelected && isMobile ? 'black' : 'transparent'}
    >
      {isMobile ? (
        <Pressable
          onPress={() => handlePress(sectionHeaderRef as React.RefObject<HTMLDivElement>, title)}
          w={{ base: '34px', lg: '67px' }}
          h={{ base: '30px', lg: '40px' }}
        >
          <Image source={{ uri: iconUri }} w="100%" h="100%" />
        </Pressable>
      ) : null}
      <Button
        variant="inline"
        _text={{ color: isSelected ? 'black' : 'sntGrey.primary' }}
        onPress={() => handlePress(sectionHeaderRef as React.RefObject<HTMLDivElement>, title)}
        _hover={{ textDecoration: 'none', _text: { color: 'sntBlue.hover' } }}
      >
        {isMobile ? title : title.toLocaleUpperCase()}
      </Button>
      {!isMobile ? (
        <Stack w="100%" alignItems="center">
          <Image
            w="100%"
            h="6px"
            resizeMode="contain"
            opacity={isSelected ? 1 : 0}
            source={{ uri: `${ASSETS_URL}/FAQ/Underline.png` }}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};
