import {
  Stack,
  Heading,
  IconButton,
  ChevronDownIcon,
  ChevronUpIcon,
  Text,
  Divider,
  HStack,
  IIconButtonProps,
  Badge,
} from 'native-base';
import { useState } from 'react';

import { ReactivatePlanFoodSection } from './ReactivatePlanFoodSection';

import { Pet } from '@/api/types';
import { EditableCard, EditableCardProps } from '@/components/Elements';
import { formatPrice } from '@/utils';

export interface ReactivatePlanOrderSummaryProps extends EditableCardProps {
  pet: Pet;
  previewOrder: {
    products: {
      name: string;
      price: string;
      type: string;
    }[];
    total_price: string;
    total_discounts: string;
    total_tax: string;
    discount_codes: string[];
  };
}

export const ReactivatePlanOrderSummary = ({
  previewOrder,
  pet,
  ...props
}: ReactivatePlanOrderSummaryProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const meals = previewOrder.products.filter(
    (product) => product.type === 'UNKIBBLE' || product.type === 'FRESH'
  );
  const snacks = previewOrder.products.filter((product) => product.type === 'SNACK');
  const supplements = previewOrder.products.filter((product) => product.type === 'SUPPLEMENT');

  return (
    <EditableCard
      chevron={false}
      p={{ base: 4, lg: 6 }}
      onPress={() => setIsExpanded(!isExpanded)}
      {...props}
    >
      <Stack w="100%" space={{ base: 4, lg: 6 }}>
        <HStack justifyContent="space-between" alignItems="center">
          <Heading size="bodyMlToTitleSm" fontWeight="bold">
            Order Summary
          </Heading>
          <HStack>
            <ExpandChevron isExpanded={isExpanded} onPress={() => setIsExpanded(!isExpanded)} />
          </HStack>
        </HStack>
        {isExpanded ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <Stack space={{ base: 4, lg: 6 }}>
              <Heading size="bodyMdToLg" fontWeight="bold">
                {pet.name}
              </Heading>
              <ReactivatePlanFoodSection type="Meals" products={meals} />
              {snacks?.length > 0 ? (
                <ReactivatePlanFoodSection type="Snacks" products={snacks} />
              ) : null}
              {supplements?.length > 0 ? (
                <ReactivatePlanFoodSection type="Supplements" products={supplements} />
              ) : null}
            </Stack>

            <Stack space={{ base: 2, lg: 4 }}>
              <HStack justifyContent="space-between" alignItems="center">
                <Text size="bodyMdToLg">Discount</Text>
                {previewOrder?.discount_codes?.length > 0 ? (
                  <Text size="bodyMdToLg">
                    <Badge variant="smallInfoPillSecondary">{previewOrder.discount_codes[0]}</Badge>{' '}
                    -${previewOrder.total_discounts}
                  </Text>
                ) : (
                  <Text size="bodyMdToLg">-</Text>
                )}
              </HStack>
              <HStack justifyContent="space-between">
                <Text size="bodyMdToLg">Shipping</Text>
                <Text size="bodyMdToLg">FREE</Text>
              </HStack>
              <HStack justifyContent="space-between">
                <Text size="bodyMdToLg">Estimated Tax</Text>
                <Text size="bodyMdToLg">{formatPrice(previewOrder.total_tax)}</Text>
              </HStack>
            </Stack>
            <Divider bg="gallery" />
          </Stack>
        ) : null}
        <Stack space={{ base: 2, lg: 4 }}>
          <HStack justifyContent="space-between">
            <Text size="bodyMdToLg" fontWeight={isExpanded ? 'bold' : 'medium'}>
              Total
            </Text>
            <Text size="bodyMdToLg" fontWeight={isExpanded ? 'bold' : 'medium'}>
              {formatPrice(previewOrder.total_price)}
            </Text>
          </HStack>
        </Stack>
      </Stack>
    </EditableCard>
  );
};

const ExpandChevron = ({ isExpanded, onPress }: { isExpanded: boolean } & IIconButtonProps) => {
  return (
    <IconButton
      onPress={onPress}
      borderWidth="0"
      p={{ base: '4.5px', lg: '6px' }}
      icon={
        isExpanded ? (
          <ChevronUpIcon color="black" size={{ base: '9px', lg: '12px' }} />
        ) : (
          <ChevronDownIcon color="black" size={{ base: '9px', lg: '12px' }} />
        )
      }
    />
  );
};
