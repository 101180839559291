import { Button, ButtonProps } from '../Elements';

export const FormSubmitButton = ({ ...props }: ButtonProps) => (
  <Button
    w={{ base: '100%', lg: '336px' }}
    _text={{
      fontSize: { base: 'body.ml', md: 'body.lg' },
      lineHeight: { base: 'body.ml', md: 'body.lg' },
    }}
    {...props}
  />
);
