import { HStack, IPressableProps, Stack, Text } from 'native-base';
import { Pressable } from 'react-native';

import { Pill, PillSize, PillStyle } from '@/components/Elements';

interface AddNewDogButtonProps {
  onPress: IPressableProps['onPress'];
}

export const AddNewDogButton = ({ onPress }: AddNewDogButtonProps) => {
  return (
    <Stack
      backgroundColor="sntBlue.info"
      pt={{ lg: '0px' }}
      marginTop="-1px"
      borderTopWidth="1px"
      w="100%"
    >
      <Pressable onPress={onPress}>
        <HStack justifyContent="center" alignItems="center">
          <Pill size={PillSize.Tiny} style={PillStyle.Tiny}>
            <Text size="tinyToCaption" fontFamily="primary" fontWeight="medium">
              New
            </Text>
          </Pill>
          <Text
            fontSize="16px"
            fontWeight="bold"
            paddingX="8px"
            paddingY="16px"
            textAlign="center"
            textDecorationLine="underline"
          >
            Add New Dog
          </Text>
        </HStack>
      </Pressable>
    </Stack>
  );
};
