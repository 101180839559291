import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useRef, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

export const useDetectScrollOverflow = () => {
  const scrollViewRef = useRef() as React.MutableRefObject<any>;
  const scrollContentRef = useRef() as React.MutableRefObject<any>;
  const [scrollViewHeight, setScrollViewHeight] = useState(0);
  const [scrollContentHeight, setScrollContentHeight] = useState(0);
  const [notAtScrollBottom, setNotAtScrollBottom] = useState(true);
  const isOverflow = notAtScrollBottom && scrollContentHeight > scrollViewHeight;
  useFocusEffect(
    useCallback(() => {
      if (!scrollViewRef?.current || !scrollContentRef?.current) return;
      const scrollviewResizeObserver = new ResizeObserver((observerEntry) => {
        setScrollViewHeight(observerEntry[0].contentRect.height);
      });
      const scrollContentResizeObserver = new ResizeObserver((observerEntry) => {
        setScrollContentHeight(observerEntry[0].contentRect.height);
      });
      scrollviewResizeObserver.observe(scrollViewRef.current);
      scrollContentResizeObserver.observe(scrollContentRef.current);

      return () => {
        scrollviewResizeObserver.disconnect();
        scrollContentResizeObserver.disconnect();
      };
    }, [])
  );

  const handleScroll = (syntheticEvent: NativeSyntheticEvent<NativeScrollEvent>) => {
    const yOffset = syntheticEvent.nativeEvent.contentOffset.y;
    if (yOffset >= scrollContentHeight - scrollViewHeight && notAtScrollBottom === true) {
      setNotAtScrollBottom(false);
    } else if (yOffset < scrollContentHeight - scrollViewHeight && notAtScrollBottom === false) {
      setNotAtScrollBottom(true);
    }
  };
  return {
    handleScroll,
    isOverflow,
    scrollViewRef,
    scrollContentRef,
  };
};
