import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="currentColor"
    {...props}
  >
    <Path d="M8.25 11.25h1.5v1.5h-1.5v-1.5Zm0-6h1.5v4.5h-1.5v-4.5ZM9 1.5C4.853 1.5 1.5 4.875 1.5 9A7.5 7.5 0 1 0 9 1.5ZM9 15A6 6 0 1 1 9 3a6 6 0 0 1 0 12Z" />
  </Svg>
);
function SvgAlertCircleOutline({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgAlertCircleOutline;
