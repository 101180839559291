import { datadogLogs } from '@datadog/browser-logs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { initReactQueryAuth } from 'react-query-auth';

import { getAccounts, login, LoginCredentials } from '@/api';
import { Account } from '@/api/types';
import { queryClient } from '@/lib/react-query';
import { sendErrorReport } from '@/utils/analytics';

const { logger } = datadogLogs;

const loadUser = async () => {
  const authToken = localStorage.getItem('auth_token');
  if (!authToken) {
    AsyncStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
    return undefined;
  }
  try {
    const res = await getAccounts();
    return res?.data[0];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        // Local token was invalid for some reason. Clear it and log out.
        logger.error('User auth token was not found in backend', { response: error.response });
        await logoutFn();
        window.location.reload();
        return undefined;
      }
    }
    throw error;
  }
};

const loginFn = async ({ ...args }: LoginCredentials): Promise<Account | undefined> => {
  try {
    const data = await login(args);
    localStorage.setItem('auth_token', data.token);

    return loadUser();
  } catch (err: unknown) {
    sendErrorReport(err);
    throw err;
  }
};

const registerFn = async () => {
  // NOTE: register() is not used right now. We should call reset_password() if we start using this
  return loadUser();
};

const logoutFn = async () => {
  localStorage.setItem('auth_token', '');
  localStorage.setItem('impersonate_token', '');
  // clear cached account + navigation data - this is the best we can do!
  await queryClient.resetQueries(['auth-user', 'NAVIGATION_STATE_V1']);
  return AsyncStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
};

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);

export const useAccount = () => {
  const { user: account } = useAuth();
  return account as Account;
};
