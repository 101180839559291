import { HStack, Text, useBreakpointValue } from 'native-base';
import { useEffect, useState } from 'react';

import { get_promotions, PromotionBannerData, BannerType } from '@/api';

export const PromotionBanner = () => {
  const fontWeight = useBreakpointValue({ base: 'bold', lg: 'medium' });
  const [activePromotion, setActivePromos] = useState<PromotionBannerData | null>(null);

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const promotionData = await get_promotions();
        if (!promotionData?.length) return null;
        const promotion = promotionData.find(
          (promotion) => promotion.banner_type === BannerType.ONLY_CHECKOUT_PAGE
        );
        if (promotion) {
          setActivePromos(promotion);
        }
      } catch (error) {
        console.error('Failed to fetch promotions:', error);
      }
    };

    fetchPromotions();
  }, []);
  if (!activePromotion?.banner_copy) return null;
  return (
    <HStack w="100%" py={4} alignItems="center" justifyContent="center" bg="black">
      <Text size="bodySmToMd" fontWeight={fontWeight} color="white">
        {activePromotion.banner_copy}
      </Text>
    </HStack>
  );
};
