import { useIsFocused } from '@react-navigation/native';
import { useEffect } from 'react';

export const useEnterPressEffect = (callbackFn: Function) => {
  const isFocused = useIsFocused();

  const keyDownHandler = (event: KeyboardEvent) => {
    if (isFocused && event.key === 'Enter') {
      event.preventDefault();
      callbackFn();
    }
  };

  return useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);
};
