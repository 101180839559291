import * as React from 'react';
import Svg from 'react-native-svg';

const SvgIconFront = ({ ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 202 204"
  >
    <g filter="url(#filter2_d_1132_4374)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9743 93.5608C51.2321 98.303 48.5941 104.752 48.6529 111.458L49.2596 180.576L51.6686 178.167L115.101 114.735L74.9508 74.5843L55.9743 93.5608ZM93.933 55.6041C98.6752 50.8619 105.124 48.2239 111.83 48.2827L180.948 48.8894L178.539 51.2984L115.107 114.731L74.9565 74.5806L93.933 55.6041Z"
        fill={props.bgColor}
      />
    </g>
    <defs>
      <filter
        id="filter2_d_1132_4374"
        x="44.6523"
        y="46.2812"
        width="140.295"
        height="140.293"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1132_4374" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1132_4374"
          result="shape"
        />
      </filter>
    </defs>
  </Svg>
);

const SvgIconBack = ({ ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 202 204"
  >
    <g filter="url(#filter0_d_1132_4374)">
      <path
        d="M49.223 174.261L55.1396 174.205L55.356 197.036L49.3108 190.991L49.223 174.261Z"
        fill={props.bgColor}
      />
    </g>
    <g filter="url(#filter1_d_1132_4374)">
      <path
        d="M174.63 48.8519L174.574 54.7686L197.406 54.9849L191.361 48.9397L174.63 48.8519Z"
        fill={props.bgColor}
      />
    </g>

    <defs>
      <filter
        id="filter0_d_1132_4374"
        x="45.2227"
        y="172.203"
        width="14.1328"
        height="30.832"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1132_4374" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1132_4374"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1132_4374"
        x="170.574"
        y="46.8516"
        width="30.832"
        height="14.1328"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.27 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1132_4374" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1132_4374"
          result="shape"
        />
      </filter>
    </defs>
  </Svg>
);

export const BannerRecipeCardFront = ({ ...props }) => (
  <SvgIconFront
    width={props.width || '202px'}
    height={props.height || '204px'}
    bgColor={props.bgColor || '#9FCDFF'}
  />
);

export const BannerRecipeCardBack = ({ ...props }) => (
  <SvgIconBack
    width={props.width || '202px'}
    height={props.height || '204px'}
    bgColor={props.bgColor || '#9FCDFF'}
  />
);

export default BannerRecipeCardFront;
