import { useMutation } from 'react-query';

import { PetPlan } from '@/api/types/accountServices';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

interface UpdatePlanDatesRequest {
  petPlanIds: string[];
  date: string;
}

const postUpdatePlanDates = async ({ petPlanIds, date }: UpdatePlanDatesRequest) =>
  axios
    .post(
      `/pet_plans/update_plan_dates`,
      {
        pet_plan_ids: petPlanIds,
        date,
      },
      { noErrorToast: true }
    )
    .then(({ data }) => data);

export const useUpdatePlanDates = () => {
  const { refetchUser } = useAuth();

  return useMutation<PetPlan, Error, UpdatePlanDatesRequest>(postUpdatePlanDates, {
    onSuccess: () => refetchUser(),
  });
};
