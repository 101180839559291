import { PlanSize, Product, RecipeType } from '.';

export interface MealPlans {
  max_recipes_unkibble: number;
  max_recipes_fresh: number;
  max_topper_recipes_unkibble: number;
  base_caloric_need: number;
  caloric_need_adjusted: number;
  topper_caloric_need: number;
  plan_size: PlanSize;
  topper_plan_size: PlanSize;
  is_puppy: boolean;
  recommended_daily_oz: number;
  rounded_oz_value: number;
  topper_daily_oz: number;
  topper_rounded_oz_value: number;
  discounts: any[];
  prices: Prices;
}

export interface Prices {
  unkibble: UnkibbleFreshTopper[];
  fresh: UnkibbleFreshTopper[];
  topper: UnkibbleFreshTopper[];
}

export interface UnkibbleFreshTopper {
  trial: PlanData;
  subscription: PlanData;
}

export interface PricingModel {
  id: string;
  name: string;
  created: string;
  last_updated: string;
  status: PricingModelStatus;
  is_default: boolean;
  creator?: string;
}

export enum PricingModelStatus {
  ACTIVE = 'ACTIVE',
  DISCONTINUED = 'DISCONTINUED',
}

export interface Item {
  sku: string;
  name: string;
  quantity: number;
  recipe_id: string;
  description: null;
  type: string;
  status: ItemStatus;
}

export enum ItemStatus {
  Active = 'active',
}

export enum ProductType {
  MEAL = 'MEAL',
  SNACK = 'SNACK',
  RECIPE = 'RECIPE',
  SCOOP = 'SCOOP',
  MATERIAL = 'MATERIAL',
  SUPPLEMENT = 'SUPPLEMENT',
  SAMPLE = 'SAMPLE',
  REPLACE = 'REPLACE',
  MERCH = 'MERCH',
}

export type MealPrice = {
  status: any;
  id: string;
  price: string;
  price_per_meal: number | null;
  price_per_day: number | null;
  price_per_week: number | null;
  total_price: number;
  discounted_price_per_pack: number | null;
  discounted_price_per_meal: number | null;
  discounted_price_per_day: number | null;
  discounted_price_per_week: number | null;
  discounted_total_price: number | null;
  product: Product;
  pricing_plan: PricingPlan;
  frequency: 4;
  packs_for_meal_plan: 3;
  plan_size: PlanSize;
  created: string;
  last_updated: string;
};

export type FoodTypes = {
  [key: string]: {
    subscription?: MealPrice[];
    trial?: MealPrice[];
    topper?: MealPrice[];
  };
};

type PricingPlan = { id: string; created: string; last_updated: string; name: string };

export interface BuildPlan {
  calories_per_day: number;
  plan_size: number;
  recipe_type: RecipeType;
  trial: PlanData;
  subscription: PlanData;
}

export interface PlanData {
  product: Product;
  price: Price;
}

export interface Price {
  id: string;
  price: string;
  price_per_meal: string | null;
  price_per_day: string | null;
  price_per_week: string | null;
  discounted_price_per_pack: string | null;
  discounted_price_per_meal: string | null;
  discounted_price_per_day: string | null;
  discounted_price_per_week: string | null;
  discounted_price: string | null;
  pricing_model: PricingModel;
  created: string;
  last_updated: string;
  frequency: number;
}
