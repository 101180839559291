/**
 * Capitalizes the first letter of each word in `str`
 */
export const capitalize = (str: string | undefined) => {
  if (!str) return '';

  let strArr = str.split(' ');

  strArr = strArr.map((word) => {
    let transformed = word.toLocaleLowerCase();

    transformed = transformed.charAt(0).toLocaleUpperCase() + transformed.slice(1);

    return transformed;
  });

  return strArr.join(' ');
};

/**
 * Joins the strings of `parts` with commas and "and". For only
 * two elements, joins with "and".
 */
export const joinWithCommas = (parts: string[]) => {
  if (parts.length < 2) {
    return parts.join('');
  }
  const rest = parts.slice(0, -1);
  const last = parts[parts.length - 1];
  return `${rest.join(', ')} and ${last}`;
};
