import { Stack, Text, useBreakpointValue, View } from 'native-base';

import { RecipeType } from '@/api';

export const SubscriptionMealPlan = ({ plan }: any) => {
  const { recipe_type, trial } = plan;
  const { price, product } = trial;
  const { discounted_price, price: total_price } = price;
  const nonDiscountedPriceWeight = useBreakpointValue({ base: 'light', lg: 'medium' });

  return (
    <View py={3}>
      <View>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={2}>
          <Text size="bodyMdToLg" fontWeight="medium">
            {recipe_type === RecipeType.UNKIBBLE ? 'UnKibble™' : 'Fresh'}
          </Text>
          <View flexDirection="row">
            {discounted_price && discounted_price !== total_price ? (
              <Text
                size="bodyMdToLg"
                mx={1}
                color="sntGrey.primary"
                fontWeight={nonDiscountedPriceWeight}
                strikeThrough
                style={{ textDecorationThickness: '1px' } as any}
              >
                {`$${total_price}`}
              </Text>
            ) : null}
            <Text size="bodyMdToLg" mx={1} fontWeight="bold">
              {`$${discounted_price || total_price}`}
            </Text>
          </View>
        </Stack>
        <Stack>
          <Text
            size="bodySmToMd"
            maxW="300px"
            h="auto"
            color={{ base: 'sntGrey.secondary', lg: 'sntGrey.primary' }}
            fontWeight="medium"
          >
            {product?.recipes?.map(
              (recipe: any, index: number, arr: any[]) =>
                `${recipe.name}${arr[index + 1] ? `, ` : ''}`
            )}
          </Text>
        </Stack>
      </View>
    </View>
  );
};
