import { IIconButtonProps, IconButton, Image } from 'native-base';
import { Linking } from 'react-native';

export const SocialIconButton = ({
  imgSrc,
  alt,
  url,
  ...props
}: { imgSrc: string; alt: string; url: string } & IIconButtonProps) => (
  <IconButton
    p="8px"
    bg="transparent"
    onPress={() => Linking.openURL(url)}
    icon={<Image size={{ base: '18px', lg: '24px' }} source={{ uri: imgSrc }} alt={alt} />}
    {...props}
  />
);
