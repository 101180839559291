import { useMutation } from 'react-query';

import { DiscountExclusiveType } from '@/api';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

interface PetPlanRequestData {
  discount_type: DiscountExclusiveType;
  recipes: string[];
}

interface PetPlanResponse {
  discount_code: string;
  discount_id: string;
  previous_recipe_ids: string[];
  new_recipe_ids: string[];
}

const postRecipesWithExclusiveDiscount = (petPlanId: string, data: PetPlanRequestData) => {
  return axios
    .post<PetPlanResponse>(`/pet_plans/${petPlanId}/update_with_exclusive_discount`, data)
    .then(({ data }) => data);
};
export const useUpdateRecipesWithExclusiveDiscount = () => {
  const { refetchUser } = useAuth();

  return useMutation(
    ({ petPlanId, data }: { petPlanId: string; data: PetPlanRequestData }) =>
      postRecipesWithExclusiveDiscount(petPlanId, data),
    {
      onSuccess: () => refetchUser(),
    }
  );
};
