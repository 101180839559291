import { useRef, useState } from 'react';
import { Animated, View } from 'react-native';

export const Scrollbar = () => {
  const [completeScrollBarHeight, _setCompleteScrollBarHeight] = useState(1);
  const [visibleScrollBarHeight, _setVisibleScrollBarHeight] = useState(0);

  const scrollIndicator = useRef(new Animated.Value(0)).current;

  const scrollIndicatorSize =
    completeScrollBarHeight > visibleScrollBarHeight
      ? (visibleScrollBarHeight * visibleScrollBarHeight) / completeScrollBarHeight
      : visibleScrollBarHeight;

  const difference =
    visibleScrollBarHeight > scrollIndicatorSize ? visibleScrollBarHeight - scrollIndicatorSize : 1;

  const scrollIndicatorPosition = Animated.multiply(
    scrollIndicator,
    visibleScrollBarHeight / completeScrollBarHeight
  ).interpolate({
    inputRange: [0, difference],
    outputRange: [0, difference],
    extrapolate: 'clamp',
  });

  return (
    <View
      style={{
        height: '100%',
        width: 6,
        backgroundColor: 'transparent',
        borderRadius: 8,
        position: 'absolute',
        right: '20px',
        top: '45px',
        bottom: '45px',
      }}
    >
      <Animated.View
        style={{
          width: 6,
          borderRadius: 8,
          backgroundColor: '#d2d2d7',
          opacity: 0.6,
          height: scrollIndicatorSize,
          transform: [{ translateY: scrollIndicatorPosition }],
        }}
      />
    </View>
  );
};
