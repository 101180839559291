import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Heading, Stack, Text } from 'native-base';
import { useEffect } from 'react';

import { FormSubmitButton } from '../../FormSubmitButton';
import { RecipeSelectForm } from './RecipeSelectForm';
import { useRecipeSelectForm } from './useRecipeSelectForm';

import { useUpdatePetPlan } from '@/api';
import { usePetPlansPrices } from '@/api/services/petplans/usePetPlanPrices';
import { LoadingSpinner, displayToast, ToastType } from '@/components/Elements';
import { useAccount } from '@/hooks/useAuth';
import { ProtectedStackParamList } from '@/types';
import { sendErrorReport } from '@/utils/analytics';

export const ChangeRecipe = ({
  navigation,
  route,
}: NativeStackScreenProps<ProtectedStackParamList, 'ChangeRecipe'>) => {
  const account = useAccount();
  const { pets } = account;

  const { formState, setValue, getValues, watch, reset } = useRecipeSelectForm();
  const { isDirty } = formState;
  const selectedPlanRecipeIds = watch('recipeIds');

  const pet = pets.find((pet) => pet.id === route.params?.petId);
  const { data: plansPrices } = usePetPlansPrices({
    planSize: pet?.pet_plan.plan_size,
    account_id: account.id,
  });
  const currentRecipes = pet?.pet_plan.recipes;
  const seletedPlanRecipeBundledIds = selectedPlanRecipeIds.sort().join('-');
  const selectedPlanPrice = plansPrices?.prices[seletedPlanRecipeBundledIds];
  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();

  useEffect(() => {
    if (pet) {
      navigation.setOptions({ title: `Change ${pet.name}'s Recipes` });
    }
  }, [account, navigation, pet]);

  if (!pet) {
    return <LoadingSpinner accessLabel="Change Recipe Page" />;
  }

  const onSubmit = async () => {
    // TODO: Remove temporary logic to disable 'Chicken + Brown Rice' recipe
    // https://spotandtango.atlassian.net/browse/SWE-928
    if (!currentRecipes?.includes('U-C') && selectedPlanRecipeIds.includes('U-C')) {
      displayToast({
        title: 'Out of Stock',
        message: 'Sorry, we are currently out of the Chicken + Brown Rice recipe.',
        type: ToastType.Error,
      });
      return;
    }
    try {
      await updatePetPlan({
        petPlanId: pet.pet_plan.id,
        data: { recipes: selectedPlanRecipeIds },
      });
      displayToast({ message: `${pet.name}'s recipes have been updated.` });
      navigation.navigate(
        route.params?.successRedirect || 'Account',
        route.params?.successRedirectParams || {}
      );
    } catch (error) {
      sendErrorReport(error);
    }
  };

  return (
    <>
      <Stack alignItems="center" w="100%" px="16px" pb="0px">
        <Heading paddingBottom="24px" variant="subtitle">
          Choose one, or mix and match for more variety!
        </Heading>
        <Stack w="100%" justifyContent="space-between" alignItems="center">
          <Stack mb={5} w="100%" flex={1}>
            <RecipeSelectForm
              getValues={getValues}
              pet={pet}
              recipeType={route.params?.foodType}
              reset={reset}
              setValue={setValue}
              excludeRecipes={!currentRecipes?.includes('U-C') ? ['U-C'] : undefined}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack variant="stickyFooter" alignItems="center" px="16px">
        {selectedPlanPrice ? (
          <Text fontWeight="medium" size="bodyMdToLg" paddingBottom="24px">
            {`${pet.name}'s Price: $${selectedPlanPrice}/week`}
          </Text>
        ) : null}

        <FormSubmitButton
          onPress={onSubmit}
          isLoading={isLoadingUpdatePetPlan}
          isDisabled={!isDirty || selectedPlanRecipeIds.length === 0}
        >
          Save Changes
        </FormSubmitButton>
      </Stack>
    </>
  );
};
