import {
  HStack,
  Heading,
  IHeadingProps,
  ITextProps,
  PresenceTransition,
  Text,
  VStack,
} from 'native-base';

import { Dog } from '@/types';
import { getBreed, dogAge } from '@/utils';

export const DogFactsGrid = ({ dog, progress }: { dog: Dog; progress: number }) => {
  const RowHeading = ({ ...props }: IHeadingProps) => (
    <Heading color="sntGrey.dark" size="bodyMl" fontWeight="bold" {...props} />
  );

  const RowContent = ({ ...props }: ITextProps) => (
    <Text color="sntGrey.dark" size="bodyMl" fontWeight="medium" {...props} />
  );

  const fadeIn = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 500,
      },
    },
  };

  const Columns = [
    ['Dog Breed', 'Age', 'Weight'],
    [
      getBreed(dog.breeds),
      dogAge(dog?.birth_year, dog?.birth_month) || 'Unknown age',
      dog?.weight ? `${dog.weight} pounds` : 'Not specified',
    ],
  ].map((col, colIdx) => {
    return colIdx === 0 ? (
      <VStack space="24px" key={colIdx}>
        {col.map((row, rowIdx) => (
          <PresenceTransition key={rowIdx} visible={progress >= 15 * (rowIdx + 1)} {...fadeIn}>
            <RowHeading>{row}</RowHeading>
          </PresenceTransition>
        ))}
      </VStack>
    ) : (
      <VStack space="24px" key={colIdx}>
        {col.map((row, rowIdx) => (
          <PresenceTransition key={rowIdx} visible={progress >= 15 * (rowIdx + 1)} {...fadeIn}>
            <RowContent>{row}</RowContent>
          </PresenceTransition>
        ))}
      </VStack>
    );
  });

  return <HStack space="36px">{Columns}</HStack>;
};
