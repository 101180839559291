import * as Linking from 'expo-linking';
import { Button, Stack, Text } from 'native-base';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useAccount } from '@/hooks';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';

export const ChangePlanStatusNoDiscount = ({
  navigation,
  route,
}: ProtectedScreenProps<'ChangePlanStatus'>) => {
  const account = useAccount();
  const pet = account.pets.find((accountPet) => accountPet.id === route.params.petId);
  const nextOrderForPet = account.orders.upcoming.find((order) =>
    order.products.find((orderProduct) => orderProduct.pet_plan?.id === pet?.pet_plan.id)
  );

  const onPressReschedule = () => {
    if (!nextOrderForPet) {
      // fallback -- active pets should always have an order
      navigation.navigate('Orders');
      return;
    }
    segment.trackEvent('Manage Plans - Selected Reschedule Next Order', {
      account_id: account.id,
      email: account.email,
      pet_id: pet?.id,
    });
    navigation.navigate('RescheduleDelivery', {
      orderId: nextOrderForPet.id,
      successRedirect: 'Orders',
    });
  };

  const onPressTakeMeBack = () => {
    navigation.navigate('Home');
  };

  const onPressConnectToHuman = () => {
    segment.trackEvent('Cancellation Flow - Selected Contact CX', {
      account_id: account.id,
      email: account.email,
      pet_id: pet?.id,
      cancellation_reason: '',
      cancellation_reason_secondary: '',
      cancellation_reason_comment: '',
    });
    Linking.openURL(
      'https://spotandtango.com/faq?a=How-do-I-contact-customer-support---id--IKoHhdLiR3SYrMUbeS90EQ'
    );
  };

  const onPressCancel = () => {
    segment.trackEvent('Select Cancel Plan', {
      email: account.email,
      pet_name: pet?.name,
      dog_name: pet?.name,
      pet_id: pet?.id,
      pet_plan_id: pet?.pet_plan.id,
      account_id: account.id,
    });

    navigation.navigate('StopPlanSurvey', {
      petId: route.params.petId,
      pauseOrCancel: 'CANCEL',
    });
  };

  return (
    <Stack
      alignItems="center"
      w="100%"
      px={{ base: 4, lg: 6 }}
      pb={{ base: 6, lg: 8 }}
      pt={{ base: 2, lg: 4 }}
    >
      <Stack
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="center"
        space={4}
        mb={{ base: 4, lg: 6 }}
      >
        <Stack
          borderColor="black"
          borderWidth={{ base: '1px', lg: '2px' }}
          alignItems="center"
          justifyContent="space-between"
          py={{ base: 6, lg: 6 }}
          w={{ lg: '408px' }}
          mr={{ lg: 6 }}
        >
          <Text size="bodyMlToTitleSm" fontWeight="bold">
            Need to Skip a Box or Reschedule?
          </Text>
          <Image
            source={{ uri: `${ASSETS_URL}/pictos/Illustrations/Calendar.png` }}
            w={{ base: '311px', lg: '432px' }}
            h={{ base: '337px', lg: '468px' }}
            alt="Calendar"
            mt="-45px"
            mb="-20px"
          />
          <Button variant="hugSecondary" onPress={onPressReschedule}>
            RESCHEDULE
          </Button>
        </Stack>
        <Stack
          borderColor="black"
          borderWidth={{ base: '1px', lg: '2px' }}
          alignItems="center"
          justifyContent="space-between"
          py={{ base: 4, lg: 6 }}
          w={{ lg: '408px' }}
        >
          <Text size="bodyMlToTitleSm" fontWeight="bold">
            We're here to help
          </Text>
          <Image
            source={{ uri: `${ASSETS_URL}/pictos/Illustrations/HelpPhone.png` }}
            w={{ base: '311px', lg: '360px' }}
            h={{ base: '337px', lg: '390px' }}
            alt="Phone displaying help chat"
          />
          <Button variant="hugSecondary" onPress={onPressConnectToHuman}>
            CONNECT TO A HUMAN
          </Button>
        </Stack>
      </Stack>
      <Button
        variant="primary"
        mb={{ base: 6, lg: 8 }}
        onPress={onPressTakeMeBack}
        px={{ lg: '48px' }}
      >
        NEVER MIND, TAKE ME BACK
      </Button>
      <Button variant="underlineMini" onPress={onPressCancel}>
        Continue to cancellation
      </Button>
    </Stack>
  );
};
