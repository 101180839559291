import { NavigationProp, useNavigation } from '@react-navigation/native';
import { IPressableProps, Pressable, Text, Toast } from 'native-base';
import { useMemo, useState } from 'react';

import { OrderStatus, useChargeHoldOrders } from '@/api';
import { RetryErrorToastMessage, displayToast } from '@/components/Elements';
import { ConfirmPaymentRetryModal } from '@/components/Modals';
import { AccountNavTextBanner } from '@/components/Portal/Home/AccountNavTextBanner';
import { useAuth, useOrdersOnHold } from '@/hooks';
import { ProtectedStackParamList } from '@/types';
import { paymentFailedErrorHandler } from '@/utils';

interface ErrorBannerProps extends IPressableProps {
  paymentMethodError: 'expired' | 'expiringSoon' | 'error';
  dogCount: number;
}

export const ErrorBanner = ({
  paymentMethodError = 'error',
  dogCount = 1,
  ...props
}: ErrorBannerProps) => {
  const { refetchUser } = useAuth();
  const ordersOnHold = useOrdersOnHold();
  const [showConfirmPaymentRetryModal, setShowConfirmPaymentRetryModal] = useState<boolean>(false);
  const [isPaymentRetrying, setIsPaymentRetrying] = useState<boolean>(false);
  const { mutateAsync: chargeHoldOrders } = useChargeHoldOrders();
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();

  const onPressToast = () => {
    navigation.navigate('PaymentMethodList');
    Toast.close('payment_retry_error_toast');
  };

  const handleAddNewCard = () => {
    navigation.navigate('AddCreditCard');
    setShowConfirmPaymentRetryModal(false);
  };

  const onSubmit = async () => {
    setIsPaymentRetrying(true);
    try {
      await chargeHoldOrders();
      const refetchResponse = await refetchUser();
      const refetchedAccount = refetchResponse.data;

      if (refetchedAccount?.orders?.processing.some((order) => order.status === OrderStatus.HOLD)) {
        displayToast({
          title: 'Success!',
          message:
            'Your payment retry was successful. You may temporarily see that your order is still in an "error" state, but rest assured your order was charged and is getting ready to be fulfilled. Please refresh this page in a few minutes to see the updated order status.',
        });
      } else {
        displayToast({
          title: 'Success!',
          message:
            "Your payment retry was successful. We'll get started on your order and send you tracking information as soon as it's officially on its way.",
        });
      }
    } catch (err) {
      paymentFailedErrorHandler(RetryErrorToastMessage(err), onPressToast, true);
    }
    setIsPaymentRetrying(false);
    navigation.navigate('Orders');
    setShowConfirmPaymentRetryModal(false);
  };

  const bannerMessageStart = useMemo(() => {
    switch (paymentMethodError) {
      case 'expired':
        return 'Your payment has expired.';
      case 'expiringSoon':
        return 'Your payment is expiring soon.';
      default:
        return 'Your payment has been declined.';
    }
  }, [paymentMethodError]);

  const bannerMessageEnd = useMemo(() => {
    switch (paymentMethodError) {
      case 'expired':
        return `to continue receiving your pup${dogCount > 1 ? "s'" : "'s"} food`;
      default:
        return `so we can start prepping your pup${dogCount > 1 ? "s'" : "'s"} order.`;
    }
  }, [dogCount, paymentMethodError]);

  return (
    <>
      <AccountNavTextBanner w="100%" bg="error.default25">
        <Text size="bodySmToMd" w="100%">
          {bannerMessageStart}{' '}
          <Pressable onPress={() => navigation.navigate('PaymentMethodList')} {...props}>
            <Text fontWeight="bold" textDecorationLine="underline">
              Add a new form of payment
            </Text>
          </Pressable>{' '}
          {!!ordersOnHold.length && (
            <>
              or{' '}
              <Pressable onPress={() => setShowConfirmPaymentRetryModal(true)} {...props}>
                <Text fontWeight="bold" textDecorationLine="underline">
                  retry your primary card
                </Text>
              </Pressable>{' '}
            </>
          )}
          {bannerMessageEnd}
        </Text>
      </AccountNavTextBanner>
      <ConfirmPaymentRetryModal
        isOpen={showConfirmPaymentRetryModal}
        isLoading={isPaymentRetrying}
        onClose={() => setShowConfirmPaymentRetryModal(false)}
        handleAddNewCard={handleAddNewCard}
        onConfirm={onSubmit}
      />
    </>
  );
};
