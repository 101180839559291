import { useRecipes } from './useRecipes';

/**
 * Utility hook for querying all addons
 * @returns useQuery-style response data for snack and supplement recipes
 */
export const useAddonRecipes = (accountId?: string | undefined) => {
  const {
    isLoading: isLoadingSnacks,
    isSuccess: isSuccessSnacks,
    data: snacks,
  } = useRecipes({
    recipeType: 'snacks',
    accountId,
  });
  const {
    isLoading: isLoadingSupplements,
    isSuccess: isSuccessSupplements,
    data: supplements,
  } = useRecipes({
    recipeType: 'supplements',
    accountId,
  });

  return {
    isLoading: isLoadingSnacks || isLoadingSupplements,
    isSuccess: isSuccessSnacks && isSuccessSupplements,
    data: isSuccessSnacks && isSuccessSupplements ? [...snacks, ...supplements] : undefined,
  };
};
