export const linkingOptions = {
  prefixes: ['https://'],
  config: {
    screens: {
      Portal: {
        path: 'account',
        screens: {
          PublicScreens: {
            path: '',
            screens: {
              Login: 'login',
            },
            initialRouteName: 'Login',
          },
          ProtectedScreens: {
            path: 'PortalScreens',
            screens: {
              Home: 'Home',
              Orders: 'Orders',
              Dogs: 'Dogs',
              Shop: 'Shop',
              DogSelectFoodType: 'DogSelectFoodType',
              DogSelectRecipe: 'DogSelectRecipe',
              Account: 'Account',
              PersonalInformation: 'PersonalInformation',
              AddNewAddress: 'AddNewAddress',
              AddressList: 'AddressList',
              EditAddress: 'EditAddress',
              PaymentMethodList: 'PaymentMethodList',
              AddCreditCard: 'AddCreditCard',
              EditCreditCard: 'EditCreditCard',
              ManageActivePlans: 'ManageActivePlans',
              ReactivatePlan: 'ReactivatePlan',
              ManageInactivePlans: 'ManageInactivePlans',
              AdjustPortionSize: 'AdjustPortionSize',
              ChangeRecipe: 'ChangeRecipe',
              ChangePlanStatus: 'ChangePlanStatus',
              StopPlanSurvey: 'StopPlanSurvey',
              NotificationPreferences: 'NotificationPreferences',
              RescheduleDelivery: 'RescheduleDelivery',
              ShoppingExperience: 'ShoppingExperience',
              OrderDetailPage: 'OrderDetailPage',
              AddNewDogScreens: {
                path: 'AddNewDog',
                screens: {
                  DogName: 'DogName',
                  Gender: 'Gender',
                  Neutered: 'Neutered',
                  Breed: 'Breed',
                  Birthday: 'Birthday',
                  BodyType: 'BodyType',
                  Weight: 'Weight',
                  Lifestyle: 'Lifestyle',
                  HealthConditions: ' HealthConditions',
                  HealthConditionsList: 'HealthConditionsList',
                  FoodTypes: 'FoodTypes',
                  Recipes: 'Recipes',
                  Calculating: 'Calculating',
                  MealPlan: 'MealPlan',
                  AdjustPortionSize: 'AdjustPortionSize',
                  Snacks: 'Snacks',
                  Supplments: 'Supplements',
                  PlanSummary: 'PlanSummary',
                  RescheduleDelivery: 'RescheduleDelivery',
                },
              },
            },
          },
        },
      },
      Questionnaire: {
        path: 'signup',
        screens: {
          SignUp: '',
          NameAndEmail: 'NameAndEmail',
          HowManyDogs: 'HowManyDogs',
          DogName: 'DogName',
          Location: 'Location',
          Gender: 'Gender',
          Neutered: 'Neutered',
          Breed: 'Breed',
          Birthday: 'Birthday',
          BodyType: 'BodyType',
          Weight: 'Weight',
          Lifestyle: 'Lifestyle',
          HealthConditions: 'HealthConditions',
          HealthConditionsList: 'HealthConditionsList',
          FoodTypes: 'FoodTypes',
          Recipes: 'Recipes',
          Calculating: 'Calculating',
          MealPlan: 'MealPlan',
          Snacks: 'Snacks',
          Supplements: 'Supplements',
          Checkout: 'Checkout',
          ProcessingOrder: 'ProcessingOrder',
          PostCheckout: 'PostCheckout',
          WelcomeScreen: 'WelcomeScreen',
        },
      },
      ResetPassword: {
        path: 'reset_password/:token_id/:token_key',
        screens: {
          ResetPassword: {
            path: '/:token_id/:token_key',
            parse: {
              token_id: (token_id: string) => `${token_id}`,
              token_key: (token_key: string) => `${token_key}`,
            },
          },
        },
      },
    },
  },
};
