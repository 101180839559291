import { Heading, Spacer } from 'native-base';
import React from 'react';

import { UpcomingOrderCard } from './UpcomingOrderCard';

import { Order, Pet } from '@/api/types/accountServices';

type CurrentOrdersCardsProps = {
  orders: {
    processing: Order[];
    upcoming: Order[];
  };
  pets: Pet[];
};

export const CurrentOrdersCards = ({ orders, pets }: CurrentOrdersCardsProps) => {
  return (
    <>
      {orders.processing.length > 0 && (
        <Heading fontWeight="bold" size="bodyMlToTitleSm" mb={{ base: 4, lg: 6 }}>
          {`Current Order${orders.processing.length > 1 ? 's' : ''}`}
        </Heading>
      )}
      {orders.processing.map((order, i) => (
        <UpcomingOrderCard key={i} order={order} pets={pets} mb={{ base: 4, lg: 6 }} />
      ))}
      <Spacer size={{ base: 2, lg: 3 }} />
      {orders.upcoming.length > 0 && (
        <Heading fontWeight="bold" size="bodyMlToTitleSm" mb={{ base: 4, lg: 6 }}>
          {`Upcoming Order${orders.upcoming.length > 1 ? 's' : ''}`}
        </Heading>
      )}
      {orders.upcoming.map((order, i) => (
        <UpcomingOrderCard key={i} order={order} pets={pets} mb={{ base: 4, lg: 6 }} />
      ))}
    </>
  );
};
