import { datadogLogs } from '@datadog/browser-logs';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getPlanOptions } from './getPlanOptions';

import { UsePlanOptionsQueryData } from '@/api';
import { useAxiosStatusContext } from '@/context';
import { useFunnelErrorHandler } from '@/hooks';

const { logger } = datadogLogs;

export const useSessionPlans = ({
  pet,
  discounts,
  sessionId,
  accountId,
}: UsePlanOptionsQueryData) => {
  const funnelErrorHandler = useFunnelErrorHandler();
  const isFocused = useIsFocused();
  const { axiosPromises } = useAxiosStatusContext();
  const [allCallsCompleted, setAllCallsCompleted] = useState(false);
  const route = useRoute();

  useEffect(() => {
    if (isFocused) {
      setAllCallsCompleted(false);
      const checkAllCallsCompleted = async () => {
        let axiosCompleted = false;

        const timeout = new Promise<void>((resolve) =>
          setTimeout(() => {
            if (!axiosCompleted) {
              logger.error(
                `Warning: ${route.name} page - Axios calls for pet data took more than 30 seconds`
              );
            }
            resolve();
          }, 30000)
        );

        const axiosPromise = Promise.allSettled(axiosPromises).then(() => {
          axiosCompleted = true;
        });

        await Promise.race([axiosPromise, timeout]);
        await axiosPromise; // Ensure all axios calls are completed before setting the state
        setAllCallsCompleted(true);
      };

      checkAllCallsCompleted();
    }
  }, [axiosPromises, route.name, isFocused]);

  return {
    query: useQuery(
      [
        'SessionPlans',
        pet?.name,
        pet?.weight,
        pet?.birth_year,
        pet?.birth_month,
        pet?.body_type,
        pet?.is_neutered,
        pet?.lifestyle,
        discounts,
        sessionId,
        accountId,
      ],
      () => getPlanOptions(sessionId as string),
      {
        enabled: allCallsCompleted && isFocused,
        cacheTime: 1000 * 60 * 60, // cache pet plan data for these specific params for 1 hour
        staleTime: 1000 * 60 * 60, // data is fresh for 1 hour
        retry: false,
        onError: (error: AxiosError) => {
          funnelErrorHandler(
            new AxiosError(
              `${route.name}: Something went wrong with plan_options session call: ${error.message}`,
              '500'
            ),
            'SessionPlans'
          );
        },
      }
    ),
    allCallsCompleted,
  };
};
