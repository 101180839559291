import { datadogLogs } from '@datadog/browser-logs';
import { VStack, Heading, Stack, Box } from 'native-base';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { PetGender } from '@/api';
import { Card, displayToast, ToastType } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants/addNewDogScreens';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';

const { logger } = datadogLogs;

export const AddNewDogGender = ({ navigation }: AddNewDogScreenProps<'Gender'>) => {
  const account = useAccount();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.GENDER);
  const { petData, savePetData } = useAddNewDogContext();
  const handlePress = async (gender: PetGender) => {
    try {
      savePetData({ gender });
      navigation.navigate(nextStep);
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's gender`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's gender, account_id=${account.id}`, { error });
    }
  };
  return (
    <AddNewDogWrapper
      containerProps={{
        justifyContent: 'flex-start',
        space: { base: '16px', lg: '24px' },
      }}
    >
      <VStack alignItems="center" space={{ base: '36px', md: '48px' }}>
        <Heading size="titleSmToMd" fontWeight="bold">
          Let's talk about {petData?.name}
        </Heading>
        <Heading size="bodyMdToTitleSm" fontWeight="bold">
          Is {petData?.name} a boy or a girl?
        </Heading>
      </VStack>
      <Stack
        w="100%"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Card
          w={{ base: '100%', md: '324px' }}
          pictoWidth={{ base: '84px', lg: '140px' }}
          pictoHeight={{ base: '84px', lg: '140px' }}
          onPress={() => handlePress(PetGender.MALE)}
          textData={{
            picto: 'Gender/BoyDog.png',
            heading: 'Boy',
          }}
          isPressed={petData.gender === PetGender.MALE}
        />
        <Box w={{ base: '16px', lg: '24px' }} h={{ base: '16px', lg: '24px' }} />
        <Card
          w={{ base: '100%', md: '324px' }}
          pictoWidth={{ base: '84px', lg: '140px' }}
          pictoHeight={{ base: '84px', lg: '140px' }}
          onPress={() => handlePress(PetGender.FEMALE)}
          textData={{
            picto: 'Gender/GirlDog.png',
            heading: 'Girl',
          }}
          isPressed={petData.gender === PetGender.FEMALE}
        />
      </Stack>
    </AddNewDogWrapper>
  );
};
