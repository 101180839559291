import {
  CloseIcon,
  IIconButtonProps,
  IIconProps,
  IStackProps,
  IconButton,
  Stack,
} from 'native-base';
import React from 'react';

interface CloseIconButtonProps extends IIconButtonProps {
  wrapperStackProps?: IStackProps;
  iconProps?: IIconProps;
}

export const CloseIconButton = ({
  wrapperStackProps,
  iconProps,
  ...props
}: CloseIconButtonProps) => (
  <Stack
    w="100%"
    alignItems="flex-end"
    position="absolute"
    top={0}
    zIndex={9999}
    {...wrapperStackProps}
  >
    <IconButton
      size="14px"
      icon={<CloseIcon color="sntGrey.primary" {...iconProps} />}
      {...props}
    />
  </Stack>
);
