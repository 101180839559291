import { FormControl, Stack, Input, Text } from 'native-base';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { PersonalInformationFormValues } from './usePersonalInformationForm';

import { PhoneInput } from '@/components/Elements';

interface PersonalInformationFormProps {
  control: Control<PersonalInformationFormValues>;
  errors: FieldErrors<PersonalInformationFormValues>;
}

export const PersonalInformationForm = ({ control, errors }: PersonalInformationFormProps) => {
  return (
    <Stack direction="column" space="12px">
      <FormControl isInvalid={'firstName' in errors}>
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value, onBlur } }) => {
            return (
              <Stack direction="column" justifyContent="center" alignItems="start" w="100%">
                <FormControl.Label htmlFor="firstName" variant="portal" size={{ base: 'bodyMd' }}>
                  First name
                </FormControl.Label>
                <Input
                  variant="portal"
                  w="100%"
                  size={{ base: 'bodySm', lg: 'bodyLg' }}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  textAlign="start"
                  placeholder="First name"
                />
                {errors?.firstName?.message ? (
                  <Text size="bodySmToMd" mt="4px" color="error.default">
                    {errors?.firstName?.message}
                  </Text>
                ) : null}
              </Stack>
            );
          }}
        />
      </FormControl>
      <FormControl isInvalid={'lastName' in errors}>
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value, onBlur } }) => {
            return (
              <Stack direction="column" justifyContent="center" alignItems="start" w="100%">
                <FormControl.Label htmlFor="lastName" variant="portal" size={{ base: 'bodyMd' }}>
                  Last name
                </FormControl.Label>
                <Input
                  variant="portal"
                  w="100%"
                  size={{ base: 'bodySm', lg: 'bodyLg' }}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  textAlign="start"
                  placeholder="Last name"
                />
                {errors?.lastName?.message ? (
                  <Text size="bodySmToMd" mt="4px" color="error.default">
                    {errors?.lastName?.message}
                  </Text>
                ) : null}
              </Stack>
            );
          }}
        />
      </FormControl>
      <FormControl isInvalid={'phone' in errors}>
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, value, onBlur } }) => {
            return (
              <Stack direction="column" justifyContent="center" alignItems="start" w="100%">
                <FormControl.Label htmlFor="phone" variant="portal" size={{ base: 'bodyMd' }}>
                  Phone (optional)
                </FormControl.Label>
                <PhoneInput
                  variant="portal"
                  w="100%"
                  size={{ base: 'bodySm', lg: 'bodyLg' }}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  textAlign="start"
                  placeholder="###-###-####"
                />
                {errors?.phone?.message ? (
                  <Text size="bodySmToMd" mt="4px" color="error.default">
                    {errors?.phone?.message}
                  </Text>
                ) : null}
              </Stack>
            );
          }}
        />
      </FormControl>
    </Stack>
  );
};
