import { datadogLogs } from '@datadog/browser-logs';
import axios from 'axios';
import { FormControl, Heading, Stack } from 'native-base';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { password_reset_request } from '@/api';
import { Button, InputFloatLabel, displayToast, ToastType } from '@/components/Elements';
import {
  PENDING_ACCOUNT_ERROR,
  PendingAccountView,
} from '@/components/Portal/Login/LoginErrorMessage';
import { EmailResolver } from '@/screens';
import { PublicScreenProps } from '@/types';

type FormData = {
  email: string;
};

const { logger } = datadogLogs;

export const SetPassword = ({ navigation, route }: PublicScreenProps<'SetPassword'>) => {
  const variant = route?.params?.variant;
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmited, setHasSubmitted] = useState(false);
  const [onSubmitError, setOnSubmitError] = useState<string | null>(null);

  useEffect(() => {
    switch (variant) {
      case 'reset':
        navigation.setOptions({ title: 'Reset Password' });
        break;
      case 'firsttime':
        navigation.setOptions({ title: 'Set Up Your Account' });
        break;
      default:
        break;
    }
  }, [navigation, variant]);

  const defaultValues = {
    email: '',
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: EmailResolver, defaultValues });

  const onSubmit = async ({ email }: FormData) => {
    setIsLoading(true);
    setHasSubmitted(true);
    setOnSubmitError(null);

    try {
      await password_reset_request(email);
      displayToast({
        message: `If the email address ${email} is associated with a Spot & Tango account, you'll receive an email to create your password. If you don't receive an email within a couple of minutes, please check your spam folder.`,
        type: ToastType.Success,
        duration: 10000,
      });
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error?.response?.status === 403 &&
        error?.response?.data === PENDING_ACCOUNT_ERROR
      ) {
        setOnSubmitError(PENDING_ACCOUNT_ERROR);
      } else {
        logger.error('password_reset_request error', { error });
        displayToast({
          message:
            'We are unable to process your request at this time. Please try again later or contact care@spotandtango.com for further assistance.',
          type: ToastType.Error,
        });
      }
    } finally {
      setIsLoading(false);
      setTimeout(() => setHasSubmitted(false), 20000);
    }
  };

  const content = {
    reset: {
      header: 'Reset Password',
      secondHeader: `Enter the email address associated with your account, and we'll email you a link to reset your password.`,
      button: 'Send Reset Link',
    },
    firsttime: {
      header: 'Set up your account',
      secondHeader:
        "Enter the email address associated with your account, and we'll email you a link to set up your password.",
      button: 'Send Link',
    },
  };

  const page = content[variant];

  return (
    <Stack alignItems="center" w="100%" h="100%" px={{ base: 4, lg: 0 }}>
      <Heading size="bodySmToMd" mb={6}>
        {page.secondHeader}
      </Heading>

      <FormControl
        w={{ base: '100%', lg: '540px' }}
        maxW={{ lg: '540px' }}
        isRequired
        isInvalid={'email' in errors}
        mb={4}
      >
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value } }) => (
            <InputFloatLabel
              w="100%"
              m="0px"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              placeholder="Email"
              textAlign="start"
              keyboardType="email-address"
            />
          )}
        />
        {errors?.email?.message && (
          <FormControl.ErrorMessage>{errors.email.message}</FormControl.ErrorMessage>
        )}
        {onSubmitError ? (
          <Stack direction="row" flexWrap="wrap" justifyContent="start">
            <PendingAccountView />
          </Stack>
        ) : null}
      </FormControl>

      <Stack w={{ base: '100%', lg: '336px' }} justifyContent="center">
        <Button
          w={{ base: '100%', md: '336px' }}
          size="bodyMdToLg"
          onPress={handleSubmit(onSubmit)}
          isDisabled={hasSubmited || !watch('email') || !!errors?.email}
          isLoading={isLoading}
          spinnerPlacement="end"
        >
          {page.button}
        </Button>
      </Stack>
    </Stack>
  );
};
