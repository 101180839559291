import { UnkibbleFreshTopper, PricingModelStatus, RecipeType, MealPlans } from '@/api';
import { cheapestPlanByProduct } from '@/utils';

export const processPlanData = (data: MealPlans | undefined) => {
  if (!data) {
    return undefined;
  }

  function filterByActiveStatus(pricesData: UnkibbleFreshTopper[]) {
    return pricesData.filter(
      (meal) => meal.subscription.price.pricing_model.status === PricingModelStatus.ACTIVE
    );
  }

  const unkibblePlans = filterByActiveStatus(data.prices.unkibble);
  const freshPlans = filterByActiveStatus(data.prices.fresh);
  const cheapestPlans = cheapestPlanByProduct(
    [...unkibblePlans, ...freshPlans],
    'trial',
    'price_per_meal'
  );

  return {
    planSize: data.plan_size.id,
    maxRecipes: {
      [RecipeType.FRESH]: data.max_recipes_fresh,
      [RecipeType.UNKIBBLE]: data.max_recipes_unkibble,
    },
    plans: {
      [RecipeType.FRESH]: freshPlans,
      [RecipeType.UNKIBBLE]: unkibblePlans,
    },
    cheapestPlans: [cheapestPlans[RecipeType.UNKIBBLE], cheapestPlans[RecipeType.FRESH]],
  };
};
