import { useFocusEffect } from '@react-navigation/native';
import { Heading, FormControl, Text, VStack, HStack } from 'native-base';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, InputSideLabel } from '@/components/Elements/';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useEnterPressEffect, useIsMobile } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { WeightResolver } from '@/screens/resolvers';
import { FunnelScreenProps } from '@/types';
import { getCurrentPet } from '@/utils/getCurrentPet';

const defaultValues = {
  weight: '',
};

export default function Weight({ navigation }: FunnelScreenProps<'Weight'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.WEIGHT);
  const { session: funnelSession, mutateUpdatePetAnswer } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { addAxiosPromise } = useAxiosStatusContext();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm({ resolver: WeightResolver, defaultValues });
  const isMobile = useIsMobile();

  const dog = getCurrentPet(funnelSession);

  useFocusEffect(
    useCallback(() => {
      reset({
        weight: dog?.weight as string,
      });
    }, [dog?.weight, reset])
  );

  const onSubmit = ({ weight }: { weight?: string }) => {
    if (!weight) {
      return;
    }
    try {
      addAxiosPromise(
        mutateUpdatePetAnswer({
          weight,
        })
      );
    } catch (error) {
      funnelErrorHandler(error, 'Update weight');
    }
    navigation.navigate(nextStep);
  };

  useEnterPressEffect(() => {
    handleSubmit(onSubmit)();
  });

  return (
    <FunnelWrapper>
      <VStack w="100%" justifyContent={{ base: 'center', md: 'flex-start' }} alignItems="center">
        <Heading
          size="titleSmToMd"
          textAlign="center"
          fontWeight="bold"
          mb={{ base: '36px', md: '48px' }}
        >
          How much does {dog?.name} weigh?
        </Heading>
        <FormControl isRequired isInvalid={'weight' in errors}>
          <VStack alignItems="center">
            <VStack w="fit-content" alignItems="center">
              <Controller
                control={control}
                name="weight"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <HStack justifyContent="center" alignItems="center" space={3}>
                    <InputSideLabel
                      variant={'weight' in errors ? 'error' : 'base'}
                      label="Pounds"
                      w={{ base: '156px', md: '208px' }}
                      stackProps={{
                        space: { base: 2, md: 3 },
                      }}
                      size="bodyMd"
                      mx={0}
                      onBlur={onBlur}
                      onChangeText={(weightValue) => onChange(weightValue)}
                      value={value}
                      placeholder="Enter weight"
                      keyboardType="numeric"
                      maxLength={3}
                      _invalid={{
                        borderColor: 'error.default',
                      }}
                      onSubmitEditing={(e) => {
                        e.preventDefault();
                        handleSubmit(onSubmit)();
                      }}
                      onKeyPress={(event) => {
                        // only allow numbers to be entered
                        const nativeEvent = event.nativeEvent;
                        // if the key press is a number, allow it
                        if (/[0-9]/.test(nativeEvent.key)) {
                          return;
                        }
                        // if the key press is a backspace, allow it
                        if (nativeEvent.key === 'Backspace') {
                          return;
                        }

                        // otherwise, prevent the key press
                        (nativeEvent as any).preventDefault();
                      }}
                      autoFocus={!isMobile}
                    />
                  </HStack>
                )}
              />
              {errors.weight &&
              errors.weight.message !==
                'weight must be a `number` type, but the final value was: `NaN` (cast from the value `""`).' ? (
                <FormControl.ErrorMessage>{errors.weight?.message}</FormControl.ErrorMessage>
              ) : null}
            </VStack>
            <FormControl.HelperText>
              <Text
                color="black"
                fontFamily="secondary"
                mb={{ base: '36px', md: '48px' }}
                mt={{ base: '16px', md: '24px' }}
                size="bodySmToMd"
              >
                If you're not sure, just give your best guess!
              </Text>
            </FormControl.HelperText>
          </VStack>
        </FormControl>
      </VStack>

      <Button
        variant="primary"
        size="bodyMdToLg"
        w="100%"
        maxW={{ base: '100%', md: '290px' }}
        h={{ base: '52px', md: '56px' }}
        disabled={!watch('weight') && !isValid}
        onPress={handleSubmit(onSubmit)}
        isDisabled={!watch('weight') && !isValid}
        isLoading={isSubmitting}
      >
        CONTINUE
      </Button>
    </FunnelWrapper>
  );
}
