import { FieldValues, Path } from 'react-hook-form';

import { LabeledFormControl, LabeledFormControlProps } from '../LabeledFormControl';
import { Select, SelectProps } from '../Select';

export interface OptionType<TValue = string, TLabel = string> {
  value: TValue;
  label: TLabel;
}

export interface LabeledSelectProps<
  TOption extends OptionType,
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> extends Omit<LabeledFormControlProps<TFieldValues, TName>, 'children'> {
  options?: TOption[];
  placeholder?: string;
  selectProps?: Partial<SelectProps>;
  textStyling?: any;
}

export const LabeledSelect = <
  TOption extends OptionType,
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  options = [],
  control,
  name,
  label,
  placeholder,
  textStyling = {},
  selectProps = {},
  ...formControlProps
}: LabeledSelectProps<TOption, TFieldValues, TName>) => (
  <LabeledFormControl
    {...{
      control,
      name,
      label,
      ...formControlProps,
    }}
  >
    {({ field: { onChange, value } }) => (
      <Select
        onChange={(option: any) => onChange(option?.value)}
        value={options.find((option) => option.value === value)}
        options={options}
        placeholder={placeholder}
        textStyling={textStyling}
        {...selectProps}
      />
    )}
  </LabeledFormControl>
);
