import { useMutation } from 'react-query';

import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

const pausePet = async ({ petId }: { petId: string }) =>
  axios.post(`/pets/${petId}/pause`).then((response) => response.data);

export const usePausePet = () => {
  const { refetchUser } = useAuth();

  return useMutation(({ petId }: { petId: string }) => pausePet({ petId }), {
    onSuccess: () => refetchUser(),
  });
};
