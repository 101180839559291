import { Box } from 'native-base';
import { CarouselRenderItem } from 'react-native-reanimated-carousel/lib/typescript/types';

import { MyCarousel } from '@/components/Elements';

export type ResponsiveCarouselProps = {
  data: any;
  width: { base: number; md: number };
  height: { base: number; md: number };
  variant?: 'bordered' | 'gallery';
  onPrev?: (val?: any) => void;
  onNext?: (val?: any) => void;
  onScrollBegin?: () => void;
  onScrollEnd?: (index: number) => void;
  renderItem: CarouselRenderItem<any>;
  itemWidth: { base: number; md: number };
  disableLoop?: boolean;
};

export const ResponsiveCarousel = ({
  data,
  width,
  height,
  variant,
  renderItem,
  onPrev,
  onNext,
  onScrollBegin,
  onScrollEnd,
  itemWidth,
  disableLoop,
}: ResponsiveCarouselProps) => {
  const carouselProps = {
    onPrev,
    onNext,
    onScrollBegin,
    onScrollEnd,
    data,
    renderItem,
    variant,
    disableLoop,
  };

  return (
    <>
      {/* Small Screens */}
      <Box
        w="100%"
        display={{ base: 'flex', md: 'none' }}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <MyCarousel
          {...carouselProps}
          width={width.base}
          height={height.base}
          itemWidth={itemWidth.base}
        />
      </Box>
      {/* Large Screens */}
      <Box
        w="100%"
        display={{ base: 'none', md: 'flex' }}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <MyCarousel
          {...carouselProps}
          width={width.md}
          height={height.md}
          itemWidth={itemWidth.md}
        />
      </Box>
    </>
  );
};
