import { ASSETS_URL } from './constants';

export const socials = [
  {
    src: `${ASSETS_URL}/pictos/Social/facebook-black.svg`,
    url: 'https://www.facebook.com/groups/1000066503949814',
    alt: "Link to Spot & Tango's Facebook page",
  },
  {
    src: `${ASSETS_URL}/pictos/Social/instagram-black.svg`,
    url: 'https://www.instagram.com/spotandtango/',
    alt: "Link to Spot & Tango's Instagram page",
  },
  {
    src: `${ASSETS_URL}/pictos/Social/tiktok-black.svg`,
    url: 'https://www.tiktok.com/@spotandtango',
    alt: "Link to Spot & Tango's TikTok page",
  },
  {
    src: `${ASSETS_URL}/pictos/Social/pinterest-black.svg`,
    url: 'https://www.pinterest.com/spotandtango/pins/',
    alt: "Link to Spot & Tango's Pinterest page",
  },
];
