import { HStack, Skeleton, VStack } from 'native-base';

import { AddOnCarouselSkeleton } from '../../AddOnShopping';

import { AvatarSkeleton } from '@/components/Elements';

const HomeSkeleton = () => {
  return (
    <VStack space="24px" w={{ base: '100%', lg: '840px' }} overflow="hidden">
      {/* Upcoming Order Cards */}
      <Skeleton.Text lines={1} w="200px" />
      <Skeleton w="100%" h={{ base: '166px', lg: '248px' }} />

      {/* Add On Carousel */}
      <AddOnCarouselSkeleton numItems={4} />
      <Skeleton h="1px" w="100%" />

      {/* Dog Avatars */}
      <Skeleton.Text lines={1} w={{ base: '100px', lg: '150px' }} />
      <HStack justifyContent="flex-start" space={{ base: '16px', lg: '24px' }}>
        <AvatarSkeleton />
        <AvatarSkeleton />
      </HStack>
    </VStack>
  );
};

export default HomeSkeleton;
