import { axios } from '@/lib/axios';
import { FunnelSession, Dog } from '@/types';

interface PartialUpdatePetAnswersData {
  id: string;
  pet_index: number;
  pet_data: Partial<Dog>;
}

export const partial_update_pet_answers = async ({
  id,
  pet_index,
  pet_data,
}: PartialUpdatePetAnswersData) => {
  return axios.patch<FunnelSession>(`/sessions/${id}/partial_update_pet_answers`, {
    pet_index,
    pet_data,
  });
};
