import { datadogLogs } from '@datadog/browser-logs';

import { states } from '@/constants';

const { logger } = datadogLogs;

export const sanitizeState = (state?: string, sessionId?: string): string | undefined => {
  let sanitizedState = state;
  if (state && state.length > 2) {
    const matchingState = states.find(
      (stateNames) => stateNames.name.toLocaleLowerCase() === state.toLocaleLowerCase()
    );
    if (matchingState) {
      sanitizedState = matchingState.short;
      logger.error(
        `Long state name detected. Abbreviating state ${state} to ${sanitizedState}. session_id=${sessionId}`
      );
    }
  }
  return sanitizedState;
};
