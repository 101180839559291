import { BuildPlan, RecipeType } from '@/api';

type Plan = Pick<BuildPlan, 'trial' | 'subscription'>;
type FoodPlan = {
  [recipe in RecipeType]: Plan;
};

export const cheapestPlanByProduct = (
  data: Plan[],
  plan_type: 'trial' | 'subscription',
  pricing_unit: 'price_per_week' | 'price_per_meal' | 'price_per_day'
) =>
  data.reduce((lowestPricedPlan, current) => {
    const currentPlan = current[plan_type];
    const recipeType = currentPlan.product.recipes[0].type;

    // if current's pricing unit is falsy,
    // go to next item
    if (!currentPlan.price[pricing_unit]) return lowestPricedPlan;

    // If the lowestPricedPlan has no recipes of this type,
    // map the recipeType to the current
    if (!lowestPricedPlan[recipeType]) {
      lowestPricedPlan[recipeType] = current;
      return lowestPricedPlan;
    }

    const lowestPlan = lowestPricedPlan[recipeType][plan_type];
    const currentPricePerUnit = Number(currentPlan.price[pricing_unit]);
    const lowestPricePerUnit = Number(lowestPlan.price[pricing_unit]);

    // if the current recipeType has a lower price than the accumulating recipe
    if (currentPricePerUnit < lowestPricePerUnit) lowestPricedPlan[recipeType] = current;
    return lowestPricedPlan;
  }, {} as FoodPlan);
