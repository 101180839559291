import { Divider, Box, Stack } from 'native-base';
import { useState } from 'react';

import { AccordionItem, AccordionItemContent } from './AccordionItem';

type AccordionProps = {
  items: AccordionItemContent[];
  defaultOpenIndex?: number;
};

export const Accordion = (props: AccordionProps) => {
  const [openIndex, setOpenIndex] = useState(props.defaultOpenIndex ? props.defaultOpenIndex : 0);

  return (
    <>
      {props.items.map((item, i, arr) => {
        return (
          <Stack key={i + item.index}>
            <AccordionItem
              item={item}
              getOpenItem={() => openIndex}
              updateOpenItem={setOpenIndex}
            />
            <Box w="8px" h="8px" />
            {i < arr.length - 1 && <Divider bg="gallery" />}
          </Stack>
        );
      })}
    </>
  );
};
