import { IStackProps, PresenceTransition, Stack } from 'native-base';
import { IPresenceTransitionProps } from 'native-base/lib/typescript/components/composites/Transitions/types';
import { useRef } from 'react';

type AnimationType = 'opacityAndTranslateY';

const createAnimationObject = (type: AnimationType) => {
  if (type === 'opacityAndTranslateY') {
    return {
      initState: {
        opacity: 0,
        translateY: 20,
      },
      animateTo: {
        opacity: 1,
        translateY: 0,
      },
      transition: {
        duration: 250,
        useNativeDriver: true,
      },
    };
  }
  return {
    initState: {},
    animateTo: {},
    transition: {},
  };
};

const getAnimation = (
  type: AnimationType,
  showBlock: boolean,
  showBlockRef: React.MutableRefObject<boolean>
) => {
  const { initState, animateTo, transition } = createAnimationObject(type);

  if (showBlock && !showBlockRef.current) {
    showBlockRef.current = true;
    return {
      initial: initState,
    };
  } else if (showBlock) {
    return {
      initial: initState,
      animate: {
        ...animateTo,
        ...transition,
      },
    };
  } else if (!showBlock) {
    setTimeout(() => {
      showBlockRef.current = false;
    }, transition.duration);

    return {
      initial: animateTo,
      animate: {
        ...initState,
        ...transition,
      },
    };
  }
};

interface AnimatedBlockProps extends IPresenceTransitionProps {
  showBlock: boolean;
  children: IStackProps['children'];
  type?: AnimationType;
}

export const AnimatedBlock = ({
  showBlock = false,
  type = 'opacityAndTranslateY',
  children,
  ...props
}: AnimatedBlockProps) => {
  const showBlockRef = useRef(showBlock);
  const animation = getAnimation(type, showBlock, showBlockRef);

  return (
    <PresenceTransition visible={showBlockRef.current} {...animation} {...props}>
      <Stack opacity={showBlockRef.current ? 1 : 0}>{children}</Stack>
    </PresenceTransition>
  );
};
