import { Heading, Image, Stack, Box } from 'native-base';
import React, { ReactNode } from 'react';

export type StepProps = {
  subtitle: string;
  description: ReactNode;
  image: {
    desktop: string;
    mobile: string;
  };
};
export const Step = ({ subtitle, description, image }: StepProps) => {
  return (
    <Stack>
      <Stack minH={{ base: '84px', md: '64px' }} justifyContent="center">
        <Heading
          textAlign="center"
          size="bodyMdToLg"
          fontWeight="medium"
          mb={{ base: '8px', md: '16px' }}
        >
          {subtitle}
        </Heading>
        {description}
      </Stack>

      <Box my={{ base: '16px', md: '24px' }} alignItems="center">
        <Box display={{ base: 'none', md: 'block' }}>
          <Image source={{ uri: image.desktop }} alt={subtitle} w="700px" h="400px" />
        </Box>
        <Box display={{ base: 'block', md: 'none' }}>
          <Image source={{ uri: image.mobile }} alt={subtitle} w="300px" h="380px" />
        </Box>
      </Box>
    </Stack>
  );
};
