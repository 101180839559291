import { CheckIcon, Box, Stack, Text, IStackProps } from 'native-base';

interface FieldFeedbackHelperTextProps extends IStackProps {
  isValid?: any;
  text?: string;
}

/**
 * Displays a validation message and a checkmark for use alongside form fields
 */
const FieldFeedbackHelperText = ({
  isValid,
  text,
  ...stackProps
}: FieldFeedbackHelperTextProps) => (
  <Stack flexDir="row" alignItems="center" {...stackProps}>
    {!!isValid && (
      <>
        <CheckIcon color="successGreen" size="14px" />
        <Box w="4px" h="4px" />
      </>
    )}
    <Text size={{ base: 'bodySm' }} color={isValid ? 'successGreen' : 'grey.secondary'}>
      {text}
    </Text>
  </Stack>
);

export default FieldFeedbackHelperText;
