import { Badge, Heading, Stack, Text, View, useBreakpointValue } from 'native-base';

import { CheckoutAddon } from '@/api';

interface SubscriptionAddonPlanProps {
  items: CheckoutAddon[];
  discount_value: string | undefined;
  header: string;
}

export const SubscriptionAddonPlan = ({
  header,
  items,
  discount_value,
}: SubscriptionAddonPlanProps) => {
  const nonDiscountedPriceWeight = useBreakpointValue({ base: 'light', lg: 'medium' });
  const areAllAddOnsDiscounted = items.every((item) => item.price !== item.discounted_price);

  return (
    <View py={3}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={2}>
        <Heading size="bodyMdToLg" fontWeight="bold">
          {header}
        </Heading>
        {areAllAddOnsDiscounted && discount_value ? (
          <Badge variant="smallInfoPillSecondary" p={2}>
            {`${Number(discount_value).toFixed(0)}% Off`}
          </Badge>
        ) : null}
      </Stack>
      {items?.map(({ price, discounted_price, product }) => (
        <Stack
          key={product.code}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          py={1}
        >
          <View flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Text size="bodyMdToLg" fontWeight="medium">
              {product.recipes[0].name}
            </Text>
          </View>

          <View flexDirection="row">
            {price !== discounted_price ? (
              <Text
                size="bodyMdToLg"
                mx={1}
                color="sntGrey.primary"
                fontWeight={nonDiscountedPriceWeight}
                strikeThrough
                style={{ textDecorationThickness: '1px' } as any}
              >
                {`$${Number(price).toFixed(2)}`}
              </Text>
            ) : null}
            <Text size="bodyMdToLg" mx={1} fontWeight="bold">
              {`$${Number(price !== discounted_price ? discounted_price : price).toFixed(2)}`}
            </Text>
          </View>
        </Stack>
      ))}
    </View>
  );
};
