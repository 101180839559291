import { datadogLogs } from '@datadog/browser-logs';
import { Heading, Stack } from 'native-base';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { LifestyleCode, usePetFormOptions } from '@/api';
import { Card, ToastType, displayToast } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';

const { logger } = datadogLogs;

export const AddNewDogLifestyle = ({ navigation }: AddNewDogScreenProps<'Lifestyle'>) => {
  const account = useAccount();
  const { petData, savePetData } = useAddNewDogContext();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.LIFESTYLE);

  const { data: petFormData } = usePetFormOptions();
  const lifestyles = petFormData?.lifestyles;

  const onSubmit = (lifestyle: LifestyleCode) => {
    try {
      savePetData({ lifestyle });
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's lifestyle`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's lifestyle, account_id=${account.id}`, { error });
    }
    navigation.navigate(nextStep);
  };
  return (
    <AddNewDogWrapper
      containerProps={{ justifyContent: 'flex-start', space: { base: '36px', lg: '48px' } }}
    >
      <Heading size="titleSmToMd" textAlign="center" fontWeight="bold">
        What is {petData?.name}&apos;s lifestyle like?
      </Heading>

      <Stack
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="center"
        w="100%"
      >
        {lifestyles &&
          lifestyles.map((lifestyle, key) => {
            const my = key === 1 ? { base: '16px', lg: '0' } : { base: '0', lg: '0' };
            const mx = key === 1 ? { base: '0', lg: '24px' } : { base: '0', lg: '0' };
            const description =
              petData?.gender === 'MALE'
                ? lifestyle.description_male
                : lifestyle.description_female;

            return (
              <Card
                pictoWidth={{ base: '84px', lg: '140px' }}
                pictoHeight={{ base: '84px', lg: '140px' }}
                minH={{ lg: '330px' }}
                h={{ base: 'auto', md: 'fit-content', lg: '100%' }}
                key={key}
                mx={mx}
                my={my}
                textData={{
                  picto: `Lifestyle/${lifestyle.slug}.png`,
                  heading: lifestyle.name,
                  content: `${petData?.name}${description}`,
                  contentProps: {
                    color: 'black',
                    fontFamily: 'secondary',
                    fontWeight: 'medium',
                    size: 'bodySmToLg',
                  },
                }}
                onPress={() => onSubmit(lifestyle.slug)}
                isPressed={petData?.lifestyle === lifestyle.slug}
              />
            );
          })}
      </Stack>
    </AddNewDogWrapper>
  );
};
