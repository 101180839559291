import { Pressable } from 'native-base';
import { useWindowDimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';

import { Image } from '@/components/Elements';

type BannerContent = { uri: string; ref?: React.RefObject<HTMLDivElement> };

export const BannerCarousel = ({ bannerContent }: { bannerContent: BannerContent[] }) => {
  const windowWidth = useWindowDimensions().width;

  const handleBannerPress = (ref?: React.RefObject<HTMLDivElement>) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <Carousel
      width={windowWidth * 0.8} // renderItem width
      height={140}
      style={{
        width: windowWidth,
        height: 140,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data={bannerContent}
      renderItem={({ item }) => (
        <Pressable onPress={() => handleBannerPress(item.ref)} w="100%" h="100%" px={1}>
          <Image
            borderRadius="8px"
            w="100%"
            h="100%"
            resizeMode="cover"
            source={{ uri: item.uri }}
          />
        </Pressable>
      )}
    />
  );
};
