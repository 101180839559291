import { BuildPlan } from '@/api';
import { round } from '@/utils';

export const getFunnelMealPlanPrices = (data: BuildPlan | undefined) => {
  const discountedWeekPrice = data?.trial?.price?.discounted_price_per_week;
  const weekPrice = data?.trial?.price?.price_per_week;
  const discountPercent =
    discountedWeekPrice && weekPrice
      ? round(((Number(weekPrice) - Number(discountedWeekPrice)) / Number(weekPrice)) * 100, 0)
      : null;
  const CTAText = `TRY TWO WEEKS ${discountPercent ? `(${discountPercent}% OFF)` : ''}`;
  return {
    CTAText,
    discountedWeekPrice,
    weekPrice,
    discountPercent,
  };
};
