import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';

type PetPlansPricesResponse = {
  code: string;
  price: number;
  discounted_price?: number;
  recipes: { id: string }[];
}[];

const createPricesObj = (response: PetPlansPricesResponse) => {
  const prices: Record<string, string> = {};
  const discountedPrices: Record<string, string> = {};
  response.forEach((plan) => {
    const bundledPlanRecipesIds = plan.recipes
      .flatMap((recipe) => recipe.id)
      .sort()
      .join('-');
    Object.assign(prices, {
      [bundledPlanRecipesIds]: plan.price.toFixed(2),
    });
    if (plan.discounted_price) {
      Object.assign(discountedPrices, {
        [bundledPlanRecipesIds]: plan.discounted_price.toFixed(2),
      });
    }
  });
  return { prices, discountedPrices };
};

const getPetPlansPrices = async (
  planSize?: number,
  previewRetentionDiscount = false,
  account_id?: string
) => {
  if (!planSize) {
    return [];
  }
  return axios
    .get<PetPlansPricesResponse>(`/prices/meals_per_size`, {
      params: {
        plan_size: planSize,
        preview_retention_discount: previewRetentionDiscount ? 'true' : undefined,
        account_id: account_id || undefined,
      },
    })
    .then((res) => res.data);
};

export const usePetPlansPrices = ({
  planSize,
  previewRetentionDiscount = false,
  account_id,
}: { planSize?: number; previewRetentionDiscount?: boolean; account_id?: string } = {}) => {
  return useQuery(['PetPlanPrices', planSize, previewRetentionDiscount, account_id], {
    queryFn: () => getPetPlansPrices(planSize, previewRetentionDiscount, account_id),
    select: createPricesObj, // transform data before returning it
    enabled: !!planSize,
  });
};
