import { Badge, HStack, IPressableProps, Pressable } from 'native-base';
import { useState } from 'react';
import { PencilIcon } from 'react-native-heroicons/solid';

import { Close } from '@/components/Icons';

export enum PillSize {
  Tiny,
  ExtraSmall,
  Small,
  Large,
  Tall,
}

export enum PillIcon {
  Edit,
  Close,
}

export enum PillFill {
  Filled,
  Outline,
}

export enum PillStyle {
  Info,
  Status,
  Error,
  ErrorSelected,
  Quantity,
  Tiny,
}

export interface PillProps extends Omit<IPressableProps, 'size' | 'style'> {
  icon?: PillIcon;
  isDisabled?: boolean;
  isError?: boolean;
  fill?: PillFill;
  size?: PillSize;
  style?: PillStyle;
  isSelected?: boolean;
  onClickClose?: () => void;
}

const getBackgroundColor = (
  isDisabled: boolean,
  isHovered: boolean,
  isError: boolean,
  style: PillStyle,
  fill: PillFill
) => {
  if (style === PillStyle.Error) {
    return 'white';
  }
  if (style === PillStyle.ErrorSelected || (isError && fill === PillFill.Filled)) {
    return 'error.default';
  }
  if (isDisabled && fill === PillFill.Filled) {
    return 'sntBlue.disabled';
  }
  if (isHovered && fill === PillFill.Filled && style === PillStyle.Status) {
    return 'sntBlue.hover';
  }
  if (fill === PillFill.Outline) {
    return 'white';
  }
  if (style === PillStyle.Quantity) {
    return 'sntGrey.outline';
  }
  return 'sntBlue.primary';
};

const getBorderColor = (
  isDisabled: boolean,
  isSelected: boolean,
  isError: boolean,
  isHovered: boolean,
  style: PillStyle,
  fill: PillFill,
  size: PillSize
) => {
  if (style === PillStyle.ErrorSelected) {
    return 'black';
  }
  if (style === PillStyle.Error || isError) {
    return 'error.default';
  }
  if (isDisabled && fill === PillFill.Outline && (size !== PillSize.Tall || isSelected)) {
    return 'sntBlue.disabled';
  }
  if (size === PillSize.Tall || (fill === PillFill.Outline && style === PillStyle.Info)) {
    return 'black';
  }
  if (isHovered && fill === PillFill.Outline && style === PillStyle.Status) {
    return 'sntBlue.hover';
  }
  if (fill === PillFill.Outline && style === PillStyle.Status) {
    return 'sntBlue.primary';
  }
  return 'white';
};

const getPy = (style: PillStyle) => {
  if (style === PillStyle.Info) {
    return { base: '8px', lg: '12px' };
  }
  if (style === PillStyle.Status) {
    return { base: '10px', lg: '10px' };
  }
  if (style === PillStyle.Quantity) {
    return '4px';
  }
  if (style === PillStyle.Tiny) {
    return '3px';
  }
};

const getPx = (size: PillSize, style: PillStyle) => {
  if (size === PillSize.Tall) {
    return { base: '24px', lg: '30px' };
  }
  if (size === PillSize.Large && style === PillStyle.Info) {
    return { base: '12px', lg: '12px' };
  }
  if (size === PillSize.Small && style === PillStyle.Info) {
    return { base: '8px', lg: '12px' };
  }
  if (style === PillStyle.Status) {
    return { base: '16px', lg: '24px' };
  }
  if (size === PillSize.ExtraSmall) {
    return '8px';
  }
  if (size === PillSize.Tiny) {
    return '6px';
  }
};

const getBorderWidth = (size: PillSize, fill: PillFill) => {
  if (size === PillSize.Tall) {
    return '2px';
  }
  if (fill === PillFill.Outline) {
    return '1px';
  }
  return '0px';
};

export const Pill = ({
  children,
  icon,
  isDisabled = false,
  isError = false,
  isSelected = false,
  fill = PillFill.Filled,
  size = PillSize.Large,
  style = PillStyle.Info,
  onClickClose,
  ...props
}: PillProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Pressable
      onHoverIn={() => {
        setIsHovered(true);
      }}
      onHoverOut={() => {
        setIsHovered(false);
      }}
      {...props}
    >
      <Badge
        borderRadius="25px"
        px={getPx(size, style)}
        py={getPy(style)}
        bg={getBackgroundColor(isDisabled, isHovered, isError, style, fill)}
        borderWidth={getBorderWidth(size, fill)}
        borderColor={getBorderColor(isDisabled, isSelected, isError, isHovered, style, fill, size)}
        minW={size === PillSize.Tall ? '168px' : ''}
        minH={size === PillSize.Tall ? '72px' : ''}
      >
        <HStack space="xs">
          {children}
          {icon === PillIcon.Edit && <PencilIcon size="16px" />}
          {icon === PillIcon.Close && (
            <Close
              color="black"
              mt="4px"
              alignContent="center"
              justifyContent="center"
              w={{ base: '18px', lg: '14px' }}
              h={{ base: '18px', lg: '14px' }}
              onClick={onClickClose}
            />
          )}
        </HStack>
      </Badge>
    </Pressable>
  );
};
