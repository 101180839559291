import { Order } from '@/api/types/accountServices';

export const sortOrderByChargedOrScheduled = (
  orderA: Order,
  orderB: Order,
  direction?: 'asc' | 'desc'
) => {
  const aDateSortDate = orderA?.charged ? orderA.charged : orderA.scheduled;
  const bDateSortDate = orderB?.charged ? orderB.charged : orderB.scheduled;
  const aDate = new Date(aDateSortDate).getTime();
  const bDate = new Date(bDateSortDate).getTime();
  const dir = direction && direction === 'desc' ? -1 : 1;
  if (aDate > bDate) {
    return 1 * dir;
  }
  if (aDate < bDate) {
    return -1 * dir;
  }
  return 0;
};
