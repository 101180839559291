export enum Intervention {
  SwapToTopper = 'SwapToTopper',
  TenPercentDiscount = 'TenPercentDiscount',
  TryNewRecipe = 'TryNewRecipe',
  SpeakWithSpecialist = 'SpeakWithSpecialist',
  SnoozePlan = 'SnoozePlan',
  AdjustPortionSizeUp = 'AdjustPortionSizeUp',
  AdjustPortionSizeDown = 'AdjustPortionSizeDown',
  RushADelivery = 'RushADelivery',
}
