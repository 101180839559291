import { Button, HStack, Heading, useBreakpointValue, Text } from 'native-base';

import { getFunnelMealPlanPrices } from './formatMealPrices';

import { BuildPlan } from '@/api/types';
import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';

interface MealPlanFooterProps {
  data: BuildPlan | undefined;
  isLoading: boolean;
  isDisabled?: boolean;
  onSubmit: () => void;
}

export const MealPlanFooter = ({ data, onSubmit, isLoading, isDisabled }: MealPlanFooterProps) => {
  const { CTAText } = getFunnelMealPlanPrices(data);
  const showArrow = useBreakpointValue({ base: false, lg: true });

  return (
    <HStack
      justifyContent="center"
      alignItems="center"
      bg="white"
      w="100%"
      py={{ base: '16px', lg: '26px' }}
      position="sticky"
      bottom={0}
      style={{
        shadowColor: 'rgba(0,0,0,0.05)',
        shadowOffset: { width: -4, height: -4 },
        shadowRadius: 4,
      }}
    >
      <HStack
        maxW={{ base: '200px', lg: '100%' }}
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        space={{ base: '8px', lg: '16px' }}
      >
        <Heading fontWeight="bold" size={{ base: 'bodySm', lg: 'titleSm' }}>
          {CTAText}
        </Heading>
        {showArrow ? (
          <Image
            source={{ uri: `${ASSETS_URL}/pictos/Icons/curved-arrow.png` }}
            w="80px"
            h="26px"
            alt="curved arrow"
          />
        ) : null}
        <Button
          variant="primary"
          w={{ base: '130px', md: '205px' }}
          h={{ base: '48px', md: '52px' }}
          py="14px"
          px={{ base: '24px', md: '16px' }}
          size="captionToBodySm"
          mt={{ base: '8px', lg: 0 }}
          onPress={onSubmit}
          isLoading={isLoading}
          isDisabled={isDisabled}
        >
          <Text size="captionToBodySm" fontWeight="bold" letterSpacing="md">
            CONTINUE
          </Text>
        </Button>
      </HStack>
    </HStack>
  );
};
