import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useRef } from 'react';

/**
 * Provides a ref to add to a ScrollView, which keeps the document
 * from scrolling while the `enabled` is true.
 */
export const useBodyScrollLock = ({ enabled = false }: { enabled?: boolean }) => {
  const lockRef = useRef<Document | null>(null);

  useEffect(() => {
    if (!lockRef.current) {
      return;
    }
    if (enabled) {
      disableBodyScroll(lockRef.current);
    } else {
      enableBodyScroll(lockRef.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [enabled, lockRef]);

  return lockRef;
};
