import { Stack, VStack } from 'native-base';

import { ButtonSkeleton, OrderCardSkeleton } from '@/components/Elements';

export const AccountTabSkeleton = () => {
  return (
    <VStack w="100%" alignItems="center" space={{ base: '16px', lg: '24px' }}>
      {/* Personal Info Card */}
      <OrderCardSkeleton h={{ base: '124px', lg: '182px' }} />

      {/* Shipping & Billing Cards */}
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        space={{ base: '16px', lg: '24px' }}
        justifyContent="center"
        alignItems="center"
        w={{ base: '100%', lg: '408px' }}
      >
        <OrderCardSkeleton h={{ base: '124px', lg: '182px' }} />
        <OrderCardSkeleton h={{ base: '90px', lg: '182px' }} />
      </Stack>

      {/* Active Plans Card */}
      <OrderCardSkeleton h={{ base: '90px', lg: '132px' }} />

      {/* Password & Notification Preferences Cards */}
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        space={{ base: '16px', lg: '24px' }}
        justifyContent="center"
        alignItems="center"
        w={{ base: '100%', lg: '408px' }}
        mb={2}
      >
        <OrderCardSkeleton h={{ base: '68px', lg: '130px' }} />
        <OrderCardSkeleton h={{ base: '68px', lg: '130px' }} />
      </Stack>

      {/* Logout Button */}
      <ButtonSkeleton />
    </VStack>
  );
};
