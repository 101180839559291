import { Heading, IPressableProps, Pressable, Stack } from 'native-base';

import { ChevronDown } from '@/components/Icons';

export interface EditableCardProps extends IPressableProps {
  heading?: any;
  onPress?: (any?: any) => void;
  headingProps?: React.ComponentProps<typeof Heading>;
  stackProps?: React.ComponentProps<typeof Stack>;
  children?: React.ReactNode;
  chevron?: 'right' | 'down' | false;
  accordion?: boolean;
  my?: string | number;
}

const ChevronIcon = ({ dir = 'right' }: { dir: 'right' | 'down' }) => {
  return (
    <Stack
      w={{ base: '18px', lg: '24px' }}
      h={{ base: '18px', lg: '24px' }}
      justifyContent="center"
      alignItems="center"
    >
      <ChevronDown
        size={{ base: '10px', lg: '12px' }}
        style={{ transform: dir === 'right' ? [{ rotate: '-90deg' }] : undefined }}
        color="black"
      />
    </Stack>
  );
};

export const EditableCard = ({
  heading,
  headingProps,
  onPress,
  stackProps,
  chevron = 'right',
  accordion = false,
  my,
  ...props
}: EditableCardProps) => {
  return (
    <Pressable
      variant="editCard"
      my={my}
      w="100%"
      h="fit-content"
      flexDirection="row"
      borderWidth="1px"
      onPress={onPress}
      {...props}
    >
      <Stack
        flex={1}
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        {...stackProps}
      >
        {heading && (
          <Heading
            size="bodyMlToTitleSm"
            w="100%"
            fontWeight="bold"
            textAlign="start"
            {...headingProps}
          >
            {heading}
          </Heading>
        )}
        {props.children}
      </Stack>
      {chevron && (
        <Stack alignSelf={accordion ? 'flex-start' : 'center'} justifyContent="center" h="100%">
          <ChevronIcon dir={chevron} />
        </Stack>
      )}
    </Pressable>
  );
};
