const defaultProps = {
  onTrackColor: 'sntBlue.primary',
  offTrackColor: 'sntGrey.secondary',
  _hover: {
    onTrackColor: 'sntBlue.primary',
    offTrackColor: 'sntGrey.secondary',
  },
};

export const Switch = {
  defaultProps,
};
