import { Button, IButtonProps, IInputProps, Input } from 'native-base';

export interface InputWithButtonProps extends IInputProps {
  buttonOnPress?: IButtonProps['onPress'];
  buttonProps?: Omit<IButtonProps, 'children' | 'onPress'>;
}

export const InputWithButton = ({
  children,
  buttonOnPress,
  buttonProps,
  ...inputProps
}: InputWithButtonProps) => {
  return (
    <Input
      rightElement={
        <Button
          variant="inline"
          w="fit-content"
          size="bodyMdToLg"
          fontWeight="bold"
          onPress={buttonOnPress}
          {...buttonProps}
        >
          {children}
        </Button>
      }
      {...inputProps}
    />
  );
};
