import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { PlansDetails, BuildPlan } from '@/api/types';
import { axios } from '@/lib/axios';

export interface PlanSizeData {
  planSize: number;
  account_id?: string;
  session_id?: string;
}

export interface PlansForSize {
  [id: string]: BuildPlan;
}

export const getPlansBySize = (data: PlanSizeData, previewRetentionDiscount: boolean) => {
  return axios
    .post<PlansDetails, AxiosResponse<PlansForSize>>(`/calculator/build_plans_for_size`, {
      plan_size: data.planSize,
      preview_retention_discount: previewRetentionDiscount,
      account_id: data.account_id,
    })
    .then((res) => res.data);
};

export const usePlansBySize = (data?: PlanSizeData, previewRetentionDiscount = false) => {
  return useQuery(['PlansBySize', data?.planSize, previewRetentionDiscount], {
    queryFn: () => getPlansBySize(data as PlanSizeData, previewRetentionDiscount),
    enabled: !!data,
  });
};
