import Birthday from './Birthday';
import BodyType from './BodyType';
import Breed from './Breed';
import Calculating from './Calculating';
import Checkout from './Checkout';
import DogName from './DogName';
import FoodTypes from './FoodTypes';
import Gender from './Gender';
import HealthConditions, { HealthConditionsList } from './HealthConditions';
import HowManyDogs from './HowManyDogs';
import Lifestyle from './Lifestyle';
import Location from './Location';
import MealPlan from './MealPlan';
import NameAndEmail from './NameAndEmail';
import Neutered from './Neutered';
import PostCheckout from './PostCheckout';
import ProcessingOrder from './ProcessingOrder';
import Recipes from './Recipes';
import Snacks from './Snacks';
import Supplements from './Supplements';
import Weight from './Weight';
import WelcomeScreen from './WelcomeScreen';

export const FunnelComponents = {
  NameAndEmail,
  HowManyDogs,
  DogName,
  Location,
  Gender,
  Breed,
  Birthday,
  Neutered,
  BodyType,
  Weight,
  Lifestyle,
  HealthConditions,
  HealthConditionsList,
  FoodTypes,
  Recipes,
  Calculating,
  MealPlan,
  Snacks,
  Supplements,
  Checkout,
  ProcessingOrder,
  PostCheckout,
  WelcomeScreen,
};
