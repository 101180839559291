// do this to prep a session for checkout
import { PrepareCheckout, PromotionBannerData } from '@/api/types';
import { axios } from '@/lib/axios';

export const prepare_checkout = async (
  id: string,
  atPageLoad?: boolean
): Promise<PrepareCheckout> =>
  await axios
    .post(`/sessions/${id}/prepare_checkout`, { at_page_load: atPageLoad }, { noErrorToast: true })
    .then((res) => res.data);

export const get_promotions = async (): Promise<PromotionBannerData[]> =>
  axios.get('/active_promotions').then((res) => res.data);
