import {
  Stack,
  Heading,
  IconButton,
  ChevronDownIcon,
  ChevronUpIcon,
  Text,
  Divider,
  HStack,
  Badge,
} from 'native-base';
import { useState } from 'react';

import { OrderStatusPill } from '../OrderStatusPill';
import { ProductsSummarySection } from './ProductsSummarySection';
import { TotalSection } from './TotalSection';

import { Order } from '@/api/types';
import { EditableCard, EditableCardProps } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { formatPrice } from '@/utils';

interface OrderSummaryProps extends EditableCardProps {
  order?: Order;
  hasDropdown?: boolean;
}

export const OrderSummary = ({ order, hasDropdown, ...props }: OrderSummaryProps) => {
  const account = useAccount();
  const [isExpanded, setIsExpanded] = useState(false);

  const showFull = !hasDropdown || (hasDropdown && isExpanded);

  if (!order) {
    return null;
  }

  const discountCode = order.historical_coupon_code || order.discounts?.[0]?.discount?.code;

  return (
    <EditableCard
      chevron={false}
      p={{ base: '16px', lg: '24px' }}
      onPress={() => setIsExpanded(!isExpanded)}
      {...props}
    >
      <Stack w="100%" space={{ base: '16px', lg: '24px' }}>
        <HStack justifyContent="space-between" alignItems="center">
          <Heading size="bodyMlToTitleSm" fontWeight="bold">
            Order Summary
          </Heading>
          <HStack>
            <OrderStatusPill order={order} />
            {hasDropdown ? (
              <ExpandChevron isExpanded={isExpanded} onPress={() => setIsExpanded(!isExpanded)} />
            ) : null}
          </HStack>
        </HStack>
        {showFull ? (
          <Stack space={{ base: '16px', lg: '24px' }}>
            <ProductsSummarySection order={order} pets={account.pets} />
            <Stack space={{ base: '8px', lg: '16px' }}>
              {discountCode && order.total_discounts && (
                <HStack justifyContent="space-between" alignItems="center">
                  <Text fontWeight="medium" size="bodyMdToLg">
                    Discount
                  </Text>
                  <Text fontWeight="medium" size="bodyMdToLg">
                    <Badge variant="smallInfoPillSecondary">{discountCode}</Badge> -$
                    {order.total_discounts}
                  </Text>
                </HStack>
              )}
              <HStack justifyContent="space-between">
                <Text fontWeight="medium" size="bodyMdToLg">
                  Shipping
                </Text>
                <Text fontWeight="medium" size="bodyMdToLg">
                  FREE
                </Text>
              </HStack>
              <HStack justifyContent="space-between">
                <Text fontWeight="medium" size="bodyMdToLg">
                  Tax
                </Text>
                <Text fontWeight="medium" size="bodyMdToLg">
                  {formatPrice(order.total_tax)}
                </Text>
              </HStack>
            </Stack>
            <Divider bg="gallery" />
          </Stack>
        ) : null}
        <TotalSection order={order} showFull={showFull} />
      </Stack>
    </EditableCard>
  );
};

const ExpandChevron = ({ isExpanded, onPress }: { isExpanded: boolean; onPress?: () => void }) => {
  return (
    <IconButton
      onPress={onPress}
      borderWidth="0"
      p={{ base: '4.5px', lg: '6px' }}
      icon={
        isExpanded ? (
          <ChevronUpIcon color="black" size={{ base: '9px', lg: '12px' }} />
        ) : (
          <ChevronDownIcon color="black" size={{ base: '9px', lg: '12px' }} />
        )
      }
    />
  );
};
