import { Button, HStack, Spacer, Stack, Text } from 'native-base';
import { useState } from 'react';

import { RetentionDiscountModal } from '..';
import {
  nextPlanSizeLower,
  useAnswerSurveyAndCancelPlan,
  useInterventionEligibility,
} from '../hooks';
import { StopPlanSuccessModal } from './StopPlanSuccessModal';
import { SwapToTopperSkeleton } from './SwapToTopperSkeleton';
import { Intervention } from './types';

import {
  DiscountExclusiveType,
  ProductType,
  RecipeType,
  usePlanSizesForRecipes,
  usePlansByPet,
  useUpdatePetPlan,
} from '@/api';
import { Image, displayToast } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useAccount } from '@/hooks';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';
import { getRecipeImageSrc, getRecipesBowlImageSrc } from '@/utils';

export const SwapToTopper = ({ navigation, route }: ProtectedScreenProps<'SwapToTopper'>) => {
  const account = useAccount();
  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();
  const { isLoading: isSubmittingCancel, answerSurveyAndCancelPlan } =
    useAnswerSurveyAndCancelPlan();
  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);
  const pet = account.pets.find((pet) => pet.id === route.params.petId);
  const { interventions, isLoading: isLoadingInterventionEligiblity } = useInterventionEligibility(
    pet?.id
  );
  const [retentionDiscountModalOpen, setRetentionDiscountModalOpen] = useState(false);

  const { data: planData } = usePlansByPet(pet, null, account.id);
  const { data: planSizes } = usePlanSizesForRecipes({
    recipes: pet?.pet_plan.recipe_data.map((recipe) => recipe.id) || [],
    account_id: account.id,
  });

  let topperPlanSize = planData?.topper_plan_size.id;
  // if the current plan size is already at topper size or lower, move one step down
  if (pet && planSizes && topperPlanSize && topperPlanSize >= pet?.pet_plan.plan_size) {
    topperPlanSize = nextPlanSizeLower(planSizes, pet.pet_plan.plan_size);
  }
  const topperPlanData = topperPlanSize ? planSizes?.[topperPlanSize] : undefined;

  const petMealProduct = pet?.pet_plan.products.find(
    (product) => product.product_type === ProductType.MEAL
  );
  const recipeType = petMealProduct?.recipes[0].type as RecipeType.FRESH | RecipeType.UNKIBBLE;

  const onPressSwapToTopper = async () => {
    if (!pet || !topperPlanData) {
      return;
    }
    const original_plan_size = pet.pet_plan.plan_size;
    await updatePetPlan({
      petPlanId: pet.pet_plan.id,
      data: {
        plan_size: topperPlanSize,
        is_topper: true,
      },
    });
    segment.trackEvent('Cancellation Flow - Selected Switch To Topper', {
      account_id: account.id,
      email: account.email,
      cancellation_reason: route.params.primaryAnswer,
      cancellation_reason_secondary: route.params.secondaryAnswer,
      cancellation_reason_comment: route.params.customerComment,
      pet_id: pet.id,
      previous_plan_size: original_plan_size,
      new_plan_size: topperPlanSize,
    });
    displayToast({ message: `${pet.name}'s meal plan has been updated.` });
    navigation.navigate('Dogs', { petId: pet.id });
  };

  const navigateToAccount = () => {
    setStopPlanSuccessModalOpen(false);
    setRetentionDiscountModalOpen(false);
    navigation.navigate('Account');
  };

  const onPressCancel = async () => {
    if (interventions.includes(Intervention.TenPercentDiscount)) {
      setRetentionDiscountModalOpen(true);
    } else {
      doCancelPlan();
    }
  };

  const doCancelPlan = async () => {
    if (!pet) {
      return;
    }
    await answerSurveyAndCancelPlan({
      pet,
      primaryAnswer: route.params.primaryAnswer,
      secondaryAnswer: route.params.secondaryAnswer,
      customerComment: route.params.customerComment,
    });

    setRetentionDiscountModalOpen(false);
    setStopPlanSuccessModalOpen(true);
  };

  if (!pet || !recipeType || !topperPlanData || isLoadingInterventionEligiblity) {
    return <SwapToTopperSkeleton />;
  }

  return (
    <Stack alignItems="center" w="100%">
      <Stack alignItems="center" w="100%" px={4} maxW={{ lg: '672px' }}>
        <Text
          size="bodySmToMd"
          fontFamily="secondary"
          fontWeight="medium"
          textAlign="center"
          mb={{ base: 6, lg: 4 }}
          display={{ lg: 'none' }}
        >
          Lower your weekly cost by mixing our food with another food source (even a little bit of
          real, fresh food goes a long way!)
        </Text>
        <Stack display={{ base: 'none', lg: 'flex' }}>
          <Text size="bodySmToMd" fontFamily="secondary" fontWeight="medium" textAlign="center">
            Lower your weekly cost by mixing our food with another food source
          </Text>
          <Text
            size="bodySmToMd"
            fontFamily="secondary"
            fontWeight="medium"
            textAlign="center"
            mb={6}
          >
            (even a little bit of real, fresh food goes a long way!)
          </Text>
        </Stack>
        <HStack space={4} mb={{ base: 2, lg: 4 }}>
          <Stack alignItems="center">
            <Text size="bodyMdToLg" fontWeight="bold" mb={{ base: 2, lg: 1 }}>
              Current Plan
            </Text>
            <Stack mb={1}>
              <Image
                alt="Full bowl of food"
                source={{
                  uri: getRecipeImageSrc(pet.pet_plan.recipe_data, 'FoodType'),
                }}
                size={{ base: '140px', lg: '193px' }}
              />
            </Stack>
            <HStack
              w={{ base: '139px', lg: '183px' }}
              h={{ base: '40px', lg: '52px' }}
              alignItems="center"
              justifyContent="center"
              mt={1}
            >
              <Text fontWeight="bold" size="bodyMdToLg" mr={1}>
                ${petMealProduct?.price_per_week}
              </Text>
              <Text fontWeight="medium" size="bodySmToMd">
                /week
              </Text>
            </HStack>
          </Stack>
          <Spacer display={{ base: 'none', lg: 'flex' }} w="126px" />
          <Stack alignItems="center">
            <Text size="bodyMdToLg" fontWeight="bold" mb={{ base: 2, lg: 1 }}>
              Mixed Plan
            </Text>
            <Stack mb={1}>
              <Image
                alt="Half a bowl of food"
                source={{
                  uri: getRecipesBowlImageSrc([pet.pet_plan.recipes.sort()[0]], recipeType, true),
                }}
                size={{ base: '140px', lg: '193px' }}
              />
            </Stack>
            <Stack
              position="relative"
              w={{ base: '139px', lg: '183px' }}
              h={{ base: '40px', lg: '52px' }}
              alignItems="center"
              justifyContent="center"
            >
              <Image
                alt="oval"
                position="absolute"
                source={{ uri: `${ASSETS_URL}/pictos/Illustrations/Handdrawn-Blue-Oval.png` }}
                top={0}
                bottom={0}
                right={0}
                left={0}
              />
              <HStack
                w={{ base: '139px', lg: '183px' }}
                h={{ base: '40px', lg: '52px' }}
                alignItems="center"
                justifyContent="center"
                mt={1}
              >
                <Text fontWeight="bold" size="bodyMdToLg" mr={1}>
                  ${topperPlanData.subscription.price?.price_per_week}
                </Text>
                <Text fontWeight="medium" size="bodySmToMd">
                  /week
                </Text>
              </HStack>
            </Stack>
          </Stack>
        </HStack>
        <Stack mb={{ base: 8, lg: '48px' }}>
          <Image
            alt="Right arrow"
            source={{ uri: `${ASSETS_URL}/pictos/Illustrations/Arrow-Right-Looping.png` }}
            w={{ base: '109px', lg: '188px' }}
            h={{ base: '24px', lg: '41px' }}
          />
        </Stack>
        <Text size="bodyMdToLg" fontWeight="bold" mb={{ base: 4, lg: 6 }}>
          Start a mixing plan and you'll get...
        </Text>
        <HStack mb="82px" space={{ base: 2, lg: 6 }}>
          <Stack flex={1} alignItems="center">
            <Image
              alt="Dog with dollar bills"
              source={{ uri: `${ASSETS_URL}/pictos/Illustrations/DiscountDog.png` }}
              w={{ base: '63px', lg: '92px' }}
              h={{ base: '82px', lg: '120px' }}
              mb={{ base: 1, lg: 2 }}
            />
            <Text size="bodySmToMd" fontWeight="bold" mb={{ base: 1, lg: 2 }}>
              Lower Price
            </Text>
            <Text
              size="captionToBodyMd"
              fontWeight="medium"
              fontFamily="secondary"
              textAlign="center"
            >
              Partial portions deliver the benefits while keeping costs down
            </Text>
          </Stack>
          <Stack flex={1} alignItems="center">
            <Image
              alt="Fork carrot knife"
              source={{ uri: `${ASSETS_URL}/pictos/Illustrations/Fork-Carrot-Knife.png` }}
              w={{ base: '63px', lg: '92px' }}
              h={{ base: '82px', lg: '120px' }}
              mb={{ base: 1, lg: 2 }}
            />
            <Text size="bodySmToMd" fontWeight="bold" mb={{ base: 1, lg: 2 }}>
              Real Food
            </Text>
            <Text
              size="captionToBodyMd"
              fontWeight="medium"
              fontFamily="secondary"
              textAlign="center"
            >
              High-quality, human grade ingredients
            </Text>
          </Stack>
          <Stack flex={1} alignItems="center">
            <Stack
              w="100%"
              h={{ base: '82px', lg: '120px' }}
              mb={{ base: 1, lg: 2 }}
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                alt="Sitting dog"
                source={{ uri: `${ASSETS_URL}/pictos/Illustrations/Sitting-Dog.png` }}
                position="absolute"
                w={{ base: '120px', lg: '126px' }}
                h={{ base: '82px', lg: '120px' }}
              />
            </Stack>
            <Text size="bodySmToMd" fontWeight="bold" mb={{ base: 1, lg: 2 }} textAlign="center">
              Health Benefits
            </Text>
            <Text
              size="captionToBodyMd"
              fontWeight="medium"
              fontFamily="secondary"
              textAlign="center"
            >
              Energy boosts, shiny coats, and perfect poops
            </Text>
          </Stack>
        </HStack>
        <Button
          variant="hugPrimary"
          w={{ base: '208px', lg: '291px' }}
          onPress={onPressSwapToTopper}
          isLoading={isLoadingUpdatePetPlan}
          isDisabled={isSubmittingCancel || isLoadingInterventionEligiblity}
          mb={{ base: 4, lg: 6 }}
        >
          START MIXING PLAN
        </Button>
        <Button
          variant="underlineMini"
          onPress={onPressCancel}
          isDisabled={
            isSubmittingCancel || isLoadingUpdatePetPlan || isLoadingInterventionEligiblity
          }
          mb={{ base: 8, lg: 10 }}
        >
          Continue to cancellation
        </Button>
        <RetentionDiscountModal
          isOpen={retentionDiscountModalOpen}
          onClose={() => setRetentionDiscountModalOpen(false)}
          petId={pet.id}
          onSecondaryPress={doCancelPlan}
          discountType={DiscountExclusiveType.WINBACK_10_PERCENT}
          cancellationReason={route?.params?.primaryAnswer}
          cancellationReasonSecondary={route?.params?.secondaryAnswer}
          cancellationReasonComment={route?.params?.customerComment}
        />
        <StopPlanSuccessModal
          isOpen={stopPlanSuccessModalOpen}
          onPressX={navigateToAccount}
          onConfirm={navigateToAccount}
        />
      </Stack>
    </Stack>
  );
};
