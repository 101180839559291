import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { Heading, Stack } from 'native-base';
import { useState, useCallback } from 'react';

import { LifestyleCode, usePetFormOptions } from '@/api';
import { Card } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FunnelScreenProps } from '@/types';
import { Dog } from '@/types/FunnelSession';
import { getCurrentPet } from '@/utils/getCurrentPet';

export default function Lifestyle({ navigation }: FunnelScreenProps<'Lifestyle'>) {
  const isFocused = useIsFocused();
  const nextStep = useNextFunnelStep(FunnelScreenNames.LIFESTYLE);
  const { session, mutateUpdatePetAnswer } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { addAxiosPromise } = useAxiosStatusContext();
  const [previousLifestyle, setLifestyle] = useState<Dog['lifestyle'] | undefined>();
  const dog = getCurrentPet(session);

  const { data: petFormData } = usePetFormOptions();
  const lifestyles = petFormData?.lifestyles;

  const onSubmit = (lifestyle: LifestyleCode) => {
    setLifestyle(lifestyle);
    try {
      addAxiosPromise(
        mutateUpdatePetAnswer({
          lifestyle,
        })
      );
    } catch (error) {
      funnelErrorHandler(error, 'Update lifestyle');
    }
    navigation.navigate(nextStep);
  };

  useFocusEffect(
    useCallback(() => {
      setLifestyle(dog?.lifestyle);
    }, [dog])
  );

  if (!isFocused || !dog || !Object.keys(dog).length) {
    return null;
  }

  return (
    <FunnelWrapper
      containerProps={{ justifyContent: 'flex-start', space: { base: '36px', lg: '48px' } }}
    >
      <Heading size="titleSmToMd" textAlign="center" fontWeight="bold">
        What is {dog?.name}&apos;s lifestyle like?
      </Heading>

      <Stack
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="center"
        w="100%"
      >
        {lifestyles &&
          lifestyles.map((lifestyle, key) => {
            const my = key === 1 ? { base: '16px', lg: '0' } : { base: '0', lg: '0' };
            const mx = key === 1 ? { base: '0', lg: '24px' } : { base: '0', lg: '0' };
            const description =
              dog?.gender === 'MALE' ? lifestyle.description_male : lifestyle.description_female;

            return (
              <Card
                pictoWidth={{ base: '84px', lg: '140px' }}
                pictoHeight={{ base: '84px', lg: '140px' }}
                minH={{ lg: '330px' }}
                h={{ base: 'auto', md: 'fit-content', lg: '100%' }}
                key={key}
                mx={mx}
                my={my}
                textData={{
                  picto: `Lifestyle/${lifestyle.slug}.png`,
                  pictoAlt: '',
                  heading: lifestyle.name,
                  content: `${dog?.name}${description}`,
                  contentProps: {
                    color: 'black',
                    fontFamily: 'secondary',
                    fontWeight: 'medium',
                    size: 'bodySmToLg',
                  },
                }}
                onPress={() => onSubmit(lifestyle.slug)}
                isPressed={previousLifestyle === lifestyle.slug}
              />
            );
          })}
      </Stack>
    </FunnelWrapper>
  );
}
