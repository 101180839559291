import { axios } from '@/lib/axios';

type SetPasswordData = {
  password: string;
  password_confirm: string;
  reset_token_id: string;
  reset_token_key: string;
};

type ResetPasswordResponse = {
  auth_token: string;
};

/**
 *
 * Sets the user's password using reset token ID and key.
 */

export const reset_password = (data: SetPasswordData) => {
  return axios.post<SetPasswordData, ResetPasswordResponse>(`/reset_password`, data, {
    noErrorToast: true,
  });
};
