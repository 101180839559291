import * as Linking from 'expo-linking';
import { Stack, Button } from 'native-base';

import { useAccount } from '@/hooks';

export const ReferralBtn = ({ isReferral }: { isReferral: boolean }) => {
  const account = useAccount();

  const handleHref = () => {
    const uri = isReferral ? 'https://spotandtango.com/refer' : Linking.createURL('/signup');

    Linking.canOpenURL(uri).then(() => Linking.openURL(uri));
  };

  return (
    <Stack direction="row" alignItems="center" space={account ? '11px' : 0}>
      <Button onPress={handleHref} variant="hugPrimary" py={{ base: 2, lg: 3 }}>
        GET $50
      </Button>
    </Stack>
  );
};
