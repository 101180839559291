import { datadogLogs } from '@datadog/browser-logs';

const sendDatadogReport = (error: Error | string, prefix?: string) => {
  const prefixStr = `${prefix ? `${prefix} ` : ''}`;
  if (error instanceof Error) {
    datadogLogs.logger.error(`${prefixStr}${error.message}`);
  } else {
    datadogLogs.logger.error(`${prefixStr}${error}`);
  }
};

export const sendErrorReport = (error: any) => {
  // logic to add
  if (error instanceof Error) {
    console.log(error);
  }
};

export const sendFunnelErrorReport = (error: Error | string) => {
  sendDatadogReport(error, 'Funnel error:');
};
