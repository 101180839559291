import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Stack, Heading } from 'native-base';
import { useEffect } from 'react';

import { BuildPlan, RecipeType, usePlansBySize } from '@/api';
import { LoadingSpinner, ProductCard } from '@/components/Elements';
import { useAccount } from '@/hooks/useAuth';
import { ProtectedStackParamList } from '@/types';
import { cheapestPlanByProduct } from '@/utils';

type FoodProductLine = RecipeType.FRESH | RecipeType.UNKIBBLE;

export const DogSelectFoodType = ({
  navigation,
  route,
}: NativeStackScreenProps<ProtectedStackParamList, 'DogSelectFoodType'>) => {
  const account = useAccount();
  const { pets } = account;
  const pet = pets.find((pet) => pet.id === route.params?.petId);
  const { data: availablePlans, isSuccess: isSuccessPlansBySize } = usePlansBySize(
    pet && {
      planSize: pet?.pet_plan.plan_size,
      account_id: account.id,
    }
  );

  useEffect(() => {
    if (pet) {
      navigation.setOptions({ title: `Change ${pet.name}'s Food Type` });
    }
  }, [account, navigation, pet]);

  const isReady = pet && isSuccessPlansBySize && availablePlans;

  if (!isReady || !pet) {
    return <LoadingSpinner accessLabel="Change Food Type Page" />;
  }

  const activeRecipeOptions = Object.values(availablePlans).filter(
    (meal) => meal?.subscription?.price?.pricing_model.status !== 'DISCONTINUED'
  );
  const cheapestRecipeOptions = cheapestPlanByProduct(
    activeRecipeOptions,
    'subscription',
    'price_per_week'
  );

  const recipeOptions = [
    cheapestRecipeOptions[RecipeType.UNKIBBLE] as BuildPlan,
    cheapestRecipeOptions[RecipeType.FRESH] as BuildPlan,
  ].map(({ subscription }) => ({
    id: subscription.product.recipes[0].id,
    recipes: subscription.product.recipes,
    pricePerWeek: subscription.price.price_per_week,
    discountedPricePerWeek: subscription.price.discounted_price_per_week,
  }));

  const selectedFoodType = pet.pet_plan.recipe_data.some(
    (recipe) => recipe.type === RecipeType.FRESH
  )
    ? RecipeType.FRESH
    : RecipeType.UNKIBBLE;

  const onSelect = (foodType: FoodProductLine) => {
    navigation.navigate('DogSelectRecipe', {
      successRedirect: route.params?.successRedirect || 'Dogs',
      successRedirectParams: route.params?.successRedirectParams,
      foodType,
      petId: pet.id,
    });
  };

  return (
    <Stack alignItems="center" w="100%" p="24px" paddingTop="0px">
      <Heading paddingBottom="24px" variant="subtitle">
        Two incredible choices, with nothing artificial in sight. You really can't go wrong!
      </Heading>
      <Stack direction={{ base: 'column', lg: 'row' }} space={6} justifyContent="center">
        {recipeOptions.map((option) => (
          <Stack key={option.id}>
            <ProductCard
              recipe={option.recipes[0]}
              heading={option.recipes[0].type === RecipeType.UNKIBBLE ? 'UnKibble™' : 'Fresh'}
              pricing={
                option.discountedPricePerWeek ? option.discountedPricePerWeek : option.pricePerWeek
              }
              infoPills={[
                option.recipes[0].type === RecipeType.UNKIBBLE
                  ? 'Most Popular'
                  : 'Original Recipes',
              ]}
              pricingUnit="week"
              onPress={() => onSelect?.(option.recipes[0].type as FoodProductLine)}
              selected={selectedFoodType === option.recipes[0].type}
              pricingPrefix="From"
              showInclusions={false}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
