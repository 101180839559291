import { CommonActions, NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { HStack, IconButton, Pressable, Stack, Text, View } from 'native-base';
import { useState } from 'react';

import { PortalPopUp } from '../PortalPopUp';

import { LeftArrow } from '@/components/Icons';
import { AddNewDogStackParamList } from '@/components/Portal';
import { AddNewDogScreenNames, AddNewDogScreens } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { ProtectedStackParamList } from '@/types';

export const AddNewDogHead = () => {
  const { resetData } = useAddNewDogContext();
  const navigation = useNavigation<NavigationProp<AddNewDogStackParamList>>();
  const portalNavigation = useNavigation<NavigationProp<ProtectedStackParamList>>();

  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const { name: routeName } = useRoute();

  const handleGoBack = async () => {
    const screenConfig = AddNewDogScreens.find((screen) => screen.name === routeName);
    if (!screenConfig?.previousStep) {
      return navigation.goBack();
    }

    return navigation.navigate(screenConfig.previousStep);
  };

  return (
    <View
      bgColor="white"
      position="relative"
      w="100%"
      overflow="hidden"
      mb={{ base: '24px', md: '64px' }}
    >
      <Stack alignItems="center" w="100%" borderBottomColor="gallery" borderBottomWidth={1}>
        <HStack w="100%" justifyContent="space-between" alignItems="center" pt="48px" pb="24px">
          {routeName !== AddNewDogScreenNames.DOG_NAME && (
            <IconButton
              position="absolute"
              left={0}
              ml={{ base: '16px', lg: '84px' }}
              onPress={handleGoBack}
              _icon={{ color: 'black' }}
              icon={<LeftArrow size={{ base: '18px', lg: '24px' }} />}
            />
          )}
          <Text fontWeight="bold" size="titleSm" flex={1} textAlign="center">
            Add A New Dog
          </Text>
          <Pressable
            position="absolute"
            right={{ base: '16px', lg: '84px' }}
            onPress={() => {
              if (routeName === AddNewDogScreenNames.DOG_NAME) {
                portalNavigation.dispatch(
                  CommonActions.reset({ index: 0, routes: [{ name: 'Dogs' }] })
                );
                return;
              }
              setShowConfirmExitModal(true);
            }}
          >
            <Text fontWeight="bold" size="bodySmToMd">
              Exit
            </Text>
          </Pressable>
        </HStack>
      </Stack>
      <PortalPopUp
        isOpen={showConfirmExitModal}
        onConfirm={() => setShowConfirmExitModal(false)}
        onClose={() => {
          resetData();
          portalNavigation.dispatch(CommonActions.reset({ index: 0, routes: [{ name: 'Dogs' }] }));
        }}
        onPressX={() => setShowConfirmExitModal(false)}
        heading="Heads Up!"
        description="If you exit now, you'll lose the information you've entered so far. Would you like to
        continue?"
        size={{ base: 'fit-content', md: '440px' }}
        marginY="auto"
        alignSelf="center"
        contentStackProps={{ px: { base: 4, md: 6 } }}
        headingProps={{ size: 'bodyMlToTitleSm' }}
        subHeadingProps={{ fontFamily: 'primary', size: 'bodySmToMd' }}
        confirmBtnText="CONTINUE QUIZ"
        backBtnText="LEAVE QUIZ"
      />
    </View>
  );
};
