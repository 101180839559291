import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';

export type ProductBodyData = {
  code: string;
  quantity: number;
  recurring: boolean;
};

const postProductsToPetPlan = ({
  petPlanId,
  products,
}: {
  petPlanId: string;
  products: ProductBodyData[];
}) => {
  const currentPathName = window.location.pathname;
  return axios
    .post(`/pet_plans/${petPlanId}/products`, { products, page_path: currentPathName })
    .then((response) => response.data);
};

export const useAddProductToPetPlan = () => {
  return useMutation(
    ({ petPlanId, products }: { petPlanId: string; products: ProductBodyData[] }) =>
      postProductsToPetPlan({ petPlanId, products })
  );
};
