import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Stack, Text, Link, Heading, HStack, Button, Image } from 'native-base';

import { Order } from '@/api';
import { CarrierLogo } from '@/components/Elements/CarrierLogo';
import { TRACKING_URL } from '@/config';
import { ASSETS_URL } from '@/constants';
import { ProtectedStackParamList } from '@/types';
import { getEstimatedArrivalRangeString, getModifyByDate, getOrderType } from '@/utils';

const DeliveredInfo = ({
  order,
  orderDate,
  type,
}: {
  order: Order;
  orderDate: string;
  type: 'shipped' | 'delivered';
}) => {
  const shipments = order.fulfillments.flatMap((fulfillment) => fulfillment.shipments);
  const tracks = shipments
    .map((shipment) => {
      const dataToInclude = {
        carrierCode: shipment.carrier_code,
        trackingNumber: shipment.tracking_number,
      };
      if (type === 'delivered' && shipment.delivered) {
        return {
          ...dataToInclude,
          date: `Arrived ${getModifyByDate(shipment.delivered)}`,
        };
      }
      return {
        ...dataToInclude,
        date: `Est. arrival ${orderDate}`,
      };
    })
    .filter((track) => track.trackingNumber && track.carrierCode);
  if (!tracks.length) {
    return null;
  }

  if (tracks.length === 1) {
    return (
      <Stack alignItems="center">
        <Stack
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
          paddingBottom="8px"
        >
          <Stack flexDirection="row" paddingRight="8px" paddingBottom="16px">
            <Stack paddingRight="8px">
              <CarrierLogo carrierCode={tracks[0].carrierCode} />
            </Stack>
            <Link
              href={`${TRACKING_URL}/${tracks[0].trackingNumber}`}
              isExternal
              variant="inline"
              _text={{ fontWeight: 'bold', size: 'bodySmToMd', textDecorationLine: 'underline' }}
            >
              {tracks[0].trackingNumber}
            </Link>
          </Stack>

          <Stack>
            <Text fontFamily="secondary" fontWeight="500" size="bodySmToMd">
              {tracks[0].date}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center">
      {tracks.map((track) => (
        <Stack flexDirection="row" key={track.trackingNumber} w="100%" paddingBottom="8px">
          <Stack paddingRight="8px">
            <CarrierLogo carrierCode={track.carrierCode} />
          </Stack>

          <Stack flex="1" paddingRight="8px">
            <Link
              href={`${TRACKING_URL}/${track.trackingNumber}`}
              isExternal
              variant="inline"
              _text={{ fontWeight: 'bold', size: 'bodySmToMd', textDecorationLine: 'underline' }}
            >
              {track.trackingNumber}
            </Link>
          </Stack>

          <Stack>
            <Text fontFamily="secondary" fontWeight="500" size="bodySmToMd">
              {track.date}
            </Text>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

const PreparingInfo = ({ orderDate }: { orderDate: string }) => {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Text fontFamily="secondary" color="sntGrey.primary" paddingBottom="8px" fontSize="body.md">
        Tracking available soon
      </Text>
      <Text paddingBottom="8px" fontSize="body.md" fontWeight="700">
        Est. arrival {orderDate}
      </Text>
    </Stack>
  );
};

const UpcomingInfo = ({
  order,
  orderDate,
  navigation,
}: {
  order: Order;
  orderDate: string;
  navigation: NativeStackNavigationProp<ProtectedStackParamList>;
}) => {
  const modifyDeadline = getModifyByDate(orderDate);
  return (
    <>
      {order.charged || !modifyDeadline ? null : (
        <Heading
          paddingBottom="16px"
          fontSize="body.lg"
          fontWeight="700"
          textAlign="center"
        >{`Modify this order until ${modifyDeadline}, 5pm ET`}</Heading>
      )}
      <Text
        paddingBottom="16px"
        color="sntGrey.primary"
        fontFamily="secondary"
        fontSize="body.md"
      >{`Est. Arrival ${getEstimatedArrivalRangeString(orderDate)}`}</Text>
      {order.charged ? null : (
        <HStack alignItems="center" space="8px">
          <Image w="18px" h="18px" source={{ uri: `${ASSETS_URL}/pictos/Icons/Calendar.svg` }} />
          <Button
            variant="underlineMini"
            onPress={() =>
              navigation.navigate('RescheduleDelivery', {
                orderId: order.id,
                successRedirect: 'Orders',
              })
            }
            size="bodySmToMd"
          >
            Reschedule
          </Button>
        </HStack>
      )}
    </>
  );
};

interface OrderInfoProps {
  selectedOrder?: Order;
  navigation: NativeStackNavigationProp<ProtectedStackParamList>;
}

export const OrderInfo = ({ navigation, selectedOrder }: OrderInfoProps) => {
  if (!selectedOrder) {
    return null;
  }

  const orderType = getOrderType(selectedOrder);
  const selectedOrderDate = selectedOrder?.charged
    ? selectedOrder.charged
    : selectedOrder?.scheduled;
  const estimatedArrivalDate = getEstimatedArrivalRangeString(selectedOrderDate);

  return (
    <Stack paddingTop="0px" w="100%" justifyContent="center" alignItems="center">
      {(orderType === 'delivered' || orderType === 'shipped') && (
        <DeliveredInfo order={selectedOrder} orderDate={estimatedArrivalDate} type={orderType} />
      )}
      {orderType === 'preparing' && <PreparingInfo orderDate={estimatedArrivalDate} />}
      {orderType === 'upcoming' && (
        <UpcomingInfo navigation={navigation} order={selectedOrder} orderDate={selectedOrderDate} />
      )}
    </Stack>
  );
};
