import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const createPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .trim()
    .min(8, 'min8Chars')
    .matches(/[0-9]+/, 'min1Number')
    .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/, 'min1SpecialChar'),

  password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'match'),
  // .required('Must retype password'),
});

export const createPasswordResolver = yupResolver(createPasswordSchema);
