import { AxiosError } from 'axios';
import { useErrorBoundary } from 'react-error-boundary';

import { sendFunnelErrorReport } from '@/utils';

export const useFunnelErrorHandler = () => {
  const { showBoundary } = useErrorBoundary();
  return (
    axiosError: any,
    requestName: string,
    errorCb?: () => void,
    criticalErrorCb?: () => void
  ) => {
    if (axiosError instanceof AxiosError) {
      sendFunnelErrorReport(`${requestName} ${axiosError.message}`);

      if (
        !axiosError.response ||
        axiosError.response?.status === 500 ||
        axiosError.response?.status === 404
      ) {
        // no response in axios error obj means broken data in network response
        if (criticalErrorCb) {
          criticalErrorCb();
        }
        showBoundary(axiosError);
      }
    }
    if (errorCb) {
      errorCb();
    }
  };
};
