import { datadogLogs } from '@datadog/browser-logs';
import { Heading, Stack, Box, Pressable, Text } from 'native-base';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { PetGender } from '@/api';
import { displayToast, ToastType } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';

const { logger } = datadogLogs;
export const AddNewDogNeutered = ({ navigation }: AddNewDogScreenProps<'Neutered'>) => {
  const account = useAccount();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.NEUTERED);
  const { petData, savePetData } = useAddNewDogContext();

  const onSubmit = async (isNeutered: boolean) => {
    try {
      savePetData({ is_neutered: isNeutered });
      navigation.navigate(nextStep);
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's information`,
        type: ToastType.Error,
      });
      logger.error(`Error saving 'isNeutered' for dog, account_id=${account.id}`, { error });
    }
    navigation.navigate(nextStep);
  };

  return (
    <AddNewDogWrapper
      containerProps={{
        justifyContent: 'flex-start',
        space: { base: '16px', lg: '24px' },
      }}
    >
      <Heading size="titleSmToMd" fontWeight="bold">
        Is {petData?.name ? petData.name : 'your dog'}{' '}
        {petData?.gender === PetGender.FEMALE ? 'spayed' : 'neutered'}?
      </Heading>

      <Stack
        w="100%"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Pressable
          variant="card"
          _hover={{ bg: 'white' }}
          w={{ base: '100%', md: '324px' }}
          onPress={() => onSubmit(true)}
          py={{ base: '42px', md: '36px' }}
          px="16px"
          isPressed={petData.is_neutered === true}
          alignItems="center"
        >
          <Text size="titleSmToMd" fontWeight="bold">
            Yes
          </Text>
        </Pressable>
        <Box w={{ base: '16px', lg: '24px' }} h={{ base: '16px', lg: '24px' }} />
        <Pressable
          variant="card"
          _hover={{ bg: 'white' }}
          w={{ base: '100%', md: '324px' }}
          onPress={() => onSubmit(false)}
          py={{ base: '42px', md: '36px' }}
          px="16px"
          isPressed={petData.is_neutered === false}
          alignItems="center"
        >
          <Text size="titleSmToMd" fontWeight="bold">
            No
          </Text>
        </Pressable>
      </Stack>
    </AddNewDogWrapper>
  );
};
