import { useIsFocused } from '@react-navigation/native';
import { Stack, FormControl, IFormControlProps, IInputProps, IStackProps, Text } from 'native-base';
import { LegacyRef, forwardRef, useEffect } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import ReactPhoneNumberInput from 'react-phone-number-input/input';

import { CheckoutDetails } from '../../../Checkout';

import {
  InputFloatLabel,
  InputFloatLabelProps,
  SelectFloatLabel,
  SelectOption,
} from '@/components/Elements';
import { states } from '@/constants';
import { useSessionQuery } from '@/hooks';

const stateOptions: SelectOption[] = states.map(({ short }) => ({ label: short, value: short }));

type InputType = 'input' | 'select' | 'addressInput' | 'phoneInput';

interface CheckoutFormRowProps {
  types: InputType[];
  names: ControllerProps<CheckoutDetails>['name'][];
  flexRatio: IFormControlProps['flex'][];
  direction?: IStackProps['direction'];
  placeholders: IInputProps['placeholder'][];
  required: boolean[];
  autoFocus?: boolean;
  addressInputRef?: React.MutableRefObject<any>;
}

const ControlledInputFloatLabel = forwardRef(
  (inputProps: InputFloatLabelProps, ref: LegacyRef<HTMLInputElement>) => {
    return <InputFloatLabel externalRef={ref} {...inputProps} />;
  }
);

export const CheckoutFormRow = ({
  types,
  names,
  flexRatio,
  direction,
  placeholders,
  addressInputRef,
  required,
  autoFocus,
}: CheckoutFormRowProps) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<CheckoutDetails>();

  const { session } = useSessionQuery();

  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused || !names) return;
    if (names.includes('first_name') && session?.first) {
      resetField('first_name', { defaultValue: session.first });
    }
    if (names.includes('email') && session?.email) {
      resetField('email', { defaultValue: session?.email });
    }
    if (names.includes('zip_code') && session?.zip_code) {
      resetField('zip_code', { defaultValue: session?.zip_code });
    }
  }, [isFocused]);

  return (
    <Stack
      direction={direction || { base: 'column', md: 'row' }}
      space={{ base: direction === 'row' ? 4 : 0, sm: 6 }}
    >
      {names.map((name, index) => {
        return (
          <FormControl
            flex={flexRatio[index]}
            key={`${name}-${index}`}
            isInvalid={name in errors}
            isRequired={required[index]}
            my={{ base: 3 }}
          >
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <>
                  <Stack h={{ base: '52px', lg: '56px' }}>
                    {types[index] === 'input' && (
                      <InputFloatLabel
                        nativeID={`checkout-input-${name}`}
                        variant={name in errors ? 'error' : 'base'}
                        onChangeText={onChange}
                        value={value as string}
                        placeholder={placeholders[index]}
                        autoFocus={index === 0 && autoFocus}
                        _stackProps={{ h: '100%' }}
                        h="100%"
                        maxLength={name === 'address2' ? 40 : undefined}
                      />
                    )}
                    {types[index] === 'phoneInput' && (
                      <ReactPhoneNumberInput
                        country="US"
                        inputComponent={ControlledInputFloatLabel}
                        nativeID={`checkout-input-${name}`}
                        variant={name in errors ? 'error' : 'base'}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value as string}
                        placeholder={placeholders[index]}
                        _stackProps={{ h: '100%' }}
                        h="100%"
                      />
                    )}
                    {types[index] === 'select' && (
                      <SelectFloatLabel
                        value={stateOptions.find(({ value: stateValue }) => stateValue === value)}
                        options={stateOptions}
                        onChange={(selectedOption) =>
                          onChange(
                            selectedOption ? (selectedOption as SelectOption).value : undefined
                          )
                        }
                        onBlur={onBlur}
                        placeholder="State"
                        _stackProps={{ h: '100%' }}
                      />
                    )}
                    {types[index] === 'addressInput' && (
                      <InputFloatLabel
                        nativeID={`checkout-input-${name}`}
                        variant={name in errors ? 'error' : 'base'}
                        externalRef={addressInputRef}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value as string}
                        placeholder={placeholders[index]}
                        _stackProps={{ h: '100%' }}
                        h="100%"
                        maxLength={40}
                      />
                    )}
                  </Stack>
                  {error?.message && (
                    <Text size="bodySm" mt="4px" color="error.default">
                      {error?.message}
                    </Text>
                  )}
                </>
              )}
            />
          </FormControl>
        );
      })}
    </Stack>
  );
};
