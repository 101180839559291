import { useNavigation } from '@react-navigation/native';
import { Stack, Text, Button } from 'native-base';
import React from 'react';

import { RootScreenNavigationProp } from '@/types';

export const PENDING_ACCOUNT_ERROR = 'Account is PENDING';

export const BARK_ACCOUNT_ERROR = 'Bark accounts cannot login to the portal.';

const DefaultErrorView = ({ message }: { message: string }) => {
  return (
    <Text size="bodySmToMd" color="error.default" my={{ base: 2, lg: 3 }}>
      {message} Please try again or contact us at{' '}
      <Text fontWeight="bold">care@spotandtango.com</Text> for assistance.
    </Text>
  );
};

export const PendingAccountView = () => {
  const navigation = useNavigation<RootScreenNavigationProp>();
  return (
    <Text size="bodySmToMd" color="error.default" my={{ base: 2, lg: 3 }}>
      Welcome to Spot & Tango! To get started, please complete our{' '}
      <Button
        onPress={() => navigation.navigate('Questionnaire')}
        size="bodySmToMd"
        variant="inline"
        _text={{ color: 'error.default' }}
      >
        onboarding quiz
      </Button>
      .
    </Text>
  );
};

export const LoginErrorMessage = ({ loginError }: { loginError: string | undefined }) => {
  if (!loginError) return null;

  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="start">
      {loginError === PENDING_ACCOUNT_ERROR ? (
        <PendingAccountView />
      ) : (
        <DefaultErrorView message={loginError} />
      )}
    </Stack>
  );
};
