import { useMutation } from 'react-query';

import { Account } from '@/api/types/accountServices';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

const patchAccount = async ({ accountId, data }: { accountId?: string; data: Partial<Account> }) =>
  axios.patch(`/accounts/${accountId}`, data).then((response) => response.data);

export const useUpdateAccount = () => {
  const { user: account, refetchUser } = useAuth();

  return useMutation<Account, Error, Partial<Account>>(
    (data) => patchAccount({ data, accountId: account?.id }),
    {
      onSuccess: () => refetchUser(),
    }
  );
};
