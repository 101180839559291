import { Stack, Text, Badge, Button } from 'native-base';

import { EditableCard } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { ProtectedScreenProps } from '@/types';

export const AddressList = ({ navigation, route }: ProtectedScreenProps<'AddressList'>) => {
  const account = useAccount();

  const successRedirect = route.params?.successRedirect || 'Account';
  const successRedirectParams = route.params?.successRedirectParams || {};

  return (
    <Stack
      w={{ base: '100%', lg: '540px' }}
      px={{ base: '16px', lg: '0px' }}
      pt={{ base: '0px', lg: '24px' }} // 24 + 24 from FormScreenHeader = 48 from Figma
      space={{ base: '16px', lg: '24px' }}
      justifyContent="start"
      alignItems="start"
      alignSelf="center"
    >
      {account.addresses
        .filter((address) => address.type === 'SHIPPING')
        .map((address, i) => (
          <EditableCard
            onPress={() =>
              navigation.navigate('EditAddress', {
                addressId: address.id,
                successRedirect,
                successRedirectParams,
              })
            }
            key={i}
            stackProps={{ flexDirection: 'column' }}
          >
            <Stack flexDirection="row">
              <Stack flexDirection="column" space={{ base: '0px', lg: '8px' }}>
                <Text
                  fontSize={{ base: 'body.md', lg: 'body.lg' }}
                  lineHeight={{ base: 'body.md', lg: 'body.lg' }}
                  color="grey.dark"
                >
                  {address.address1}
                </Text>
                <Text
                  fontSize={{ base: 'body.md', lg: 'body.lg' }}
                  lineHeight={{ base: 'body.md', lg: 'body.lg' }}
                  color="grey.dark"
                >
                  {address.address2}
                </Text>
                <Text
                  fontSize={{ base: 'body.md', lg: 'body.lg' }}
                  lineHeight={{ base: 'body.md', lg: 'body.lg' }}
                  color="grey.dark"
                >
                  {address.city}, {address.state.toUpperCase()} {address.zip}
                </Text>
                {address.is_primary ? (
                  <Badge mt={2} variant="primaryTag" size="xs">
                    Primary
                  </Badge>
                ) : null}
              </Stack>
            </Stack>
          </EditableCard>
        ))}
      <Stack px={2} pt={2}>
        <Button
          variant="underlineMini"
          onPress={() =>
            navigation.navigate('AddNewAddress', { successRedirect, successRedirectParams })
          }
          _text={{
            fontSize: { base: 'body.sm', lg: 'body.md' },
            lineHeight: { base: 'body.sm', lg: 'body.md' },
          }}
        >
          Add New Address
        </Button>
      </Stack>
    </Stack>
  );
};
