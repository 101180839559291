import { axios } from '@/lib/axios';

export type LoginCredentials = {
  email: string;
  password: string;
};

/**
 * Logs the user into an associated account.
 *
 * @params _Object_ `{email, password}`
 *
 * @description This should only occur on the Login page.
 * The function takes the email/password and sends a request to the backend API
 * to attempt to retrieve a user with the matching account credentials
 */
export const login = async ({ email, password }: LoginCredentials) =>
  axios.post(`/login`, { email, password }, { noErrorToast: true }).then(({ data }) => data);
