import {
  Button,
  CloseIcon,
  Heading,
  IHeadingProps,
  IModalProps,
  IconButton,
  Modal,
  Stack,
  Text,
} from 'native-base';

interface IBaseModal extends Omit<IModalProps, 'children' | 'onExit'> {
  header?: IHeadingProps['children'];
  body?: React.ReactNode;
  retakeCta?: string;
  continueCta?: string;
  handleContinuePress: () => void;
  handleRetakePress?: () => void;
  onClose: () => void;
}

export const SessionModal = ({
  header = 'Welcome Back!',
  body = 'Would you like to continue the quiz from where you left off?',
  retakeCta = 'START OVER',
  continueCta = 'Continue Quiz',
  handleContinuePress,
  handleRetakePress,
  onClose,
  ...props
}: IBaseModal) => (
  <Modal {...props}>
    <Modal.Content maxW="440px">
      <Stack w="100%" alignItems="flex-end" position="absolute" top={0} padding={5} zIndex={9999}>
        <IconButton size="24px" icon={<CloseIcon color="black" />} onPress={onClose} />
      </Stack>
      <Modal.Body p={{ base: '16px', md: '24px' }}>
        <Heading size="bodyMlToTitleSm" pb="16px" fontWeight="bold" textAlign="center">
          {header}
        </Heading>

        <Text size="bodySmToMd" fontWeight="medium" textAlign="center">
          {body}
        </Text>
      </Modal.Body>
      <Modal.Footer mx={{ base: '16px', md: '24px' }} py={{ base: '16px', md: '24px' }} px="0">
        <Stack flexDirection="row" justifyContent={retakeCta ? 'space-between' : 'center'} w="100%">
          {retakeCta && (
            <Button
              variant="tertiary"
              size="bodyMlToLg"
              px={4}
              py={2}
              pl="2px"
              pr="2px"
              w="fit-content"
              onPress={handleRetakePress}
            >
              {retakeCta}
            </Button>
          )}
          <Button
            bg="blueBayoux.primary"
            size="bodyMlToLg"
            px={{ base: '16px', md: '36px' }}
            py={{ base: '12px', md: '14px' }}
            w="fit-content"
            onPress={handleContinuePress}
          >
            {continueCta}
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);
