import { Skeleton, Stack, VStack } from 'native-base';

import { AddOnCarouselSkeleton } from '../AddOnShopping';

import {
  ButtonSkeleton,
  OrderCardSkeleton,
  PictoGroupSkeleton,
  PillMenuSkeleton,
} from '@/components/Elements';

export const DogTabSkeleton = () => {
  return (
    <Stack w="100%" alignItems="center">
      <VStack
        w={{ base: '100%', lg: '840px' }}
        space={{ base: 4, lg: 6 }}
        alignItems="center"
        py={4}
      >
        <ButtonSkeleton mb={{ base: 4, lg: 1 }} w={{ base: '154px', lg: '106px' }} h={{ lg: 8 }} />
        {/* Pills, Avatar, & Picto Header */}
        <VStack space={{ base: '24px', lg: '48px' }} alignItems="center">
          <PillMenuSkeleton numPills={2} />
        </VStack>
        <PictoGroupSkeleton numPictos={4} mt={{ base: 4, lg: '48px' }} />
        <ButtonSkeleton h={{ base: 8 }} w={{ base: '154px', lg: '208px' }} />
        <Stack px={{ base: 8 }} w="100%" space={{ base: 4, lg: 6 }}>
          <Skeleton h="1px" w="100%" />
          {/* Next Order, Plan Summary, & Feeding Guide */}
          <OrderCardSkeleton />
          <OrderCardSkeleton h={{ base: '554px', lg: '774px' }} />
          <OrderCardSkeleton />
          {/* Add On Carousel */}
          <AddOnCarouselSkeleton numItems={4} />
        </Stack>
      </VStack>
    </Stack>
  );
};
