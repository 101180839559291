import { Badge, Button, Center, Stack } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';

import { RecipeSelectFormSkeleton } from './RecipeSelectFormSkeleton';
import { RecipeSelectFormValues } from './useRecipeSelectForm';

import { usePlansByPet, usePlansBySize } from '@/api';
import { BuildPlan, Pet, PlanData, ProductStatus, RecipeType } from '@/api/types';
import { PDPModal, RecipeCard } from '@/components/Elements';
import { RecipeCardBanners } from '@/constants';
import { useAccount } from '@/hooks';

interface RecipeSelectFormProps {
  getValues: UseFormGetValues<RecipeSelectFormValues>;
  setValue: UseFormSetValue<RecipeSelectFormValues>;
  reset: UseFormReset<RecipeSelectFormValues>;
  recipeType?: RecipeType.FRESH | RecipeType.UNKIBBLE;
  pet: Pet;
  pricingUnit?: string;
  autoSelectRecipes?: boolean;
  retentionPricing?: boolean;
  hideBanners?: boolean;
  excludeRecipes?: string[];
}

/**
 * Generic form for selecting a recipe. Displays both Fresh and Unkibble by default,
 * but can be restricted to showing one or the other using `recipeType` option.
 *
 * @param getValues - React Hook Form value from useForm
 * @param setValue - React Hook Form value from useForm
 * @param reset - React Hook Form value from useForm
 * @param recipeType - Allows restricting the form to Fresh or Unkibble.
 * @param pet - Pet associated with the recipes
 * @param pricingUnit - displays next to the price (default: "week")
 * @param autoSelectRecipes - Controls whether the pet's current recipes should be selected on component mount (default: true)
 * @param retentionPricing - Controls whether to show intervention pricing next to standard pricing with a strikethrough (default: false)
 * @param hideBanners - Use to hide blue ribbon banners (default: false)
 * @param excludeRecipes - Use to set recipes as out of stock
 */
export const RecipeSelectForm = ({
  getValues,
  setValue,
  reset,
  recipeType,
  pet,
  pricingUnit = 'week',
  autoSelectRecipes = true,
  retentionPricing = false,
  hideBanners = false,
  excludeRecipes,
}: RecipeSelectFormProps) => {
  const account = useAccount();
  const { data: plansData, isSuccess: isSuccessPlansData } = usePlansByPet(pet, null, account.id);
  const { data: availablePlans, isSuccess: isSuccessPlansBySize } = usePlansBySize(
    pet && { planSize: pet?.pet_plan.plan_size, account_id: account.id },
    retentionPricing
  );

  const [selectedProductType, setSelectedProductType] = useState<RecipeType>(RecipeType.FRESH);
  const [showPDPModal, setShowPDPModal] = useState(false);
  const [planDetails, setPlanDetails] = useState<PlanData>();
  const [initialValueSelected, setInitialValueSelected] = useState(false);

  const isReady = pet && isSuccessPlansData && isSuccessPlansBySize;

  // divide plan object into unkibble and fresh sets
  const [unKibblePlans, freshPlans, allPlans] = useMemo(
    () =>
      Object.keys(availablePlans || {}).reduce<BuildPlan[][]>(
        (planSets, planId) => {
          const [unPlans, frPlans, all] = planSets;
          const plan = availablePlans?.[planId];
          if (plan) {
            all.push(plan);
            if (plan.recipe_type === RecipeType.UNKIBBLE) {
              unPlans.push(plan);
            } else if (plan.recipe_type === RecipeType.FRESH) {
              frPlans.push(plan);
            }
          }
          return [unPlans, frPlans, all];
        },
        [[], [], []]
      ),
    [availablePlans]
  );

  let recipeOptions: BuildPlan[] = [];
  let maxRecipes = 2;
  if (
    recipeType === RecipeType.FRESH ||
    (!recipeType && selectedProductType === RecipeType.FRESH)
  ) {
    recipeOptions = freshPlans;
    maxRecipes = plansData?.max_recipes_fresh || 2;
  } else if (
    recipeType === RecipeType.UNKIBBLE ||
    (!recipeType && selectedProductType === RecipeType.UNKIBBLE)
  ) {
    recipeOptions = unKibblePlans;
    maxRecipes = plansData?.max_recipes_unkibble || 2;
  }

  const togglePlan = (recipeId: string) => {
    const recipeIds = getValues('recipeIds');
    if (recipeIds.includes(recipeId)) {
      setValue(
        'recipeIds',
        recipeIds.filter((selectedId) => selectedId !== recipeId),
        { shouldDirty: true }
      );
    } else {
      const newRecipeIds = [...recipeIds, recipeId];
      if (newRecipeIds.length <= maxRecipes) {
        setValue('recipeIds', newRecipeIds, { shouldDirty: true });
      }
    }
  };

  const onPressSwitchType = () => {
    const newProductType =
      selectedProductType === RecipeType.FRESH ? RecipeType.UNKIBBLE : RecipeType.FRESH;
    setSelectedProductType(newProductType);
    if (pet.pet_plan.recipe_data.some((recipe) => recipe.type === newProductType)) {
      reset();
    } else {
      setValue('recipeIds', []);
    }
  };

  useEffect(() => {
    if (isReady && autoSelectRecipes && !initialValueSelected) {
      setInitialValueSelected(true);
      // pre-select the pet's existing recipes, unless this form is only showing recipes of a different food type
      // than the pet's current food type
      if (recipeType && !pet.pet_plan.recipe_data.some((recipe) => recipe.type === recipeType)) {
        setSelectedProductType(recipeType);
        reset({ recipeIds: [] }, { keepDirtyValues: true });
      } else {
        setSelectedProductType(
          pet.pet_plan.recipe_data.some((recipe) => recipe.type === RecipeType.FRESH)
            ? RecipeType.FRESH
            : RecipeType.UNKIBBLE
        );
        const planRecipeIds = allPlans.flatMap((planData) =>
          planData?.subscription?.product.recipes.map((recipe) => recipe.id)
        );
        reset(
          {
            recipeIds: pet.pet_plan.recipes.filter((recipeId) => planRecipeIds.includes(recipeId)),
          },
          { keepDirtyValues: true }
        );
      }
    }
  }, [
    allPlans,
    autoSelectRecipes,
    initialValueSelected,
    isReady,
    pet.pet_plan.recipe_data,
    pet.pet_plan.recipes,
    recipeType,
    reset,
  ]);

  if (!isReady) {
    return <RecipeSelectFormSkeleton />;
  }

  return (
    <Stack w="100%" alignItems="center">
      <Center marginBottom={{ base: 3, lg: 9 }}>
        <Badge variant="largeInfoPill" size="captionToBodySm">
          {`Select up to ${maxRecipes}`}
        </Badge>
      </Center>

      <Stack
        w="100%"
        direction={{ base: 'column', lg: 'row' }}
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
      >
        {recipeOptions.map((plan, i) => {
          const recipe = plan.subscription.product.recipes[0];
          const bannerText = RecipeCardBanners[recipe.id]?.portal;
          return plan?.subscription ? (
            <RecipeCard
              key={i}
              recipe={recipe}
              w={{ base: '100%', md: '484px', lg: '324px' }}
              h={{ base: '142px', md: '175px', lg: 'inherit' }}
              pricing={
                retentionPricing
                  ? plan.subscription.price.discounted_price_per_week
                  : plan.subscription.price.price_per_week
              }
              strikethroughPrice={retentionPricing ? plan.subscription.price.price_per_week : null}
              pricingUnit={pricingUnit}
              selected={getValues('recipeIds').includes(plan.subscription.product.recipes[0].id)}
              hideBanner={hideBanners}
              bannerText={bannerText}
              onPress={() => togglePlan(plan.subscription.product.recipes[0].id)}
              isDisabled={plan.subscription.product.status === ProductStatus.DISCONTINUED}
              underlineButton={{
                text: 'View Details',
                onPress: () => {
                  setPlanDetails(plan.subscription);
                  setShowPDPModal(true);
                },
              }}
              showInclusions={false}
              excludeRecipes={excludeRecipes}
            />
          ) : null;
        })}
      </Stack>
      {planDetails && (
        <PDPModal
          recipe={planDetails.product.recipes[0]}
          buttonText="Select Recipe"
          prices={
            retentionPricing
              ? [
                  planDetails.price.discounted_price_per_meal,
                  planDetails.price.discounted_price_per_week,
                ]
              : [planDetails.price.price_per_meal]
          }
          pricingUnits={retentionPricing ? ['meal', 'week'] : undefined}
          isTrial={retentionPricing}
          updateState={() => togglePlan(planDetails.product.recipes[0].id)}
          isOpen={showPDPModal}
          onClose={() => setShowPDPModal(false)}
        />
      )}
      {!recipeType && (
        <Stack alignItems="center">
          <Button variant="inline" mt={5} onPress={onPressSwitchType}>
            {`Switch to ${selectedProductType === RecipeType.FRESH ? 'UnKibble' : 'Fresh'}`}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
