import axios from 'axios';
import { Stack, Flex, Divider, FormControl, InfoOutlineIcon, Switch, Tooltip } from 'native-base';
import { useEffect } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';

import { FormSubmitButton } from '../../FormSubmitButton';
import { AddressForm } from './AddressForm';
import { AddressFormValues } from './AddressForm/types';
import { useAddressForm } from './AddressForm/useAddressForm';
import { useConfirmSetPrimaryModal } from './ConfirmSetPrimaryModal';

import { useCreateAddress } from '@/api/services';
import { displayToast } from '@/components/Elements';
import { useValidateAddressQuery } from '@/hooks';
import { ProtectedScreenProps } from '@/types/navigation';
import { defaultErrorHandler, invalidAddressErrorHandler } from '@/utils';

export const AddNewAddress = ({ navigation, route }: ProtectedScreenProps<'AddNewAddress'>) => {
  const { handleSubmit, formState, control, setValue, trigger, watch, setError } = useAddressForm();

  const { isValid, isSubmitting } = formState;

  const { mutateAsync: createAddress, isLoading } = useCreateAddress({
    noErrorToast: true,
  });

  const successRedirect = route.params?.successRedirect;
  const successRedirectParams = route.params?.successRedirectParams || {};

  const {
    validateAddress: { mutateAsync: validateAddress },
  } = useValidateAddressQuery();

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit: SubmitHandler<AddressFormValues> = async (data) => {
    try {
      setShowConfirmSetPrimaryModal(false);
      await validateAddress(data);
      await createAddress(data, {
        onSuccess: () => {
          if (successRedirect) {
            navigation.navigate(successRedirect, successRedirectParams);
          } else {
            navigation.navigate('AddressList');
          }
          displayToast({
            message: 'A new shipping address has been added.',
          });
        },
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const resData = e.response?.data;
        const errors = resData?.errors;
        if (Array.isArray(errors)) {
          // handle cases like mismatching state + zipcode
          const fullAddressErrors = errors.filter((error) => error?.name === 'full_address');
          if (fullAddressErrors.length > 0) {
            setError('address1', {
              type: 'string',
              message: 'There was a problem with the address you submitted.',
            });
          }
          for (const error of errors) {
            if (error.name === 'zip_code') {
              setError('zip', { type: 'string', message: error.message });
            } else {
              setError(error.name, { type: 'string', message: error.message });
            }
          }

          if (errors.length >= 1) {
            invalidAddressErrorHandler();
          }
        }
      } else {
        defaultErrorHandler();
      }
    }
  };
  const onSaveClick = () => {
    if (watch('is_primary')) {
      setShowConfirmSetPrimaryModal(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const { Modal: ConfirmSetPrimaryModal, setShowModal: setShowConfirmSetPrimaryModal } =
    useConfirmSetPrimaryModal();

  return (
    <Stack
      alignItems="center"
      alignSelf="center"
      h="100%"
      w={{ base: '100%', md: '540px' }}
      px={{ base: '16px', md: '0px' }}
      pb="24px"
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        space={{ base: '16px', lg: '24px' }}
        w="100%"
        h="100%"
      >
        <Stack space={{ base: '16px', lg: '24px' }}>
          <AddressForm formState={formState} control={control} />
          <Divider />
          <FormControl>
            <Controller
              control={control}
              name="is_primary"
              render={({ field }) => {
                return (
                  <Stack direction="column" alignItems="start" w="100%">
                    <Stack
                      direction="row"
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormControl.Label
                        htmlFor="is_primary"
                        variant="portal"
                        _text={{
                          fontSize: { base: 'body.md', lg: 'body.lg' },
                          lineHeight: { base: 'body.md', lg: 'body.lg' },
                        }}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        m="0px"
                      >
                        Set as primary{' '}
                        <Tooltip
                          label="By setting a new address as primary, all future orders will be shipped to your new primary address."
                          placement="top"
                          openDelay={200}
                          maxW="75vw"
                          ml="8px"
                        >
                          <InfoOutlineIcon />
                        </Tooltip>
                      </FormControl.Label>
                      <Switch {...field} onToggle={() => setValue('is_primary', !field.value)} />
                    </Stack>
                  </Stack>
                );
              }}
            />
          </FormControl>
        </Stack>
        <Flex w="100%" justify="center" align="center">
          <FormSubmitButton onPress={onSaveClick} isDisabled={!isValid} isLoading={isSubmitting}>
            Add Address
          </FormSubmitButton>
        </Flex>
      </Stack>
      <ConfirmSetPrimaryModal
        onClose={() => setShowConfirmSetPrimaryModal(false)}
        onConfirm={() => handleSubmit(onSubmit)()}
        isBackButtonDisabled={isLoading}
        isConfirmButtonDisabled={isLoading}
      />
    </Stack>
  );
};
