import { Skeleton, VStack } from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

export const GridSkeleton = (props: IVStackProps) => {
  return (
    <VStack space={{ base: '8px', lg: '16px' }} mb={4} {...props}>
      <Skeleton w={{ base: '140px', lg: '220px' }} h={{ base: '140px', lg: '220px' }} />
      <VStack space={{ base: '4px', lg: '8px' }} justifyContent="center" alignItems="center">
        <Skeleton.Text lines={1} />
        <Skeleton.Text lines={1} />
        <Skeleton.Text lines={1} mb={2} />
        <Skeleton rounded="full" size={6} />
      </VStack>
    </VStack>
  );
};
