import { datadogLogs } from '@datadog/browser-logs';
import { Stack, Heading, PresenceTransition, Box, Center } from 'native-base';

import { FoodTypeCardSkeleton } from '../../Home/ManageDogPlans/FoodTypeCardSkeleton';
import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { RecipeType, useRecipeOptions } from '@/api';
import { ProductCard, ToastType, displayToast } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';
import { MealRecipeType } from '@/types';

const { logger } = datadogLogs;

export const AddNewDogFoodTypes = ({ navigation }: AddNewDogScreenProps<'FoodTypes'>) => {
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.FOOD_TYPES);
  const account = useAccount();
  const { petData, discountCode, savePetData } = useAddNewDogContext();

  const { data: plansData, isLoading } = useRecipeOptions({
    pet: petData,
    accountId: account.id,
    discounts: discountCode ? [discountCode] : [],
  });

  const products = plansData?.cheapestPlans;

  const onSubmit = (foodType: MealRecipeType) => {
    try {
      savePetData({ food_type: foodType });
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's food type`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's food type, account_id=${account.id}`, { error });
    }
    navigation.navigate(nextStep);
  };

  return (
    <AddNewDogWrapper height="auto">
      {/* the mb acts as a buffer between this stack and the following stack in the UI, if the vh gets too small */}
      <Stack justifyContent="center" alignItems="center" mb={{ base: '36px', md: '48px' }}>
        <Heading fontWeight="bold" size="titleSmToMd" mb="16px" textAlign="center">
          How should we prepare {petData.name}&apos;s food?
        </Heading>
        <Heading
          fontWeight="medium"
          size="bodySmToMd"
          fontFamily="secondary"
          textAlign="center"
          maxW={{ base: '100%', md: '960px' }}
        >
          Two incredible choices, with nothing artificial in sight. Both our UnKibble™ and Fresh
          recipes are made with only real, whole ingredients. So you really can't go wrong.
        </Heading>
      </Stack>
      <Stack
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        w={{ base: '100%', md: '484px' }}
      >
        <PresenceTransition
          visible={products && products.length > 0}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 250,
            },
          }}
          style={{
            width: '100%',
          }}
        >
          <Box
            flexDir={{ base: 'column', lg: 'row' }}
            justifyContent="center"
            alignItems="center"
            w="100%"
          >
            {!isLoading && products ? (
              products.map(
                (
                  {
                    subscription: {
                      product: { recipes },
                      price: { price_per_meal, discounted_price_per_meal },
                    },
                  },
                  index
                ) => (
                  <ProductCard
                    recipe={recipes[0]}
                    heading={recipes[0].type === RecipeType.UNKIBBLE ? 'UnKibble™' : 'Fresh'}
                    infoPills={[
                      recipes[0].type === RecipeType.UNKIBBLE ? 'Most Popular' : 'Original Recipes',
                    ]}
                    mx={{ base: 0, md: 4 }}
                    my={{ base: 2, md: 4 }}
                    key={`${recipes[0].type}-container-${index}`}
                    pricing={discounted_price_per_meal ? discounted_price_per_meal : price_per_meal}
                    onPress={() => onSubmit(recipes[0].type as MealRecipeType)}
                    selected={petData.food_type === recipes[0].type}
                    pricingPrefix="From"
                  />
                )
              )
            ) : (
              <FoodTypeCardSkeleton mx={{ base: 0, md: 4 }} my={{ base: 2, md: 4 }} />
            )}
          </Box>
        </PresenceTransition>
      </Stack>

      <Center h={{ base: '32px', lg: '56px' }} />
    </AddNewDogWrapper>
  );
};
