import { useFocusEffect } from '@react-navigation/native';
import axios, { AxiosError } from 'axios';
import { FormControl, Heading, Input, Button, Spacer, VStack, Text, View } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ZIPCODE_BLACKLIST_MESSAGE } from '../../resolvers/index';

import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useEnterPressEffect, useIsMobile, useValidateAddressQuery } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { LocationResolver } from '@/screens/resolvers';
import segment from '@/segment';
import { FunnelScreenProps } from '@/types';
import { getCurrentPet } from '@/utils/getCurrentPet';

const defaultValues = {
  zip_code: '',
};

export default function Location({ navigation }: FunnelScreenProps<'Location'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.LOCATION);
  const { session, mutateUpdateSession } = useSessionQuery();
  const dog = getCurrentPet(session);
  const [numberOfDogs] = useState<number>(session?.pets?.length || 0);
  if (session?.pets === undefined) {
    console.log('Location.tsx: Pets array is undefined, using fallback value for numberOfDogs');
  }
  const {
    validateZip: { mutateAsync: validateZip },
  } = useValidateAddressQuery();
  const isMobile = useIsMobile();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { addAxiosPromise } = useAxiosStatusContext();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors, isSubmitting, isValid },
  } = useForm({ resolver: LocationResolver, defaultValues, mode: 'onChange' });

  useFocusEffect(
    useCallback(() => {
      if (session) {
        const { zip_code } = session;
        if (zip_code) {
          reset({ zip_code });
        }
      }
    }, [session])
  );

  const FUNNEL_SESSION_INVALID_ZIP_COUNT = Number(localStorage.getItem('invalidZipCount') ?? '0');

  useEffect(() => {
    const zipCode = watch('zip_code');
    const zipError = errors.zip_code;
    if (
      zipError?.message === ZIPCODE_BLACKLIST_MESSAGE &&
      zipCode.length === 5 &&
      FUNNEL_SESSION_INVALID_ZIP_COUNT < 5
    ) {
      localStorage.setItem('invalidZipCount', String(FUNNEL_SESSION_INVALID_ZIP_COUNT + 1));
      segment.trackEvent('Out Of Network Zip Entered', {
        first_name: session?.first,
        dog_name: dog?.name,
        email: session?.email,
        zip_code: zipCode,
      });
    }
  }, [
    errors.zip_code,
    session?.first,
    session?.email,
    watch,
    dog?.name,
    FUNNEL_SESSION_INVALID_ZIP_COUNT,
  ]);

  useEnterPressEffect(() => {
    handleSubmit(onSubmit)();
  });

  if (!session) {
    return null;
  }

  const onSubmit = async ({ zip_code }: { zip_code: string }) => {
    try {
      await validateZip(zip_code);
      addAxiosPromise(
        mutateUpdateSession({
          data: {
            zip_code,
          },
        })
      );

      segment.trackEvent('User Updates Questionnaire', {
        pet_names: (session.pets || []).map(({ name }) => name),
        email: session.email,
        name: session.first,
        num_dogs: session.number_of_pets,
        zip_code,
        session_id: session.id,
        account_id: null,
      });

      navigation.navigate(nextStep);
    } catch (error: AxiosError | any) {
      if (axios.isAxiosError(error) && error.response?.status === 406) {
        setError('zip_code', { type: 'string', message: error.response?.data });
      } else {
        funnelErrorHandler(error, 'Validate zip code');
      }
    }
  };

  return (
    <FunnelWrapper containerProps={{ space: '48px' }}>
      <VStack
        w={{ base: '100%', md: 'fit-content' }}
        space="48px"
        justifyContent={{ base: 'center', md: 'flex-start' }}
      >
        <Heading size="titleSmToMd" textAlign="center" fontWeight="bold">
          Where {numberOfDogs > 1 ? 'do they' : `does ${dog?.name}`} live?
        </Heading>

        <FormControl isRequired isInvalid={'zip_code' in errors}>
          <Controller
            control={control}
            name="zip_code"
            render={({ field: { onChange, value } }) => (
              <VStack w="100%" justifyContent="center" alignItems="center">
                <Input
                  variant={'zip_code' in errors ? 'error' : 'base'}
                  w={{ base: '100%', md: '472px' }}
                  onChangeText={(nameValue) => onChange(nameValue)}
                  onSubmitEditing={(event) => {
                    event.preventDefault();
                    handleSubmit(onSubmit)();
                  }}
                  onBlur={() => {
                    if (value?.length !== 5) {
                      setError('zip_code', {
                        type: 'string',
                        message: 'Must be a valid zipcode',
                      });
                    }
                  }}
                  value={value}
                  maxLength={5}
                  placeholder="Zip code"
                  keyboardType="numeric"
                  size="bodyMd"
                  autoFocus={!isMobile}
                />
                {errors?.zip_code?.message && (
                  <View w="100%">
                    <Text size="bodySmToMd" mt="4px" textAlign="start" color="error.default">
                      {errors.zip_code.message}
                    </Text>
                  </View>
                )}
              </VStack>
            )}
          />
          <Spacer size={{ base: '30px', lg: '20px' }} />
          <FormControl.HelperText textAlign="center" w={{ base: '100%', lg: '360px' }}>
            <Text fontFamily="secondary" size="bodySmToMd" fontWeight="medium">
              {numberOfDogs > 1
                ? 'We need to make sure we can ship to their location.'
                : `We need to make sure we can ship to ${dog?.name}'s location.`}
            </Text>
          </FormControl.HelperText>
        </FormControl>
      </VStack>
      <Button
        variant="primary"
        size="bodyMdToLg"
        w="100%"
        maxW={{ base: '100%', md: '290px' }}
        h={{ base: '52px', md: '56px' }}
        onPress={handleSubmit(onSubmit)}
        isDisabled={!watch('zip_code') || !isValid}
        isLoading={isSubmitting}
      >
        CONTINUE
      </Button>
    </FunnelWrapper>
  );
}
