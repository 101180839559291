import { useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import {
  Box,
  Center,
  Divider,
  FormControl,
  HStack,
  Stack,
  Switch,
  Text,
  Tooltip,
} from 'native-base';
import { useState, useEffect } from 'react';

import { FormSubmitButton } from '../../FormSubmitButton';
import { CardBrandLogo } from './CardBrandLogo';
import { useConfirmSetPrimaryCardModal } from './ConfirmSetPrimaryCardModal';

import { useCompleteCardSetup } from '@/api';
import { InfoCta, LockAlertOutline } from '@/components/Icons';
import { usePaymentError } from '@/hooks';

interface AddCreditCardFormProps {
  clientSecret: string;
  onSuccess: () => void;
  /**
   * Provides a default value for isDefault input
   */
  initialIsDefault?: boolean;
  forceDefault?: boolean;
}

export const AddCreditCardForm = ({
  clientSecret,
  onSuccess,
  initialIsDefault,
  forceDefault = false,
}: AddCreditCardFormProps) => {
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [cardInputError, setCardInputError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();
  const { completeCardSetup } = useCompleteCardSetup();
  const { setShowModal: setShowPrimaryModal, Modal: ConfirmSetPrimaryCardModal } =
    useConfirmSetPrimaryCardModal();
  const paymentError = usePaymentError();

  useEffect(() => {
    if (initialIsDefault) {
      setIsDefault(true);
    }
  }, [initialIsDefault]);

  useEffect(() => {
    if (paymentError) {
      setIsDefault(true);
    }
  }, [paymentError]);

  const handleChange = async (event: StripeCardElementChangeEvent) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setSubmitError(undefined);
    setCardInputError(event.error ? event.error.message : '');
  };

  const onSubmit = async () => {
    const cardElement = elements?.getElement('card');
    if (!cardElement) {
      return;
    }

    setShowPrimaryModal(false);
    setIsLoading(true);
    const { error } = await completeCardSetup({ clientSecret, cardElement, isDefault });
    if (error) {
      setSubmitError(error.message || 'An unexpected error occurred.');
    }
    if (!error) {
      await onSuccess();
    }
    setIsLoading(false);
  };

  const onPressSave = () => {
    if (isDefault) {
      setShowPrimaryModal(true);
    } else {
      onSubmit();
    }
  };

  return (
    <Stack w="100%" h="100%">
      <Stack w="100%" h="100%" justifyContent="space-between" alignItems="center">
        <Stack w={{ base: '100%', lg: '540px' }} space={{ base: '24px', lg: '48px' }}>
          <Text
            variant="helperText"
            size="tinyToCaption"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LockAlertOutline size="14px" color="black" mr={2} />
            All transactions are secure and encrypted through Stripe.
          </Text>
          <Stack space={{ base: '16px', lg: '24px' }}>
            <Stack space="16px">
              <HStack space={2}>
                <CardBrandLogo brand="Visa" />
                <CardBrandLogo brand="Mastercard" />
                <CardBrandLogo brand="American Express" />
                <CardBrandLogo brand="Discover" />
              </HStack>
              {clientSecret ? <CardElement onChange={handleChange} /> : null}
            </Stack>
            {cardInputError ? (
              <Text variant="errorMessage" size="bodySmToMd">
                {cardInputError}
              </Text>
            ) : null}
            {/* Show any error that happens when processing the payment */}
            {submitError ? (
              <Text variant="errorMessage" size="bodySmToMd">
                {submitError} Please try again or reach out to us at{' '}
                <Text fontWeight="bold">care@spotandtango.com</Text> for assistance.
              </Text>
            ) : null}
            <Divider bg="sntGrey.outline" />
            <Stack alignItems="start" w="100%">
              <Stack direction="row" w="100%" justifyContent="space-between">
                <FormControl.Label
                  variant="portal"
                  _text={{ size: 'bodyMdToLg' }}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  m="0px"
                >
                  Set as primary{' '}
                  <Tooltip
                    label="By setting a new payment method as primary, all future orders will be updated to your new payment method."
                    placement="top"
                    openDelay={200}
                    maxW="75vw"
                    ml="8px"
                  >
                    <Box>
                      <InfoCta color="black" />
                    </Box>
                  </Tooltip>
                </FormControl.Label>
                <Switch
                  onToggle={() => {
                    setIsDefault(!isDefault);
                  }}
                  value={isDefault}
                  isDisabled={!!paymentError || forceDefault}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Center w="100%">
          <FormSubmitButton
            isLoading={isLoading}
            onPress={onPressSave}
            isDisabled={!!cardInputError}
            w={{ base: '100%', md: '424px' }}
          >
            Add New Payment
          </FormSubmitButton>
          {!!paymentError && (
            <Text
              size="bodySmToMd"
              fontFamily="secondary"
              textAlign={{ base: 'left', lg: 'center' }}
              mt={{ base: 3, lg: 6 }}
              px={{ base: 2, lg: 6 }}
            >
              By proceeding with adding this new card, you are setting this card as your default
              payment method for all future orders. We will immediately retry any previously failed
              charges with this new card.
            </Text>
          )}
        </Center>
      </Stack>
      <ConfirmSetPrimaryCardModal onClose={() => setShowPrimaryModal(false)} onConfirm={onSubmit} />
    </Stack>
  );
};
