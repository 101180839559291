import { HStack, Radio, Text, TextArea, VStack } from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';
import { useEffect, useState } from 'react';

import { SurveyQuestion } from '@/components/Portal/DogsAndPlans/CancelAddons';
import { shuffle } from '@/utils';

export interface SurveyRadioGroupProps extends IVStackProps {
  surveyOptions: SurveyQuestion[] | undefined;
  selectedOption: SurveyQuestion | undefined;
  setSelectedOption: (option: SurveyQuestion) => void;
  otherText: string;
  setOtherTextControlled: (value: string) => void;
}
export const SurveyRadioGroup = ({
  surveyOptions,
  selectedOption,
  setSelectedOption,
  otherText,
  setOtherTextControlled,
  ...props
}: SurveyRadioGroupProps) => {
  const [sortedOptions, setSortedOptions] = useState<SurveyQuestion[]>();

  useEffect(() => {
    const nonOtherOptions = surveyOptions?.filter((opt) => !opt.isOtherOption);
    const otherOptions = surveyOptions?.find((opt) => opt.isOtherOption);
    const sortedOptions = shuffle(nonOtherOptions || []).concat(otherOptions ? otherOptions : []);
    setSortedOptions(sortedOptions);
  }, [surveyOptions]);

  const onPressOption = (value: string) => {
    const correspondingOption = surveyOptions?.find((option) => option.label === value);
    if (correspondingOption) {
      setSelectedOption(correspondingOption);
    }
  };

  return (
    <VStack {...props}>
      <Radio.Group
        w="100%"
        name="selectedOption"
        accessibilityLabel="Survey answer"
        value={surveyOptions?.find((option) => option.label === selectedOption?.label)?.label}
        onChange={onPressOption}
      >
        {sortedOptions?.map(({ label }, i) => (
          <VStack key={label + i} w="100%">
            <HStack
              w="100%"
              mt={i > 0 ? { base: 6, lg: 9 } : 0}
              space={2}
              alignItems="center"
              onClick={() => onPressOption(label)}
            >
              <Radio value={label} />
              <Text size="bodySmToMd">{label}</Text>
            </HStack>
            {selectedOption?.label === label && selectedOption?.showCommentField ? (
              <VStack key={`textfield-${i}`} px={2} mt={{ base: 4, lg: 6 }} w="100%">
                <Text size="bodySmToMd" fontFamily="secondary" color="sntGrey.dark" mb={2}>
                  Please provide additional details{' '}
                  {selectedOption?.commentRequired ? '' : '(optional)'}
                </Text>
                <TextArea
                  value={otherText}
                  onChangeText={(value) => {
                    setOtherTextControlled(value);
                  }}
                  size="bodySmToMd"
                  autoCompleteType="off"
                  textAlign="left"
                  borderWidth="1px"
                  borderColor="sntGrey.outline"
                  totalLines={16}
                  minHeight="150px"
                  maxLength={255}
                  autoFocus
                />
              </VStack>
            ) : null}
          </VStack>
        ))}
      </Radio.Group>
    </VStack>
  );
};
