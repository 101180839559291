import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { Heading, VStack, Text } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { MultiValue, createFilter } from 'react-select';

import { usePetFormOptions } from '@/api';
import { Button, Select, SelectedPills, AnimatedBlock } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useEnterPressEffect, useFunnelErrorHandler } from '@/hooks';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FunnelScreenProps } from '@/types';
import { BREEDS_LIMIT, BreedOption, getBreedOptions } from '@/utils';
import { getCurrentPet } from '@/utils/getCurrentPet';

export default function Breed({ navigation }: FunnelScreenProps<'Breed'>) {
  const isFocused = useIsFocused();
  const nextStep = useNextFunnelStep(FunnelScreenNames.BREED);
  const { session, mutateUpdatePetAnswer } = useSessionQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const funnelErrorHandler = useFunnelErrorHandler();
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<BreedOption>>([]);
  const [showBreedsLimitWarning, setShowBreedsLimitWarning] = useState(false);
  const breedsOverlimited = selectedOptions.length >= BREEDS_LIMIT;
  const dog = getCurrentPet(session);
  const { addAxiosPromise } = useAxiosStatusContext();

  const { data: petFormData } = usePetFormOptions();
  const breedOptions = getBreedOptions(petFormData?.breeds);

  const onSubmit = () => {
    if (!selectedOptions?.length) {
      return;
    }
    setIsSubmitting(true);
    const breeds = selectedOptions.map((breed: BreedOption) => breed.value) || [];
    try {
      addAxiosPromise(mutateUpdatePetAnswer({ breeds }));
    } catch (error) {
      funnelErrorHandler(error, 'Update breeds');
    }
    navigation.navigate(nextStep);
    setIsSubmitting(false);
  };

  const onSelectChange = (newOptions: any) => {
    setSelectedOptions(newOptions);
  };

  const removeBreed = (value: string) => {
    const newValues = selectedOptions.filter(
      (option) => (option as BreedOption).value.toLowerCase() !== value.toLowerCase()
    );

    setSelectedOptions(newValues);
  };

  useEnterPressEffect(() => {
    onSubmit();
  });

  // When navigating back to a page, restore any previously submitted data
  useFocusEffect(
    useCallback(() => {
      setSelectedOptions(() => {
        return breedOptions.filter((breedOption) =>
          dog?.breeds?.find((breed) => breedOption?.value === breed)
        );
      });
    }, [dog, petFormData])
  );

  useEffect(() => {
    if (breedsOverlimited) {
      setShowBreedsLimitWarning(true);
    } else {
      setShowBreedsLimitWarning(false);
    }
  }, [selectedOptions.length]);

  if (!isFocused || !dog || !Object.keys(dog).length) {
    return null;
  }
  return (
    <FunnelWrapper containerProps={{ space: '62px' }}>
      <VStack
        justifyContent={{ base: 'center', md: 'flex-start' }}
        alignItems="center"
        w={{ base: '100%', md: '472px' }}
        space={3}
      >
        <VStack space="8px" w="100%">
          <VStack alignItems="center" w="100%">
            <Heading size="titleSmToMd" fontWeight="bold">
              What breed is {dog.name}?
            </Heading>
            <Heading
              size="bodyMdToTitleSm"
              fontWeight="bold"
              mt={{ base: '36px', md: '48px' }}
              mb={{ base: '16px', md: '24px' }}
            >
              Select all that apply
            </Heading>
            <Select
              isMulti
              closeMenuOnSelect
              isDisabled={breedsOverlimited}
              options={breedOptions}
              onChange={onSelectChange}
              placeholder="Breed"
              value={selectedOptions}
              blurInputOnSelect
              autoFocus
              extraStyles={{ valueContainer: { padding: '16px 20px' } }}
              filterOption={createFilter({ ignoreAccents: false })} // helps performance
            />
            {breedsOverlimited ? (
              <AnimatedBlock showBlock={showBreedsLimitWarning}>
                <Text
                  fontFamily="secondary"
                  fontWeight="medium"
                  color="black"
                  size="bodySmToMd"
                  pt={{ base: '16px', md: '24px' }}
                >
                  {`If ${dog.name} is more than five breeds, choose Mixed Breed`}
                </Text>
              </AnimatedBlock>
            ) : null}
          </VStack>
          <SelectedPills
            w="100%"
            mt="16px"
            selected={selectedOptions}
            onPillPress={(breed) => removeBreed(breed)}
            justifyContent="center"
          />
        </VStack>
      </VStack>

      <VStack
        w={{ base: '100%', lg: '336px' }}
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        space={{ base: '8px', lg: '16px' }}
      >
        <Button
          variant="primary"
          w="100%"
          maxW={{ base: '100%', md: '290px' }}
          h={{ base: '52px', md: '56px' }}
          size="bodyMdToLg"
          onPress={onSubmit}
          isDisabled={selectedOptions.length === 0}
          isLoading={isSubmitting}
        >
          CONTINUE
        </Button>
      </VStack>
    </FunnelWrapper>
  );
}
