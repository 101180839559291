import { useFocusEffect } from '@react-navigation/native';
import { Stack, Flex, Text } from 'native-base';
import { useCallback } from 'react';

import { PersonalInformationForm } from './PersonalInformationForm';
import {
  usePersonalInformationForm,
  PersonalInformationFormValues,
} from './usePersonalInformationForm';

import { useUpdateAccount } from '@/api/services/account/useUpdateAccount';
import { Button, displayToast } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { sendErrorReport } from '@/utils/analytics';

export const PersonalInformation = ({ navigation }: any) => {
  const account = useAccount();

  const { mutateAsync: updateAccount, isLoading } = useUpdateAccount();
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
    trigger,
  } = usePersonalInformationForm();

  useFocusEffect(
    useCallback(() => {
      if (account) {
        const phoneNumber = account.phone_number || undefined;
        const phone =
          phoneNumber && !phoneNumber.startsWith('+1') ? `+1${phoneNumber}` : phoneNumber;
        reset({
          firstName: account.first_name,
          lastName: account.last_name,
          phone,
        });
        trigger();
      }
    }, [account])
  );

  const onSubmit = async (data: PersonalInformationFormValues) => {
    try {
      await updateAccount({
        first_name: data.firstName,
        last_name: data.lastName,
        phone_number: data.phone || null,
      });
      navigation.navigate('Account');
      displayToast({
        message: 'Your personal information has been updated.',
      });
    } catch (error) {
      sendErrorReport(error);
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      h="100%"
      w="100%"
      p="16px"
    >
      <Stack w={{ base: '100%', lg: '336px' }}>
        <Text size="bodyMd" fontWeight={450} color="grey.dark" mb={1}>
          Email
        </Text>
        <Text size="bodyMd" mb={4}>
          {account.email}
        </Text>
        <PersonalInformationForm control={control} errors={errors} />
      </Stack>
      <Flex w={{ base: '100%', lg: '336px' }} direction="column" justify="center" align="center">
        <Button
          w={{ base: '100%', lg: '336px' }}
          size={{ base: 'bodyMd', md: 'bodyLg' }}
          onPress={handleSubmit(onSubmit)}
          isDisabled={!isValid || isLoading}
        >
          Save Changes
        </Button>
      </Flex>
    </Stack>
  );
};
