import { HStack, Box, Text, Stack } from 'native-base';
import React, { useState } from 'react';

interface Option {
  value: string;
  label: string;
}

export interface OptionSelectorProps {
  label: string;
  options: Option[];
  onSelect: (selectedOption: Option) => void;
}

export const OptionSelector = ({ label, options, onSelect }: OptionSelectorProps) => {
  const [selectedOption, setSelectedOption] = useState<Option>(options[0]);
  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <Stack>
      <Text textAlign="left" size={{ base: 'body' }}>
        {label}
      </Text>
      <HStack justifyContent="space-between" alignItems="center" maxWidth="500px" borderWidth="2px">
        {options.map((option) => (
          <Box
            key={option.value}
            onClick={() => handleOptionClick(option)}
            padding="5px"
            margin="2px"
            flex={1}
            backgroundColor={selectedOption.value === option.value ? 'sntBlue.primary' : 'white'}
          >
            <Text textAlign="center" size={{ base: 'body' }}>
              {option.label}
            </Text>
          </Box>
        ))}
      </HStack>
    </Stack>
  );
};

export default OptionSelector;
