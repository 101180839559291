import { AccountStack } from './AccountStack';

import { PortalHead } from '@/components/Elements';
import { useShouldShowPortalHead } from '@/hooks/useShouldShowPortalHead';

export const PortalScreens = () => {
  const showPortalHead = useShouldShowPortalHead();

  return (
    <>
      {showPortalHead && <PortalHead />}
      <AccountStack />
    </>
  );
};
