import { ISkeletonProps, Skeleton, VStack } from 'native-base';
import { ISkeletonTextProps } from 'native-base/lib/typescript/components/composites';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

type AvatarSkeletonProps = {
  imageProps?: ISkeletonProps;
  captionProps?: ISkeletonTextProps;
  stackProps?: IVStackProps;
};

export const AvatarSkeleton = ({ imageProps, captionProps, stackProps }: AvatarSkeletonProps) => {
  return (
    <VStack alignItems="center" space={{ base: '8px', lg: '16px' }} {...stackProps}>
      <Skeleton
        w={{ base: '140px', lg: '180px' }}
        h={{ base: '140px', lg: '180px' }}
        borderRadius="100%"
        {...imageProps}
      />
      <Skeleton.Text lines={1} w={{ base: '60px', lg: '70px' }} {...captionProps} />
    </VStack>
  );
};
