import {
  Button,
  Heading,
  Stack,
  Text,
  View,
  Divider,
  FormControl,
  Input,
  Spacer,
  Badge,
  Pressable,
} from 'native-base';
import { Controller, useForm } from 'react-hook-form';

import { Cart } from '@/api/types/sessionServices';
import { Close } from '@/components/Icons';
import { useSessionQuery } from '@/hooks';
import { queryClient } from '@/lib/react-query';
import { FunnelSession } from '@/types';
import { contactUs } from '@/utils';

export interface TotalProps {
  isLoading?: boolean;
  onRemoveDiscount: (discountCode: string) => Promise<void>;
  onApplyDiscount: (discountCode: string) => Promise<void>;
  discountError?: string;
}

export const Total = ({
  isLoading,
  onRemoveDiscount,
  onApplyDiscount,
  discountError,
}: TotalProps) => {
  const { plans, prices } = queryClient.getQueryData<Cart>('cart')
    ?.cart_preview as Cart['cart_preview'];
  const { session: funnelSession } = useSessionQuery();
  const { code } = plans[0]?.discounts[0] ?? { code: '' };
  const { tax_amount, total_to_be_charged } = prices;
  const taxAmount = Number(tax_amount);
  const totalToBeCharged = Number(total_to_be_charged);

  const defaultValues = {
    code: '',
  };
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const removeDiscountCode = async () => {
    const { discounts } = funnelSession as FunnelSession;
    await onRemoveDiscount(discounts[0].code);
  };

  const submitDiscountCode = async ({ code }: { code: string }) => {
    await onApplyDiscount(code.toUpperCase());
    reset();
  };

  return (
    <View>
      <Stack py={3} space="8px" w="100%" justifyContent="center" alignItems="flex-start">
        {code ? (
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center" w="100%">
            <Heading size="bodyMdToLg" fontWeight="medium">
              Promo Code
            </Heading>
            <Pressable onPress={() => removeDiscountCode()} isDisabled={isLoading}>
              <Badge
                variant="smallInfoPillSecondary"
                borderColor={isLoading ? 'sntGrey.outline' : undefined}
                _text={{ color: isLoading ? 'sntGrey.outline' : undefined }}
                rightIcon={
                  <Close
                    size={{ base: 2, lg: 3 }}
                    pl={1}
                    color={isLoading ? 'sntGrey.outline' : undefined}
                  />
                }
              >
                {code}
              </Badge>
            </Pressable>
          </Stack>
        ) : null}
        <Stack
          w="100%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          pt={1}
        >
          <Text size="bodyMdToLg" fontWeight="medium">
            Shipping
          </Text>

          <Text mx={1} size="bodyMdToLg" fontWeight="medium">
            FREE
          </Text>
        </Stack>
        <Stack
          w="100%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          pb={1}
        >
          <Text size="bodyMdToLg" fontWeight="medium">
            Estimated Tax
          </Text>

          <Text mx={1} size="bodyMdToLg" fontWeight="bold">
            {taxAmount > 0 ? `$${taxAmount.toFixed(2)}` : `—`}
          </Text>
        </Stack>
      </Stack>
      <Divider bg="sntGrey.outline" />
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pt={4}>
        <Text size="bodyMdToLg" h="auto" w="fit-content" fontWeight="medium">
          Today's Total
        </Text>

        <Text mx={1} size="bodyMdToLg" fontWeight="bold">
          {`$${totalToBeCharged.toFixed(2)}`}
        </Text>
      </Stack>
      {!code ? (
        <Stack mt={{ base: 2, lg: 4 }}>
          <FormControl isRequired isInvalid={'code' in errors}>
            <Controller
              control={control}
              name="code"
              render={({ field: { onChange, value } }) => (
                <Input
                  variant="portal"
                  nativeID="checkout-input-code"
                  w="100%"
                  mx={0}
                  py={1}
                  size="bodySmToMd"
                  onChangeText={onChange}
                  value={value}
                  placeholder="Enter discount code"
                  type="text"
                  textAlign="start"
                  InputRightElement={
                    <Button
                      variant="inline"
                      w="fit-content"
                      isDisabled={!watch('code') || isLoading}
                      onPress={handleSubmit(submitDiscountCode)}
                      size="bodySmToLg"
                    >
                      APPLY
                    </Button>
                  }
                />
              )}
            />
            {discountError ? (
              <Stack w="100%" alignItems="start">
                <Spacer size={{ base: 2, lg: 4 }} />
                <Stack direction="row" flexWrap="wrap" justifyContent="start">
                  <Text size="bodySmToMd" color="error.default">
                    {`${discountError} Please try again or `}
                    <Pressable onPress={() => contactUs()}>
                      <Text size="bodySmToMd" fontWeight="bold" textDecorationLine="underline">
                        contact us
                      </Text>
                    </Pressable>{' '}
                    for help
                  </Text>
                </Stack>
              </Stack>
            ) : null}
          </FormControl>
        </Stack>
      ) : null}
    </View>
  );
};
