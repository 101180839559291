import { useMutation } from 'react-query';

import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

const deleteAddress = (addressId: string) =>
  axios.delete(`/addresses/${addressId}`).then((response) => response.data);

export const useRemoveAddress = () => {
  const { refetchUser } = useAuth();

  return useMutation((addressId: string) => deleteAddress(addressId), {
    onSuccess: () => refetchUser(), // refresh with new addresses data
  });
};
