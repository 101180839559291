import { getFocusedRouteNameFromRoute, useFocusEffect, useRoute } from '@react-navigation/native';
import { CloseIcon, ITooltipProps, Pressable, Stack, Text } from 'native-base';
import { useCallback, useState } from 'react';

import { Tooltip } from '@/components/Elements';
import { useIsMobile } from '@/hooks';

export const AccountIconTooltip = ({ ...props }: Omit<ITooltipProps, 'label'>) => {
  const isMobile = useIsMobile();
  const route = useRoute();
  const currentActiveRoute = getFocusedRouteNameFromRoute(route);
  const [isOpen, setIsOpen] = useState(false);

  useFocusEffect(
    useCallback(() => {
      const customerShownTooltip = localStorage.getItem('shownAccountTooltip');
      if (!customerShownTooltip && !isMobile) {
        setIsOpen(true);
      }
    }, [isMobile])
  );

  const handleOnClose = () => {
    localStorage.setItem('shownAccountTooltip', 'true');
    setIsOpen(false);
  };

  return (
    <Tooltip
      label={
        <Pressable onPress={handleOnClose}>
          <Stack justifyContent="center" alignItems="center" px={{ md: 4, lg: '44px' }} py={4}>
            <CloseIcon w="25px" h="25px" color="black" position="absolute" top={2} right={2} />
            <Text size="bodyMdToLg" fontWeight="medium" color="black" textAlign="center">
              Looking for your <Text fontWeight="bold">Account Settings?</Text> Click on this icon!
            </Text>
          </Stack>
        </Pressable>
      }
      display={{ base: 'none', lg: 'inline-block' }}
      hasArrow
      closeOnClick
      bg="sntBlue.primary50"
      maxW="413px"
      p={0}
      mr={4}
      isOpen={isOpen && currentActiveRoute !== 'Account'}
      {...props}
    />
  );
};
