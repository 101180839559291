import { useQuery, UseQueryOptions } from 'react-query';

import { CardPaymentMethod } from '@/api/types';
import { axios } from '@/lib/axios';

const getPaymentMethods = () =>
  axios
    .get<CardPaymentMethod[]>(`/payment_methods`, { noErrorToast: true })
    .then((res) => res.data);

export const usePaymentMethods = (
  options: UseQueryOptions<unknown, unknown, CardPaymentMethod[], 'PaymentMethod'> = {}
) => {
  return useQuery('PaymentMethod', { queryFn: getPaymentMethods, ...options });
};
