const variants = {
  card: {
    borderColor: 'sntGrey.outline',
    borderWidth: '1px',
    p: { base: 4, lg: 6 },
  },
  stickyFooter: {
    position: 'sticky',
    bottom: 0,
    background: '#FFF',
    style: { boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.05)' },
    py: 6,
    w: '100%',
    flexDir: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const Stack = {
  variants,
};
