import { Recipe, RecipeType } from '@/api/types';

export type AddonsType = RecipeType.SNACK | RecipeType.SUPPLEMENT;

export interface AddonConfig {
  title: string;
  type: RecipeType;
  unit: 'bag' | 'pack';
}

/**
 * Some of this should be moved to the BE at some point
 */
export const ADDONS_CONFIGURATION: Record<AddonsType, AddonConfig> = {
  [RecipeType.SUPPLEMENT]: {
    title: 'Supplements',
    type: RecipeType.SUPPLEMENT,
    unit: 'pack',
  },
  [RecipeType.SNACK]: {
    title: 'Snacks',
    type: RecipeType.SNACK,
    unit: 'bag',
  },
};

export type RecipeCardType = RecipeType.UNKIBBLE | RecipeType.FRESH | 'other';
export type RecipeCardBanner = { funnel?: string; portal?: string };

export const RecipeCardInfoPillWithStar = 'Best Seller';

export const RecipeCardBanners: Record<Recipe['id'], RecipeCardBanner> = {
  'U-B': {
    funnel: 'RECOMMENDED',
    portal: 'RECOMMENDED',
  },
  'U-T': {
    funnel: 'NEW RECIPE',
  },
  'F-T': {
    funnel: 'RECOMMENDED',
    portal: 'RECOMMENDED',
  },
};

export const ADDONS_FREE_FOR_LIFE_CODES = [
  'SNACK-SPBITES-1PK-R-FREE',
  'SNACK-BISONBITES-1PK-R-FREE',
  'SNACK-CHICKENBITES-1PK-R-FREE',
];

export const NON_CUSTOMER_FACING_PRODUCT_CODES = [
  'COL-REFORMULATION-FRESH',
  'COL-REFORMULATION-UNKIBBLE',
];

export const LINK_TO_WEB1 = 'https://app.spotandtango.com/login';
export const LINK_TO_WEB2 = 'https://shop.spotandtango.com/account/login';
export const ASSETS_URL = 'https://assets.spotandtango.com';
export const WTP_URL = 'https://whatthepup.spotandtango.com/';
export const SNT_HOMEPAGE_URL = 'https://spotandtango.com';
export const HOW_TO_CONTACT_SUPPORT_URL =
  'https://spotandtango.com/faq?a=How-do-I-contact-customer-support---id--IKoHhdLiR3SYrMUbeS90EQ';
export const FRIENDBUY_MERCHANT_ID = '19dddc88-1f0b-4624-9952-32b86c5756cf';
export const BLOCKED_USER_AGENTS = [
  'GOOGLEBOT',
  'APPLEBOT',
  'PETALBOT',
  'AMAZONPRODUCTBOT',
  'PINGBOT',
  'STOREBOT-GOOGLE',
  'SEEKPORTBOT',
  'SNAPCHATADS',
  'IMPACT RADIUS COMPLIANCE BOT',
  'BITSIGHTBOT',
];

export const PRODUCT_PLATFORM_V2_FEATURE_FLAG = '2025_1_product_platform_v2';
