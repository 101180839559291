import { Pet, PetStatus } from '@/api';

export const sortPetsByPetPlanID = (pets: Pet[]) =>
  pets.reduce<Record<string, Pet>>((pets, pet) => {
    pets[pet.pet_plan?.id] = pet;
    return pets;
  }, {});

export const sortPetsAlphabetically = (pets: Pet[]) => {
  return [...pets].sort((a, b) => a.name.localeCompare(b.name));
};

export const checkIfAllPetsAreDeceased = (pets: Pet[]) =>
  pets.every(
    (pet) => pet.status === PetStatus.DECEASED || pet.status === PetStatus.DECEASED_TO_NOT_LIST
  );
