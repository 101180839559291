import { useQuery } from 'react-query';

import { DiscountExclusiveType } from '@/api/types';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

type IsEligibleResponse = {
  [k in DiscountExclusiveType]: boolean;
};

const postIsEligible = async ({ accountId, petId }: { accountId?: string; petId?: string }) =>
  axios
    .post<IsEligibleResponse>(`/accounts/${accountId}/is_eligible_for_exclusive_discount`, {
      pet_id: petId,
    })
    .then((response) => response.data);

export const useIsEligibleForExclusiveDiscount = (petId?: string) => {
  const { user: account } = useAuth();
  return useQuery(
    ['IsEligibleForExclusiveDiscount', account?.id, petId],
    () =>
      postIsEligible({
        accountId: account?.id,
        petId,
      }),
    { enabled: !!account?.id && !!petId }
  );
};
