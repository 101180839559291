import { Center, Radio, ScrollView, Stack, Text, TextArea, VStack } from 'native-base';
import { useLayoutEffect, useMemo, useState } from 'react';

import { AdjustPortionSizeModal, SuggestionMode } from '../AdjustPortionSizeModal';
import { RetentionDiscountModal } from '../RetentionDiscountModal';
import { SpeakWithSpecialistModal } from '../SpeakWithSpecialistModal';
import { useAnswerSurveyAndCancelPlan, useInterventionEligibility } from '../hooks';
import { StopPlanSuccessModal, StopPlanSuccessModalProps } from './StopPlanSuccessModal';
import { SurveyOption, SURVEY_OPTIONS } from './exitSurveyOptions';
import { Intervention } from './types';

import { DiscountExclusiveType, PetStatus } from '@/api';
import { FloatingPill, ToastType, displayToast } from '@/components/Elements';
import { FormSubmitButton } from '@/components/Portal/FormSubmitButton';
import { useDetectScrollOverflow } from '@/hooks';
import { useAccount } from '@/hooks/useAuth';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';
import { shuffle } from '@/utils';

export const StopPlanSubSurvey = ({
  navigation,
  route,
}: ProtectedScreenProps<'StopPlanSubSurvey'>) => {
  const account = useAccount();
  const { isLoading: isSubmitting, answerSurveyAndCancelPlan } = useAnswerSurveyAndCancelPlan();

  const pet = account.pets.find(
    (pet) => pet.status === PetStatus.ACTIVE && pet.id === route.params.petId
  );

  const { interventions, isLoading: isLoadingInterventionEligiblity } = useInterventionEligibility(
    pet?.id
  );

  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const [surveyOptions, setSurveyOptions] = useState<SurveyOption[]>([]);
  const [otherText, setOtherText] = useState('');

  const primarySurveyOption = SURVEY_OPTIONS.find(
    ({ label }) => label === route.params.surveyValue
  );
  const subSurveyOption = useMemo(() => {
    return primarySurveyOption?.subsurvey?.find(({ label }) => label === selectedValue);
  }, [primarySurveyOption?.subsurvey, selectedValue]);

  useLayoutEffect(() => {
    if (!primarySurveyOption) {
      return;
    }
    const subsurveyOptions = primarySurveyOption.subsurvey as SurveyOption[];
    const shuffleableOptions = subsurveyOptions.filter(({ isOtherOption }) => !isOtherOption);
    const otherOptions = subsurveyOptions.filter(({ isOtherOption }) => isOtherOption);
    setSurveyOptions([...shuffle(shuffleableOptions), ...otherOptions]);
  }, [primarySurveyOption]);

  const { handleScroll, isOverflow, scrollViewRef, scrollContentRef } = useDetectScrollOverflow();

  const [stopPlanSuccessModalOpen, setStopPlanSuccessModalOpen] = useState(false);
  const [planSuccessModalType, setPlanSuccessModalType] =
    useState<StopPlanSuccessModalProps['type']>('cancel');

  const [retentionDiscountModalOpen, setRetentionDiscountModalOpen] = useState(false);
  const [retentionSpecialistModalOpen, setRetentionSpecialistModalOpen] = useState(false);
  const [adjustPortionSizeModalOpen, setAdjustPortionSizeModalOpen] = useState(false);
  const [adjustPortionMode, setAdjustPortionMode] = useState<SuggestionMode>(
    SuggestionMode.INCREASE
  );

  const navigateToAccount = () => {
    setStopPlanSuccessModalOpen(false);
    setRetentionDiscountModalOpen(false);
    navigation.navigate('Account');
  };

  const doCancelPlan = async () => {
    if (!selectedValue || !pet) {
      return;
    }

    const selectedSurveyOption = surveyOptions.find((option) => option.label === selectedValue);
    await answerSurveyAndCancelPlan({
      pet,
      primaryAnswer: route.params.surveyValue,
      secondaryAnswer: selectedValue,
      customerComment: otherText,
      passedAway: selectedSurveyOption?.passedAway,
    });
    setRetentionDiscountModalOpen(false);
    setRetentionSpecialistModalOpen(false);
    if (selectedSurveyOption?.passedAway) {
      setPlanSuccessModalType('passed_away');
    }
    setStopPlanSuccessModalOpen(true);
  };

  const onPressContinue = async () => {
    if (!selectedValue || !pet) {
      return;
    }

    segment.trackEvent('Confirm Change Status', {
      cancellation_reason: route.params.surveyValue,
      cancellation_reason_secondary: selectedValue,
      cancellation_reason_comment: otherText,
      pet_id: pet.id,
      pet_name: pet.name,
      email: account.email,
      account_id: account.id,
    });

    try {
      switch (primarySurveyOption?.interventionType) {
        case 'its-too-expensive':
          if (interventions.includes(Intervention.SwapToTopper)) {
            navigation.navigate('SwapToTopper', {
              petId: pet.id,
              primaryAnswer: route.params.surveyValue,
              secondaryAnswer: selectedValue,
              customerComment: otherText,
            });
            return;
          } else if (interventions.includes(Intervention.TenPercentDiscount)) {
            setRetentionDiscountModalOpen(true);
            return;
          }
          break;
        case 'dont-want-subscription':
          if (interventions.includes(Intervention.SnoozePlan)) {
            navigation.navigate('SnoozePlan', {
              petId: pet.id,
              primaryAnswer: route.params.surveyValue,
              secondaryAnswer: selectedValue,
              customerComment: otherText,
            });
            return;
          } else if (interventions.includes(Intervention.TenPercentDiscount)) {
            setRetentionDiscountModalOpen(true);
            return;
          }
          break;
        case 'dog-dislikes-it':
          if (interventions.includes(Intervention.TryNewRecipe)) {
            navigation.navigate('TryNewRecipe', {
              petId: pet.id,
              primaryAnswer: route.params.surveyValue,
              secondaryAnswer: selectedValue,
              customerComment: otherText,
            });
            return;
          } else if (interventions.includes(Intervention.SpeakWithSpecialist)) {
            setRetentionSpecialistModalOpen(true);
            return;
          }
          break;
        case 'too-much-too-little-food':
          if (subSurveyOption?.interventionSubtype === 'too-little-food') {
            if (interventions.includes(Intervention.RushADelivery)) {
              navigation.navigate('RushADelivery', {
                petId: pet.id,
                primaryAnswer: route.params.surveyValue,
                secondaryAnswer: selectedValue,
                customerComment: otherText,
              });
              return;
            } else if (interventions.includes(Intervention.AdjustPortionSizeUp)) {
              setAdjustPortionMode(SuggestionMode.INCREASE);
              setAdjustPortionSizeModalOpen(true);
              return;
            } else if (interventions.includes(Intervention.TenPercentDiscount)) {
              setRetentionDiscountModalOpen(true);
              return;
            }
          } else if (subSurveyOption?.interventionSubtype === 'too-much-food') {
            if (interventions.includes(Intervention.SnoozePlan)) {
              navigation.navigate('SnoozePlan', {
                petId: pet.id,
                primaryAnswer: route.params.surveyValue,
                secondaryAnswer: selectedValue,
                customerComment: otherText,
                withAdjustPortionModal: true,
                title: 'Need More Time?',
                description: 'No problem. Snooze your subscription and take the time you need.',
              });
              return;
            } else if (interventions.includes(Intervention.AdjustPortionSizeDown)) {
              setAdjustPortionMode(SuggestionMode.DECREASE);
              setAdjustPortionSizeModalOpen(true);
              return;
            } else if (interventions.includes(Intervention.TenPercentDiscount)) {
              setRetentionDiscountModalOpen(true);
              return;
            }
          }
          break;
      }

      await doCancelPlan();
    } catch (e) {
      displayToast({ type: ToastType.Error, message: 'An unexpected error occurred.' });
    }
  };

  return (
    <Stack alignItems="center" w="100%" flex={1} px="16px">
      <Stack w={{ base: '100%', lg: '450px' }} flex={1} justifyContent="space-between">
        <Stack flex={1}>
          <Center mb={7}>
            <Text size="bodySm">
              Let us know why you're{' '}
              {route.params.pauseOrCancel === 'CANCEL' ? 'cancelling' : 'pausing'}
            </Text>
          </Center>
          <ScrollView
            flex={1}
            contentContainerStyle={{ display: 'flex' }}
            ref={scrollViewRef}
            onScroll={handleScroll}
            scrollEventThrottle={500}
          >
            <VStack ref={scrollContentRef} mb={4}>
              <Radio.Group
                mx={2}
                name="selectedValue"
                accessibilityLabel="Survey answer"
                value={selectedValue}
                onChange={(value) => setSelectedValue(value)}
              >
                {surveyOptions.map(({ label, hideCommentField }, i) => (
                  <Stack key={label} w="100%">
                    <Stack w="100%" mb={4}>
                      <Radio value={label}>
                        <Text fontSize="body.sm" color="grey.dark">
                          {label}
                        </Text>
                      </Radio>
                    </Stack>
                    {selectedValue === label && !hideCommentField ? (
                      <Stack key={`textfield-${i}`} px={2} mb={2} w="100%">
                        <Text size="bodySm" variant="helperText" textAlign="left" mb={2}>
                          Please provide additional details (optional)
                        </Text>
                        <TextArea
                          value={otherText}
                          onChangeText={setOtherText}
                          size="bodySm"
                          fontSize="body.sm"
                          autoCompleteType="off"
                          textAlign="left"
                          borderWidth="1px"
                          borderRadius="8px"
                          totalLines={16}
                          minHeight="150px"
                          maxLength={255}
                          autoFocus
                        />
                      </Stack>
                    ) : null}
                  </Stack>
                ))}
              </Radio.Group>
            </VStack>
            <FloatingPill alignSelf="center" showPill={isOverflow} />
          </ScrollView>
        </Stack>
        <Center py={4}>
          <FormSubmitButton
            onPress={onPressContinue}
            mt={3}
            isLoading={isSubmitting}
            isDisabled={!selectedValue || isSubmitting || isLoadingInterventionEligiblity}
          >
            Confirm
          </FormSubmitButton>
        </Center>
        {pet ? (
          <RetentionDiscountModal
            isOpen={retentionDiscountModalOpen}
            onClose={() => setRetentionDiscountModalOpen(false)}
            petId={pet.id}
            onSecondaryPress={doCancelPlan}
            discountType={DiscountExclusiveType.WINBACK_10_PERCENT}
            cancellationReason={route.params.surveyValue}
            cancellationReasonSecondary={selectedValue}
            cancellationReasonComment={otherText}
          />
        ) : null}
        {pet ? (
          <SpeakWithSpecialistModal
            isOpen={retentionSpecialistModalOpen}
            onClose={() => setRetentionSpecialistModalOpen(false)}
            onSecondaryPress={doCancelPlan}
            petId={pet.id}
            cancellationReason={route.params.surveyValue}
            cancellationReasonSecondary={selectedValue}
            cancellationReasonComment={otherText}
          />
        ) : null}
        {pet ? (
          <AdjustPortionSizeModal
            isOpen={adjustPortionSizeModalOpen}
            petId={pet.id}
            petName={pet.name}
            mode={adjustPortionMode}
            onClose={() => {
              setAdjustPortionSizeModalOpen(false);
              if (interventions.includes(Intervention.TenPercentDiscount)) {
                setRetentionDiscountModalOpen(true);
              } else {
                doCancelPlan();
              }
            }}
            onPressX={() => setAdjustPortionSizeModalOpen(false)}
            cancellationReason={route.params.surveyValue}
            cancellationReasonSecondary={selectedValue}
            cancellationReasonComment={otherText}
          />
        ) : null}
        <StopPlanSuccessModal
          isOpen={stopPlanSuccessModalOpen}
          type={planSuccessModalType}
          onPressX={navigateToAccount}
          onConfirm={navigateToAccount}
        />
      </Stack>
    </Stack>
  );
};
