import { Stack, Heading, Text, Badge, HStack } from 'native-base';

import { LineItemPlanCardHeadingProps } from './LineItemPlanCardHeading';

export const LineItemOneTimeCardHeading = ({
  disabled,
  planStatus,
  orderEditable,
}: LineItemPlanCardHeadingProps) => {
  return (
    <Stack space={{ base: 2, lg: 4 }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading
          size="bodyMlToTitleSm"
          fontWeight="bold"
          color={disabled ? 'sntGrey.primary' : undefined}
        >
          One-Time Products
        </Heading>

        {disabled && <Badge variant="smallTabInactive">{planStatus}</Badge>}
      </HStack>

      {orderEditable && (
        <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
          Delivered with this order only
        </Text>
      )}
    </Stack>
  );
};
