import { round } from '.';

export const calcTotalDiscountPercent = (
  discountedPrice: number,
  fullPrice: number,
  toDecPlace?: number
) => {
  const percent = (1 - discountedPrice / fullPrice) * 100;
  return toDecPlace || toDecPlace === 0 ? round(percent, toDecPlace) : percent;
};
