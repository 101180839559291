import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';

type TaxRatesByZipCode = { ZipCode: string; EstimatedCombinedRate: string }[];

export const FALLBACK_TAX_RATE = 0.065;

const getTaxRates = () =>
  axios
    .get<TaxRatesByZipCode>('https://s3.amazonaws.com/assets.spotandtango/taxRates.json')
    .then((res) => res.data);

export const useTaxRates = () => {
  const { data: taxRates, isLoading } = useQuery(['TaxRates'], getTaxRates);

  const getTaxRateByZipCode = (zipCode: string) => {
    if (!taxRates) {
      return {
        taxRate: FALLBACK_TAX_RATE,
        result: 'rates-were-not-loaded',
      };
    }

    const taxRate = taxRates.find(
      (record) => record.ZipCode.padStart(5, '0') === zipCode
    )?.EstimatedCombinedRate;
    if (taxRate) {
      return {
        taxRate: Number(taxRate),
        result: 'estimated',
      };
    }
    return {
      taxRate: FALLBACK_TAX_RATE,
      result: 'zip-code-not-found',
    };
  };

  return { isLoading, getTaxRateByZipCode };
};
