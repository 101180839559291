import { Stack, HStack } from 'native-base';

import { AvatarImage } from './AvatarImage';

import { Pet } from '@/api/types';

export const AvatarImageGroup = ({ pets }: { pets: Pet[] }) => {
  return (
    <Stack flexDirection="row">
      {pets.map((pet, i) => (
        <HStack
          zIndex={10 - i}
          ml={i > 0 ? '-6px' : ''}
          justifyContent="start"
          alignItems="center"
          key={i}
        >
          <AvatarImage
            petId={pet.id}
            petName={pet.name}
            petStatus={pet.status}
            noImgVariant="initial"
            imageSize={{ base: '24px', lg: '36px' }}
          />
        </HStack>
      ))}
    </Stack>
  );
};
