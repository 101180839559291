import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { zip_codes as zipcode_blacklist, top_level_domains } from '@/constants';

export const ZIPCODE_BLACKLIST_MESSAGE =
  "Oops, we can't ship that far right now! We've added you to our waitlist and will be in touch once we can.";

const name = Yup.string()
  .required('Name is required')
  .trim()
  .min(1, 'Please enter your name')
  .max(20, 'Name cannot exceed 20 characters')
  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ.'\- ]+$/, 'Please enter a valid name');

const email = Yup.string()
  .required('Email is required')
  .trim()
  .max(320, 'Email cannot exceed 320 characters')
  .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Must be a valid email address')
  .test('top_level_domain', 'Must be a valid email', function (value) {
    const { path, createError } = this;
    if (!value) {
      return createError({ path, message: 'Email is required' });
    }
    const [, domain] = value.split('@');
    const tld = domain?.split('.').slice(-1)[0];
    return !!tld && top_level_domains.includes(tld.toUpperCase());
  });

const weight = Yup.number()
  .required('Weight is required.')
  .min(3, 'Weight must be 3 pounds or greater')
  .max(250, 'Weight must be 250 pounds or less');

const zip_code = Yup.string()
  .required(`Please enter your dog's zipcode`)
  .matches(/^[0-9]+$/, 'Must be only digits') // Only numerical digits
  .notOneOf(zipcode_blacklist, ZIPCODE_BLACKLIST_MESSAGE);

const dogName = Yup.string()
  .required("Please enter your dog's name")
  .trim()
  .max(12, 'Name cannot exceed 12 characters')
  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ.' -]*$/, 'Please enter a valid name');

export const phone_number = Yup.string()
  .notRequired()
  .test('phone_format', 'Please input a valid phone number', (value?: string | null) => {
    const digitFormat = new RegExp('^\\+1[0-9]{10}$'); // enforces leading 1 format (1##########)
    return !value || digitFormat.test(value);
  });

const address1 = Yup.string().required('Street address is required').trim();
const address2 = Yup.string().trim();
const city = Yup.string().required('City is required').trim();
const state = Yup.string().required('State is required');
const to_receive_text_messages = Yup.string();

const birthday = Yup.object().shape({
  birth_year: Yup.number()
    .max(new Date().getFullYear(), 'Date must be in the past')
    .min(new Date().getFullYear() - 30, 'Date must be in the past 30 years'),
  birth_month: Yup.number()
    .min(1)
    .when('birth_year', {
      is: (val: number) => val === new Date().getFullYear(),
      then: (schema) => schema.max(new Date().getMonth() + 1, 'Date must be in the past'),
      otherwise: (schema) => schema.max(12),
    }),
});

export const NameAndEmailResolver = yupResolver(
  Yup.object().shape({
    first_name: name,
    email,
  })
);

export const EmailResolver = yupResolver(Yup.object().shape({ email }));

export const WeightResolver = yupResolver(Yup.object().shape({ weight }));

export const LocationResolver = yupResolver(Yup.object().shape({ zip_code }));

export const DogNameResolver = yupResolver(Yup.object().shape({ dogName }));

export const BirthdayResolver = yupResolver(birthday);

export const CheckoutFormResolver = yupResolver(
  Yup.object().shape({
    first_name: name,
    last_name: name,
    email,
    phone_number,
    address1,
    address2,
    city,
    state,
    zip_code,
    to_receive_text_messages,
  })
);
