import { useForm, UseFormProps } from 'react-hook-form';

export interface RecipeSelectFormValues {
  recipeIds: string[];
}

const defaultValues: RecipeSelectFormValues = {
  recipeIds: [],
};

export const useRecipeSelectForm = (props: UseFormProps<RecipeSelectFormValues> = {}) => {
  return useForm<RecipeSelectFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues,
    ...props,
  });
};
