import { Pet } from '@/api';
import { sortPetsAlphabetically, capitalize } from '@/utils';

export interface BreedObject {
  slug: string;
  name: string;
}
export type BreedOption = { value: string; label: string };

const MIXED_BREED_NAME = 'Mixed Breed';

export const BREEDS_LIMIT = 5;

export const getBreedOptions = (unsortedBreedList?: BreedObject[]) => {
  if (!unsortedBreedList) return [];

  const mixedBreed = unsortedBreedList.find((breed) => breed.name === MIXED_BREED_NAME);
  const sortedBreedList = sortPetsAlphabetically(unsortedBreedList as unknown as Pet[]).filter(
    (breed) => breed.name !== MIXED_BREED_NAME
  ) as unknown as BreedObject[];
  const breedList = [mixedBreed].concat(sortedBreedList) as BreedObject[];
  return breedList
    ? breedList.map(({ slug, name }) => {
        return { value: slug, label: name };
      })
    : [];
};

export const getBreed = (breeds: string[] | undefined): string => {
  if (breeds && breeds.length > 0) {
    if (breeds.length > 1) return MIXED_BREED_NAME;
    return capitalize(breeds[0].replaceAll('-', ' '));
  }
  return 'Unknown Breed';
};
