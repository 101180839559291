import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 12 12"
    {...props}
  >
    <Path
      fill={undefined}
      d="M11.25 1.808 10.193.75 6 4.942 1.808.75.75 1.808 4.942 6 .75 10.193l1.058 1.057L6 7.058l4.193 4.192 1.057-1.057L7.058 6l4.192-4.192Z"
    />
    <Path
      fill={undefined}
      d="M11.25 1.808 10.193.75 6 4.942 1.808.75.75 1.808 4.942 6 .75 10.193l1.058 1.057L6 7.058l4.193 4.192 1.057-1.057L7.058 6l4.192-4.192Z"
    />
  </Svg>
);
function SvgClose({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgClose;
