import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';

import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

export interface ReactivateRequestData {
  petId: string;
  /**
   * `YYYY-MM-DD` date string
   */
  startDate: string;
  discountCodes: string[];
}

const reactivate = async (
  { petId, startDate, discountCodes }: ReactivateRequestData,
  axiosConfig?: AxiosRequestConfig
) =>
  axios
    .post(
      `/pets/${petId}/reactivate`,
      { start_date: startDate, discount_codes: discountCodes },
      axiosConfig
    )
    .then((response) => response.data);

export const useReactivate = (axiosConfig?: AxiosRequestConfig) => {
  const { refetchUser } = useAuth();

  return useMutation(
    ({ petId, startDate, discountCodes }: ReactivateRequestData) =>
      reactivate({ petId, startDate, discountCodes }, axiosConfig),
    {
      onSuccess: () => {
        return refetchUser();
      },
    }
  );
};
