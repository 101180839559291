import { useMemo } from 'react';

import { UsePlanOptionsQueryData, usePlans } from './usePlans';

import { processPlanData } from '@/utils/processPlanData';

/**
 * Helper hook to wrap useSessionPlans and expose common data structures
 */
export const useRecipeOptions = ({
  pet,
  discounts,
  sessionId,
  accountId,
}: UsePlanOptionsQueryData) => {
  const { data, isLoading, isIdle } = usePlans({ pet, discounts, sessionId, accountId });

  return useMemo(() => {
    const recipeOptionsData = processPlanData(data);
    return { data: recipeOptionsData, isLoading, isIdle };
  }, [data, isIdle, isLoading]);
};
