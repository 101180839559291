import { Card, PaymentMethod } from '@stripe/stripe-js';
import { addMonths, getMonth, getYear, startOfMonth } from 'date-fns';

export const cardIsExpired = (card: Card | PaymentMethod.Card) => {
  const startOfThisMonth = startOfMonth(new Date());
  return (
    card.exp_year < getYear(startOfThisMonth) ||
    (card.exp_year === getYear(startOfThisMonth) && card.exp_month <= getMonth(startOfThisMonth))
  );
};

export const cardIsExpiringSoon = (card: Card | PaymentMethod.Card) => {
  const twoMonthsAhead = addMonths(startOfMonth(new Date()), 2);
  return (
    card.exp_year < getYear(twoMonthsAhead) ||
    (card.exp_year === getYear(twoMonthsAhead) && card.exp_month <= getMonth(twoMonthsAhead))
  );
};
