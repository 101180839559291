import { Button as NativeButton } from 'native-base';

type DefaultNativeButtonType = React.ComponentProps<typeof NativeButton>;

export interface ButtonProps extends Omit<DefaultNativeButtonType, 'Group'> {
  form?: string;
  isLoading?: boolean;
  startIcon?: any;
  endIcon?: any;
  name?: string;
  isDone?: boolean;
  isDisabled?: boolean;
  isHovered?: boolean;
  isFocused?: boolean;
}

export const Button = ({ variant = 'primary', size = 'md', children, ...props }: ButtonProps) => (
  <NativeButton variant={variant} size={size} {...props}>
    {children}
  </NativeButton>
);
