/**
 * Defaults: https://github.com/GeekyAnts/NativeBase/blob/v3.4.19/src/theme/components/pressable.ts
 */

const baseStyle = () => ({
  _focusVisible: {
    _web: {
      outlineWidth: '0',
      style: { boxShadow: `none` },
    },
  },
});

// hover and selected styling
const _productCardHover = {
  borderColor: 'black',
  style: { boxShadow: '0 0 0 2px black' },
  bg: 'white',
};
const _productCardSelected = {
  borderColor: 'black',
  style: { boxShadow: '0 0 0 2px black' },
  bg: 'sntBlue.primary20',
};

const _productCard = {
  borderWidth: '1px',
  borderColor: 'sntGrey.outline',
  py: { base: '16px', lg: '24px' },
  px: { base: '16px', lg: '1px' },
  _light: {
    bg: 'transparent',
    _hover: _productCardHover,
    _pressed: _productCardSelected,
  },
};

const _editCard = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'start',
  w: '100%',
  h: 'fit-content',
  borderWidth: '1px',
  p: { base: '16px', lg: '24px' },
  _light: {
    bg: 'white',
    borderColor: 'sntGrey.outline',
    _hover: {
      borderColor: 'black',
    },
    _pressed: {
      p: { base: '15px', lg: '23px' }, // account for pressed border
      borderWidth: '2px',
    },
  },
};

const _productCardInactive = {
  ..._productCard,
  _light: {
    ..._productCard._light,
    bg: 'gallery40',
  },
};

const variants = {
  card: {
    borderWidth: '1px',
    borderColor: 'sntGrey.outline',
    _light: {
      bg: 'white',
      borderColor: 'sntGrey.outline',
      _hover: {
        borderColor: 'black',
        style: { boxShadow: '0 0 0 2px black' },
        bg: 'white',
      },
      _pressed: {
        borderColor: 'black',
        style: { boxShadow: '0 0 0 2px black' },
        bg: 'sntBlue.primary20',
      },
    },
  },
  productCard: _productCard,
  productCardSelected: {
    ..._productCard,
    ..._productCardSelected,
    _light: {
      bg: 'sntBlue.primary20',
      _hover: _productCardSelected,
    },
  },
  productCardInactive: _productCardInactive,
  row: {
    w: '370px',
    h: 'fit-content',
  },
  column: {
    w: '324px',
    h: 'fit-content',
  },
  icon: {
    borderStyle: 'none',
    w: 'fit-content',
    h: 'fit-content',
    padding: 0,
    bgColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accordionItem: {
    w: '100%',
    h: 'fit-content',
    borderStyle: 'none',
    px: '0px',
    paddingTop: { base: '16px', lg: '24px' },
    paddingBottom: { base: '8px', lg: '16px' },
  },
  editCard: _editCard,
  editCardError: {
    ..._editCard,
    _light: {
      ..._editCard._light,
      borderColor: 'error.default',
      _hover: {
        borderColor: 'error.hover',
      },
    },
  },
};

export const Pressable = {
  baseStyle,
  variants,
};
