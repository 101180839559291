import { useFocusEffect } from '@react-navigation/native';
import { useState, useCallback } from 'react';
import { clearInterval, setInterval } from 'worker-timers';

const BASE_COEFF = 0.5;

export function useProgressBar() {
  const [progress, setProgress] = useState(0);

  useFocusEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          let coeff = 1;
          if (prevProgress < 90) coeff = 2 * BASE_COEFF;
          if (prevProgress < 80) coeff = 4 * BASE_COEFF;
          if (prevProgress < 66) coeff = 6 * BASE_COEFF;
          if (prevProgress < 25) coeff = 2 * BASE_COEFF;
          if (prevProgress < 10) coeff = 1 * BASE_COEFF;
          return prevProgress + coeff;
        });
      }, 50);

      return () => clearInterval(interval);
    }, [])
  );

  return {
    progress,
  };
}
