import { Link, Stack, Text } from 'native-base';
import { useState } from 'react';

import { PortalPopUp } from '@/components/Elements';

const linkStyles = {
  textDecorationLine: 'underline',
  color: 'black',
};

const dogModalType = {
  add: {
    heading: 'Add Your Dog by Emailing Us',
    description: (
      <>
        Please answer the following questions in an email to{' '}
        <Link {...linkStyles} href="mailto:care@spotandtango.com">
          care@spotandtango.com
        </Link>
        , and our team will assist you further.
      </>
    ),
    questions: [
      "What is your dog's name?",
      'Is your dog a boy or a girl?',
      'Is your dog neutered / spayed?',
      'What breed is your dog? (mixed or unknown is ok!)',
      'How old is your dog?',
      "How would you describe your dog's body type (a little skinny, just right, or a little chubby)?",
      'How much does your dog weigh (lbs)?',
      "What's your dog's lifestyle like (lazy, active, very active)?",
      'Does your dog have any special health conditions? If yes, please specify.',
      'Will you be feeding your dog only Spot & Tango or mixing it as a topper with other food?',
      'Would you like to feed your dog our Fresh or UnKibble recipes? If Fresh, choose up to three recipe options from Turkey, Beef, or Lamb. If UnKibble, choose up to two recipe options from Turkey, Chicken, Beef, or Cod + Salmon.',
    ],
  },
  update: {
    heading: "Update Your Dog's Profile by Emailing Us! ",
    description: (
      <>
        Please answer any of the following questions you'd like to update and email them to{' '}
        <Link {...linkStyles} href="mailto:care@spotandtango.com">
          care@spotandtango.com
        </Link>
        . Our team will assist you further.
      </>
    ),
    questions: [
      "What is your dog's name?",
      'Is your dog neutered / spayed?',
      'How old is your dog?',
      "How would you describe your dog's body type (a little skinny, just right, or a little chubby)?",
      'How much does your dog weigh (lbs)?',
      "What's your dog's lifestyle like (lazy, active, very active)?",
      'Does your dog have any special health conditions? If yes, please specify.',
    ],
  },
};

export const useDogModal = (type: 'add' | 'update') => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { heading, description, questions } = dogModalType[type];
  return {
    showModal,
    setShowModal,
    Modal: ({ ...props }) => {
      return (
        <PortalPopUp
          alignSelf="center"
          confirmBtnText="CLOSE"
          heading={heading}
          description={<Text fontSize="16px">{description}</Text>}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={() => setShowModal(false)}
          contentStackProps={{
            px: '24px',
            w: '100%',
          }}
          showCrossButton
          showCloseButton={false}
          size="full"
          useRNModal
          w={{ base: '100%', md: '800px' }}
          {...props}
        >
          <Stack flex={1} w={{ base: '100%', md: '800px' }} px="24px">
            <Stack size="bodySm" px="2px">
              {questions.map((question, index) => (
                <Stack key={index} flexDirection="row">
                  <Text fontFamily="secondary" lineHeight="30px" paddingRight="5px" size="bodySm">
                    •
                  </Text>
                  <Text fontFamily="secondary" lineHeight="30px" size="bodySm">
                    {question}
                  </Text>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </PortalPopUp>
      );
    },
  };
};
