import { Stack, Text } from 'native-base';

import { PopUpProps, PortalPopUp } from '@/components/Elements';

export interface StopPlanSuccessModalProps extends PopUpProps {
  type?: 'cancel' | 'passed_away';
}

export const StopPlanSuccessModal = ({ type = 'cancel', ...props }: StopPlanSuccessModalProps) => {
  const heading =
    type === 'passed_away' ? "We're sorry for your loss" : "We're sorry to see you go!";
  const bodyText =
    type === 'passed_away'
      ? "We've cancelled your plan, but please know that your Spot & Tango Family is always here for you."
      : "You can reactivate at any time through the portal or by contacting us. We're always here to help!";
  return (
    <PortalPopUp
      heading={heading}
      confirmBtnText="Close"
      showCloseButton={false}
      size="xl"
      modalContentProps={{ size: { lg: 'md' }, height: 'auto', borderRadius: '16px' }}
      useRNModal
      {...props}
    >
      <Stack textAlign="center" space={2} mb={4} px={{ lg: 4 }}>
        <Text size="bodySm">{bodyText}</Text>
      </Stack>
    </PortalPopUp>
  );
};
