import { Button, IButtonProps } from 'native-base';

import { Pet } from '@/api/types';
import { Checkbox } from '@/components/Elements';

export interface PetPillButtonProps extends IButtonProps {
  pet: Pet;
  selected?: boolean;
}

export const PetPillButton = ({ pet, selected, ...props }: PetPillButtonProps) => (
  <Button
    px={{ base: 4, lg: 6 }}
    py="10px"
    variant={selected ? 'badgeBold' : 'badgeBoldOutline'}
    borderRadius="25px"
    leftIcon={
      <Checkbox
        isChecked={selected}
        value={pet.id}
        borderRadius="3px"
        _icon={{ color: 'sntBlue.primary' }}
        _checked={{ background: 'black' }}
        borderColor="black"
        borderWidth="2px"
        p="1px"
        h={{ base: '20px', md: '24px' }}
        w={{ base: '20px', md: '24px' }}
      />
    }
    {...props}
  >
    {pet.name}
  </Button>
);
