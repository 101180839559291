import { useMemo } from 'react';

import { OrderStatus } from '@/api';
import { useAuth } from '@/hooks';

export const useOrdersOnHold = () => {
  const { user: account } = useAuth();

  const ordersOnHold = useMemo(
    () => account?.orders?.processing.filter((order) => order.status === OrderStatus.HOLD) || [],
    [account?.orders]
  );

  return ordersOnHold;
};
