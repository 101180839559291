import { Box, Button, Stack } from 'native-base';

import { ReschedulingCalendar } from '../../ReschedulingCalendar';
import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';

export const AddNewDogRescheduleDelivery = ({
  navigation,
}: AddNewDogScreenProps<'RescheduleDelivery'>) => {
  const { startDate, saveStartDate } = useAddNewDogContext();

  const handleDayPress = ({ day, month, year }: { day: number; month: number; year: number }) => {
    saveStartDate(new Date(year, month - 1, day));
  };

  const onPressSubmit = () => {
    navigation.navigate(AddNewDogScreenNames.PLAN_SUMMARY);
  };

  return (
    <AddNewDogWrapper
      containerProps={{
        w: '100%',
        h: '100%',
        paddingBottom: '24px',
        px: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack w={{ base: '100%', lg: '396px' }} space="8px">
        <ReschedulingCalendar selectedDate={startDate} onDayPress={handleDayPress} />
      </Stack>
      <Box w={{ base: '100%', md: '357px' }}>
        <Button variant="primary" onPress={onPressSubmit}>
          Reschedule
        </Button>
      </Box>
    </AddNewDogWrapper>
  );
};
