import { round } from '@/utils';

/**
 * Returns price rounded to two decimal places if there
 * are cents, and zero places if there aren't ('8.00' => '8')
 */
export const roundPriceShort = (total: string | number) => {
  const hasCents = Number(total) !== Math.floor(Number(total));
  return round(total, hasCents ? 2 : 0);
};

export const formatPrice = (
  total?: string | number,
  formatFree: 'free' | 'dash' | 'none' = 'dash'
) => {
  const priceFormatted = round(total || 0, 2);
  const priceAmt = Number(total || 0);
  if (priceAmt > 0 || formatFree === 'none') {
    if (priceAmt >= 1000) {
      return `$${parseFloat(priceFormatted).toLocaleString('en', { minimumFractionDigits: 2 })}`;
    }
    return `$${priceFormatted}`;
  } else {
    return formatFree === 'free' ? 'FREE' : '–';
  }
};
