import { PaymentMethod } from '@stripe/stripe-js';
import { Stack, Flex, Text } from 'native-base';

import { HiddenTextDots } from '../../../Elements';
import { CardBrandLogo } from './CardBrandLogo';

interface CreditCardDetailsCardProps {
  stripeCard: PaymentMethod.Card;
}

export const CreditCardDetailsCard = ({ stripeCard }: CreditCardDetailsCardProps) => (
  <Stack flexDirection="column" space={2}>
    <Stack flexDirection="row" alignItems="center">
      <Stack mr={1}>
        <CardBrandLogo brand={stripeCard.brand} />
      </Stack>
      <Flex direction="row" mr={1}>
        <HiddenTextDots />
      </Flex>
      <Text size="bodySm" color="grey.dark">
        {stripeCard.last4}
      </Text>
    </Stack>
    <Text size="bodySm" color="grey.dark">
      {`${stripeCard.exp_month}`.padStart(2, '0')}/{stripeCard.exp_year}
    </Text>
  </Stack>
);
