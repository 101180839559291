import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';

import { ENVIRONMENT } from '../config';

import { FRIENDBUY_MERCHANT_ID } from '@/constants';

const FRIENDBUY_SCRIPT_URL = 'https://static.fbot.me/friendbuy.js';
const FRIENDBUY_CAMPAIGNS_SCRIPT_URL = `https://campaign.fbot.me/${FRIENDBUY_MERCHANT_ID}/campaigns.js`;

const { logger } = datadogLogs;

const loadFriendbuyScript = () => {
  if (window && document) {
    window['friendbuyAPI'] = window['friendbuyAPI'] || [];
    window.friendbuyAPI.push(['merchant', FRIENDBUY_MERCHANT_ID]);
    const friendbuyScript = document.createElement('script');
    const body = document.getElementsByTagName('body')[0];
    friendbuyScript.src = FRIENDBUY_SCRIPT_URL;
    body.appendChild(friendbuyScript);
    friendbuyScript.addEventListener('load', () => {
      const campaigns = document.createElement('script');
      campaigns.src = FRIENDBUY_CAMPAIGNS_SCRIPT_URL;
      body.appendChild(campaigns);
      // Add custom styles after Friendbuy scripts have loaded
      const customStyles = document.createElement('style');
      customStyles.innerHTML = `.Friendbuy-ribbon-transition {
        background-color: rgb(159, 205, 255);
        width: 130px;
        cursor: pointer;
        margin-top: -20px;
        font-family: Roboto;
        border-color: rgb(159, 205, 255);
        color: black;
        transform: rotate(-90deg);
        top: 50%;
        border-width: 1px;
        background-size: contain;
        border-style: solid;
        font-weight: 700;
        height: 40px;
        z-index: 99;
        align-items: center;
        text-align: center;
        flex-direction: row;
        display: flex;
        justify-content: center;
        border-top-left-radius: 0px;
        left: -45px;
        background: rgb(159, 205, 255);
        font-size: 16px;
        position: fixed;
        background-repeat: no-repeat;
      }`;
      body.appendChild(customStyles);
    });
  }
};

export const useFriendbuy = () => {
  useEffect(() => {
    if (!window?.friendbuyAPI && ENVIRONMENT === 'prod') {
      loadFriendbuyScript();
    }
  }, []);
  const friendbuyAPI: { push: (params: any) => void } = window?.friendbuyAPI as any;
  const trackFunnelCheckoutOrder = (
    accountID?: string,
    initialOrderID?: string,
    amount?: string,
    email?: string,
    couponCode?: string,
    name?: string
  ) => {
    if (accountID && initialOrderID && amount && email && friendbuyAPI) {
      try {
        friendbuyAPI.push([
          'track',
          'purchase',
          {
            id: initialOrderID,
            amount: Number(amount),
            couponCode,
            currency: 'USD',
            customer: { id: accountID, email, name },
            isNewCustomer: true,
          },
        ]);
      } catch (error) {
        logger.error('Friendbuy order tracking failed', { error });
      }
    }
  };
  return {
    trackFunnelCheckoutOrder,
  };
};
