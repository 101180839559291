import { datadogLogs } from '@datadog/browser-logs';
import { VStack, Heading, HStack, Text, Button } from 'native-base';
import { useMemo, useState } from 'react';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { usePetFormOptions } from '@/api';
import { Checkbox, ToastType, displayToast } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';

const { logger } = datadogLogs;

export const AddNewDogHealthConditionsList = ({
  navigation,
}: AddNewDogScreenProps<'HealthConditionsList'>) => {
  const account = useAccount();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.HEALTH_CONDITIONS_LIST);
  const { savePetData } = useAddNewDogContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { data: petFormOptions } = usePetFormOptions();

  const sortedHealthConditionOptions = useMemo(
    () =>
      petFormOptions?.health_conditions.sort((a, b) => {
        if (a.seq_no === b.seq_no) {
          return a.name < b.name ? -1 : 1;
        }
        return a.seq_no - b.seq_no;
      }) ?? [],
    [petFormOptions?.health_conditions]
  );

  const onSubmit = () => {
    if (selectedOptions.length === 0) {
      return;
    }
    setIsSubmitting(true);
    try {
      savePetData({ health_conditions: selectedOptions });
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's health condition(s)`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's health conditions list, account_id=${account.id}`, {
        error,
      });
    }
    navigation.navigate(nextStep);
    setIsSubmitting(false);
  };

  return sortedHealthConditionOptions ? (
    <AddNewDogWrapper>
      <VStack
        justifyContent={{ base: 'center', md: 'flex-start' }}
        alignItems="center"
        space={{ base: '30px', lg: '75px' }}
        w="100%"
        h="75%"
      >
        <VStack
          w="100%"
          maxW="813px"
          flex={1}
          alignItems="center"
          space={{ base: '36px', lg: '48px' }}
        >
          <Heading size="titleSmToMd" fontWeight="bold">
            Check all that apply
          </Heading>
          <Checkbox.Group
            accessibilityLabel="Health conditions"
            value={selectedOptions}
            onChange={setSelectedOptions}
            w="100%"
          >
            <VStack w="100%" maxH={{ base: '100%', lg: '276px' }} flexWrap="wrap">
              {sortedHealthConditionOptions.map(
                ({ slug, name }: { slug: string; name: string }, i: number) => (
                  <HStack key={i} space={{ base: 2, lg: 5 }} mb="18px">
                    <Checkbox value={slug}>
                      <Text size="bodyMlToLg" color="black" fontWeight={500}>
                        {name}
                      </Text>
                    </Checkbox>
                  </HStack>
                )
              )}
            </VStack>
          </Checkbox.Group>
          <Button
            variant="primary"
            onPress={onSubmit}
            position={{ base: 'sticky', lg: 'relative' }}
            bottom={{ base: 6, lg: 0 }}
            isDisabled={selectedOptions.length === 0}
            isLoading={isSubmitting}
          >
            CONTINUE
          </Button>
        </VStack>
      </VStack>
    </AddNewDogWrapper>
  ) : null;
};
