import { ReactNode, createContext, useContext, useState } from 'react';

import { BuildPlan, RecipeType } from '@/api';
import { Dog } from '@/types';

interface AddNewDogContextType {
  petData: Partial<Dog>;
  petPlan: BuildPlan | null;
  snacks: AddOnMap;
  supplements: AddOnMap;
  startDate: Date;
  discountCode: string | null;
  savePetData: (data: Partial<Dog>) => void;
  savePetPlan: (petPlan: BuildPlan) => void;
  saveAddOnQuantity: (addOn: { id: string; quantity: number }, type: RecipeType) => void;
  saveAddOnRecipes: (recipes: string[], type: RecipeType) => void;
  saveStartDate: (date: Date) => void;
  saveDiscountCode: (code: string) => void;
  resetData: () => void;
}

export interface AddOnMap {
  [recipeId: string]: number;
}

export const AddNewDogContext = createContext<AddNewDogContextType>({} as AddNewDogContextType);

export const useAddNewDogContext = () => useContext(AddNewDogContext);

export const AddNewDogContextProvider = ({ children }: { children: ReactNode }) => {
  const [petData, setPetData] = useState<Partial<Dog>>({});
  const [petPlan, setPetPlan] = useState<BuildPlan | null>(null);
  const [snacks, setSnacks] = useState<AddOnMap>({});
  const [supplements, setSupplements] = useState<AddOnMap>({});
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [discountCode, setDiscountCode] = useState<string>('');
  const savePetData = (data: Partial<Dog>) => {
    setPetData((prev) => ({ ...prev, ...data }));
  };
  const savePetPlan = (petPlan: BuildPlan) => {
    setPetPlan(petPlan);
  };

  const saveAddOnQuantity = (addOn: { id: string; quantity: number }, type: RecipeType) => {
    const setAddOns = type === RecipeType.SNACK ? setSnacks : setSupplements;
    const newAddOns = { ...(type === RecipeType.SNACK ? snacks : supplements) };
    if (addOn.quantity === 0) {
      // remove addon
      delete newAddOns[addOn.id];
      setAddOns(newAddOns);
    } else {
      // update addon
      newAddOns[addOn.id] = addOn.quantity;
      setAddOns(newAddOns);
    }
  };

  const saveAddOnRecipes = (recipes: string[], type: RecipeType) => {
    const setAddOns = type === RecipeType.SNACK ? setSnacks : setSupplements;
    setAddOns((prev) => {
      const newAddOns = { ...prev };
      // delete addons not in recipes list
      for (const key of Object.keys(newAddOns)) {
        if (!recipes.includes(key)) {
          delete newAddOns[key];
        }
      }
      // add new addons
      recipes.forEach((recipe) => {
        if (!newAddOns[recipe]) newAddOns[recipe] = 1;
      });
      return newAddOns;
    });
  };

  const saveStartDate = (date: Date) => {
    setStartDate(date);
  };
  const saveDiscountCode = async (code: string) => {
    setDiscountCode(code);
  };

  const resetData = () => {
    setPetData({});
    setPetPlan(null);
    setSnacks({});
    setSupplements({});
    setStartDate(new Date());
    setDiscountCode('');
  };

  return (
    <AddNewDogContext.Provider
      value={{
        petData,
        petPlan,
        snacks,
        supplements,
        startDate,
        discountCode,
        savePetData,
        savePetPlan,
        saveAddOnQuantity,
        saveAddOnRecipes,
        saveStartDate,
        saveDiscountCode,
        resetData,
      }}
    >
      {children}
    </AddNewDogContext.Provider>
  );
};
