import { Stack, ScrollView, KeyboardAvoidingView, IStackProps } from 'native-base';
import { IKeyboardAvoidingViewProps } from 'native-base/lib/typescript/components/basic/KeyboardAvoidingView/types';
import { ReactNode } from 'react';

import { AddNewDogHead } from '@/components/Elements';

interface AddNewDogProps extends IKeyboardAvoidingViewProps {
  containerProps?: IStackProps;
  showHeader?: boolean;
  children: ReactNode;
}
export const AddNewDogWrapper = ({
  containerProps,
  showHeader = true,
  ...props
}: AddNewDogProps) => (
  <ScrollView contentContainerStyle={{ height: '100%', width: '100%' }}>
    <KeyboardAvoidingView w="100%" h="100%" {...props}>
      {showHeader && <AddNewDogHead />}
      <Stack
        flex={1}
        w="100%"
        maxW={1200}
        alignItems="center"
        alignSelf="center"
        justifyContent={{ base: 'space-between', md: 'flex-start' }}
        pb="24px"
        px="16px"
        {...containerProps}
      >
        {props.children}
      </Stack>
    </KeyboardAvoidingView>
  </ScrollView>
);
