import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';

export interface ReactivationPreviewResponse {
  petPlanId: string;
  products: {
    product_code: string;
    unit_price: string;
    quantity: number;
  }[];
  discount_codes: string[];
  total: {
    total_price: string;
    total_discounts: string;
    total_tax: string;
  };
  errors: { INVALID_DISCOUNT_CODE: string };
}

const getReactivationPreview = async ({
  petPlanId,
  discountCode,
}: {
  petPlanId: string;
  discountCode: string;
}) =>
  axios
    .get(`/pet_plans/${petPlanId}/reactivation_preview?discount_code=${discountCode}`)
    .then(({ data }) => data);

export const useReactivationPreview = ({
  petPlanId,
  discountCode,
}: {
  petPlanId?: string;
  discountCode?: string;
}) => {
  return useQuery<ReactivationPreviewResponse>(
    ['ReactivationPreview', petPlanId, discountCode],
    () =>
      getReactivationPreview({
        petPlanId: petPlanId as string,
        discountCode: discountCode as string,
      }),
    { enabled: !!petPlanId }
  );
};
