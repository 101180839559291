import { useQuery } from 'react-query';

import { RecipeType, RecipeFull } from '@/api/types';
import { axios } from '@/lib/axios';

interface AddNewDogPreviewData {
  accountId: string;
  discountCode: string;
  mealProductCode: string;
  addons: { [recipeId: string]: number };
}
export interface AddNewDogPreviewResponse {
  products: {
    code: string;
    type: RecipeType;
    recipes: RecipeFull[];
    unit_price: string;
    quantity: number;
  }[];
  discount_codes: string[];
  total: {
    total_price: string;
    total_discounts: string;
    total_tax: string;
  };
  errors: { INVALID_DISCOUNT_CODE: string };
}

const getAddNewDogPreview = ({
  accountId,
  discountCode,
  mealProductCode,
  addons,
}: AddNewDogPreviewData) =>
  axios
    .post(`/accounts/${accountId}/add_new_dog_preview`, {
      discount_code: discountCode,
      meal_product_code: mealProductCode,
      addons,
    })
    .then((response) => response.data);

export const useAddNewDogPreview = ({
  accountId,
  discountCode,
  mealProductCode,
  addons,
}: AddNewDogPreviewData) => {
  return useQuery<AddNewDogPreviewResponse>(
    ['AddNewDogPreview', accountId, discountCode, mealProductCode, addons],
    () =>
      getAddNewDogPreview({
        accountId,
        discountCode,
        mealProductCode,
        addons,
      }),
    { enabled: !!accountId }
  );
};
