import { Divider, Stack, Text } from 'native-base';

import { AccountNavTextBanner } from './AccountNavTextBanner';

import { PetStatus } from '@/api/types';
import { Button } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { useCardsExpiryStatus } from '@/hooks/useCardsExpiryStatus';

export const AccountNav = ({ route, navigation }: any) => {
  const account = useAccount();
  const { isPrimaryCardExpired, isPrimaryCardExpiringSoon } = useCardsExpiryStatus();

  const dogCount = account.pets.filter((pet) => pet.status === PetStatus.ACTIVE).length;

  return (
    <Stack w="100%" alignItems="center">
      <Stack
        direction="row"
        justifyContent={{ base: 'space-evenly', lg: 'space-between' }}
        alignItems="center"
        px={{ base: '16px', lg: '0px' }}
        py="18px"
        bgColor="white"
        w={{ base: '100%', lg: '502px' }}
        h="fit-content"
      >
        <Stack>
          <Button
            variant="portalNav"
            onPress={() => navigation.navigate('Home')}
            size={{ base: 'bodyMd' }}
            _text={{
              color: route.name === 'Home' ? 'teal' : 'blueBayoux.primary',
            }}
          >
            Home
          </Button>
          {route.name === 'Home' && <Divider bgColor="teal" h="3px" />}
        </Stack>

        <Stack>
          <Button
            variant="portalNav"
            size={{ base: 'bodyMd' }}
            onPress={() => navigation.navigate('Orders')}
            _text={{
              color: route.name === 'Orders' ? 'teal' : 'blueBayoux.primary',
            }}
          >
            Orders
          </Button>
          {route.name === 'Orders' && <Divider bgColor="teal" h="3px" />}
        </Stack>

        <Stack>
          <Button
            variant="portalNav"
            size={{ base: 'bodyMd' }}
            onPress={() => navigation.navigate('Dogs')}
            _text={{
              color: route.name === 'Dogs' ? 'teal' : 'blueBayoux.primary',
            }}
          >
            Dogs
          </Button>
          {route.name === 'Dogs' && <Divider bgColor="teal" h="3px" />}
        </Stack>

        <Stack>
          <Button
            variant="portalNav"
            size={{ base: 'bodyMd' }}
            onPress={() => navigation.navigate('Account')}
            _text={{
              color: route.name === 'Account' ? 'teal' : 'blueBayoux.primary',
            }}
          >
            Account
          </Button>
          {route.name === 'Account' && <Divider bgColor="teal" h="3px" />}
        </Stack>
      </Stack>
      <Divider />
      {isPrimaryCardExpired ? (
        <AccountNavTextBanner backgroundColor="error.bg">
          <Text size="bodySm" color="grey.dark">
            <Text>Your payment has expired. </Text>
            <Text
              onPress={() =>
                navigation.navigate('EditBilling', {
                  screen: 'AddCreditCard',
                  params: { setDefault: true },
                })
              }
              fontWeight={900}
              textDecorationLine="underline"
            >
              Add a new form of payment
            </Text>{' '}
            <Text>to continue receiving your {dogCount > 1 ? "pups'" : "pup's"} food.</Text>
          </Text>
        </AccountNavTextBanner>
      ) : null}
      {isPrimaryCardExpiringSoon ? (
        <AccountNavTextBanner backgroundColor="warning">
          <Text size="bodySm" color="grey.dark">
            <Text>Your primary payment will expire soon. </Text>
            <Text
              onPress={() => navigation.navigate('EditBilling')}
              fontWeight={900}
              textDecorationLine="underline"
            >
              Change or add a new form of payment
            </Text>{' '}
            <Text>to continue receiving your {dogCount > 1 ? "pups'" : "pup's"} food.</Text>
          </Text>
        </AccountNavTextBanner>
      ) : null}
    </Stack>
  );
};
