import {
  Badge,
  Button,
  FormControl,
  Heading,
  Input,
  Pressable,
  Spinner,
  Stack,
  Text,
} from 'native-base';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Linking } from 'react-native';

import { ToastType, displayToast } from '../Toast';

import { useApplyDiscount } from '@/api/services/orders/useApplyDiscount';
import { Discount, Order, OrderStatus } from '@/api/types/accountServices';

export const PromoCard = ({ order }: { order: Order }) => {
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const { mutateAsync: applyDiscount, isLoading: isApplyDiscountLoading } = useApplyDiscount();

  const defaultValues = {
    discountCode: '',
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const handleApplyDiscount = ({ discountCode }: { discountCode: string }) => {
    applyDiscount(
      { orderId: order.id, discountCode },
      {
        onSuccess: (response) => {
          setShowPromoInput(false);
          setShowError(false);
          if (response?.discount_warning) {
            displayToast({ message: response?.discount_warning, type: ToastType.Warning });
          } else {
            displayToast({
              message: `${watch(
                'discountCode'
              ).toLocaleUpperCase()} promo code has been applied to your order.`,
              type: ToastType.Success,
            });
          }
          reset();
        },
        onError: () => {
          setShowError(true);
          reset();
        },
      }
    );
  };

  if (order.status !== OrderStatus.PREVIEW) {
    return null;
  }

  return (
    <Stack variant="card" w="100%">
      <Stack direction="column" space="4px" w="100%" alignItems="start">
        <Stack direction="row" w="100%" justifyContent="space-between">
          <Heading size="bodyMlToTitleSm" w="100%" textAlign="start" fontWeight="700">
            Promo Code
          </Heading>
          <Stack space="4px" justifyContent="flex-end" alignItems="center">
            {order.discounts?.map(({ discount }: { discount: Discount }, i: number) => (
              <Badge
                key={`${i}-${discount.id}`}
                variant="smallInfoPillSecondary"
                _text={{ size: 'tinyToBodyMd' }}
              >
                {discount.code}
              </Badge>
            ))}
          </Stack>
        </Stack>
        {showPromoInput || !!order.charged ? null : (
          <Button
            variant="underlineMini"
            size={{ base: 'bodySm' }}
            display={showPromoInput ? 'none' : 'flex'}
            onPress={() => setShowPromoInput(true)}
          >
            Add a Code
          </Button>
        )}
        {showPromoInput && (
          <FormControl isRequired isInvalid={'discountCode' in errors}>
            <Controller
              control={control}
              name="discountCode"
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  w="100%"
                  m="0px"
                  variant={showError ? 'error' : 'outline'}
                  size={{ base: 'bodySm', lg: 'bodyLg' }}
                  onBlur={() => {
                    if (!watch('discountCode')) setShowPromoInput(false);
                    onBlur();
                  }}
                  onChangeText={(val) => {
                    onChange(val.toUpperCase());
                  }}
                  value={value}
                  placeholder="Enter Promo Code"
                  textAlign="start"
                  InputRightElement={
                    <Button
                      variant="inline"
                      w="fit-content"
                      isDisabled={!watch('discountCode') || isApplyDiscountLoading}
                      onPress={handleSubmit(handleApplyDiscount)}
                    >
                      {isApplyDiscountLoading ? <Spinner /> : 'Apply'}
                    </Button>
                  }
                />
              )}
            />
            {showError && (
              <Text size={{ base: 'bodySm' }} color="error.nativeBase">
                The promo code you've entered is invalid. Please try again or{' '}
                <Pressable
                  onPress={() =>
                    Linking.openURL(
                      'https://spotandtango.com/faq?a=How-do-I-contact-customer-support---id--IKoHhdLiR3SYrMUbeS90EQ'
                    )
                  }
                  textDecorationLine="underline"
                  fontWeight={900}
                  size={{ base: 'bodySm' }}
                >
                  <Text>contact us</Text>
                </Pressable>{' '}
                for help.
              </Text>
            )}
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};
