export type BaseEntity = {
  id: string;
  createdAt: number;
};

export type PetFormOptions = {
  lifestyles: Lifestyles[];
  body_types: BodyTypes[];
  health_conditions: HealthConditions[];
  breeds: Breed[];
};

export interface Breed extends BaseEntity {
  id: string;
  created: string;
  last_updated: string;
  name: string;
  slug: string;
  description?: any;
  seq_no: number;
  feature_flag?: any;
}

export interface HealthConditions extends BaseEntity {
  id: string;
  created: string;
  last_updated: string;
  name: string;
  slug: string;
  description?: any;
  seq_no: number;
  feature_flag?: any;
}

export enum BodyTypeCode {
  ALittleSkinny = 'a-little-skinny',
  JustRight = 'just-right',
  ALittleChubby = 'a-little-chubby',
}

export interface BodyTypes extends BaseEntity {
  id: string;
  created: string;
  last_updated: string;
  name: string;
  slug: BodyTypeCode;
  description: string;
  seq_no: number;
  feature_flag: any;
}

export type RecipeInfo = {
  seq_no: number;
  slug: string;
  text: string;
};

export enum LifestyleCode {
  Active = 'active',
  Lazy = 'lazy',
  VeryActive = 'very-active',
}

export interface Lifestyles extends BaseEntity {
  name: string;
  slug: LifestyleCode;
  description: string;
  description_male: string;
  description_female: string;
}
