import { Box, ChevronLeftIcon, ChevronRightIcon, HStack, Stack, useTheme, Text } from 'native-base';
import { Calendar, DateData } from 'react-native-calendars';
import { MarkedDates } from 'react-native-calendars/src/types';

import {
  getEstimatedArrivalRange,
  getEstimatedArrivalRangeString,
  toIsoDate,
  toIsoDateFormat,
} from '@/utils';

export interface ReschedulingCalendarProps {
  selectedDate: Date;
  onDayPress?: (date: DateData) => void;
}

export const ReschedulingCalendar = ({ selectedDate, onDayPress }: ReschedulingCalendarProps) => {
  const { colors, fonts } = useTheme();
  const { deliverStartDay, deliverMiddleDay, deliverEndDay } =
    getEstimatedArrivalRange(selectedDate);

  const date = new Date();
  const minDate = new Date();
  const maxDate = new Date(date.setMonth(date.getMonth() + 3));

  // Add ship date to marked dates, as well as
  // the 3-5 day est. delivery date range.
  // Object format dictated by react-native-calendars

  const markedDates: MarkedDates = {
    [toIsoDate(selectedDate)]: {
      startingDay: true,
      endingDay: true,
      color: colors.sntBlue.primary,
      textColor: colors.black,
      customContainerStyle: {
        borderRadius: 0,
      },
    },
    [toIsoDate(deliverStartDay.toDate())]: {
      startingDay: true,
      textColor: colors.black,
      customContainerStyle: {
        borderTopWidth: 2,
        borderBottomWidth: 2,
        borderRightWidth: 2,
        borderLeftWidth: 2,
        borderRadius: 0,
        borderColor: colors.sntBlue.primary,
      },
    },
    [toIsoDate(deliverMiddleDay.toDate())]: {
      textColor: colors.black,
      customContainerStyle: {
        borderTopWidth: 2,
        borderBottomWidth: 2,
        borderRightWidth: 2,
        borderLeftWidth: 2,
        borderRadius: 0,
        borderColor: colors.sntBlue.primary,
      },
    },
    [toIsoDate(deliverEndDay.toDate())]: {
      endingDay: true,
      textColor: colors.black,
      customContainerStyle: {
        borderTopWidth: 2,
        borderBottomWidth: 2,
        borderRightWidth: 2,
        borderLeftWidth: 2,
        borderRadius: 0,
        borderColor: colors.sntBlue.primary,
      },
    },
  };

  return (
    <Stack space={{ base: 4, md: 3 }}>
      <Calendar
        allowSelectionOutOfRange={false}
        onDayPress={onDayPress}
        initialDate={selectedDate.toISOString().split('T')[0]}
        minDate={toIsoDate(minDate)}
        maxDate={toIsoDate(maxDate)}
        markingType="period"
        markedDates={markedDates}
        renderArrow={(direction: 'right' | 'left') =>
          direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />
        }
        theme={{
          textDayFontWeight: '500',
          textDayFontFamily: fonts.primary,
          textMonthFontWeight: 'bold',
          textMonthFontFamily: fonts.primary,
          textDayHeaderFontWeight: 'bold',
          textDayHeaderFontSize: 14,
          textDayStyle: { color: 'black' },
          dayTextColor: 'black',
          textSectionTitleDisabledColor: colors.sntGrey.primary,
          textDisabledColor: colors.sntGrey.primary,
        }}
      />
      <Stack space={3}>
        <HStack space={2} alignItems="center">
          <Box
            bg="sntBlue.primary"
            w={{ base: '12px', lg: '16px' }}
            h={{ base: '12px', lg: '16px' }}
          />
          <Text fontWeight="medium" size="bodySmToMd">{`Charge date ${toIsoDateFormat(
            selectedDate
          )}`}</Text>
        </HStack>
        <HStack space={2} alignItems="center">
          <Box
            borderWidth="2px"
            borderColor="sntBlue.primary"
            w={{ base: '12px', lg: '16px' }}
            h={{ base: '12px', lg: '16px' }}
          />
          <Text
            fontWeight="medium"
            size="bodySmToMd"
          >{`Delivery date ${getEstimatedArrivalRangeString(toIsoDate(selectedDate))}`}</Text>
        </HStack>
      </Stack>
    </Stack>
  );
};
