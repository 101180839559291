import { IBoxProps, ITooltipProps, Tooltip as NativeBaseTooltip } from 'native-base';

interface TooltipProps extends Omit<ITooltipProps, 'label'> {
  label: IBoxProps['children'];
}

export const Tooltip = (props: TooltipProps) => {
  return (
    <NativeBaseTooltip openDelay={200} maxW="340px" bg="sntBlue.primary25" hasArrow {...props} />
  );
};
