import { HStack, Skeleton } from 'native-base';

export const PillMenuSkeleton = ({ numPills }: { numPills: number }) => {
  return (
    <HStack alignItems="center">
      {[...Array(numPills)].map((_, i) => (
        <HStack key={i} alignItems="center">
          <Skeleton
            w={{ base: '168px', lg: '168px' }}
            h={{ base: '72px', lg: '72px' }}
            borderRadius="25px"
          />
          {i < numPills - 1 ? <Skeleton h="2px" w={{ base: '8px', lg: '16px' }} /> : null}
        </HStack>
      ))}
    </HStack>
  );
};
