import dayjs from 'dayjs';

const getAgeDiffs = (birth_year: number | string | undefined, birth_month?: number | string) => {
  const ageInMonths = dayjs().diff(`${birth_year}-${birth_month || 1}-1`, 'month');

  return {
    yearDiff: Math.floor(ageInMonths / 12),
    monthDiff: ageInMonths % 12,
  };
};

export const isPup = (birth_year: number | string | undefined, birth_month?: number | string) => {
  if (!birth_year) return null;

  const { yearDiff, monthDiff } = getAgeDiffs(birth_year, birth_month);

  if (yearDiff * 12 + monthDiff >= 12) {
    return false;
  }

  return true;
};

/**
 * Formats pet age as a human readable string.
 * @param birthYear Birth year as a string or integer
 * @param birthMonth Birth month as a string or integer
 * @param isPredicate
 *
 * Changes the format based on if the return value is used after a verb, e.g.:
 *
 * `Your dog is ${dogAge(y, m)} years old`
 *
 * `This meal is perfect for a ${dogAge(y, m, false)} old dog`
 */
export const dogAge = (
  birthYear?: number | string,
  birthMonth?: number | string,
  isPredicate = true
) => {
  const dogIsPup = isPup(birthYear, birthMonth);
  if (dogIsPup === null) {
    return null;
  }

  const { yearDiff, monthDiff } = getAgeDiffs(birthYear, birthMonth);
  const monthDisplay = monthDiff > 0 ? monthDiff : 1;

  if (!dogIsPup) {
    return `${Math.floor(yearDiff)} year${isPredicate && yearDiff > 1 ? 's' : ''}`;
  }
  if (birthMonth) {
    return `${monthDisplay} month${isPredicate && monthDiff > 1 ? 's' : ''}`;
  }
  return isPredicate ? 'Less than 1 year' : 'less than 1 year old';
};
