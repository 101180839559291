import { default as dayjs } from 'dayjs';

export const formatDate = (date: number) => dayjs(date).format('MMMM D, YYYY h:mm A');

export const dateFormat = (
  date: Date | string,
  month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined
) => {
  const theDate = typeof date === 'string' ? new Date(date) : date;
  return theDate?.toLocaleDateString('en-us', { month, day: 'numeric' });
};

export const orderScheduled = (orderDate: string) => {
  return dayjs(orderDate).format('MMMM D');
};

export const dateToTime = (date: Date | string) => {
  const theDate = typeof date === 'string' ? new Date(date) : date;
  return theDate?.toLocaleTimeString('en-us', { hour: 'numeric' });
};

export const dateRangeFormat = (
  date1: Date,
  date2: Date,
  month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined
) => {
  return date1.getMonth() === date2.getMonth()
    ? `${date1.toLocaleDateString('en-us', {
        month,
      })} ${date1.getDate()}-${date2.getDate()}`
    : `${date1.toLocaleDateString('en-us', {
        month,
        day: 'numeric',
      })}-${date2.toLocaleDateString('en-us', { month, day: 'numeric' })}`;
};

export const toIsoDate = (date: Date) => date.toISOString().split('T')[0];

export const toIsoDateFormat = (date: Date, format?: string) => {
  if (format) {
    return dayjs(toIsoDate(date)).format(format);
  }
  return dayjs(toIsoDate(date)).format('MMM D');
};
