import { HStack, Skeleton, Stack } from 'native-base';

export const SwapToTopperSkeleton = () => {
  return (
    <Stack w="100%" alignItems="center">
      <Stack alignItems="center" w="100%" px={4} maxW={{ lg: '672px' }} space={{ base: 4, md: 6 }}>
        <Skeleton.Text lines={1} w="100%" />
        <Skeleton.Text lines={1} w="80%" />
        <HStack space={{ base: 4, md: 12 }}>
          <Skeleton w={{ base: '140px', md: '193px' }} h={{ base: '216px', md: '281px' }} />
          <Skeleton w={{ base: '140px', md: '193px' }} h={{ base: '216px', md: '281px' }} />
        </HStack>
        <Skeleton.Text lines={1} w="80%" />
        <HStack space={{ base: 2, md: 6 }}>
          {[...Array(3)].map((_, i) => {
            return (
              <Stack key={i} alignItems="center" space={2}>
                <Skeleton w={{ base: '82px', md: '208px' }} h={{ base: '82px', md: '208px' }} />
                <Skeleton.Text lines={1} w="80%" /> <Skeleton.Text lines={1} w="80%" />{' '}
                <Skeleton.Text lines={1} w="80%" />{' '}
              </Stack>
            );
          })}
        </HStack>
      </Stack>
    </Stack>
  );
};
