import { Stack, HStack, Heading, Divider, Text } from 'native-base';

import { formatPrice } from '@/utils';

export interface AddNewDogPlanPreviewProduct {
  name: string;
  price: string;
  type: string;
  quantity: number;
}

export interface AddNewDogPlanFoodSectionProps {
  type: 'Meals' | 'Snacks' | 'Extras' | 'Supplements';
  products: AddNewDogPlanPreviewProduct[];
}

export const AddNewDogPlanFoodSection = ({ type, products }: AddNewDogPlanFoodSectionProps) => {
  return (
    <Stack space={{ base: 4, lg: 6 }}>
      <Stack space={{ base: 2, lg: 4 }} w="100%" justifyContent="center" alignItems="start">
        <HStack w="100%" justifyContent="space-between">
          <Heading size="bodyMdToLg" fontWeight="bold">
            {type}
          </Heading>
        </HStack>
        {products.map((product, i) => {
          return (
            <Stack w="100%" space={{ base: 2, lg: 4 }} key={i}>
              <HStack w="100%" justifyContent="space-between">
                <Text size="bodyMdToLg">
                  {type === 'Meals'
                    ? `${product.type === 'UNKIBBLE' ? 'UnKibble' : 'Fresh'}`
                    : `${product.quantity} x ${product.name}`}
                </Text>
                <Text size="bodyMdToLg" fontWeight={type === 'Extras' ? 'medium' : 'bold'}>
                  {formatPrice(
                    Number(product.price) * product.quantity,
                    type === 'Extras' ? 'free' : 'dash'
                  )}
                </Text>
              </HStack>
              {type === 'Meals' ? (
                <HStack w="100%" justifyContent="space-between">
                  <Text size="bodySmToMd">{product.name}</Text>
                </HStack>
              ) : null}
            </Stack>
          );
        })}
      </Stack>
      <Divider bg="gallery" />
    </Stack>
  );
};
