import { CarrierCode } from '@/api/types';
import { Image } from '@/components/Elements';
import { Fedex } from '@/components/Icons';
import { ASSETS_URL } from '@/constants';

export const CarrierLogo = ({ carrierCode, ...props }: any) => {
  switch (carrierCode) {
    case CarrierCode.FEDEX:
      return <Fedex color="black" alt="FedEx logo" size={{ base: 4, lg: 5 }} {...props} />;
    case CarrierCode.UPS:
      return (
        <Image
          source={{
            uri: `${ASSETS_URL}/pictos/ShippingStatus/ups-logo.svg`,
          }}
          size={{ base: 4, lg: 5 }}
          alt="UPS logo"
          {...props}
        />
      );
    default:
      return (
        <Image
          source={{
            uri: `${ASSETS_URL}/pictos/Icons/shipping.png`,
          }}
          size={{ base: 4, lg: 5 }}
          alt=""
          {...props}
        />
      );
  }
};
