import { PaymentIntent, SetupIntent } from '@stripe/stripe-js';

import { Discount, Product } from './accountServices';
import { BuildPlan, ProductType } from './mealPlanServices';

import { Order } from '@/api/types';

export type QuestionnaireType = {
  id: string;
  created: string;
  last_updated: string;
  title: string;
  tags: null;
  template: any;
  context: string;
  default_for_context: false;
  start_timestamp: null;
  end_timestamp: string;
};

export interface PricePlan {
  id: string;
  created: string;
  last_updated: string;
  name: string;
}

export interface PrepareCheckout {
  stripe_payment_intent_id: string;
  stripe_payment_intent_secret: string;
  cart_preview: Cart;
  account_id: string;
}

export interface FinalizeResponse {
  reset_token: string;
  reset_key: string;
  order: Order;
}

export interface Cart {
  reset_token: string;
  reset_key: string;
  stripe_payment_intent_id: string;
  stripe_payment_intent_secret: string;
  stripe_payment_intent_object: PaymentIntent['object'] | SetupIntent['object'];
  account_id: string;
  cart_preview: {
    promotions: Promotion[];
    prices: CheckoutPrice;
    plans: CheckoutPlan[];
  };
}

export interface Promotion {
  banner_copy: string;
  free_product_code: FreeProductCode;
  id: string;
  name: string;
  quantity: number;
  banner_type: BannerType;
}

export type PromotionBannerData = {
  banner_copy: string;
  banner_type: BannerType;
};

export interface FreeProductCode {
  code: string;
  id: string;
  name: string;
  type: ProductType;
}

export enum BannerType {
  ONLY_CHECKOUT_PAGE = 'ONLY_CHECKOUT_PAGE',
  NO_FUNNEL_UI = 'NO_FUNNEL_UI',
}

export interface CheckoutPrice {
  discounts: string[];
  pet_name: string;
  plan: {
    calories_per_day: number;
    plan_size: number;
    recipe_type: string;
    trial: object;
    subscription: object;
  };
  snacks: [];
  tax_amount: string;
  subtotal_to_be_charged: string;
  total_to_be_charged: string;
}

export interface CheckoutPlan {
  pet_name: string;
  plan: BuildPlan;
  snacks: CheckoutAddon[];
  supplements: CheckoutAddon[];
  discounts: Discount[];
}

export interface CheckoutAddon {
  product: Product;
  price: string;
  discounted_price: string;
  quantity: number;
}
