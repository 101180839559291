import { Badge, HStack, IBadgeProps, PresenceTransition } from 'native-base';
import { IPresenceTransitionProps } from 'native-base/lib/typescript/components/composites/Transitions/types';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';

import { RightArrow } from '@/components/Icons';

interface FloatingPillProps extends IHStackProps {
  showPill: boolean;
  content?: IBadgeProps['children'];
  badgeProps?: IBadgeProps;
  presenceTransitionProps?: IPresenceTransitionProps;
}

export const FloatingPill = ({
  showPill,
  content = 'Scroll for more items',
  badgeProps,
  presenceTransitionProps,
  ...props
}: FloatingPillProps) => {
  const fadeIn = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 200,
      },
    },
  };
  return (
    <HStack position="sticky" bottom={{ base: 4, md: 6 }} {...props}>
      <PresenceTransition visible={showPill} {...fadeIn} {...presenceTransitionProps}>
        <Badge
          variant="floatingPill"
          rightIcon={<RightArrow color="white" style={{ transform: [{ rotate: '90deg' }] }} />}
          {...badgeProps}
        >
          {content}
        </Badge>
      </PresenceTransition>
    </HStack>
  );
};
