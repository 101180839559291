import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Stack, Text } from 'native-base';

import { Address } from '@/api/types';
import { EditableCard, EditableCardProps } from '@/components/Elements';
import { ProtectedStackParamList } from '@/types';

interface ShippingAddressProps extends EditableCardProps {
  address?: Partial<Address>;
}

export const ShippingAddress = ({ address, ...props }: ShippingAddressProps) => {
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();

  return address ? (
    <EditableCard
      heading="Shipping"
      onPress={() => navigation.navigate('AddressList', { successRedirect: 'Orders' })}
      stackProps={{ direction: { base: 'row', lg: 'column' }, space: { base: '0px', lg: '16px' } }}
      h="fit-content"
      {...props}
    >
      <Stack
        alignItems={{ base: 'end', lg: 'start' }}
        justifyContent={{ base: 'space-between', lg: 'start' }}
        h="fit-content"
        space={{ base: '0px', lg: '8px' }}
      >
        <Text size="bodyMdToLg">{address.address1}</Text>
        {address.address2 ? <Text size="bodyMdToLg">{address.address2}</Text> : null}
        <Text size="bodyMdToLg">
          {address.city}, {address?.state ? address.state.toUpperCase() : ''} {address.zip}
        </Text>
      </Stack>
    </EditableCard>
  ) : null;
};
