import { Heading, Box, Pressable, HStack } from 'native-base';
import { useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import { ChevronDownIcon } from 'react-native-heroicons/solid';

export type AccordionItemContent = {
  heading: string;
  body: React.ReactNode | string;
  index: number;
};

type AccordionItemProps = {
  item: AccordionItemContent;
  getOpenItem?: () => number;
  updateOpenItem?: (i: number) => void;
  hideArrow?: boolean;
  isChild?: boolean;
  isLastItem?: boolean;
};

export const AccordionItem = ({
  getOpenItem,
  updateOpenItem,
  item,
  hideArrow,
  isChild,
  isLastItem,
}: AccordionItemProps) => {
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(0);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });

  const triggerAnimation = (toValue: number) => {
    Animated.timing(animatedController, {
      duration: 300,
      toValue, // Close = 0 || Open = 1
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: true,
    }).start();
  };

  const handlePress = () => {
    if (getOpenItem && getOpenItem() === item.index) {
      updateOpenItem?.(-1);
    } else {
      updateOpenItem?.(item.index);
    }
  };

  return (
    <Animated.View>
      <>
        {getOpenItem && getOpenItem() === item.index ? triggerAnimation(1) : triggerAnimation(0)}
        <Pressable
          w="100%"
          paddingTop={isChild ? 0 : { base: 4, lg: 6 }}
          paddingBottom={isChild ? 2 : 4}
          onPress={handlePress}
        >
          <HStack justifyContent="space-between" alignItems="center">
            <Heading size="bodySmToMd" fontWeight="bold" textAlign="left">
              {item.heading}
            </Heading>
            {!hideArrow && (
              <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
                <ChevronDownIcon size="19px" fill="black" />
              </Animated.View>
            )}
          </HStack>
        </Pressable>

        <Animated.View style={[{ overflow: 'hidden', height: bodyHeight }]}>
          <Box
            paddingBottom={
              isChild && isLastItem ? { base: 0 } : isChild ? { base: 4 } : { base: 2, lg: 4 }
            }
            onLayout={(event) => setBodySectionHeight(event.nativeEvent.layout.height)}
          >
            {item.body}
          </Box>
        </Animated.View>
      </>
    </Animated.View>
  );
};
