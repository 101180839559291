import { HStack, IconButton, Text } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';

import { Tooltip } from '../Tooltip/Tooltip';

import { Delete, MinusCircleOutline, PlusCircleOutline } from '@/components/Icons';

export interface StepperProps extends IHStackProps {
  controlledQuantity: number;
  onPressInc: () => void;
  onPressDec: () => void;
  disabledMinus?: boolean;
  disabledPlus?: boolean;
  plusTooltip?: string;
}

export const Stepper = ({
  controlledQuantity = 0,
  onPressInc,
  onPressDec,
  disabledMinus = false,
  disabledPlus = false,
  isDisabled,
  plusTooltip,
  ...props
}: StepperProps) => {
  const iconButtonSize = { base: '28px', lg: '32px' };
  const iconSize = { base: '20px', lg: '25px' };

  return (
    <HStack alignItems="center" {...props}>
      <HStack w="33%" justifyContent="right">
        <IconButton
          size={iconButtonSize}
          isDisabled={isDisabled || disabledMinus}
          _icon={{
            color: isDisabled || disabledMinus ? 'sntGrey.primary' : 'black',
          }}
          icon={
            controlledQuantity <= 1 ? (
              <Delete size={{ base: '18px', lg: '20px' }} />
            ) : (
              <MinusCircleOutline size={iconSize} />
            )
          }
          onPress={onPressDec}
        />
      </HStack>
      <HStack w="33%" justifyContent="center">
        <Text
          fontWeight="bold"
          size="bodyMdToLg"
          color={isDisabled ? 'sntGrey.primary' : undefined}
        >
          {controlledQuantity}
        </Text>
      </HStack>

      <Tooltip label={plusTooltip || ''} placement="top" isDisabled={!plusTooltip}>
        <HStack w="33%" justifyContent="flex-start">
          <IconButton
            size={iconButtonSize}
            isDisabled={isDisabled || disabledPlus}
            _icon={{
              color: isDisabled || disabledPlus ? 'sntGrey.primary' : 'black',
              size: iconSize,
            }}
            icon={<PlusCircleOutline />}
            onPress={onPressInc}
          />
        </HStack>
      </Tooltip>
    </HStack>
  );
};
