import { Intervention } from '../ChangePlanStatus/types';
import { usePetsNextOrder } from './usePetsNextOrder';

import { Pet, PetStatus, useIsEligibleForExclusiveDiscount } from '@/api';
import { useAccount, usePaymentError } from '@/hooks';

const eligibleForSwapToTopperIntervention = (pet: Pet) => {
  return !pet.pet_plan.is_topper && pet.pet_plan.plan_size !== 75;
};

const eligibleForAdjustPortionSizeUp = (pet: Pet) => {
  return pet.pet_plan?.plan_size < 2400;
};

const eligibleForAdjustPortionSizeDown = (pet: Pet) => {
  return pet.pet_plan?.plan_size > 75;
};

type InterventionEligibilityResponse = {
  interventions: Intervention[];
  isLoading: boolean;
};

export const useInterventionEligibility = (
  petId: string | undefined
): InterventionEligibilityResponse => {
  const account = useAccount();
  const paymentError = usePaymentError();
  const pet = account.pets.find((pet) => pet.status === PetStatus.ACTIVE && pet.id === petId);
  const { petsNextOrder } = usePetsNextOrder(pet?.id);
  const { data: isEligibleResponse, isLoading } = useIsEligibleForExclusiveDiscount(petId);

  // payment error should disqualify user from all interventions
  if (!pet || paymentError === 'error' || paymentError === 'expired') {
    return { interventions: [], isLoading: false };
  }

  const interventions = [Intervention.SpeakWithSpecialist, Intervention.SnoozePlan];
  if (eligibleForSwapToTopperIntervention(pet)) {
    interventions.push(Intervention.SwapToTopper);
  }
  if (isEligibleResponse?.WINBACK_10_PERCENT) {
    interventions.push(Intervention.TenPercentDiscount);
  }
  if (isEligibleResponse?.WINBACK_30_PERCENT) {
    interventions.push(Intervention.TryNewRecipe);
  }
  if (petsNextOrder) {
    interventions.push(Intervention.RushADelivery);
  }
  if (eligibleForAdjustPortionSizeUp(pet)) {
    interventions.push(Intervention.AdjustPortionSizeUp);
  }
  if (eligibleForAdjustPortionSizeDown(pet)) {
    interventions.push(Intervention.AdjustPortionSizeDown);
  }

  return { interventions, isLoading };
};
