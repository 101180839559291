import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="currentColor"
    {...props}
  >
    <Path d="M8.25 6.75h1.5v-1.5h-1.5v1.5ZM9 15c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6ZM9 1.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Zm-.75 11.25h1.5v-4.5h-1.5v4.5Z" />
  </Svg>
);
function SvgInfoCta({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgInfoCta;
