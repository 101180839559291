import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import { HStack, Stack, Text, VStack } from 'native-base';
import { useState } from 'react';
import { GestureResponderEvent } from 'react-native';

import { ProductBodyData } from '@/api';
import { Pet, RecipeFull } from '@/api/types';
import {
  PetPillButton,
  PopUpProps,
  PortalPopUp,
  ToastType,
  displayToast,
} from '@/components/Elements';
import { useAccount, useAuth } from '@/hooks';
import { ProtectedStackParamList } from '@/types';

type SelectDogProps = {
  recipe: RecipeFull;
  onConfirm: ({
    petPlanId,
    products,
  }: {
    petPlanId: string;
    products: ProductBodyData[];
  }) => Promise<any>;
  onSuccess?: () => void;
  isLoading: boolean;
  /**
   * Filters shown pets by these IDs. If undefined, show all active pets.
   */
  petIds?: string[];
  showQuantityByPet?: boolean;
} & Omit<PopUpProps, 'onConfirm'>;

export const DogSelectModal = ({
  recipe,
  onConfirm,
  onSuccess,
  isLoading,
  petIds,
  showQuantityByPet,
  ...props
}: SelectDogProps) => {
  const account = useAccount();
  let activePets = account.pets
    .filter((pet) => pet.status === 'ACTIVE')
    .sort((petA, petB) => petA.name.localeCompare(petB.name));
  const { refetchUser } = useAuth();
  if (petIds) {
    activePets = activePets.filter((pet) => petIds.includes(pet.id));
  }
  const navigation = useNavigation<StackNavigationProp<ProtectedStackParamList>>();
  const [selectedPets, setSelectedPets] = useState<
    { id: string; name: string; petPlanId: string }[]
  >([]);
  const togglePet = (e: GestureResponderEvent, petId: string) => {
    e.preventDefault();
    const pet = activePets.find((pet) => pet.id === petId);
    if (selectedPets.map((pet) => pet.id).includes(petId)) {
      setSelectedPets(selectedPets.filter((pet) => petId !== pet.id));
    } else if (pet) {
      setSelectedPets([...selectedPets, { id: petId, name: pet.name, petPlanId: pet.pet_plan.id }]);
    }
  };
  const getExistingQuantity = (petId: string, productCode: string) => {
    return (
      activePets
        .find((p) => p.id === petId)
        ?.pet_plan.products.find((product) => product.code === productCode)?.quantity || 0
    );
  };
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const handleConfirm = async () => {
    const snack = recipe.default_product;
    const successes = [];

    setIsRequestLoading(true);
    try {
      for (const pet of selectedPets) {
        const existingQuantity = getExistingQuantity(pet.id, snack.code);
        await onConfirm({
          petPlanId: pet.petPlanId,
          products: [{ code: snack.code, quantity: existingQuantity + 1, recurring: true }],
        });
        successes.push([pet.id, pet.name]);
      }
    } catch (err) {
      displayToast({
        message: `An error occurred while adding ${snack.name} to one or more of your plans.`,
        type: ToastType.Error,
      });
      console.error(err);
    }

    await refetchUser();
    onSuccess?.();
    setIsRequestLoading(false);

    for (const petInfo of successes) {
      const petId = petInfo[0];
      const petName = petInfo[1];
      displayToast({
        message: `${snack?.name} have been added to ${petName}'s plan.`,
        type: ToastType.Success,
        onPress: () => navigation.push('Dogs', { petId, scrollToTop: true }),
      });
    }
    setSelectedPets([]);
  };
  const quantityTextStyle = {
    fontFamily: 'secondary',
    size: 'captionToBodySm',
    color: 'sntGrey.primary',
    fontWeight: 'bold',
  };
  return (
    <PortalPopUp
      heading={`Which plans would you like to add ${recipe.name} to?`}
      size="xl"
      isConfirmButtonDisabled={selectedPets.length === 0 || isLoading || isRequestLoading}
      onConfirm={handleConfirm}
      childSpacerSize={0}
      useRNModal
      {...props}
    >
      <Stack
        px="20px"
        w="100%"
        space={{ base: '16px', lg: '24px' }}
        justifyContent="center"
        alignItems="center"
      >
        <VStack w="100%" space={4}>
          {activePets
            .reduce((rows: Pet[][], pet, index) => {
              if (index % 2 === 0) rows.push([]);
              rows[rows.length - 1].push(pet);
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <HStack key={`row-${rowIndex}`} justifyContent="center" space={4}>
                {row.map((pet: Pet) => {
                  const existingQuantity = getExistingQuantity(pet.id, recipe.default_product.code);
                  return (
                    <VStack key={pet.id} alignItems="center" flex={1}>
                      <PetPillButton
                        pet={pet}
                        selected={selectedPets.map((p) => p.id).includes(pet.id)}
                        onPress={(e) => togglePet(e, pet.id)}
                        isDisabled={isRequestLoading}
                      />
                      {showQuantityByPet && existingQuantity > 0 && (
                        <Text {...quantityTextStyle} textAlign="center" mt={2}>
                          {existingQuantity} already in plan
                        </Text>
                      )}
                    </VStack>
                  );
                })}
              </HStack>
            ))}
        </VStack>
      </Stack>
    </PortalPopUp>
  );
};
