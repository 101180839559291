import { useQuery } from 'react-query';

import { MerchandiseProduct } from '@/api/types';
import { axios } from '@/lib/axios';

export const getMerchProducts = ({ accountId }: { accountId?: string }) => {
  return axios
    .get<MerchandiseProduct[]>('/products/merchandise', {
      params: {
        account_id: accountId,
      },
    })
    .then((res) => res.data);
};

export const useMerchandiseProducts = (accountId?: string) => {
  return useQuery(['MerchProducts', accountId], {
    cacheTime: 0,
    queryFn: () => getMerchProducts({ accountId }),
  });
};
