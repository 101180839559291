import { datadogLogs } from '@datadog/browser-logs';
import { useMutation } from 'react-query';

import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

const { logger } = datadogLogs;

const assignUserTreatment = async ({
  accountId,
  feature_flag,
}: {
  accountId?: string;
  feature_flag: string;
}): Promise<boolean> =>
  axios
    .post(`/accounts/${accountId}/should_receive_treatment`, { feature_flag })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      logger.error('Error assigning user treatment: ', { accountId }, error);
      return false;
    });

export const useShouldReceiveTreatment = (feature_flag: string) => {
  const { user: account, refetchUser } = useAuth();
  return useMutation<boolean, Error>(
    () => assignUserTreatment({ accountId: account?.id, feature_flag }),
    {
      onSuccess: () => refetchUser(),
      retry: 3,
      retryDelay: 1000,
    }
  );
};
