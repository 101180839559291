/**
 * Defaults: https://github.com/GeekyAnts/NativeBase/blob/v3.4.19/src/theme/components/modal.ts
 */

export const ModalContent = {
  baseStyle: {
    borderRadius: 0,
    // This just widens the modal on mobile (the md+ width is constrainted by maxWidth)
    width: { base: '90%', md: '100%' },
  },
};
