import { NavigationProp, useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import { HStack, Skeleton, Stack, Text } from 'native-base';
import { useMemo } from 'react';

import { usePetsNextOrder, usePlanSizeSuggestion } from './hooks';

import { useUpdatePetPlan } from '@/api';
import { Image, PopUpProps, PortalPopUp, ToastType, displayToast } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useAccount } from '@/hooks';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';

enum PlanType {
  CURRENT = 'Current',
  NEW = 'New',
}

export enum SuggestionMode {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

interface AdjustPortionSizeModalProps extends Omit<PopUpProps, 'heading' | 'onPressX'> {
  petId: string;
  petName: string;
  onPressX: () => void;
  mode: SuggestionMode;
  cancellationReason?: string;
  cancellationReasonSecondary?: string;
  cancellationReasonComment?: string;
}

export const AdjustPortionSizeModal = ({
  petId,
  petName,
  onClose,
  onPressX,
  mode,
  cancellationReason,
  cancellationReasonSecondary,
  cancellationReasonComment,
  ...props
}: AdjustPortionSizeModalProps) => {
  const { nextOrderDate } = usePetsNextOrder(petId);
  const {
    pet,
    currentPlanPricePerWeek,
    newPlanSize,
    newPlanPricePerWeek,
    percentCalorieDifference,
    isLoadingPlanSizes,
  } = usePlanSizeSuggestion(petId, mode);
  const account = useAccount();
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();

  const PricePicto = ({
    planType,
    mode,
    price,
  }: {
    planType: PlanType;
    mode: SuggestionMode;
    price?: string | null;
  }) => {
    const isSmallerBowl =
      (planType === PlanType.CURRENT && mode === SuggestionMode.INCREASE) ||
      (planType === PlanType.NEW && mode === SuggestionMode.DECREASE);

    return (
      <Stack alignItems="center" space={2}>
        <Stack>
          <Image
            source={{
              uri: `${ASSETS_URL}/pictos/CancellationIntervention/${
                isSmallerBowl ? 'low' : 'full'
              }_bowl_illustration.png`,
            }}
            w={{ base: '115px', md: '165px' }}
            h={isSmallerBowl ? { base: '50px', md: '65px' } : { base: '65px', md: '85px' }}
            resizeMode="contain"
          />
          {planType === PlanType.NEW ? (
            <Text
              size="bodyMdToMl"
              fontWeight="bold"
              w="100%"
              textAlign="center"
              position="absolute"
              bottom={{ base: 2, md: 3 }}
            >
              {mode === SuggestionMode.INCREASE ? '+' : '-'} {percentCalorieDifference}% kcals
            </Text>
          ) : null}
        </Stack>
        <Stack alignItems="center" space={1} w="100%">
          <Text size="tinyToCaption" fontWeight="bold" color="sntGrey.primary">
            {planType}
          </Text>
          {price ? (
            <Text size="bodyMdToLg">
              <Text fontWeight="bold">${price}</Text>/wk
            </Text>
          ) : (
            <Skeleton.Text w="80%" lines={1} />
          )}
          {price && mode === SuggestionMode.DECREASE && isSmallerBowl && (
            <Image
              source={{
                uri: `${ASSETS_URL}/pictos/CancellationIntervention/circled_text_illustration.png`,
              }}
              alt="Illustrated blue circle"
              w={{ base: '112px', md: '118px' }}
              h={{ base: '32px', md: '34px' }}
              position="absolute"
              bottom={0}
              zIndex={-10}
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const handleAdjustPortionSize = async () => {
    if (!pet || !newPlanSize || !account) return;
    const originalPlanSize = pet.pet_plan?.plan_size;

    await updatePetPlan({
      petPlanId: pet.pet_plan?.id,
      data: {
        plan_size: newPlanSize,
      },
    });

    segment.trackEvent('Cancellation Flow - Adjust Daily Portion Size', {
      account_id: account.id,
      email: account.email,
      cancellation_reason: cancellationReason,
      cancellation_reason_secondary: cancellationReasonSecondary,
      cancellation_reason_comment: cancellationReasonComment,
      pet_id: pet.id,
      previous_plan_size: originalPlanSize,
      new_plan_size: newPlanSize,
    });

    onPressX();

    navigation.navigate('Dogs', { petId });
    displayToast({
      message: `${petName}'s meal plan has been updated.`,
      type: ToastType.Success,
    });
  };

  const planOptions = [
    { planType: PlanType.CURRENT, price: currentPlanPricePerWeek },
    { planType: PlanType.NEW, price: newPlanPricePerWeek },
  ];

  const nextOrderDateString = useMemo(() => {
    if (nextOrderDate) return ` on ${dayjs(nextOrderDate, 'YYYY-MM-DD').local().format('MMMM D')}`;
  }, [nextOrderDate]);

  const description = useMemo(() => {
    return `Is ${petName}'s meal size not quite right? If you want to feed ${
      mode === SuggestionMode.INCREASE ? 'more' : 'less'
    } of our food per meal, please adjust your meal size so that your dog gets the perfect portions, delivered right on time!`;
  }, [mode, petName]);

  const arrowImageSize =
    mode === SuggestionMode.INCREASE
      ? { w: { base: '125px', md: '190px' }, h: { base: '58px', md: '75px' } }
      : { w: { base: '145px', md: '225px' }, h: { base: '75px', md: '100px' } };

  return (
    <PortalPopUp
      heading="Adjust Daily Portion Size"
      confirmBtnText="SAVE CHANGES"
      backBtnText="CANCEL PLAN"
      description={description}
      onConfirm={handleAdjustPortionSize}
      onClose={onClose}
      onPressX={() => {
        if (isLoadingPlanSizes || isLoadingUpdatePetPlan) return;
        onPressX();
      }}
      modalContentProps={{ w: '100%', maxW: { md: '440px' } }}
      contentStackProps={{ px: 0 }}
      subHeadingProps={{ fontFamily: 'primary', color: 'black' }}
      isConfirmButtonLoading={isLoadingUpdatePetPlan}
      isConfirmButtonDisabled={isLoadingPlanSizes}
      isBackButtonDisabled={isLoadingUpdatePetPlan || isLoadingPlanSizes}
      {...props}
    >
      <Stack space={{ base: 4, md: 6 }}>
        <Stack alignItems="center">
          <Image
            source={{
              uri: `${ASSETS_URL}/pictos/CancellationIntervention/${
                mode === SuggestionMode.INCREASE ? 'more' : 'less'
              }_food_curly_arrow.png`,
            }}
            {...arrowImageSize}
            resizeMode="contain"
            style={{
              transform: [{ rotate: '-4deg' }, { translateY: 10 }, { translateX: -10 }],
            }}
          />
          <HStack justifyContent="center" alignItems="end" space={6}>
            {planOptions.map(({ planType, price }) => (
              <PricePicto key={planType} price={price} planType={planType} mode={mode} />
            ))}
          </HStack>
        </Stack>
        {nextOrderDateString ? (
          <Text
            size="bodySmToMd"
            color="sntGrey.primary"
            fontFamily="secondary"
          >{`Meal plan changes will be effective from ${petName}'s next order${nextOrderDateString}.`}</Text>
        ) : (
          <Skeleton.Text lines={2} />
        )}
      </Stack>
    </PortalPopUp>
  );
};
