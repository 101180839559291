import { AddNewDogStackParamList } from '@/components/Portal';

export enum AddNewDogScreenNames {
  DOG_NAME = 'DogName',
  GENDER = 'Gender',
  NEUTERED = 'Neutered',
  BREED = 'Breed',
  BIRTHDAY = 'Birthday',
  BODY_TYPE = 'BodyType',
  WEIGHT = 'Weight',
  LIFESTYLE = 'Lifestyle',
  HEALTH_CONDITIONS = 'HealthConditions',
  HEALTH_CONDITIONS_LIST = 'HealthConditionsList',
  FOOD_TYPES = 'FoodTypes',
  RECIPES = 'Recipes',
  CALCULATING = 'Calculating',
  MEAL_PLAN = 'MealPlan',
  ADJUST_PORTION_SIZE = 'AdjustPortionSize',
  SNACKS = 'Snacks',
  SUPPLEMENTS = 'Supplements',
  PLAN_SUMMARY = 'PlanSummary',
  RESCHEDULE_DELIVERY = 'RescheduleDelivery',
}

type AddNewDogScreenType = {
  nextStep?: keyof AddNewDogStackParamList;
  previousStep?: keyof AddNewDogStackParamList;
  name: string;
};

export const AddNewDogScreens: AddNewDogScreenType[] = [
  {
    name: AddNewDogScreenNames.DOG_NAME,
    nextStep: AddNewDogScreenNames.GENDER,
  },
  {
    name: AddNewDogScreenNames.GENDER,
    nextStep: AddNewDogScreenNames.NEUTERED,
    previousStep: AddNewDogScreenNames.DOG_NAME,
  },
  {
    name: AddNewDogScreenNames.NEUTERED,
    nextStep: AddNewDogScreenNames.BREED,
    previousStep: AddNewDogScreenNames.GENDER,
  },
  {
    name: AddNewDogScreenNames.BREED,
    nextStep: AddNewDogScreenNames.BIRTHDAY,
    previousStep: AddNewDogScreenNames.NEUTERED,
  },
  {
    name: AddNewDogScreenNames.BIRTHDAY,
    nextStep: AddNewDogScreenNames.BODY_TYPE,
    previousStep: AddNewDogScreenNames.BREED,
  },
  {
    name: AddNewDogScreenNames.BODY_TYPE,
    nextStep: AddNewDogScreenNames.WEIGHT,
    previousStep: AddNewDogScreenNames.BIRTHDAY,
  },
  {
    name: AddNewDogScreenNames.WEIGHT,
    nextStep: AddNewDogScreenNames.LIFESTYLE,
    previousStep: AddNewDogScreenNames.BODY_TYPE,
  },
  {
    name: AddNewDogScreenNames.LIFESTYLE,
    nextStep: AddNewDogScreenNames.HEALTH_CONDITIONS,
    previousStep: AddNewDogScreenNames.WEIGHT,
  },
  {
    name: AddNewDogScreenNames.HEALTH_CONDITIONS,
    nextStep: AddNewDogScreenNames.FOOD_TYPES,
    previousStep: AddNewDogScreenNames.LIFESTYLE,
  },
  {
    name: AddNewDogScreenNames.HEALTH_CONDITIONS_LIST,
    nextStep: AddNewDogScreenNames.FOOD_TYPES,
    previousStep: AddNewDogScreenNames.HEALTH_CONDITIONS,
  },
  {
    name: AddNewDogScreenNames.FOOD_TYPES,
    nextStep: AddNewDogScreenNames.RECIPES,
    previousStep: AddNewDogScreenNames.HEALTH_CONDITIONS,
  },
  {
    name: AddNewDogScreenNames.RECIPES,
    nextStep: AddNewDogScreenNames.CALCULATING,
    previousStep: AddNewDogScreenNames.FOOD_TYPES,
  },
  {
    name: AddNewDogScreenNames.CALCULATING,
    nextStep: AddNewDogScreenNames.MEAL_PLAN,
    // previousStep: null,
  },
  {
    name: AddNewDogScreenNames.MEAL_PLAN,
    nextStep: AddNewDogScreenNames.SNACKS,
    previousStep: AddNewDogScreenNames.RECIPES,
  },
  {
    name: AddNewDogScreenNames.ADJUST_PORTION_SIZE,
    nextStep: AddNewDogScreenNames.SNACKS,
    previousStep: AddNewDogScreenNames.MEAL_PLAN,
  },
  {
    name: AddNewDogScreenNames.SNACKS,
    nextStep: AddNewDogScreenNames.SUPPLEMENTS,
    previousStep: AddNewDogScreenNames.MEAL_PLAN,
  },
  {
    name: AddNewDogScreenNames.SUPPLEMENTS,
    nextStep: AddNewDogScreenNames.PLAN_SUMMARY,
    previousStep: AddNewDogScreenNames.SNACKS,
  },
  {
    name: AddNewDogScreenNames.PLAN_SUMMARY,
    // nextStep: null,
    previousStep: AddNewDogScreenNames.SUPPLEMENTS,
  },
  {
    name: AddNewDogScreenNames.RESCHEDULE_DELIVERY,
    // nextStep: null,
    previousStep: AddNewDogScreenNames.PLAN_SUMMARY,
  },
];
