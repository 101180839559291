import { axios } from '@/lib/axios';
import { FunnelSession } from '@/types';

export const clear_session_data = (id: string, first_name: string) => {
  // Separate method for clear session as we don't want to allow
  // setting of null values for any fields in update_session call
  const payload = {
    first_name,
  };
  return axios.patch<FunnelSession>(`/sessions/${id}/clear`, payload);
};
