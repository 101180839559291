import { HStack, VStack, Skeleton } from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

import { ButtonSkeleton, CarouselItemSkeleton } from '@/components/Elements';

interface AddOnCarouselSkeletonProps extends IVStackProps {
  numItems: number;
}

export const AddOnCarouselSkeleton = ({ numItems, ...props }: AddOnCarouselSkeletonProps) => {
  return (
    <VStack w="100%" space={{ base: '16px', lg: '24px' }} {...props}>
      <Skeleton.Text alignSelf="flex-start" lines={1} w="200px" />
      <HStack space={{ base: '16px', lg: '24px' }}>
        {[...Array(numItems)].map((_, i) => (
          <CarouselItemSkeleton key={i} />
        ))}
      </HStack>
      <ButtonSkeleton w={{ base: '220px', lg: '284px' }} />
    </VStack>
  );
};
