import axios from 'axios';
import { Text } from 'native-base';

export const RetryErrorToastMessage = (err: unknown) => {
  const isPartialError =
    axios.isAxiosError<string>(err) && !!err?.response?.data.startsWith('Some charges succeeded');

  return (
    <>
      Your payment retry {isPartialError && 'for one or more of your orders '}was unsuccessful.{' '}
      <Text fontWeight="bold" textDecorationLine="underline">
        Please add a new form of payment
      </Text>{' '}
      so we can start preparing your pup's order.
    </>
  );
};
