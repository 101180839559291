const baseStyle = {
  bg: 'white',
  borderRadius: 0,
  borderWidth: '1px',
  borderColor: 'sntGrey.outline',
  _stack: {
    _checked: {
      background: 'sntBlue.primary',
    },
  },
  _hover: {
    borderColor: 'black',
    borderWidth: '2px',
    p: '1px',
  },
  _checked: {
    borderColor: 'black',
    borderWidth: '2px',
    p: '1px',
    bg: 'sntBlue.primary',
    _hover: {
      bg: 'sntBlue.primary',
      borderColor: 'black',
    },
  },
  _invalid: {
    borderColor: 'error.default',
  },
  _pressed: {
    _web: {
      style: {
        outline: 'none',
      },
    },
  },
  _focusVisible: {
    _web: {
      style: {
        outline: 'none',
      },
    },
  },
  _disabled: {
    borderColor: 'sntGrey.primary',
  },
};

const defaultProps = {
  colorScheme: 'primary',
};

const variants = {
  noHoverBorder: {
    _hover: {
      borderWidth: '1px',
      borderColor: 'sntGrey.outline',
      p: '2px',
    },
    _checked: {
      _hover: {
        borderColor: 'black',
        borderWidth: '2px',
        p: '1px',
      },
    },
  },
  hoverBorder: {
    borderWidth: '1px',
    _checked: {
      borderColor: 'black',
      borderWidth: '1px',
      style: { boxShadow: '0 0 0 1px black' },
      bg: 'white',
    },
  },
  blackBorder: {
    borderColor: 'black',
    borderWidth: '2px',
    p: '1px',
    _icon: {
      color: 'black',
    },
  },
};

export const Checkbox = {
  baseStyle,
  variants,
  defaultProps,
};
