import { useState } from 'react';

import { PopUpProps, PortalPopUp } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { orderScheduled } from '@/utils';

export const useConfirmSetPrimaryCardModal = () => {
  const account = useAccount();
  const nextOrder = account.orders.upcoming[0];
  const [showModal, setShowModal] = useState(false);

  return {
    setShowModal,
    Modal: (props: PopUpProps) => (
      <PortalPopUp
        isOpen={showModal}
        size="xl"
        {...props}
        heading="Are you sure you want to change your primary payment method?"
        description={
          nextOrder
            ? `Payment method changes will be effective starting with your next order on ${orderScheduled(
                nextOrder.scheduled
              )}.`
            : ''
        }
      />
    ),
  };
};
