export interface SurveyResponse {
  survey_tag?: SurveyTag;
  primary_answer: string;
  secondary_answer?: string;
  customer_comments?: string;
  pet_plan_product?: string;
  product_code?: string;
  pet_plan?: string;
  pet_plan_product_created_at?: string;
  unit_price?: string;
}

export enum SurveyTag {
  POST_CHECKOUT = 'post-checkout',
  PET_PLAN_CANCEL = 'pet-plan-cancel',
  ACCOUNT_CANCEL = 'account-cancel',
  ADDON_CANCEL = 'addon-cancel',
}
