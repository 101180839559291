import { useIsFocused } from '@react-navigation/native';
import { Heading, Stack } from 'native-base';
import { useState, useEffect } from 'react';

import { BodyTypeCode, usePetFormOptions } from '@/api';
import { Card } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useFunnelErrorHandler } from '@/hooks';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { Dog, FunnelScreenProps } from '@/types';
import { getCurrentPet } from '@/utils/getCurrentPet';

export default function BodyType({ navigation }: FunnelScreenProps<'BodyType'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.BODY_TYPE);
  const { session, mutateUpdatePetAnswer } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();
  const [bodyType, setBodyType] = useState<Dog['body_type'] | undefined>();
  const { addAxiosPromise } = useAxiosStatusContext();

  const dog = getCurrentPet(session);
  const { data: petFormData } = usePetFormOptions();
  const bodyTypes = petFormData?.body_types;

  const contentList = bodyTypes
    ? bodyTypes.map((type) => {
        return {
          slug: type.slug,
          picto: `BodyType/${type.slug}.png`,
          pictoAlt: '',
          heading: type.name,
          content: type.description,
        };
      })
    : [];

  /**
   * When navigating back to a page, sets the currently viewed screen as "true".
   * used to get previously submitted data
   **/
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) return;

    setBodyType(dog?.body_type);
  }, [isFocused]);

  const onSubmit = (bodyType: BodyTypeCode) => {
    setBodyType(bodyType);
    try {
      addAxiosPromise(
        mutateUpdatePetAnswer({
          body_type: bodyType,
        })
      );
    } catch (error) {
      funnelErrorHandler(error, 'Update body type');
    }
    navigation.navigate(nextStep);
  };

  return (
    <FunnelWrapper containerProps={{ justifyContent: 'flex-start' }}>
      <Heading
        size="titleSmToMd"
        textAlign="center"
        fontWeight="bold"
        pt={{ base: '0px', md: '24px' }}
        mb={{ base: '36px', lg: '48px' }}
      >
        {`How would you describe ${dog?.name}'s body type?`}
      </Heading>
      <Stack
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="center"
        w="100%"
      >
        {contentList.map((content, key) => {
          const my = key === 1 ? { base: '16px', lg: '0' } : 0;
          const mx = key === 1 ? { base: '0', lg: '24px' } : 0;
          return (
            <Card
              key={key}
              h={{ base: 'auto', md: 'fit-content', lg: '100%' }}
              textData={{
                ...content,
                contentProps: {
                  color: 'black',
                  fontFamily: 'secondary',
                  fontWeight: 'medium',
                  size: 'bodySmToLg',
                },
              }}
              mx={mx}
              my={my}
              isPressed={bodyType === content.slug}
              onPress={() => onSubmit(content.slug)}
            />
          );
        })}
      </Stack>
    </FunnelWrapper>
  );
}
