import { usePaymentMethods } from '@/api';
import { useAccount } from '@/hooks';
import { cardIsExpired, cardIsExpiringSoon } from '@/utils';

export const useCardsExpiryStatus = () => {
  const account = useAccount();
  const { data: paymentMethods, isSuccess } = usePaymentMethods({ enabled: !!account });

  if (!account || !isSuccess)
    return { isPrimaryCardExpiringSoon: null, isPrimaryCardExpired: null };

  const expiredCards = paymentMethods?.filter(({ card }) => cardIsExpired(card));
  const expiringSoonCards = paymentMethods?.filter(({ card }) => cardIsExpiringSoon(card));
  const nonExpiringCards = paymentMethods?.filter(
    ({ card }) => !(cardIsExpired(card) || cardIsExpiringSoon(card))
  );
  const isPrimaryCardExpired = !!expiredCards?.find(({ is_default }) => is_default);
  const isPrimaryCardExpiringSoon = expiringSoonCards?.some(({ is_default }) => is_default);
  return {
    nonExpiringCards,
    expiringSoonCards,
    expiredCards,
    isPrimaryCardExpiringSoon,
    isPrimaryCardExpired,
  };
};
