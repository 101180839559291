import { VStack, HStack } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

export type GridProps = {
  data: IVStackProps['children'][][]; // in format [[col1 items], [col2 items], ...]
  rowProps?: IHStackProps;
  colProps?: IVStackProps | ((args: any) => IVStackProps);
} & IVStackProps;

export const Grid = ({ data, rowProps, colProps, ...props }: GridProps) => {
  return (
    <HStack flex={data.length} space="36px" {...props}>
      {data.map((col, i) => (
        <VStack key={i} flex={1} space="24px" {...colProps}>
          {col.map((row, i) => (
            <HStack key={i} flex={1} alignItems="flex-start" {...rowProps}>
              {row}
            </HStack>
          ))}
        </VStack>
      ))}
    </HStack>
  );
};
