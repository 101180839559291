import { useMemo } from 'react';

import { AddNewDogStackParamList } from '@/components/Portal';
import { AddNewDogScreenNames, AddNewDogScreens } from '@/constants';

export const useNextAddNewDogStep = (screenName: AddNewDogScreenNames) => {
  const screenConfig = useMemo(
    () => AddNewDogScreens.find((screen) => screen.name === screenName),
    []
  );

  return screenConfig?.nextStep as keyof AddNewDogStackParamList;
};
