import { useMutation } from 'react-query';

import { DiscountExclusiveType } from '@/api/types/accountServices';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

type ExclusiveDiscountResponse = {
  discount_code: string;
  discount_id: string;
};

const postCreateDiscount = async ({
  accountId,
  petId,
  discountType,
}: {
  accountId?: string;
  petId?: string;
  discountType: DiscountExclusiveType;
}) =>
  axios
    .post(`/accounts/${accountId}/create_exclusive_discount`, {
      discount_type: discountType,
      pet_id: petId,
    })
    .then((response) => response.data);

export const useCreateExclusiveDiscount = (discountType: DiscountExclusiveType, petId?: string) => {
  const { user: account, refetchUser } = useAuth();
  return useMutation<ExclusiveDiscountResponse, Error>(
    () => postCreateDiscount({ accountId: account?.id, discountType, petId }),
    {
      onSuccess: () => refetchUser(),
    }
  );
};
