import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';

const postChargeHoldOrders = () =>
  axios
    .post(`orders/charge_on_hold`, undefined, { noErrorToast: true })
    .then((response) => response.data);

export const useChargeHoldOrders = () => {
  return useMutation(postChargeHoldOrders);
};
