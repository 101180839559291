import { axios } from '@/lib/axios';

// do this to apply a discount to a session
export const apply_discounts = async (id: string, discount_codes: string[]) =>
  await axios.post(
    `/sessions/${id}/apply_discounts`,
    {
      discount_codes,
    },
    { noErrorToast: true }
  );
