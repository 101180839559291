import { FunnelStackParamList } from '@/types';

export enum FunnelScreenNames {
  NAME_AND_EMAIL = 'NameAndEmail',
  HOW_MANY_DOGS = 'HowManyDogs',
  DOG_NAME = 'DogName',
  LOCATION = 'Location',
  GENDER = 'Gender',
  NEUTERED = 'Neutered',
  BREED = 'Breed',
  BIRTHDAY = 'Birthday',
  BODY_TYPE = 'BodyType',
  WEIGHT = 'Weight',
  LIFESTYLE = 'Lifestyle',
  HEALTH_CONDITIONS = 'HealthConditions',
  HEALTH_CONDITIONS_LIST = 'HealthConditionsList',
  FOOD_TYPES = 'FoodTypes',
  RECIPES = 'Recipes',
  CALCULATING = 'Calculating',
  MEAL_PLAN = 'MealPlan',
  SNACKS = 'Snacks',
  SUPPLEMENTS = 'Supplements',
  CHECKOUT = 'Checkout',
  PROCESSING_ORDER = 'ProcessingOrder',
  POST_CHECKOUT = 'PostCheckout',
  PORTAL = 'Portal',
  WELCOME_SCREEN = 'WelcomeScreen',
}

type FunnelScreenType = {
  nextStep?: keyof FunnelStackParamList;
  previousStep?: keyof FunnelStackParamList;
  chargeDate?: string;
  name: string;
};

export const FunnelScreens: FunnelScreenType[] = [
  {
    name: FunnelScreenNames.NAME_AND_EMAIL,
    nextStep: FunnelScreenNames.HOW_MANY_DOGS,
    // previousStep: null,
  },
  {
    name: FunnelScreenNames.HOW_MANY_DOGS,
    nextStep: FunnelScreenNames.DOG_NAME,
    previousStep: FunnelScreenNames.NAME_AND_EMAIL,
  },
  {
    name: FunnelScreenNames.DOG_NAME,
    nextStep: FunnelScreenNames.LOCATION,
    previousStep: FunnelScreenNames.HOW_MANY_DOGS,
  },
  {
    name: FunnelScreenNames.LOCATION,
    nextStep: FunnelScreenNames.GENDER,
    previousStep: FunnelScreenNames.DOG_NAME,
  },
  {
    name: FunnelScreenNames.GENDER,
    nextStep: FunnelScreenNames.NEUTERED,
    previousStep: FunnelScreenNames.LOCATION,
  },
  {
    name: FunnelScreenNames.NEUTERED,
    nextStep: FunnelScreenNames.BREED,
    previousStep: FunnelScreenNames.GENDER,
  },
  {
    name: FunnelScreenNames.BREED,
    nextStep: FunnelScreenNames.BIRTHDAY,
    previousStep: FunnelScreenNames.NEUTERED,
  },
  {
    name: FunnelScreenNames.BIRTHDAY,
    nextStep: FunnelScreenNames.BODY_TYPE,
    previousStep: FunnelScreenNames.BREED,
  },
  {
    name: FunnelScreenNames.BODY_TYPE,
    nextStep: FunnelScreenNames.WEIGHT,
    previousStep: FunnelScreenNames.BIRTHDAY,
  },
  {
    name: FunnelScreenNames.WEIGHT,
    nextStep: FunnelScreenNames.LIFESTYLE,
    previousStep: FunnelScreenNames.BODY_TYPE,
  },
  {
    name: FunnelScreenNames.LIFESTYLE,
    nextStep: FunnelScreenNames.HEALTH_CONDITIONS,
    previousStep: FunnelScreenNames.WEIGHT,
  },
  {
    name: FunnelScreenNames.HEALTH_CONDITIONS,
    nextStep: FunnelScreenNames.FOOD_TYPES,
    previousStep: FunnelScreenNames.LIFESTYLE,
  },
  {
    name: FunnelScreenNames.HEALTH_CONDITIONS_LIST,
    nextStep: FunnelScreenNames.FOOD_TYPES,
    previousStep: FunnelScreenNames.HEALTH_CONDITIONS,
  },
  {
    name: FunnelScreenNames.FOOD_TYPES,
    nextStep: FunnelScreenNames.RECIPES,
    previousStep: FunnelScreenNames.HEALTH_CONDITIONS,
  },
  {
    name: FunnelScreenNames.RECIPES,
    nextStep: FunnelScreenNames.CALCULATING,
    previousStep: FunnelScreenNames.FOOD_TYPES,
  },
  {
    name: FunnelScreenNames.CALCULATING,
    nextStep: FunnelScreenNames.MEAL_PLAN,
    // previousStep: null,
  },
  {
    name: FunnelScreenNames.MEAL_PLAN,
    nextStep: FunnelScreenNames.SNACKS,
    previousStep: FunnelScreenNames.RECIPES,
  },
  {
    name: FunnelScreenNames.SNACKS,
    nextStep: FunnelScreenNames.SUPPLEMENTS,
    previousStep: FunnelScreenNames.MEAL_PLAN,
  },
  {
    name: FunnelScreenNames.SUPPLEMENTS,
    nextStep: FunnelScreenNames.CHECKOUT,
    previousStep: FunnelScreenNames.SNACKS,
  },
  {
    name: FunnelScreenNames.CHECKOUT,
    nextStep: FunnelScreenNames.PROCESSING_ORDER,
    previousStep: FunnelScreenNames.SUPPLEMENTS,
  },
  {
    name: FunnelScreenNames.PROCESSING_ORDER,
    // heading: null,
    nextStep: FunnelScreenNames.POST_CHECKOUT,
    // previousStep: null,
  },
  {
    name: FunnelScreenNames.POST_CHECKOUT,
    //  heading: null,
    // nextStep: FunnelScreenNames.PORTAL,
    //  previousStep: null
  },
  {
    name: FunnelScreenNames.WELCOME_SCREEN,
  },
];
