import { useQuery } from 'react-query';

import { MealPlans, Pet } from '@/api/types';
import { axios } from '@/lib/axios';

type Discounts = string[] | null;

export const getPlansByPet = async (
  pet: Pet,
  discounts: Discounts,
  account_id?: string
): Promise<MealPlans> => {
  try {
    const {
      properties: { weight, body_type, neutered, lifestyle },
      birth_year,
      birth_day,
      birth_month,
    } = pet;
    const response = await axios.get<MealPlans>('/calculator/plan_options', {
      params: {
        weight,
        birth_year,
        birth_day,
        birth_month,
        body_type,
        neutered,
        lifestyle,
        discounts: discounts?.join(','),
        account_id: account_id || undefined,
      },
    });
    return response.data;
  } catch (err: unknown) {
    throw new Error('Dog is not defined');
  }
};

export const usePlansByPet = (pet?: Pet, discounts: Discounts = null, account_id?: string) => {
  return useQuery<MealPlans>('Plans', () => getPlansByPet(pet as Pet, discounts, account_id), {
    enabled: !!pet,
  });
};
