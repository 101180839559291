import { useState } from 'react';

import {
  Pet,
  PetStatus,
  ProductType,
  SurveyTag,
  useCancelPet,
  useCreateSurveyResponse,
} from '@/api';
import { useAccount } from '@/hooks';

interface CancelSurveyData {
  pet: Pet;
  primaryAnswer: string;
  secondaryAnswer?: string;
  customerComment?: string;
  passedAway?: boolean;
}

/**
 * Provides an async function to create a survey response and cancel the pet's plan.
 * Also includes analytics for the user action.
 */
export const useAnswerSurveyAndCancelPlan = () => {
  const account = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: createSurveyResponse } = useCreateSurveyResponse();
  const { mutateAsync: cancelPet } = useCancelPet();

  const answerSurveyAndCancelPlan = async ({
    pet,
    primaryAnswer,
    secondaryAnswer,
    customerComment,
    passedAway,
  }: CancelSurveyData) => {
    setIsLoading(true);

    const mealPetPlanProduct = pet.pet_plan?.products?.find(
      (product) => product.product_type === ProductType.MEAL
    );

    await createSurveyResponse({
      accountId: account.id,
      data: {
        primary_answer: primaryAnswer,
        secondary_answer: secondaryAnswer,
        customer_comments: customerComment,
        survey_tag: SurveyTag.PET_PLAN_CANCEL,
        pet_plan_product: mealPetPlanProduct?.id,
        product_code: mealPetPlanProduct?.code,
        pet_plan_product_created_at: mealPetPlanProduct?.created,
        unit_price: mealPetPlanProduct?.unit_price,
      },
    });
    await cancelPet({
      petId: pet.id,
      petStatus: passedAway ? PetStatus.DECEASED_TO_NOT_LIST : undefined,
      primaryCancellationReason: primaryAnswer,
      secondaryCancellationReason: secondaryAnswer,
      cancellationReasonComments: customerComment,
    });
    setIsLoading(false);
  };

  return { isLoading, answerSurveyAndCancelPlan };
};
