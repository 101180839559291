import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';

interface MyGProps extends React.ComponentProps<G> {
  children: React.ReactNode;
}

const MyG = ({ ...props }: MyGProps) => {
  return <G {...props} />;
};
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 20 26"
    {...props}
  >
    <MyG stroke="#000" strokeWidth={2}>
      <Circle cx={10} cy={5.5} r={4.5} />
      <Path d="M18.5 21.5V25h-17v-3.5a8.5 8.5 0 0 1 17 0Z" />
    </MyG>
  </Svg>
);
function SvgAccount({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgAccount;
