import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';

import { Address } from '@/api/types/accountServices';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

const putAddress = (
  { addressId, data }: { addressId?: string; data: Partial<Address> },
  axiosConfig?: AxiosRequestConfig
) => axios.put(`/addresses/${addressId}`, data, axiosConfig).then((response) => response.data);

export const useUpdateAddress = (axiosConfig?: AxiosRequestConfig) => {
  const { refetchUser } = useAuth();

  return useMutation(
    ({ addressId, data }: { data: Partial<Address>; addressId: string }) =>
      putAddress({ data, addressId }, axiosConfig),
    {
      onSuccess: () => refetchUser(), // refresh with new addresses data
    }
  );
};
