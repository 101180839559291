import { useMutation, useQueryClient } from 'react-query';

import { axios } from '@/lib/axios';

const setDefaultPaymentMethod = (data: { paymentMethodId: string }) =>
  axios.post(`/payment_methods/set_default`, { payment_method_id: data.paymentMethodId });

export const useSetDefaultPaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation(setDefaultPaymentMethod, {
    onSuccess: () => {
      return queryClient.invalidateQueries('PaymentMethod');
    },
  });
};
