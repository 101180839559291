import {
  Appearance,
  loadStripe,
  StripeCardElementOptions,
  StripeElementsOptionsClientSecret,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { useTheme } from 'native-base';

import { STRIPE_PUBLISHABLE_KEY } from './config';

export const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const useStripeElementsOptionsBase = () => {
  const theme = useTheme();

  const appearance: Appearance = {
    theme: undefined,
    labels: 'floating',
    variables: {
      colorPrimary: theme.colors.sntGrey.primary,
      colorDanger: theme.colors.error.default,
      colorTextSecondary: theme.colors.sntGrey.primary,
      colorTextPlaceholder: theme.colors.sntGrey.primary,
      colorBackground: theme.colors.white,
      borderRadius: '0',
      spacingUnit: '4px',
      focusBoxShadow: 'transparent',
    },
    rules: {
      '.Input': {
        padding: '12px 14px',
        outline: `1px solid ${theme.colors.black}`,
      },
      '.Input:focus': {
        borderColor: theme.colors.sntBlue.primary,
        outline: `2px solid ${theme.colors.sntBlue.primary}`,
      },
      '.Input:hover': {
        outline: `2px solid ${theme.colors.black}`,
      },
      '.Input:hover:focus': {
        outline: `2px solid ${theme.colors.sntBlue.primary}`,
      },
      '.Label--resting': {
        color: theme.colors.sntGrey.primary,
        fontWeight: `${theme.fontWeights.medium}`,
      },
      '.Label--floating': {
        color: 'black',
        fontWeight: `${theme.fontWeights.medium}`,
      },
    },
  };

  return {
    appearance,
  } as StripeElementsOptionsClientSecret;
};

export const useStripeCardElementOptionsBase = () => {
  const theme = useTheme();

  const style: StripeElementStyle = {
    base: {
      '::placeholder': {
        color: theme.colors.sntGrey.primary,
        fontWeight: 'medium',
      },
    },
    invalid: {
      color: theme.colors.error.default,
    },
  };

  return {
    style,
  } as StripeCardElementOptions;
};
