import { Divider, Stack, IStackProps, Text } from 'native-base';
import { PlusIcon } from 'react-native-heroicons/outline';

import { Pill, PillFill, PillSize } from '../Pill';

export interface PillMenuItem<TValue> {
  label: string;
  value: TValue;
  inactive?: boolean;
}

export interface PillMenuProps<TValue> extends IStackProps {
  items: PillMenuItem<TValue>[];
  onPress: (value: TValue) => void;
  selectedItem: TValue;
  showAdd?: boolean;
  onAdd?: () => void;
}

export function PillMenu<TValue>({
  items,
  onPress,
  selectedItem,
  showAdd = false,
  onAdd = () => {},
  ...props
}: PillMenuProps<TValue>) {
  return (
    <Stack flexDir="row" justifyContent="center" alignItems="center" {...props}>
      {items.map(({ label, value, inactive }, i) => (
        <Stack key={i} flexDir="row" justifyContent="center" alignItems="center">
          <Pill
            isDisabled={inactive}
            onPress={() => onPress(value)}
            size={PillSize.Tall}
            fill={selectedItem !== value ? PillFill.Outline : undefined}
          >
            <Text
              fontSize={{ base: '16px', lg: '20px' }}
              fontWeight="bold"
              color={inactive && selectedItem !== value ? 'sntGrey.primary' : 'black'}
            >
              {label}
            </Text>
          </Pill>
          {i < items.length - 1 && <Divider w="8px" h="2px" backgroundColor="black" />}
        </Stack>
      ))}
      {showAdd ? (
        <>
          <Divider w="8px" backgroundColor="blueBayoux.primary" />
          <Stack flexDir="row" justifyContent="center" alignItems="center">
            <Pill onPress={() => onAdd()} size={PillSize.Tall}>
              <Text fontSize={{ base: '16px', lg: '20px' }} fontWeight="bold" color="black">
                <PlusIcon size="14px" strokeWidth={3} />
              </Text>
            </Pill>
          </Stack>
        </>
      ) : null}
    </Stack>
  );
}
