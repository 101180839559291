import { useMutation } from 'react-query';

import { SurveyResponse } from '@/api/types';
import { axios } from '@/lib/axios';

interface SessionSurveyResponseData {
  id: string;
  data: SurveyResponse;
}

export const useCreateSessionSurveyResponse = () => {
  return useMutation<SurveyResponse, Error, SessionSurveyResponseData>(
    ({ id, data }: SessionSurveyResponseData) =>
      axios.post(`sessions/${id}/add_survey_response/`, data).then((response) => response.data)
  );
};
