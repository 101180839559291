import {
  Stack,
  Pressable,
  Heading,
  IHeadingProps,
  IPressableProps,
  IStackProps,
} from 'native-base';

export type BannerProps = {
  onPress?: IPressableProps['onPress'];
  headingProps?: Omit<IHeadingProps, 'children'>;
  children?: IHeadingProps['children'];
} & Omit<IStackProps, 'children'>;

export const Banner = ({ onPress, headingProps, children, ...props }: BannerProps) => {
  return (
    <Stack
      bgColor="white"
      p={{ base: '12px 16px' }}
      position="relative"
      w="100%"
      overflow="hidden"
      flexDir="row"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Pressable onPress={onPress} w="100%">
        <Heading size={{ base: 'bodySm', lg: 'bodyMd' }} {...headingProps}>
          {children}
        </Heading>
      </Pressable>
    </Stack>
  );
};
