import { HStack, Text } from 'native-base';

export const BirthdayMonthPlaceholder = () => (
  <HStack space={1} alignItems="center">
    <Text size="bodyMd" fontWeight="medium" color="sntGrey.primary">
      Month
    </Text>
    <Text size="bodySmToMd" fontWeight="medium" color="sntGrey.primary">
      (optional)
    </Text>
  </HStack>
);
