import { useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { useQueryClient } from 'react-query';

import { useSetDefaultPaymentMethod } from '@/api';
import { Address } from '@/api/types';
import { useAccount } from '@/hooks';

/**
 * Hook providing an async method for creating a new Card payment method and
 * optionally setting the card as default.
 */
export const useCompleteCardSetup = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: modifyDefaultPaymentMethod } = useSetDefaultPaymentMethod();
  const stripe = useStripe();
  const account = useAccount();
  const primaryAddress = account.addresses.find((address) => address.is_primary) as Address;

  return {
    completeCardSetup: async ({
      isDefault,
      clientSecret,
      cardElement,
    }: {
      isDefault?: boolean;
      clientSecret: string;
      cardElement: StripeCardElement;
    }) => {
      if (!stripe) {
        return {};
      }
      const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${account.first_name} ${account.last_name}`,
            address: {
              city: primaryAddress.city,
              line1: primaryAddress.address1,
              line2: primaryAddress.address2,
              postal_code: primaryAddress.zip,
              state: primaryAddress.state,
              country: 'US',
            },
          },
        },
      });
      if (error) {
        return { error };
      } else if (setupIntent?.payment_method && typeof setupIntent?.payment_method === 'string') {
        if (isDefault) {
          const paymentMethodId = setupIntent?.payment_method;
          await modifyDefaultPaymentMethod({ paymentMethodId });
        }
      } else {
        return { error: { message: 'An unexpected error occurred.' } };
      }
      queryClient.invalidateQueries({ queryKey: 'PaymentMethod' });
      return { error: null };
    },
  };
};
