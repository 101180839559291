import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="currentColor"
    {...props}
  >
    <Path d="m29.3 17.425 2.665-2.995h-3.12l-1.085 1.24-1.125-1.24H20.7v-.8h2.805v-2.405h-7.56V15h-.025c-.48-.55-1.075-.74-1.77-.74-1.42 0-2.49.97-2.865 2.245-.9-2.97-4.87-2.88-6.095-.7v-1.21H2.45v-1.31h3V11.23H0v9.22h2.45v-3.875h2.445c-.075.285-.115.59-.115.91 0 3.655 5.13 4.57 6.51 1.185h-2.1c-.735 1.045-2.29.445-2.29-.73h4.275c.185 1.525 1.37 2.845 3.005 2.845.705 0 1.35-.345 1.745-.93h.025v.595h10.61l1.105-1.25 1.115 1.25H32zm-22.335-.83c.305-1.315 2.085-1.28 2.325 0zm7.67 2.445c-1.73 0-1.7-3.14 0-3.14 1.63 0 1.725 3.14 0 3.14zm8.39.955h-4.72V11.67h4.75v1.51H20.25v1.695h2.775v1.405H20.22v2.235h2.805zm-2.295-1.99v-1.22h2.805v-2.2l2.535 2.85-2.535 2.85v-2.28zm6.93.515-1.305 1.475H24.45l2.28-2.56-2.28-2.56h1.985l1.33 1.465 1.28-1.465h1.925l-2.27 2.55 2.3 2.57h-2.025z" />
  </Svg>
);
function SvgFedex({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgFedex;
