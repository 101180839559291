import {
  Divider,
  Heading,
  HStack,
  IImageProps,
  IPressableProps,
  Pressable,
  Stack,
  Text,
} from 'native-base';

import { Image } from '@/components/Elements';
import { ChevronDown } from '@/components/Icons';

type PlanLineItemProps = {
  imgSrc?: string;
  imgProps?: IImageProps;
  pressableProps?: IPressableProps;
  heading?: string;
  description?: string;
  secondaryDescription?: string;
  chevron?: boolean;
  divider?: boolean;
  disabled?: boolean;
};

export const PlanLineItem = ({
  imgSrc,
  imgProps,
  pressableProps,
  heading,
  description,
  secondaryDescription,
  chevron = true,
  divider = true,
  disabled,
}: PlanLineItemProps) => {
  return (
    <Pressable
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="start"
      w="100%"
      h="fit-content"
      borderWidth="0px"
      py="0px"
      px="0px"
      disabled={disabled}
      {...pressableProps}
    >
      <HStack justifyContent="space-between" w="100%">
        <HStack alignItems="center" space={{ base: '8px', lg: '16px' }} flex={1}>
          <Image
            source={{ uri: imgSrc }}
            size={{ base: '64px', lg: '84px' }}
            opacity={disabled ? 50 : undefined}
            {...imgProps}
          />
          <Stack flex={1}>
            <HStack alignItems="center">
              <Stack
                flex={1}
                justifyContent="space-between"
                alignItems="start"
                space={{ base: '8px', lg: '16px' }}
              >
                {heading ? (
                  <Heading
                    size="bodyMdToLg"
                    fontWeight="bold"
                    color={disabled ? 'sntGrey.primary' : undefined}
                  >
                    {heading}
                  </Heading>
                ) : null}
                {description ? (
                  <Text
                    size="bodyMdToLg"
                    fontWeight="medium"
                    color={disabled ? 'sntGrey.primary' : undefined}
                  >
                    {description}
                  </Text>
                ) : null}
                {secondaryDescription ? (
                  <Text size="bodySmToMg" color="sntGrey.primary">
                    {secondaryDescription}
                  </Text>
                ) : null}
              </Stack>
              {chevron ? (
                <ChevronDown
                  color={disabled ? 'sntGrey.primary' : 'black'}
                  size={{ base: '10px', lg: '12px' }}
                  style={{ transform: [{ rotate: '-90deg' }] }}
                />
              ) : null}
            </HStack>

            {divider ? <Divider mt={4} bg="gallery" /> : null}
          </Stack>
        </HStack>
      </HStack>
    </Pressable>
  );
};
