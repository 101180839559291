import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect } from '@react-navigation/native';
import { Box, Heading, Skeleton, Stack } from 'native-base';
import { useCallback, useMemo, useState } from 'react';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import {
  PetGender,
  RecipeType,
  useCallBuildPlansRegularPlan,
  useCallBuildTopperPlan,
  usePlans,
} from '@/api';
import { Button, displayToast, FullTopperCard, Image, ToastType } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';
import { PlanType } from '@/types';
import { getRecipesProductImageSrc } from '@/utils';

const { logger } = datadogLogs;
export const AddNewDogMealPlan = ({ navigation }: AddNewDogScreenProps<'MealPlan'>) => {
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.MEAL_PLAN);
  const account = useAccount();
  const { petData, discountCode, savePetData, savePetPlan } = useAddNewDogContext();

  const { data: planData } = usePlans({
    pet: petData,
    accountId: account.id,
  });
  const { mutateAsync: callBuildPlansRegularPlan, data: regularPlanData } =
    useCallBuildPlansRegularPlan();

  const { mutateAsync: callBuildPlansTopperPlan, data: topperPlanData } = useCallBuildTopperPlan();
  const [planType, setPlanType] = useState<PlanType>('full');
  const [isLoading, setIsLoading] = useState(false);

  const isUnKibble = petData.food_type === RecipeType.UNKIBBLE;
  const planTypeText = petData.food_type === RecipeType.UNKIBBLE ? 'UnKibble' : 'Fresh';
  const pronoun = petData.gender === PetGender.FEMALE ? 'her' : 'his';

  const data = useMemo(
    () => (planType === 'full' ? regularPlanData : topperPlanData),
    [planType, regularPlanData, topperPlanData]
  );

  useFocusEffect(
    useCallback(() => {
      const buildRegularAndTopperPlans = async () => {
        Promise.all([
          callBuildPlansRegularPlan({
            account_id: account.id,
            plan_size: planData?.plan_size.id as number,
            recipes: petData.recipes,
            discounts: discountCode ? [discountCode] : [],
          }),
          callBuildPlansTopperPlan({
            account_id: account.id,
            plan_size: planData?.topper_plan_size.id as number,
            recipes: petData.recipes,
            discounts: discountCode ? [discountCode] : [],
          }),
        ]);
      };

      if (planData) {
        buildRegularAndTopperPlans();
      }
    }, [
      account.id,
      callBuildPlansRegularPlan,
      callBuildPlansTopperPlan,
      discountCode,
      petData.recipes,
      planData,
    ])
  );

  const onSubmit = async () => {
    if (!data) return;

    try {
      setIsLoading(true);

      savePetData({
        plan_size: data?.plan_size,
        plan_type: planType,
      });
      savePetPlan(data);
      navigation.navigate(nextStep);
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's meal plan`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's meal plan, account_id=${account.id}`, { error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AddNewDogWrapper
      h="auto"
      containerProps={{
        pb: { base: '24px', md: isUnKibble ? '160px' : '88px' },
        justifyContent: 'flex-start',
        space: { base: '36px', md: '48px' },
      }}
    >
      {/* Shared */}
      <Stack justifyContent="center" alignItems="center">
        <Heading fontWeight="bold" size="titleSmToMd" mb="16px" textAlign="center">
          Here&apos;s {petData.name}&apos;s Plan!
        </Heading>
        <Heading
          fontWeight="medium"
          size="bodySmToMd"
          fontFamily="secondary"
          textAlign="center"
          maxW={{ base: '100%', md: '960px' }}
        >
          For {petData.name}, we recommend {planData?.plan_size.id} calories of {planTypeText} per
          day.
        </Heading>
      </Stack>
      {/* UnKibble Plan Section */}
      {petData.recipes && isUnKibble && (
        <Image
          source={{
            uri: getRecipesProductImageSrc(
              petData.recipes.map((recipe) => ({ id: recipe, type: RecipeType.UNKIBBLE }))
            ),
          }}
          alt="recipe.name"
          width="300px"
          height="300px"
          resizeMode="contain"
        />
      )}
      {/* Fresh Plan Section */}
      {!isUnKibble && (
        <Stack
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
        >
          {regularPlanData && topperPlanData ? (
            <>
              <FullTopperCard
                planType="full"
                selected={planType === 'full'}
                dog={petData}
                planData={regularPlanData}
                isTrial={false}
                onPress={() => setPlanType('full')}
              />
              <Box w={{ base: '16px', lg: '36px' }} h={{ base: '24px', lg: '16px' }} />
              <FullTopperCard
                planType="topper"
                selected={planType === 'topper'}
                dog={petData}
                planData={topperPlanData}
                isTrial={false}
                onPress={() => setPlanType('topper')}
              />
            </>
          ) : (
            <Skeleton minW="100%" minH={{ base: '296px', md: '260px', lg: '376px' }} />
          )}
        </Stack>
      )}
      {/* Shared */}
      <Stack justifyContent="center" alignItems="center">
        <Heading
          fontWeight="medium"
          size="bodySmToMd"
          fontFamily="secondary"
          textAlign="center"
          maxW={{ base: '100%', md: '800px' }}
        >
          This recommendation is for {petData.name} to eat full meals of {planTypeText} to meet{' '}
          {pronoun} daily nutritional needs. But we offer complete flexibility, so if you'd like to
          feed larger or smaller meals (perhaps to mix into or supplement their existing diet),
          simply adjust the portion size.
        </Heading>
        <Button
          pt={4}
          onPress={() => {
            savePetData({
              calories_per_day: data?.calories_per_day,
              plan_size: data?.plan_size,
              plan_type: planType,
              trial_pricing_id: data?.trial.price.id,
              subscription_pricing_id: data?.subscription.price.id,
            });
            navigation.navigate(AddNewDogScreenNames.ADJUST_PORTION_SIZE);
          }}
          variant="underlineMini"
          size="bodySmToMd"
        >
          Update Portion Size
        </Button>
      </Stack>
      <Button
        variant="primary"
        size="bodyMdToLg"
        w="100%"
        maxW={{ base: '100%', md: isUnKibble ? '400px' : '290px' }}
        h={{ base: '52px', md: '56px' }}
        onPress={onSubmit}
        isLoading={isLoading}
      >
        {isUnKibble ? 'CONTINUE WITH RECOMMENDATION' : 'CONTINUE'}
      </Button>
    </AddNewDogWrapper>
  );
};
