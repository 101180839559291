import {
  Button,
  CloseIcon,
  Heading,
  IHeadingProps,
  IModalProps,
  IconButton,
  Modal,
  Stack,
  Text,
} from 'native-base';
interface IBaseModal extends Omit<IModalProps, 'children' | 'onExit'> {
  header?: IHeadingProps['children'];
  body?: React.ReactNode;
  cancelCta?: string;
  saveCta?: string;
  handleSavePress: () => void;
  handleCancelPress: () => void;
  onClose: () => void;
}

export const ConfirmationModalForBackButton = ({
  header = 'Want to save your changes?',
  body = 'Your changes will be lost if you don’t save them.',
  cancelCta = 'DON’T SAVE',
  saveCta = 'SAVE CHANGES',
  handleSavePress,
  handleCancelPress,
  onClose,
  ...props
}: IBaseModal) => (
  <Modal {...props}>
    <Modal.Content maxW="440px">
      <Stack w="100%" alignItems="flex-end" position="absolute" top={0} padding={5} zIndex={9999}>
        <IconButton size="24px" icon={<CloseIcon color="black" />} onPress={onClose} />
      </Stack>
      <Modal.Body p={{ base: '16px', md: '24px' }}>
        <Heading size="bodyMlToTitleSm" pb="16px" fontWeight="bold" textAlign="center">
          {header}
        </Heading>

        <Text size="bodySmToMd" fontWeight="medium" textAlign="center">
          {body}
        </Text>
      </Modal.Body>
      <Modal.Footer mx={{ base: '16px', md: '24px' }} py={{ base: '16px', md: '24px' }} px="0">
        <Stack flexDirection="row" justifyContent="space-between" w="100%">
          <Button
            variant="tertiary"
            size="bodyMlToLg"
            px={4}
            py={2}
            pl="2px"
            pr="2px"
            w="fit-content"
            onPress={handleCancelPress}
          >
            {cancelCta}
          </Button>
          <Button
            bg="blueBayoux.primary"
            size="bodyMlToLg"
            px={{ base: '16px', md: '36px' }}
            py={{ base: '12px', md: '14px' }}
            w="fit-content"
            onPress={handleSavePress}
          >
            {saveCta}
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);
