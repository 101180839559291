import { IImageProps, IStackProps, ITextProps, Stack } from 'native-base';

import { LabelledPicto, LabelProps } from '@/components/Elements';

export type Content = LabelProps & {
  name: any;
};

export type PictoGroupProps = {
  pictos: { src: string | undefined | null; caption: string | undefined | null }[];
  containerProps?: IStackProps;
  imageProps?: IImageProps;
  pictoStackProps?: IStackProps;
  captionProps?: ITextProps;
} & IStackProps;

export const PictoGroup = ({
  pictos,
  containerProps,
  imageProps,
  pictoStackProps,
  captionProps,
  ...props
}: PictoGroupProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="start"
      w="100%"
      {...containerProps}
      {...props}
    >
      {pictos.map((pic, index) => (
        <LabelledPicto
          key={(pic.src ? pic.src : '') + index}
          src={pic.src ? pic.src : ''}
          caption={pic.caption ? pic.caption : ''}
          imageProps={imageProps}
          captionProps={captionProps}
        />
      ))}
    </Stack>
  );
};

PictoGroup.displayName = 'PictoGroup';
