import React, { createContext, useContext, useState } from 'react';

interface AxiosStatusContextType {
  axiosPromises: Promise<any>[];
  addAxiosPromise: (_: Promise<any>) => void;
}

const AxiosStatusContext = createContext<AxiosStatusContextType>({
  axiosPromises: [] as Promise<any>[],
  addAxiosPromise: (_: Promise<any>) => {},
});

export const AxiosStatusProvider = ({ children }: { children: React.ReactNode }) => {
  const [axiosPromises, setAxiosPromises] = useState<Promise<any>[]>([]);

  const addAxiosPromise = (promise: Promise<any>) => {
    setAxiosPromises((prevPromises) => [...prevPromises, promise]);
  };

  return (
    <AxiosStatusContext.Provider value={{ axiosPromises, addAxiosPromise }}>
      {children}
    </AxiosStatusContext.Provider>
  );
};

export function useAxiosStatusContext() {
  return useContext(AxiosStatusContext);
}
