import { HStack, Link, VStack } from 'native-base';

import { Shipment } from '@/api/types';
import { CarrierLogo } from '@/components/Elements/CarrierLogo/CarrierLogo';
import { TRACKING_URL } from '@/config';

export const TrackingLinks = ({ shipments }: { shipments: Shipment[] }) => (
  <VStack>
    {shipments.map((shipment, i) => {
      return (
        <HStack space={{ base: 1, lg: 2 }} key={i} alignItems="center">
          <CarrierLogo carrierCode={shipment.carrier_code} />

          <Link
            href={`${TRACKING_URL}/${shipment.tracking_number}`}
            isExternal
            variant="inline"
            _text={{ fontWeight: 'bold', size: 'bodySmToMd', textDecorationLine: 'underline' }}
          >
            {shipment.tracking_number || 'Tracking Number Coming Soon'}
          </Link>
        </HStack>
      );
    })}
  </VStack>
);
