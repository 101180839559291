const baseStyle = {
  _light: {
    marginRight: 1, // space between icon and label
    borderColor: 'grey.dark',
    _checked: {
      borderColor: 'grey.dark',
      _icon: {
        color: 'grey.dark',
      },
      _pressed: {
        borderColor: 'grey.dark',
      },
      _hover: {
        borderColor: 'grey.dark',
        _icon: {
          color: 'grey.dark',
        },
      },
      _focusVisible: {
        _web: {
          style: {
            outline: 0,
          },
        },
      },
    },
  },
};

const sizes = {
  lg: {
    _icon: { size: 4 },
    _text: { fontSize: 'lg' },
  },
  md: {
    _icon: { size: 3 },
    _text: { fontSize: 'md' },
  },
  sm: {
    _icon: { size: 2 },
    _text: { fontSize: 'sm' },
  },
};

const defaultProps = {
  size: 'sm',
};

export const Radio = {
  baseStyle,
  sizes,
  defaultProps,
};
