import * as Linking from 'expo-linking';
import { Button, Divider, IconButton, Stack, Text } from 'native-base';
import { useEffect } from 'react';

import { Image } from '@/components/Elements';
import { LeftArrow } from '@/components/Icons';
import { ASSETS_URL } from '@/constants';
import { useAccount } from '@/hooks';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';

export const ChangePlanStatusWithDiscount = ({
  navigation,
  route,
}: ProtectedScreenProps<'ChangePlanStatus'>) => {
  const account = useAccount();
  const pet = account.pets.find((accountPet) => accountPet.id === route.params.petId);
  const nextOrderForPet = account.orders.upcoming.find((order) =>
    order.products.find((orderProduct) => orderProduct.pet_plan?.id === pet?.pet_plan.id)
  );

  const discountTotal = nextOrderForPet?.total_discounts;

  useEffect(() => {
    // this page has its own header
    navigation.setOptions({ headerShown: false });
  }, [navigation]);

  const onPressKeepMyDiscount = () => {
    segment.trackEvent('Cancellation Flow - Selected Keep My Discount', {
      account_id: account.id,
      email: account.email,
      pet_id: pet?.id,
    });
    navigation.navigate('Home');
  };

  const onPressCancel = () => {
    segment.trackEvent('Select Cancel Plan', {
      email: account.email,
      pet_name: pet?.name,
      dog_name: pet?.name,
      pet_id: pet?.id,
      pet_plan_id: pet?.pet_plan.id,
      account_id: account.id,
    });

    navigation.navigate('StopPlanSurvey', {
      petId: route.params.petId,
      pauseOrCancel: 'CANCEL',
    });
  };

  const onPressConnectToHuman = () => {
    segment.trackEvent('Cancellation Flow - Selected Contact CX', {
      account_id: account.id,
      email: account.email,
      pet_id: pet?.id,
      cancellation_reason: '',
      cancellation_reason_secondary: '',
      cancellation_reason_comment: '',
    });
    Linking.openURL(
      'https://spotandtango.com/faq?a=How-do-I-contact-customer-support---id--IKoHhdLiR3SYrMUbeS90EQ'
    );
  };

  return (
    <Stack alignItems="center" w="100%" pb={{ base: 6, lg: 8 }} pt={{ base: 2, lg: 4 }}>
      <Stack
        position="relative"
        justifyContent="center"
        alignItems="center"
        w="90%"
        mt={{ base: 2, lg: 4 }}
        mb={{ base: 2, lg: 12 }}
      >
        <IconButton
          position="absolute"
          left={0}
          onPress={() => navigation.navigate('Account')}
          _icon={{ color: 'black' }}
          icon={<LeftArrow size={{ base: '18px', lg: '24px' }} />}
        />
        <Text
          textAlign="center"
          size="bodyMlToTitleSm"
          fontWeight="bold"
          w="70%"
        >{`Wait, you're about to lose $${discountTotal} OFF your next order!`}</Text>
      </Stack>
      <Stack px={{ base: 4, lg: 6 }} alignItems="center" w="100%" maxW={{ lg: '699px' }}>
        <Text size="bodySmToMd" fontFamily="secondary" textAlign="center" fontWeight="medium">
          If you cancel your plan, you'll lose any special discounts you have applied. Keep your
          discount and continue giving your dog the best!
        </Text>
        <Image
          source={{ uri: `${ASSETS_URL}/pictos/Illustrations/DiscountDog.png` }}
          alt="Dog with dollar bills"
          w={{ base: '80%', lg: '320px' }}
          h={{ base: '341px', lg: '417px' }}
        />
        <Button
          variant="primary"
          mb={{ base: 4, lg: 6 }}
          onPress={onPressKeepMyDiscount}
          px={{ lg: '48px' }}
        >
          KEEP MY DISCOUNT
        </Button>
        <Button variant="underlineMini" onPress={onPressCancel} mb={{ base: 4, lg: 6 }}>
          Lose discount and continue to cancellation
        </Button>
        <Divider bgColor="gallery" mb={{ base: 4, lg: 6 }} w="100%" />
        <Stack flexDirection="row" mb={{ base: 4, lg: 6 }} w="100%" justifyContent="center">
          <Stack w={{ base: '100px', lg: '120px' }} h={{ base: '100px', lg: '120px' }}>
            <Image
              source={{ uri: `${ASSETS_URL}/pictos/Illustrations/HelpPhone.png` }}
              alt="Phone displaying help chat"
              w="100%"
              h="100%"
            />
          </Stack>
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            space={2}
            flex={{ base: 'auto', lg: undefined }}
          >
            <Text size="bodyMdToLg" fontWeight="bold">
              Questions? We're here to help
            </Text>
            <Button variant="underlineMini" onPress={onPressConnectToHuman}>
              Connect to a human
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
