import { useState } from 'react';

import { PopUpProps, PortalPopUp } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { orderScheduled } from '@/utils';

export const useConfirmSetPrimaryModal = () => {
  const account = useAccount();

  const nextOrder = account.orders.upcoming[0];
  const [showModal, setShowModal] = useState(false);

  return {
    setShowModal,
    Modal: (props: PopUpProps) => (
      <PortalPopUp
        isOpen={showModal}
        useRNModal
        size="xl"
        {...props}
        heading="Are you sure you want to change your primary shipping address?"
        description={
          nextOrder
            ? `Shipping address changes will be effective starting with your next order on ${orderScheduled(
                nextOrder.scheduled
              )}`
            : ''
        }
      />
    ),
  };
};
