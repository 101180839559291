import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Button, HStack, Stack, Text } from 'native-base';

import { OrderProduct, Pet } from '@/api';
import { OrderProductCarousel } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { ProtectedStackParamList } from '@/types';

export const PetProductSection = ({
  petId,
  orderProducts,
}: {
  petId: string;
  orderProducts: OrderProduct[];
}) => {
  const account = useAccount();
  const navigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  const petName = account?.pets
    .filter((pet: Pet) => pet.id === petId)
    .map((pet: Pet) => pet.name)[0];
  return (
    <Stack space={4}>
      <HStack justifyContent="space-between" alignItems="end">
        <Text size="bodyMdToLg" fontWeight="bold">
          {petName}
        </Text>
        <Button variant="underlineMini" onPress={() => navigation.navigate('Dogs', { petId })}>
          Edit
        </Button>
      </HStack>
      <OrderProductCarousel orderProducts={orderProducts} />
    </Stack>
  );
};
