import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 21 20"
    {...props}
  >
    <Path
      fill={undefined}
      d="M10.5 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm0-18a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm-5 11h10V9h-10"
    />
  </Svg>
);
function SvgMinusCircleOutline({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgMinusCircleOutline;
