import { Button, FormControl, Stack, Text } from 'native-base';
import { useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { InputFloatLabel, InputFloatLabelProps } from '../Elements/Input';

interface PasswordFloatLabelProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> {
  control: Control<TFieldValues, TName>;
  name: TName;
  placeholder: string;
  inputProps?: Partial<InputFloatLabelProps>;
  showErrors?: boolean;
}

export const PasswordInputFloatLabel = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  control,
  name,
  inputProps,
  placeholder,
  showErrors = true,
}: PasswordFloatLabelProps<TFieldValues, TName>) => {
  const [securedTextEntry, setSecuredTextEntry] = useState(false);
  return (
    <FormControl>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Stack space="8px">
              <InputFloatLabel
                variant={error ? 'error' : 'base'}
                onBlur={(e) => {
                  if (inputProps?.onBlur) {
                    inputProps.onBlur(e);
                  } else {
                    setSecuredTextEntry(false);
                  }
                }}
                onChangeText={(val) => {
                  onChange(val);
                }}
                onFocus={(e) => {
                  if (inputProps?.onFocus) {
                    inputProps.onFocus(e);
                  }
                }}
                value={value}
                secureTextEntry={!securedTextEntry}
                placeholder={placeholder}
                type="password"
                InputRightElement={
                  <Button
                    variant="inline"
                    w="fit-content"
                    p={0}
                    onPress={() => {
                      setSecuredTextEntry((secured) => !secured);
                    }}
                  >
                    {securedTextEntry ? 'HIDE' : 'SHOW'}
                  </Button>
                }
                {...inputProps}
              />
              {error && showErrors ? (
                <Text size="bodySmToMd" color="error.default">
                  {error.message}
                </Text>
              ) : null}
            </Stack>
          );
        }}
      />
    </FormControl>
  );
};
