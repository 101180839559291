import { Stack, Image } from 'native-base';
import { useState } from 'react';

import { Pet } from '@/api';
import { ASSETS_URL } from '@/constants';
const images = `${ASSETS_URL}/pictos/Avatars/`;
const noAvatarImages = `${ASSETS_URL}/pictos/Gender/`;

export const AvatarImageLarge = ({
  petId,
  petGender,
  grayscale = false,
}: {
  src: string;
  petId: string;
  petGender?: Pet['gender'];
  grayscale?: boolean;
}) => {
  const [isValidSrc, _setIsValidSrc] = useState(false);

  // NOTE: for now, don't try to load avatars (lots of 403s otherwise)
  // isValidImgSrc(`${images}${src}`).then((isValid) => {
  //   setIsValidSrc(isValid);
  // });

  const noAvatarImage = petGender === 'MALE' ? 'BoyDog.png' : 'GirlDog.png';
  const grayscaleProps = grayscale
    ? {
        tintColor: 'grey.secondary',
        opacity: 0.5,
        position: 'absolute' as 'absolute' | 'static' | 'relative' | 'fixed' | 'sticky',
      }
    : {};

  return (
    <Stack
      bg={isValidSrc ? 'transparent' : `sntBlue.primary50`}
      borderRadius={{ base: '16px', lg: '24px' }}
      overflow="hidden"
      w={{ base: '264px', lg: '302px' }}
      h={{ base: '264px', lg: '302px' }}
      justifyContent="center"
      alignItems="center"
    >
      <Image
        source={{ uri: isValidSrc ? `${images}${petId}` : `${noAvatarImages}${noAvatarImage}` }}
        alt="Pet profile image"
        width={isValidSrc ? { base: '264px', lg: '302px' } : { base: '130px', lg: '190px' }}
        height={isValidSrc ? { base: '264px', lg: '302px' } : { base: '136px', lg: '190px' }}
        borderRadius={{ base: '16px', lg: '24px' }}
        resizeMode="contain"
        mb={4}
        {...grayscaleProps}
      />
    </Stack>
  );
};
