import { datadogLogs } from '@datadog/browser-logs';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { Stack, Flex, Heading, Spacer, VStack, Box, HStack, Text } from 'native-base';
import { useState, useEffect, useCallback } from 'react';

import { RecipeSelectFormSkeleton } from '../../Home/ManageDogPlans/RecipeSelectFormSkeleton';
import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { PlanData, RecipeType, useRecipeOptions } from '@/api';
import { RecipeCard, PDPModal, Button, displayToast, ToastType } from '@/components/Elements';
import { AddNewDogScreenNames, RecipeCardBanners } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';
import { DEFAULT_FOOD_TYPE, configStylesForContainers } from '@/utils';

const { logger } = datadogLogs;

export const AddNewDogRecipes = ({ navigation }: AddNewDogScreenProps<'Recipes'>) => {
  const account = useAccount();
  const { petData, discountCode, savePetData } = useAddNewDogContext();
  const isFocused = useIsFocused();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.RECIPES);

  const [isLoading, setIsLoading] = useState(false);

  const foodType = petData.food_type || DEFAULT_FOOD_TYPE;

  const { stackH, vStackMaxW } = configStylesForContainers[foodType];

  const { data: planOptionsData, isLoading: isLoadingRecipes } = useRecipeOptions({
    pet: petData,
    accountId: account.id,
    discounts: discountCode ? [discountCode] : [],
  });
  const recipeOptionsList = foodType && planOptionsData?.plans[foodType];

  const [showPDPModal, setShowPDPModal] = useState(false);
  const [planDetails, setPlanDetails] = useState<PlanData>();
  const [maxRecipesAllowed, setMaxRecipesAllowed] = useState<number>(1);
  const [selectedPlanCodes, setSelectedPlanCodes] = useState<string[]>([]);
  const [recipeCountDescription, setRecipeCountDescription] = useState('');

  const selectedPlans = recipeOptionsList?.filter(({ subscription: { product } }) =>
    selectedPlanCodes.includes(product.code)
  );

  const perMealPrice =
    (planDetails?.price.discounted_price_per_meal
      ? planDetails?.price.discounted_price_per_meal
      : planDetails?.price.price_per_meal) ?? null;

  const perWeekPrice =
    (planDetails?.price.discounted_price_per_week
      ? planDetails?.price.discounted_price_per_week
      : planDetails?.price.price_per_week) ?? null;

  useEffect(() => {
    if (petData && planOptionsData) {
      setMaxRecipesAllowed(planOptionsData.maxRecipes[foodType]);
      setRecipeCountDescription(
        petData?.food_type === RecipeType.UNKIBBLE ? 'two UnKibble' : 'three'
      );
    }
  }, [foodType, petData, planOptionsData]);

  const onSubmit = async () => {
    if (!selectedPlans) {
      return;
    }

    const selectedRecipesIds = selectedPlans
      .map((sub) => sub.subscription.product.recipes.map((recipe) => recipe.id))
      .flat();

    try {
      setIsLoading(true);
      savePetData({ recipes: selectedRecipesIds });
      navigation.navigate(nextStep);
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's recipes`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's recipes, account_id=${account.id}`, { error });
    } finally {
      setIsLoading(false);
    }
  };
  const togglePlan = (code: string) => {
    const planCode = selectedPlanCodes.find((selectedPlanCode) => code === selectedPlanCode);

    if (planCode) {
      // deselect: plan already previously selected
      setSelectedPlanCodes(
        selectedPlanCodes.filter((selectedPlanCode) => selectedPlanCode !== code)
      );
    } else if (selectedPlanCodes.length < maxRecipesAllowed) {
      // select: plan not previously selected and still within max recipes limit
      setSelectedPlanCodes((previousPlanCodes) => [...previousPlanCodes, code]);
    }
  };

  useFocusEffect(
    useCallback(() => {
      // if we're returning to this page, select any recipes that were submitted
      if (petData?.recipes && petData.recipes.length > 0 && recipeOptionsList) {
        const previouslySelectedCodes = recipeOptionsList
          .map((recipeData) => {
            const code = recipeData.subscription.product.code;
            const petHasRecipe = !!petData.recipes?.find((recipe) => code.includes(recipe));
            return petHasRecipe ? code : undefined;
          })
          .filter(Boolean) as string[];
        setSelectedPlanCodes(previouslySelectedCodes);
      }
    }, [petData, recipeOptionsList])
  );

  if (!isFocused || !petData || !Object.keys(petData).length) {
    return null;
  }

  return (
    <AddNewDogWrapper
      maxW="100%"
      px={{ base: '0', md: '0px' }}
      h="100%"
      containerProps={{ px: 0, pt: 0, maxW: '100%' }}
    >
      <Stack h={stackH} overflow="scroll" w="100%" alignItems="center">
        <Flex justify="center" align="center" px={5}>
          <Heading
            fontWeight="bold"
            size="titleSmToMd"
            textAlign="center"
            w={{ base: '90%', md: '100%' }}
          >
            {maxRecipesAllowed > 1
              ? `Choose up to ${recipeCountDescription} recipes for ${petData?.name}`
              : `Choose ${petData?.name}'s favorite recipe`}
          </Heading>
          <Spacer size={{ base: 2, lg: 4 }} />

          <Text
            fontFamily="secondary"
            fontWeight="medium"
            size="bodySmToMd"
            textAlign="center"
            maxW={{ base: '100%', md: '960px' }}
          >
            {`We recommend any of the following recipes for ${petData.name}. You can choose one or mix and match for more variety!`}
          </Text>
        </Flex>
        <VStack
          flexDirection={{ base: 'column', lg: 'row' }}
          flexWrap="wrap"
          w={{ base: '100%', lg: 'auto' }}
          maxW={vStackMaxW}
          px={{ base: 5, md: 0 }}
          py="36px"
        >
          {!isLoadingRecipes ? (
            recipeOptionsList?.map((plan, key) => {
              const {
                subscription: { product, price },
              } = plan;
              const recipe = product.recipes[0];

              return (
                <Box key={key} alignItems="center">
                  <RecipeCard
                    recipe={recipe}
                    w={{ base: '100%', md: '484px', lg: '324px' }}
                    h={{ base: '142px', md: '175px', lg: 'inherit' }}
                    minH={{ base: '142px', md: '175px', lg: '378px' }}
                    key={key + recipe.id}
                    pricing={
                      price.discounted_price_per_meal
                        ? price.discounted_price_per_meal
                        : price.price_per_meal
                    }
                    selected={selectedPlanCodes?.includes(product.code)}
                    onPress={() => togglePlan(product.code)}
                    underlineButton={{
                      text: 'View Details',
                      onPress: () => {
                        setShowPDPModal(true);
                        setPlanDetails(plan.subscription);
                      },
                    }}
                    bannerText={RecipeCardBanners[recipe.id]?.funnel}
                  />
                </Box>
              );
            })
          ) : (
            <RecipeSelectFormSkeleton withPills={false} />
          )}
        </VStack>
      </Stack>
      <HStack
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        w={{ base: '100%', md: '60%', lg: 'fit-content' }}
        my={4}
        px="10px"
      >
        <Button
          size="bodyMdToLg"
          w="100%"
          maxW={{ base: '100%', md: '290px' }}
          h={{ base: '52px', md: '56px' }}
          isDisabled={!selectedPlanCodes || selectedPlanCodes.length === 0}
          isLoading={isLoading}
          onPress={() => onSubmit()}
        >
          Continue
        </Button>
      </HStack>
      <>
        {planDetails && (
          <PDPModal
            recipe={planDetails.product.recipes[0]}
            buttonText="Select Recipe"
            prices={[perMealPrice, perWeekPrice]}
            pricingUnits={['meal', 'week']}
            updateState={() => togglePlan(planDetails.product.code)}
            isOpen={showPDPModal}
            onClose={() => setShowPDPModal(false)}
          />
        )}
      </>
    </AddNewDogWrapper>
  );
};
