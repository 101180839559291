import { useFocusEffect } from '@react-navigation/native';
import { VStack } from 'native-base';
import { useCallback, useState } from 'react';

import { Image } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames, ASSETS_URL } from '@/constants';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { FunnelScreenProps } from '@/types';

export default function ProcessingOrder({
  navigation,
  route,
}: FunnelScreenProps<'ProcessingOrder'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.PROCESSING_ORDER);
  const [progress, setProgress] = useState<number>(0);

  useFocusEffect(
    useCallback(() => {
      let timer: any;
      const updateProgress = () => {
        timer =
          !timer &&
          setInterval(() => {
            setProgress((prevProgress) => prevProgress + 1);
          }, 50);

        if (progress > 100) {
          clearInterval(timer);

          navigation.navigate(nextStep, {
            chargeDate: route?.params?.chargeDate,
          });
        }
      };
      if (navigation.isFocused()) {
        updateProgress();
      } else {
        setProgress(0);
      }

      return () => {
        clearInterval(timer);
      };
    }, [navigation, nextStep, progress, route?.params?.chargeDate])
  );

  return (
    <FunnelWrapper>
      <VStack justifyContent="center" alignItems="center" safeAreaTop h="85%">
        <Image
          source={{ uri: `${ASSETS_URL}/ProcessingOrder.gif` }}
          resizeMode="cover"
          w={{ base: '300px', '2xl': '500px' }}
          h={{ base: '300px', '2xl': '500px' }}
          size="4xl"
          alt="Processing your Order"
        />
      </VStack>
    </FunnelWrapper>
  );
}
