import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { phone_number } from '@/screens';

export interface PersonalInformationFormValues {
  firstName: string;
  lastName: string;
  phone?: string;
}

const defaultValues: PersonalInformationFormValues = {
  firstName: '',
  lastName: '',
};

const nameRules = /^[a-zA-Z\xC0-\uFFFF]+([ \-']{0,1}[a-zA-Z\xC0-\uFFFF]+){0,2}[.]{0,1}$/;

const personalInformationSchema: Yup.SchemaOf<PersonalInformationFormValues> = Yup.object().shape({
  firstName: Yup.string()
    .required('Please enter your first name')
    .max(50, 'Please enter a first name with no more than 50 characters')
    .matches(nameRules, 'Please enter a valid first name without any numbers or symbols'),
  lastName: Yup.string()
    .required('Please enter your last name')
    .max(50, 'Please enter a last name with no more than 50 characters')
    .matches(nameRules, 'Please enter a valid last name without any numbers or symbols'),
  phone: phone_number,
});

export const usePersonalInformationForm = () => {
  return useForm<PersonalInformationFormValues>({
    mode: 'onBlur',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(personalInformationSchema),
  });
};
