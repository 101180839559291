import { useIsFocused } from '@react-navigation/native';
import { HStack, Heading, VStack, Text, Button } from 'native-base';
import { useEffect, useMemo, useState } from 'react';

import { usePetFormOptions } from '@/api';
import { Checkbox } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useSessionQuery } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { FunnelScreenProps } from '@/types';
import { getCurrentPet } from '@/utils/getCurrentPet';

export const HealthConditionsList = ({ navigation }: FunnelScreenProps<'HealthConditionsList'>) => {
  const nextStep = useNextFunnelStep(FunnelScreenNames.HEALTH_CONDITIONS_LIST);
  const { session, mutateUpdatePetAnswer } = useSessionQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const funnelErrorHandler = useFunnelErrorHandler();
  const dog = getCurrentPet(session);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { addAxiosPromise } = useAxiosStatusContext();
  const { data: petFormOptions } = usePetFormOptions();
  const health_conditions = petFormOptions ? petFormOptions.health_conditions : [];

  const sorted_health_conditions = useMemo(() => {
    return health_conditions?.length
      ? [
          ...health_conditions.sort((a, b) => {
            if (a.seq_no - b.seq_no === 0) {
              return a.name < b.name ? -1 : 1;
            }
            return a.seq_no - b.seq_no;
          }),
        ]
      : [];
  }, [health_conditions?.length]);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) return;
    if (dog?.health_conditions?.length) {
      setSelectedOptions(dog.health_conditions);
    }
  }, [isFocused]);

  const onSubmit = () => {
    if (selectedOptions.length === 0) {
      return;
    }
    setIsSubmitting(true);
    try {
      addAxiosPromise(
        mutateUpdatePetAnswer({
          health_conditions: selectedOptions,
        })
      );
    } catch (error) {
      funnelErrorHandler(error, 'Update health conditions');
    }
    navigation.navigate(nextStep);
    setIsSubmitting(false);
  };

  return health_conditions ? (
    <FunnelWrapper>
      <VStack
        justifyContent={{ base: 'center', md: 'flex-start' }}
        alignItems="center"
        space={{ base: '30px', lg: '75px' }}
        w="100%"
        h="75%"
      >
        <VStack
          w="100%"
          maxW="813px"
          flex={1}
          alignItems="center"
          space={{ base: '36px', lg: '48px' }}
        >
          <Heading size="titleSmToMd" fontWeight="bold">
            Check all that apply
          </Heading>
          <Checkbox.Group
            accessibilityLabel="Health conditions"
            value={selectedOptions}
            onChange={setSelectedOptions}
            w="100%"
          >
            <VStack w="100%" maxH={{ base: '100%', lg: '276px' }} flexWrap="wrap">
              {sorted_health_conditions.map(
                ({ slug, name }: { slug: string; name: string }, i: number) => (
                  <HStack key={i} space={{ base: 2, lg: 5 }} mb="18px">
                    <Checkbox value={slug}>
                      <Text size="bodyMlToLg" color="black" fontWeight={500}>
                        {name}
                      </Text>
                    </Checkbox>
                  </HStack>
                )
              )}
            </VStack>
          </Checkbox.Group>
          <Button
            variant="primary"
            onPress={onSubmit}
            position={{ base: 'sticky', lg: 'relative' }}
            bottom={{ base: 6, lg: 0 }}
            isDisabled={selectedOptions.length === 0}
            isLoading={isSubmitting}
          >
            CONTINUE
          </Button>
        </VStack>
      </VStack>
    </FunnelWrapper>
  ) : null;
};
