import { responsiveSizes } from '@/theme/typography/fontSizes';

const variants = {
  disabled: {
    _text: {
      color: 'sntGrey.primary',
      _hover: {},
    },
  },
  inline: {
    bg: 'transparent',
    color: 'black',
    h: 'fit-content',
    w: 'fit-content',
    _hover: {
      _text: {
        color: 'sntBlue.hover',
      },
    },
  },
  secondary: {
    bgColor: 'transparent',
    borderColor: 'black',
    borderWidth: '2px',
    color: 'black',
    px: { base: 4, md: 9 },
    py: { base: 2, md: 3 },
    h: 'fit-content',
    _text: {
      fontWeight: 900,
      textDecorationLine: 'none',
    },
    _disabled: {
      color: 'sntGrey.primary',
      borderColor: 'sntGrey.primary',
    },
  },
  hugSecondary: {
    bgColor: 'white',
    borderColor: 'blueBayoux.primary',
    borderRadius: { base: '25px', lg: '30px' },
    borderWidth: '1px',
    color: 'blueBayoux.primary',
    px: { base: 4, lg: 9 },
    py: { base: 2, lg: 3 },
    h: 'fit-content',
    _text: {
      fontWeight: 900,
      textDecorationLine: 'none',
    },
    _disabled: {
      color: 'blueBayoux.disabled',
      borderColor: 'blueBayoux.disabled',
    },
  },
};

const defaultProps = {
  isUnderlined: false,
};

export const Link = {
  variants,
  sizes: responsiveSizes,
  defaultProps,
};
