import dayjs from 'dayjs';
import { Stack, Heading, Text, Divider, Image, IStackProps, Badge, HStack } from 'native-base';

import { OrderProduct } from '@/api';
import { LineItemPlanCardProps, Stepper } from '@/components/Elements';
import { getRecipesProductImageSrc } from '@/utils';

export interface LineItemPlanCardAddonsProps extends IStackProps {
  content: LineItemPlanCardProps['content'];
  onDecAddon?: (orderProduct: OrderProduct) => void;
  onIncAddon?: (orderProduct: OrderProduct) => void;
  disabled?: boolean;
}

export const LineItemPlanCardAddons = ({
  content,
  disabled,
  onDecAddon,
  onIncAddon,
  ...props
}: LineItemPlanCardAddonsProps) => (
  <Stack
    borderColor="sntGrey.outline"
    space={{ base: 2, lg: 4 }}
    p={4}
    borderWidth={1}
    w="100%"
    {...props}
  >
    <Stack space={{ base: 2, lg: 4 }}>
      <HStack>
        <Heading
          size="bodyMlToTitleSm"
          fontWeight="bold"
          color={disabled ? 'sntGrey.primary' : undefined}
        >
          One-Time Products
        </Heading>
      </HStack>
      <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
        Delivered with {`${content?.dog?.name}'s`}{' '}
        {dayjs(content?.orderDate).local().format('MMM D')} order only.
      </Text>
    </Stack>
    <Divider bg="gallery" />
    <Stack space={{ base: 4, lg: 6 }}>
      {content.addons.map((addon, key, arr) => (
        <HStack key={key} space={{ base: 2, lg: 4 }}>
          <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
            <Image
              source={{
                uri: getRecipesProductImageSrc(addon.recipes, false),
              }}
              w="100%"
              h="100%"
              alt={addon.recipes[0].name}
              resizeMode="contain"
              opacity={disabled ? 50 : undefined}
            />
          </Stack>
          <Stack flex={3} space={3}>
            <Stack>
              <HStack alignItems="center" justifyContent="space-between">
                <HStack space={2}>
                  <Heading
                    size="bodyMdToLg"
                    fontWeight="bold"
                    color={disabled ? 'sntGrey.primary' : undefined}
                  >
                    Buffer Bag
                  </Heading>
                  <Badge
                    variant="smallTabActive"
                    px={2}
                    _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
                    borderColor={disabled ? 'sntGrey.primary' : undefined}
                  >
                    1-time
                  </Badge>
                </HStack>
                <Badge
                  variant="smallInfoPillSecondary"
                  _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
                  borderColor={disabled ? 'sntGrey.primary' : undefined}
                >{`$${addon.price.price}`}</Badge>
              </HStack>
              <Stack>
                <Text
                  size="tinyToCaption"
                  fontWeight="medium"
                  color={disabled ? 'sntGrey.primary' : undefined}
                >
                  {addon.quantity} extra day{addon.quantity > 1 ? 's' : ''} of food
                </Text>
              </Stack>
            </Stack>
            <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
              {addon.recipes[0].name}
            </Text>
            <Stepper
              w={{ base: '50%', lg: '33%' }}
              controlledQuantity={addon.quantity}
              onPressDec={() => onDecAddon?.(addon)}
              onPressInc={() => onIncAddon?.(addon)}
              isDisabled={content.orderWasCharged || disabled}
            />
            {key < arr.length - 1 ? <Divider bgColor="gallery" w="85%" mt={3} /> : null}
          </Stack>
        </HStack>
      ))}
    </Stack>
  </Stack>
);
