import { Order } from '@/api/types/accountServices';

export const sortOrderByCharged = (orderA: Order, orderB: Order, direction?: 'asc' | 'desc') => {
  const aDate = new Date(orderA.charged).getTime();
  const bDate = new Date(orderB.charged).getTime();
  const dir = direction && direction === 'desc' ? -1 : 1;
  if (aDate > bDate) {
    return 1 * dir;
  }
  if (aDate < bDate) {
    return -1 * dir;
  }
  return 0;
};
