import { useMutation, useQueryClient } from 'react-query';

import { axios } from '@/lib/axios';

const postDeletePaymentMethod = (data: { paymentMethodId: string }) =>
  axios.post(`/payment_methods/delete`, { payment_method_id: data.paymentMethodId });

export const useDeletePaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation(postDeletePaymentMethod, {
    onSuccess: () => {
      return queryClient.invalidateQueries('PaymentMethod');
    },
  });
};
