import {
  Heading,
  Stack,
  VStack,
  Text,
  Button,
  HStack,
  Badge,
  Tooltip,
  InfoOutlineIcon,
  IButtonProps,
} from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

import { getFunnelMealPlanPrices } from '../formatMealPrices';

import { BuildPlan } from '@/api/types';
import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { Dog } from '@/types';
import { round } from '@/utils';

export const HeresDogPlanSection = ({
  dog,
  data,
  onSubmit,
}: {
  dog: Partial<Dog>;
  data: BuildPlan | undefined;
  onSubmit: () => void;
}) => {
  const { CTAText, discountedWeekPrice, discountPercent, weekPrice } =
    getFunnelMealPlanPrices(data);

  const planDays = data?.trial.product.frequency ? data?.trial.product.frequency * 7 : null;
  const recipeNames =
    data?.trial.product.recipes.length === 1
      ? data?.trial.product.recipes[0].name
      : data?.trial.product.recipes.map((recipe) => recipe.name.split(' ')[0]).join(' & ');

  const SubmitButton = (props: IButtonProps) => (
    <Button
      onPress={onSubmit}
      display={{ base: 'none', lg: 'flex' }}
      size="captionToBodySm"
      px={{ base: 6, lg: 4 }}
      py={{ base: 3, lg: 4 }}
      {...props}
    >
      <Text fontWeight="bold" letterSpacing="md">
        {CTAText}
      </Text>
    </Button>
  );

  return data ? (
    <Stack
      flexDirection={{ base: 'column', lg: 'row' }}
      w="100%"
      maxW="1079px"
      justifyContent="space-between"
      alignItems="center"
      pb={{ base: '24px', lg: '142px' }}
      px={{ base: '16px', lg: 0 }}
    >
      <VStack>
        <VStack>
          <Heading
            alignSelf={{ base: 'center', md: 'auto' }}
            color="black"
            variant="extraLargeTitle"
            fontWeight="bold"
            mb="8px"
          >
            Here's {dog.name}'s Plan!
          </Heading>
          <Stack
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems="center"
            mb={{ base: 0, md: '30px' }}
          >
            <Text color="black" fontFamily="secondary" textAlign="center" size="bodyMl">
              For {dog.name}, we recommend {round(data.calories_per_day, 0)} calories of UnKibble
              per day.
            </Text>
            <Tooltip
              label="Our calorie recommendation might feel lower than you expect. That's because our fresh UnKibble is much more digestible than kibble, so your pup needs less."
              placement="bottom"
              openDelay={200}
              maxW="250px"
              bg="white"
              rounded={0}
              shadow={1}
              _text={{
                color: 'black',
                fontFamily: 'secondary',
                fontSize: 'body.sm',
                lineHeight: 'body.sm',
                textAlign: 'center',
              }}
            >
              <InfoOutlineIcon color="black" size="24px" />
            </Tooltip>
          </Stack>
        </VStack>
        <SubmitButton />
      </VStack>
      <VStack>
        <HStack>
          <Image
            source={{
              uri: `${ASSETS_URL}/pictos/Unkibble/${dog.recipes?.sort().join('')}.png`,
            }}
            size={{ base: '375px', lg: '400px' }}
            alt="recipe"
          />
        </HStack>
        <TextBubble>
          <Text
            color="black"
            size="bodySmToLg"
            fontWeight="medium"
            maxW={{ base: '100px', lg: '125px' }}
            textAlign="center"
          >
            {recipeNames}
          </Text>
          <Text color="black" size="tinyToBodySm" fontWeight="medium" py="4px">
            TRIAL PRICE
          </Text>
          <HStack alignItems="center">
            <Text color="black" size="titleMd" fontWeight="bold">
              {`$${discountedWeekPrice || weekPrice}`}
            </Text>
            <Text color="black" fontWeight="medium" size="bodySmToLg">
              /week
            </Text>
          </HStack>

          {discountPercent ? (
            <Stack flexDirection="row" justifyContent="center" alignItems="center">
              <Badge py="4px" px="8px" mr="8px">
                <Text size="tinyToBodySm" color="black" fontWeight="bold">
                  Save {discountPercent}%
                </Text>
              </Badge>
              <Text color="black" fontSize="tiny" lineHeight="tiny" fontFamily="secondary">
                {`reg. $${data?.trial?.price?.price_per_week}/week`}
              </Text>
            </Stack>
          ) : null}

          {planDays ? (
            <Text size="captionToBodySm" fontWeight="medium" mt="5px">
              {`${planDays} days of food`}
            </Text>
          ) : null}
        </TextBubble>
      </VStack>
      <SubmitButton display={{ base: 'flex', lg: 'none' }} mt={{ base: '20px', md: 0 }} />
    </Stack>
  ) : null;
};

const TextBubble = ({ ...props }: IVStackProps) => (
  <VStack
    position="absolute"
    right={{ base: 0, lg: -40 }}
    top={{ base: 0, lg: -50 }}
    bg="sntBlue.primary:alpha.50"
    w={{ base: '186px', lg: '262px' }}
    h={{ base: '186px', lg: '262px' }}
    justifyContent="center"
    alignItems="center"
    borderRadius="100%"
    {...props}
  >
    <VStack
      bg="white"
      w={{ base: '160px', lg: '225px' }}
      h={{ base: '160px', lg: '225px' }}
      rounded="100%"
      justifyContent="center"
      alignItems="center"
      {...props}
    />
  </VStack>
);
