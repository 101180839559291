const baseStyle = {
  mx: 'auto',
  borderColorTop: 'grey.dark',
  borderWidth: '1px',
  borderStyle: 'solid',
  px: '16px',
  py: '16px',
  borderRadius: { base: '8px', lg: '12px' },
  textAlign: 'start',
  w: '100%',

  _light: {
    borderColor: 'grey.secondary',
    borderWidth: '1px',
    m: '1px',
    color: 'grey.dark',
    backgroundColor: 'transparent',
    _focus: {
      m: '0px',
      borderWidth: '2px',
      borderColor: 'aquaIsland',
      _hover: { borderWidth: '2px', borderColor: 'aquaIsland' },
      _stack: {
        style: {
          outlineWidth: '1px',
          outlineColor: 'transparent',
          outlineStyle: 'solid',
        },
      },
    },
    _hover: { margin: '0px', borderWidth: '2px', borderColor: 'aquaIsland' },
  },
  _placeholder: {
    color: 'grey.secondary',
  },
};

const sizes = {
  bodyLg: {
    w: '362px',
    fontSize: 'body.lg',
    lineHeight: 'body.lg',
    fontWeight: 'book',
  },
  bodyMd: {
    w: '362px',
    fontSize: 'body.md',
    lineHeight: 'body.md',
    fontWeight: 'book',
  },
  bodySm: {
    w: '100%',
    fontSize: 'body.sm',
    lineHeight: 'body.sm',
    fontWeight: 'book',
  },
};
const defaultProps = {
  size: 'lg',
};

export const Select = {
  baseStyle,
  sizes,
  defaultProps,
};
