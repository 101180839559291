import { Heading, HStack, IStackProps, Text, Stack, Skeleton, Box } from 'native-base';

import { AddOnItems } from './AddOnItems';
import { MerchProductItems } from './MerchProductItems';
import { SectionContent } from './ShopTab';

import { MerchandiseProduct, RecipeFull, RecipeType } from '@/api/types';
import { Image, VToHStack } from '@/components/Elements';
import { useIsMobile } from '@/hooks';
export interface ProductSectionProps extends IStackProps {
  sectionData: SectionContent;
  isLoading: boolean;
}

export const ProductSection = ({
  sectionData: {
    items,
    sectionHeaderRef,
    itemsRef,
    recipeType,
    title,
    bannerUri,
    iconUri,
    subheading,
    bannerRef,
  },
  isLoading = false,
  ...props
}: ProductSectionProps) => {
  const isMobile = useIsMobile();
  const SubHeading = () => (
    <Text fontFamily="secondary" size="bodyMdToLg" maxW={{ base: '100%', lg: '305px' }}>
      {subheading}
    </Text>
  );
  return (
    <Stack {...props} w="100%">
      <VToHStack
        mb={{ base: 9, lg: 12 }}
        justifyContent="start"
        space={{ base: 4, lg: 6 }}
        w="100%"
      >
        <Stack>
          <HStack space={{ base: 1, lg: 2 }} alignItems="center">
            <Heading size="titleMdToMl" fontWeight="bold">
              {title.toLocaleUpperCase()}
            </Heading>
            <Image
              source={{ uri: iconUri }}
              w={{ base: '34px', lg: '67px' }}
              h={{ base: '30px', lg: '40px' }}
            />
          </HStack>
          {!isMobile ? <SubHeading /> : null}
        </Stack>
        <Stack ref={sectionHeaderRef} flex={1}>
          <Image
            w="100%"
            h={{ base: '305px', lg: '359px' }}
            source={{ uri: bannerUri }}
            resizeMode="cover"
          />
        </Stack>
        {isMobile ? <SubHeading /> : null}
      </VToHStack>
      {!isMobile ? <Box ref={itemsRef} w={0} h={0} /> : null}
      {isLoading ? (
        <Skeleton h="470px" w={{ base: '100%', lg: '325px' }} />
      ) : recipeType === RecipeType.MERCH ? (
        <MerchProductItems merchProducts={items as MerchandiseProduct[]} itemsRef={itemsRef} />
      ) : (
        <AddOnItems bannerRef={bannerRef} recipes={items as RecipeFull[]} itemsRef={itemsRef} />
      )}
    </Stack>
  );
};
