import { Radio, Stack, Text } from 'native-base';
import { useState } from 'react';

import { PopUpProps, PortalPopUp } from '@/components/Elements';

interface TopperChoiceModalProps extends Omit<PopUpProps, 'onConfirm'> {
  decreaseOrIncrease?: 'decrease' | 'increase';
  petName?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  onConfirm?: (value: boolean) => void;
}

export const TopperChoiceModal = ({
  decreaseOrIncrease,
  petName,
  onConfirm,
  isOpen,
  ...props
}: TopperChoiceModalProps) => {
  const [intendsToKeepTopperChoice, setIntentToKeepTopperChoice] = useState<boolean | undefined>();
  return (
    <PortalPopUp
      isOpen={isOpen}
      size="xl"
      isConfirmButtonDisabled={intendsToKeepTopperChoice === undefined}
      confirmBtnText="Continue"
      heading={`This is ${decreaseOrIncrease === 'decrease' ? 'a decrease' : 'an increase'} in
          calories. Are you planning to keep ${
            decreaseOrIncrease === 'decrease'
              ? `feeding ${petName} exclusively Spot & Tango?`
              : decreaseOrIncrease === 'increase'
              ? `mixing ${petName}'s Spot & Tango in with other food?`
              : ''
          }`}
      w={{ base: '100%', md: '440px' }}
      contentStackProps={{ px: 0 }}
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      onConfirm={() => onConfirm?.(intendsToKeepTopperChoice as boolean)}
      useRNModal
      {...props}
    >
      <Stack>
        <Radio.Group
          mx={2}
          name="selectedValue"
          accessibilityLabel="Survey answer"
          value={
            intendsToKeepTopperChoice === undefined
              ? undefined
              : intendsToKeepTopperChoice
              ? 'true'
              : 'false'
          }
          onChange={(value) => setIntentToKeepTopperChoice(value === 'true')}
        >
          <Stack w="100%" mb={2}>
            <Radio value={decreaseOrIncrease === 'decrease' ? 'false' : 'true'}>
              <Text size="bodySmToLg" color="black">
                {decreaseOrIncrease === 'decrease'
                  ? `Yes, I'll keep feeding 100% Spot & Tango`
                  : null}
                {decreaseOrIncrease === 'increase'
                  ? `Yes, I'll keep mixing Spot & Tango in with other food`
                  : null}
              </Text>
            </Radio>
          </Stack>
          <Stack w="100%">
            <Radio value={decreaseOrIncrease === 'decrease' ? 'true' : 'false'}>
              <Text size="bodySmToLg" color="black">
                {decreaseOrIncrease === 'decrease'
                  ? `No, I'll mix Spot & Tango in with other food`
                  : null}
                {decreaseOrIncrease === 'increase' ? `No, I'll feed 100% Spot & Tango` : null}
              </Text>
            </Radio>
          </Stack>
        </Radio.Group>
      </Stack>
    </PortalPopUp>
  );
};
