import clsx from 'clsx';

// import { ReactComponent as Ribbon } from '../../../assets/ribbon.svg';

// const RibbonComponent = <Ribbon className="absolute right-0" title="Ribbon" />;

export type BadgeProps = {
  className?: string;
  discount: string;
};

export const Badge = ({ className, discount = '', ...props }: BadgeProps) => {
  return (
    <div
      className={clsx('flex justify-end items-center relative w-[99px] h-[24px]', className)}
      {...props}
    >
      {/* {RibbonComponent} */}
      <span className="relative z-10 text-white break-words font-black text-[length:var(--font-size-tiny)] py-[6px] pr-[8px] pl-[21px]">
        {discount}
      </span>
    </div>
  );
};

Badge.displayName = 'Badge';
