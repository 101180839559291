import { Icon } from 'native-base';
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { SVGNBIconProps } from '@/types';
const SvgIcon = ({ ...props }: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <Path d="M20 6v12H4V6h16Zm0-2H4c-1.11 0-2 .89-2 2v12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2Zm-9 6H6v4h5v-4Z" />
  </Svg>
);
function SvgCreditCardChipOutline({ title, titleId, ...props }: SVGNBIconProps) {
  return <Icon width="1em" height="1em" aria-labelledby={title} as={SvgIcon} {...props} />;
}
export default SvgCreditCardChipOutline;
