import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  m: 0,
  _text: {
    color: 'black',
    letterSpacing: 'none',
    fontFamily: 'primary',
  },
  _light: {
    _text: {
      color: 'black',
      letterSpacing: 'none',
      fontFamily: 'primary',
    },
  },
};

const variants = {
  portal: {
    m: '0px',
    p: '0px',

    _text: {
      fontWeight: 450,
      color: 'grey.dark',
    },
  },
};

const defaultProps = {
  size: 'bodyMd',
};

export const Label = {
  baseStyle,
  variants,
  sizes: responsiveSizes,
  defaultProps,
};
