import {
  Pet,
  PetGender,
  PetPlanProductStatus,
  PetPlanStatus,
  PetStatus,
  ProductType,
  RecipeType,
} from '@/api';

export const petMock1: Pet = {
  id: '5bb955da-4f36-41e7-b796-71c493554aea',
  created: '2023-01-10T10:57:08.336926-05:00',
  last_updated: '2023-01-10T10:57:08.336941-05:00',
  status: PetStatus.ACTIVE,
  name: 'Spot',
  account: '4325b762-3dde-4fd0-a387-d933cd70f218',
  breeds: ['mixed-breed'],
  breed_details: [
    {
      name: 'Mixed Breed',
      slug: 'mixed-breed',
      description: null,
    },
  ],
  gender: PetGender.MALE,
  birth_month: null,
  birth_year: 2020,
  properties: {
    id: '6128e70f-1520-4c87-b88f-da13ae58b0ff',
    created: '2023-01-10T10:57:08.359796-05:00',
    last_updated: '2023-01-10T10:57:08.359811-05:00',
    pet: '5bb955da-4f36-41e7-b796-71c493554aea',
    recommended_calories_day: null,
    weight: 25.0,
    neutered: true,
    body_type: 'a-little-chubby',
    lifestyle: 'lazy',
    health_conditions: ['anxiety'],
    audit_trail_status: 'ACTIVE',
  },
  pet_plan: {
    id: '197797fe-e19f-46b9-b82a-7bb48145fe35',
    created: '2023-01-10T10:57:08.836877-05:00',
    last_updated: '2023-01-10T10:57:08.984135-05:00',
    status_date: '2023-01-10T10:57:08.836598-05:00',
    start_date: '2023-01-24',
    recipes: ['U-B'],
    recipe_data: [
      {
        id: 'U-B',
        name: 'Beef + Barley',
        description:
          'Our best selling recipe is great for steak loving pups and dogs with poultry sensitivities. Beef is also an excellent source of Vitamin E, a super antioxidant.',
        type: RecipeType.UNKIBBLE,
        seq_no: 0,
      },
    ],
    plan_size: 150,
    calories_per_day: 150,
    is_topper: false,
    frequency: 4,
    base_price: 73.21,
    discount_amt: 0,
    status: PetPlanStatus.ACTIVE,
    user_last_change: null,
    account: '4325b762-3dde-4fd0-a387-d933cd70f218',
    discounts: [],
    products: [
      {
        status: PetPlanProductStatus.ACTIVE,
        id: 'fa0a3a54-3b31-4ef1-a0a3-42a4dc8ed658',
        product_id: '8b2a5678-25cc-4549-8261-581c48c32e13',
        product_type: ProductType.MEAL,
        name: 'Meal Plan - UnKibble - Beef - Plan Size 150 Calories - Frequency 4W',
        code: 'MEAL-U-B-150C-4W',
        quantity: 1,
        frequency: 4,
        type: 'SUBSCRIPTION',
        stripe_subscription_id: null,
        recipes: [
          {
            id: 'U-B',
            name: 'UnKibble',
            description: 'Beef + Barley',
            type: RecipeType.UNKIBBLE,
            seq_no: 0,
          },
        ],
        price_per_week: '37.56',
        pet_plan: '123',
        created: '2023-10-08T13:11:18.343201-04:00',
        unit_price: '12.00',
      },
      {
        status: PetPlanProductStatus.ACTIVE,
        id: 'fa0a3a54-3b31-4ef1-a0a3-42a4dc8ed658',
        product_id: 'f8eaba54-3b31-4ef1-a0a3-42a4dc8ed658',
        product_type: ProductType.SNACK,
        name: 'Snack Chicken Munchies R',
        code: 'SNACK-CM-1CT-R',
        quantity: 1,
        frequency: 4,
        type: 'SUBSCRIPTION',
        stripe_subscription_id: null,
        recipes: [
          {
            id: 'SN-CHICKENBITES',
            name: 'Snack',
            description: 'Chicken Munchies',
            type: RecipeType.SNACK,
            seq_no: 0,
          },
        ],
        price_per_week: '37.56',
        pet_plan: '123',
        created: '2023-10-08T13:11:18.343201-04:00',
        unit_price: '12.00',
      },
      {
        status: PetPlanProductStatus.ACTIVE,
        id: 'fa0a3a54-3b31-4ef1-a0a3-42a4dc8ed658',
        product_id: 'f8eaba54-3b31-4ef1-a0a3-42a4dc8ed658',
        product_type: ProductType.SNACK,
        name: 'Snack Sweet Potato Bites',
        code: 'SNACK-SB-1CT-R',
        quantity: 1,
        frequency: 4,
        type: 'SUBSCRIPTION',
        stripe_subscription_id: null,
        recipes: [
          {
            id: 'SN-SPBITES',
            name: 'Snack',
            description: 'Sweet Potato Bites',
            type: RecipeType.SNACK,
            seq_no: 0,
          },
        ],
        price_per_week: '37.56',
        pet_plan: '123',
        created: '2023-10-08T13:11:18.343201-04:00',
        unit_price: '12.00',
      },
    ],
    pet: '5bb955da-4f36-41e7-b796-71c493554aea',
  },
};

export const petMock2: Pet = {
  id: '1c9aa439-a77a-404a-bdb3-8722a2145b2a',
  created: '2023-01-10T10:57:09.091938-05:00',
  last_updated: '2023-01-10T10:57:09.091952-05:00',
  status: PetStatus.ACTIVE,
  name: 'Tango',
  account: '4325b762-3dde-4fd0-a387-d933cd70f218',
  breeds: ['pembroke-welsh-corgi'],
  breed_details: [
    {
      name: 'Pembroke Welsh Corgi',
      slug: 'pembroke-welsh-corgi',
      description: null,
    },
  ],
  gender: PetGender.FEMALE,
  birth_month: null,
  birth_year: 2020,
  properties: {
    id: '4991716d-957c-4486-8f78-222e66ff0bfa',
    created: '2023-01-10T10:57:09.102650-05:00',
    last_updated: '2023-01-10T10:57:09.102665-05:00',
    pet: '1c9aa439-a77a-404a-bdb3-8722a2145b2a',
    recommended_calories_day: null,
    weight: 35.0,
    neutered: true,
    body_type: 'a-little-chubby',
    lifestyle: 'lazy',
    health_conditions: ['anxiety'],
    audit_trail_status: 'ACTIVE',
  },
  pet_plan: {
    id: '02fb4448-166b-4bd7-9f28-a7d7a0faf6da',
    created: '2023-01-10T10:57:10.641344-05:00',
    last_updated: '2023-01-10T10:57:10.860038-05:00',
    status_date: '2023-01-10T10:57:10.641056-05:00',
    start_date: '2023-01-24',
    recipes: ['U-B', 'U-C'],
    recipe_data: [
      {
        id: 'U-B',
        name: 'Beef + Barley',
        description:
          'Our best selling recipe is great for steak loving pups and dogs with poultry sensitivities. Beef is also an excellent source of Vitamin E, a super antioxidant.',
        type: RecipeType.UNKIBBLE,
        seq_no: 0,
      },
      {
        id: 'U-C',
        name: 'Chicken & Brown Rice',
        description:
          "Our Chicken & Brown Rice recipe provides the perfect combination of high quality protein, healthy fats and fiber to keep your pup energized all day long. Chicken is an excellent source of glucosamine, supporting your pup's healthy bones.",
        type: RecipeType.UNKIBBLE,
        seq_no: 0,
      },
    ],
    plan_size: 750,
    calories_per_day: 750,
    is_topper: false,
    frequency: 4,
    base_price: 195.19,
    discount_amt: 0,
    status: PetPlanStatus.ACTIVE,
    user_last_change: null,
    account: '4325b762-3dde-4fd0-a387-d933cd70f218',
    discounts: [],
    products: [
      {
        status: PetPlanProductStatus.ACTIVE,
        id: 'fa0a3a54-3b31-4ef1-a0a3-42a4dc8ed658',
        product_id: 'da93929b-e5c7-48ed-97c4-6dc8d57b4aba',
        product_type: ProductType.MEAL,
        name: 'Meal Plan - UnKibble - Chicken Beef - Plan Size 750 Calories - Frequency 4W',
        code: 'MEAL-U-CB-750C-4W',
        quantity: 1,
        frequency: 4,
        type: 'SUBSCRIPTION',
        stripe_subscription_id: null,
        recipes: [
          {
            id: 'U-CB',
            name: 'Chicken + Brown Rice',
            description:
              'Our best selling recipe is great for steak loving pups and dogs with poultry sensitivities. Beef is also an excellent source of Vitamin E, a super antioxidant.',
            type: RecipeType.UNKIBBLE,
            seq_no: 0,
          },
        ],
        price_per_week: '37.56',
        pet_plan: '123',
        created: '2023-10-08T13:11:18.343201-04:00',
        unit_price: '12.00',
      },
      {
        status: PetPlanProductStatus.ACTIVE,
        id: 'fa0a3a54-3b31-4ef1-a0a3-42a4dc8ed658',
        product_id: 'f8eaba54-3b31-4ef1-a0a3-42a4dc8ed658',
        product_type: ProductType.SNACK,
        name: 'Snack Chicken Munchies R',
        code: 'SNACK-CM-1CT-R',
        quantity: 1,
        frequency: 4,
        type: 'SUBSCRIPTION',
        stripe_subscription_id: null,
        recipes: [
          {
            id: 'SN-CHICKENBITES',
            name: 'Chicken Munchies',
            description:
              'Meet Chicken Munchies: 100% chicken breast snacks, freeze dried for the perfect crunch. Our dog snacks are grain-free, gluten free fan favorites!',
            type: RecipeType.SNACK,
            seq_no: 0,
          },
        ],
        price_per_week: '37.56',
        pet_plan: '123',
        created: '2023-10-08T13:11:18.343201-04:00',
        unit_price: '12.00',
      },
    ],
    pet: '1c9aa439-a77a-404a-bdb3-8722a2145b2a',
  },
};
