export const fontConfig = {
  'MonumentGrotesk-Regular': {
    300: {
      normal: 'MonumentGrotesk-Light',
    },
    400: {
      normal: 'MonumentGrotesk-Regular',
    },
    500: {
      normal: 'MonumentGrotesk-Medium',
    },
    700: {
      normal: 'MonumentGrotesk-Bold',
    },
    900: {
      normal: 'MonumentGrotesk-Black',
    },
  },
  'BradfordLL-Regular': {
    300: {
      normal: 'BradfordLL-Light',
    },
    400: {
      normal: 'BradfordLL-Regular',
    },
    450: {
      normal: 'BradfordLL-Book',
    },
    500: {
      normal: 'BradfordLL-Medium',
    },
    700: {
      normal: 'BradfordLL-Bold',
    },
    900: {
      normal: 'BradfordLL-Black',
    },
  },
};

export const fontWeights = {
  book: 450,
};
