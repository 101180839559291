import { useQuery } from 'react-query';

import { RecipeFull } from '@/api/types';
import { axios } from '@/lib/axios';

export const getRecipes = ({
  recipeType,
  discount,
  isTrial,
  sessionId,
  accountId,
}: {
  recipeType: string;
  discount?: string;
  isTrial?: boolean;
  sessionId?: string;
  accountId?: string;
}) => {
  return axios
    .get<RecipeFull[]>(`/recipes/${recipeType}`, {
      params: {
        discount_code: discount || undefined,
        is_trial: isTrial ? 'true' : undefined,
        session_id: sessionId || undefined,
        account_id: accountId || undefined,
      },
    })
    .then((res) => res.data);
};

export const useRecipes = ({
  recipeType = '',
  discount,
  isTrial,
  sessionId,
  accountId,
}: {
  recipeType?: string;
  discount?: string;
  isTrial?: boolean;
  sessionId?: string;
  accountId?: string;
} = {}) => {
  return useQuery(['Recipes', recipeType, discount, isTrial, sessionId, accountId], {
    queryFn: () => getRecipes({ recipeType, discount, isTrial, sessionId, accountId }),
  });
};
