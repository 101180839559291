import { FormControl, Input, Stack } from 'native-base';
import { IInputProps } from 'native-base/lib/typescript/components/primitives/Input/types';
import { FieldValues, Path } from 'react-hook-form';

import { LabeledFormControl, LabeledFormControlProps } from '../LabeledFormControl';

export interface LabeledInputProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> extends Omit<LabeledFormControlProps<TFieldValues, TName>, 'children'> {
  error?: string;
  // convenience Input props
  placeholder?: string;
  required?: boolean;
  inputProps?: Partial<IInputProps>;
}

/**
 * Generic labelled input element for react-hook-form forms
 */
export const LabeledInput = <TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
  control,
  error,
  name,
  label,
  placeholder,
  required,
  inputProps = {},
  ...formControlProps
}: LabeledInputProps<TFieldValues, TName>) => (
  <LabeledFormControl
    {...{
      isInvalid: !!error,
      ...formControlProps,
      control,
      name,
      label,
    }}
  >
    {({ field: { onChange, onBlur, value, ref } }) => (
      <Stack space="8px" w="100%">
        <Input
          ref={ref}
          variant="portal"
          onBlur={onBlur}
          fontSize={{ base: 'body.sm', lg: 'body.md' }}
          lineHeight={{ base: 'body.sm', lg: 'body.md' }}
          textAlign="start"
          onChangeText={onChange}
          value={value}
          {...{
            placeholder,
            required,
          }}
          {...inputProps}
        />
        {error ? <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage> : null}
      </Stack>
    )}
  </LabeledFormControl>
);
