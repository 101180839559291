import { useMemo } from 'react';

import { FunnelScreenNames, FunnelScreens } from '@/constants';

export const useNextFunnelStep = (screenName: FunnelScreenNames) => {
  const screenConfig = useMemo(
    () => FunnelScreens.find((screen) => screen?.name === screenName),
    []
  );

  return screenConfig?.nextStep as any;
};
