import { KeyboardAvoidingView, ScrollView, Stack, IStackProps } from 'native-base';
import { IKeyboardAvoidingViewProps } from 'native-base/lib/typescript/components/basic/KeyboardAvoidingView/types';
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types';

import { Head } from '@/components/Head';

interface FunnelProps extends IKeyboardAvoidingViewProps {
  hasHeader?: boolean;
  showDiscountBanner?: boolean;
  showPromotionBanner?: boolean;
  containerProps?: IStackProps;
  headContainerProps?: IViewProps;
}

export const FunnelWrapper = ({
  hasHeader = true,
  showDiscountBanner = false,
  showPromotionBanner = false,
  containerProps,
  headContainerProps,
  ...props
}: FunnelProps) => (
  <ScrollView contentContainerStyle={{ height: '100%', width: '100%' }}>
    <KeyboardAvoidingView w="100%" h="100%" {...props}>
      {hasHeader && (
        <Head
          showDiscountBanner={showDiscountBanner}
          showPromotionBanner={showPromotionBanner}
          {...headContainerProps}
        />
      )}
      <Stack
        flex={1}
        w="100%"
        maxW={1200}
        alignItems="center"
        alignSelf="center"
        justifyContent={{ base: 'space-between', md: 'flex-start' }}
        pb="24px"
        px="16px"
        {...containerProps}
      >
        {props.children}
      </Stack>
    </KeyboardAvoidingView>
  </ScrollView>
);
