import { IStackProps, Stack } from 'native-base';

export const AccountNavTextBanner = (props: IStackProps) => {
  return (
    <Stack
      w="100%"
      pl={{ base: 4, lg: '84px' }}
      pr={{ base: '10%', lg: 'unset' }}
      py={{ base: '8px', lg: '16px' }}
      {...props}
    />
  );
};
