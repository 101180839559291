import { Address, Order } from '@/api';

export const getBillingInfo = (order: Order | undefined) => {
  let billingData: { brand: string | undefined; last4: string | undefined } | undefined = undefined;

  if (order?.charge) {
    billingData = {
      brand: order?.charge.card_type,
      last4: order?.charge.card_number,
    };
  }

  return billingData;
};

export const getShippingInfo = (order: Order | undefined) => {
  let shippingInfo: Partial<Address> | undefined = undefined;

  if (order?.fulfillments.length) {
    shippingInfo = {
      address1: order?.fulfillments[0].address1,
      address2: order?.fulfillments[0].address2 || undefined,
      city: order?.fulfillments[0].city,
      state: order?.fulfillments[0].state,
      zip: order?.fulfillments[0].zip,
    };
  }

  return shippingInfo;
};
