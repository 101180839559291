import { Box, IconButton, Spacer, Stack, useTheme } from 'native-base';
import React, { useState, useRef } from 'react';
import Carousel, { ICarouselInstance, TCarouselProps } from 'react-native-reanimated-carousel';

import { CarouselIndicators } from './CarouselIndicators';

import { LeftArrow, RightArrow } from '@/components/Icons';

export interface CarouselProps extends Omit<TCarouselProps, 'data'> {
  width: number;
  height?: number;
  data: any[];
  accordionInfo?: any;
  indicatorPlacement?: 'top' | 'bottom';
  variant?: 'bordered' | 'gallery';
  onPrev?: (val?: any) => void;
  onNext?: (val?: any) => void;
  onScrollBegin?: () => void;
  direction?: 'vertical' | 'horizontal';
  itemWidth: number;
  disableLoop?: boolean;
}

const NavigationButton = ({
  disabled,
  onPress,
  IconComponent,
  size,
  color,
}: {
  direction: 'left' | 'right';
  disabled: boolean;
  onPress: () => void;
  IconComponent: React.ElementType;
  size: string;
  color: string;
}) => (
  <IconButton
    position="relative"
    disabled={disabled}
    icon={<IconComponent size={size} color={color} />}
    onPress={onPress}
    display={{ base: 'none', md: 'flex' }}
  />
);

export const MyCarousel = ({
  data,
  indicatorPlacement = 'bottom',
  direction = 'horizontal',
  width,
  height,
  variant,
  renderItem,
  onPrev,
  onNext,
  onScrollEnd,
  itemWidth,
  disableLoop,
}: CarouselProps) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const carouselRef = useRef<ICarouselInstance>(null);
  const theme = useTheme();

  const scrollToIndex = (index: number) => {
    setCarouselIndex(index);
    carouselRef.current?.scrollTo({ index });
  };

  const isFirstItem = carouselIndex === 0;
  const isLastItem = carouselIndex === data.length - 1;
  const loopEnabled = !disableLoop && data.length > 1;

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      onPrev?.(carouselRef.current.getCurrentIndex());
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      onNext?.(carouselRef.current.getCurrentIndex());
    }
  };

  const panGestureHandlerProps =
    direction === 'horizontal'
      ? { activeOffsetX: [-10, 10] }
      : direction === 'vertical'
      ? { activeOffsetY: [-10, 10] }
      : {};

  return (
    <Box
      w="100%"
      h="fit-content"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <CarouselIndicators
        display={indicatorPlacement === 'top' ? 'flex' : 'none'}
        index={carouselIndex}
        length={data.length}
        scrollTo={scrollToIndex}
      />
      <Spacer size={indicatorPlacement === 'top' ? { base: '16px', lg: '24px' } : '0px'} />
      <Stack direction="row" justifyContent="center" alignItems="center" space="48px">
        {variant !== 'gallery' && (
          <NavigationButton
            direction="left"
            disabled={isFirstItem}
            onPress={handlePrev}
            IconComponent={LeftArrow}
            size="xl"
            color={isFirstItem ? theme.colors.sntGrey.secondary : theme.colors.black}
          />
        )}
        <Carousel
          style={{
            borderWidth: variant === 'bordered' ? 2 : 0,
            alignItems: 'center',
            justifyContent: 'center',
            width,
          }}
          width={itemWidth}
          height={height}
          data={data}
          onSnapToItem={setCarouselIndex}
          renderItem={renderItem}
          ref={carouselRef}
          loop={loopEnabled}
          onScrollBegin={onPrev}
          onScrollEnd={onScrollEnd}
          panGestureHandlerProps={panGestureHandlerProps}
        />
        {variant !== 'gallery' && (
          <NavigationButton
            direction="right"
            disabled={isLastItem}
            onPress={handleNext}
            IconComponent={RightArrow}
            size="xl"
            color={isLastItem ? theme.colors.sntGrey.secondary : theme.colors.black}
          />
        )}
      </Stack>
      <Spacer size={indicatorPlacement === 'bottom' ? { base: 4, lg: 6 } : 0} />
      <CarouselIndicators
        display={indicatorPlacement === 'bottom' ? 'flex' : 'none'}
        index={carouselIndex}
        length={data?.length}
        scrollTo={scrollToIndex}
      />
      <Spacer size={indicatorPlacement === 'bottom' ? { base: 4, lg: 6 } : 0} />
    </Box>
  );
};
