import { Divider, HStack, ScrollView, View } from 'native-base';
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types';

import { Button } from '@/components/Elements';
import { useDogsLastVisitedScreenContext } from '@/context';
import { PartialDog } from '@/types/FunnelSession';

interface IBreadcrumbProps extends IViewProps {
  dogs?: PartialDog[];
  currentDog: number;
  selectDog: (index: number) => void;
}

export const Breadcrumbs = ({ dogs = [], currentDog, selectDog, ...props }: IBreadcrumbProps) => {
  const { dogsVisitedScreens } = useDogsLastVisitedScreenContext();
  return (
    <View {...props}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <HStack mx={3}>
          {dogs.map((dog, index, arr) => (
            <View key={index} alignItems="center" flexDirection="row">
              <Button
                size="bodyMdToLg"
                variant={currentDog === index ? 'badgeSmall' : 'badgeSmallOutline'}
                isDisabled={!dogsVisitedScreens[index]}
                onPress={() => selectDog(index)}
                px={{ base: '16px', lg: '24px' }}
                py="10px"
              >
                {dog.name.length > 12 ? `${dog.name.slice(0, 12)}...` : dog.name}
              </Button>
              {index < arr.length - 1 && (
                <Divider
                  w={{ base: '8px', lg: '16px' }}
                  orientation="horizontal"
                  mx={0}
                  thickness={1}
                  bg={currentDog && currentDog > index ? 'sntBlue.primary' : 'sntBlue.disabled'}
                />
              )}
            </View>
          ))}
        </HStack>
      </ScrollView>
    </View>
  );
};
