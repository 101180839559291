import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { FunnelScreenNames } from '@/constants';

export type dogsVisitedScreensType = {
  [key: number]: FunnelScreenNames | null;
};
export type DogsLastVisitedScreenContextType = {
  dogsVisitedScreens: dogsVisitedScreensType;
  setDogsVisitedScreens: Dispatch<SetStateAction<dogsVisitedScreensType>>;
  changeLastVisitedScreenForDog: (dogIndex: number, screen: FunnelScreenNames) => void;
  initWithCountOfDogs: (countOfDogs: number) => void;
};
export const DogsLastVisitedScreenContext = createContext<DogsLastVisitedScreenContextType | null>(
  null
);

export const useDogsLastVisitedScreenContext = () =>
  useContext(DogsLastVisitedScreenContext) as DogsLastVisitedScreenContextType;
