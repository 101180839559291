import { useMutation } from 'react-query';

import { validate_address, validate_street_address, validate_state, validate_zip } from '@/api';

export const useValidateAddressQuery = () => {
  const validateAddressMutation = useMutation(validate_address);
  const validateStreetAddressMutation = useMutation(validate_street_address);
  const validateStateMutation = useMutation(validate_state);
  const validateZipMutation = useMutation(validate_zip);

  return {
    validateAddress: validateAddressMutation,
    validateStreetAddress: validateStreetAddressMutation,
    validateState: validateStateMutation,
    validateZip: validateZipMutation,
  };
};
