import { Button, HStack, IButtonProps } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { MultiValue } from 'react-select';

import { Delete } from '@/components/Icons';

type SelectOption = { value: string; label: string };

export const SelectedPills = ({
  selected,
  onPillPress,
  pillProps,
  ...props
}: {
  selected: MultiValue<SelectOption>;
  onPillPress: (args: any) => void;
  pillProps?: IButtonProps;
} & IHStackProps) => {
  return (
    <HStack justifyContent="flex-start" alignItems="center" flexWrap="wrap" w="100%" {...props}>
      {selected.map((opt, i, arr) => {
        const option = opt as unknown as SelectOption;
        return (
          <Button
            key={i}
            onPress={() => onPillPress(option.value)}
            variant="badgeSmallInfo"
            size="bodySmToMd"
            _text={{ fontWeight: 'bold' }}
            mb={2}
            mr={i < arr.length - 1 ? 2 : 0}
            rightIcon={<Delete h={{ base: '12px', lg: '14px' }} color="black" fill="black" />}
            {...pillProps}
          >
            {option.label}
          </Button>
        );
      })}
    </HStack>
  );
};
