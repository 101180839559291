import { useFocusEffect } from '@react-navigation/native';
import { AxiosError } from 'axios';
import { Center, Heading, Box, PresenceTransition, Stack } from 'native-base';
import { useCallback, useState } from 'react';

import { useSessionRecipeOptions, RecipeType } from '@/api';
import { ProductCard } from '@/components/Elements';
import { FoodTypeCardSkeleton } from '@/components/Portal/Home/ManageDogPlans/FoodTypeCardSkeleton';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useFunnelErrorHandler, useNextFunnelStep, useSessionQuery } from '@/hooks';
import segment from '@/segment';
import { FunnelScreenProps, MealRecipeType } from '@/types';
import { isPup } from '@/utils';

export default function FoodTypes({ navigation }: FunnelScreenProps<'FoodTypes'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.FOOD_TYPES);
  const { session: funnelSession, mutateUpdatePetAnswer } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();

  const currentDogIndex = funnelSession?.current_pet_index as number;
  const pet = funnelSession?.pets?.[currentDogIndex];
  const discounts = funnelSession?.discounts?.map((discount) => discount.code);
  const [selectedFoodType, setSelectedFoodType] = useState<MealRecipeType>();
  const dogIsPup = isPup(pet?.birth_year, pet?.birth_month);

  const { data, isFetching, allCallsCompleted } = useSessionRecipeOptions({
    pet,
    discounts,
    sessionId: funnelSession?.id,
  });
  const products = data?.cheapestPlans;
  const planSize = data?.planSize;

  const onSubmit = async (foodType: MealRecipeType) => {
    segment.trackEvent('User Selects Foodtype', {
      food_type: foodType,
      account_id: null,
      session_id: funnelSession?.id,
    });

    setSelectedFoodType(foodType);
    try {
      await mutateUpdatePetAnswer({
        food_type: foodType,
        plan_size: planSize,
      });
    } catch (error) {
      funnelErrorHandler(error as AxiosError, 'Update food type');
      return;
    }

    navigation.navigate(nextStep);
  };

  // Show previously submitted selection
  useFocusEffect(
    useCallback(() => {
      setSelectedFoodType(pet?.food_type);
    }, [pet])
  );

  return (
    <FunnelWrapper height="auto">
      <Stack justifyContent="center" alignItems="center" mb={{ base: '36px', md: '48px' }}>
        <Heading fontWeight="bold" size="titleSmToMd" mb="16px" textAlign="center">
          How should we prepare {pet?.name}&apos;s food?
        </Heading>
        <Heading
          fontWeight="medium"
          size="bodySmToMd"
          fontFamily="secondary"
          textAlign="center"
          maxW={{ base: '100%', md: '960px' }}
        >
          {dogIsPup
            ? `Two wholesome options with nothing artificial in sight. Both our UnKibble™ and Fresh recipes are made with only real, whole ingredients packed with healthy proteins and fats that are essential for a growing dog. Our recipes are formulated by animal nutritionists and specially-balanced for your growing puppy!`
            : `Two incredible choices, with nothing artificial in sight. Both our UnKibble™ and Fresh recipes are made with only real, whole ingredients. So you really can't go wrong.`}
        </Heading>
      </Stack>
      <Stack
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        w={{ base: '100%', md: '484px' }}
      >
        {!allCallsCompleted || isFetching ? (
          <FoodTypeCardSkeleton />
        ) : (
          <PresenceTransition
            visible={products && products.length > 0}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 250,
              },
            }}
            style={{
              width: '100%',
            }}
          >
            <Box
              flexDir={{ base: 'column', lg: 'row' }}
              justifyContent="center"
              alignItems="center"
              w="100%"
            >
              {!isFetching
                ? products?.map(
                    (
                      {
                        trial: {
                          product: { recipes },
                          price: { price_per_meal, discounted_price_per_meal },
                        },
                      },
                      index
                    ) => (
                      <ProductCard
                        recipe={recipes[0]}
                        heading={recipes[0].type === RecipeType.UNKIBBLE ? 'UnKibble™' : 'Fresh'}
                        infoPills={[
                          recipes[0].type === RecipeType.UNKIBBLE
                            ? 'Most Popular'
                            : 'Original Recipes',
                        ]}
                        mx={{ base: 0, md: 4 }}
                        my={{ base: 2, md: 4 }}
                        key={`${recipes[0].type}-container-${index}`}
                        pricing={
                          discounted_price_per_meal ? discounted_price_per_meal : price_per_meal
                        }
                        onPress={() => onSubmit(recipes[0].type as MealRecipeType)}
                        selected={selectedFoodType === recipes[0].type}
                      />
                    )
                  )
                : undefined}
            </Box>
          </PresenceTransition>
        )}
      </Stack>
      <Center h={{ base: '32px', lg: '56px' }} />
    </FunnelWrapper>
  );
}
