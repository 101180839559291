import { extendTheme } from 'native-base';

import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { typography } from './typography';

import { componentThemes } from '@/components/Elements';

const config = {};

export const getTheme = () =>
  extendTheme({
    config,
    colors,
    ...typography,
    breakpoints,
    components: componentThemes,
  });

type CustomThemeType = ReturnType<typeof getTheme>;
declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {
    // for some reason we need at least one key/value set here for this to work...
    components: CustomThemeType['components'];
  }
}
