/**
 * Randomize an array's order, returning a new array
 *
 * Adapted from https://stackoverflow.com/a/48083382
 */
export function shuffle<T>(originalArray: T[]): T[] {
  const array = [...originalArray];
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
