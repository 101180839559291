import { useEffect } from 'react';

import { ENVIRONMENT } from '../config';

const loadUnbounceScript = () => {
  if (window && document) {
    window['useUnbounce'] = window['useUnbounce'] || [];
    const unbounceScript = document.createElement('script');
    const body = document.getElementsByTagName('body')[0];

    unbounceScript.type = 'text/javascript';
    unbounceScript.innerHTML = `
          var _ubad = 'try.spotandtango.com';
          var _ubaq = _ubaq || [];
          _ubaq.push(['trackGoal']);
          (function() {
            var ub_script = document.createElement('script');
            ub_script.type = 'text/javascript';
            ub_script.src = '//' + _ubad + '/_ub/static/ets/t.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(ub_script, s);
            })();`;
    body.appendChild(unbounceScript);
  }
};

export const useUnbounce = () => {
  useEffect(() => {
    if (!window?.useUnbounce && ENVIRONMENT == 'prod') {
      loadUnbounceScript();
    }
  }, []);
};
