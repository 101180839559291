import { Box, HStack, IInputProps, Input, Text } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { useEffect, useRef, useState } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

export interface InputFloatLabelProps extends IInputProps {
  externalRef?: any;
  _stackProps?: IHStackProps;
}

/**
 * Watches both the input ref and value props for value, and moves floating placeholder
 * component up or down according to if the field is empty
 */
export const InputFloatLabel = ({
  placeholder,
  externalRef,
  _stackProps,
  onBlur,
  onFocus,
  ...props
}: InputFloatLabelProps) => {
  const [labelPosition, setLabelPosition] = useState<'up' | 'down'>('down');

  const internalRef = useRef() as React.MutableRefObject<any>;
  const inputRef = externalRef || internalRef;

  const downValue = 50;
  const upValue = 5;
  const textOffset = useSharedValue(downValue);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      top: `${textOffset.value}%`,
    };
  });

  const slideUp = () => {
    textOffset.value = withTiming(upValue, {
      duration: 100,
      easing: Easing.out(Easing.exp),
    });
    setLabelPosition('up');
  };

  const slideDown = () => {
    textOffset.value = withTiming(downValue, {
      duration: 150,
      easing: Easing.out(Easing.exp),
    });
    setLabelPosition('down');
  };

  useEffect(() => {
    if (labelPosition === 'down' && (inputRef?.current?.value || props.value)) {
      slideUp();
    }
  }, [inputRef?.current?.value, props.value]);

  return (
    <HStack w="100%" {..._stackProps}>
      <Input
        ref={inputRef}
        placeholder={labelPosition === 'down' ? '' : undefined} // hides text while label is over it
        onFocus={(e) => {
          if (labelPosition === 'down') {
            slideUp();
          }
          onFocus?.(e);
        }}
        onBlur={(e) => {
          if (labelPosition === 'up' && !props?.value && !inputRef?.current?.value) {
            slideDown();
          }
          onBlur?.(e);
        }}
        {...props}
      />
      {placeholder ? (
        <Animated.View
          pointerEvents="none"
          style={[
            {
              position: 'absolute',
              left: '16px',
              transform: [{ translateY: '-50%' as unknown as number }],
            },
            animatedStyles,
          ]}
        >
          <Box bgColor="white" px={1}>
            <Text
              fontFamily="primary"
              size={labelPosition === 'up' ? 'captionToBodySm' : 'bodySmToMd'}
              color={labelPosition === 'up' ? 'black' : 'sntGrey.primary'}
              fontWeight="medium"
            >
              {placeholder}
            </Text>
          </Box>
        </Animated.View>
      ) : null}
    </HStack>
  );
};
