import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';

import { ChangePlanStatusNoDiscount } from './ChangePlanStatusNoDiscount';
import { ChangePlanStatusWithDiscount } from './ChangePlanStatusWithDiscount';

import { useAccount } from '@/hooks';
import { ProtectedScreenProps } from '@/types';

export const ChangePlanStatus = ({
  navigation,
  route,
}: ProtectedScreenProps<'ChangePlanStatus'>) => {
  const account = useAccount();
  const pet = account.pets.find((accountPet) => accountPet.id === route.params.petId);
  const nextOrderForPet = account.orders.upcoming.find((order) =>
    order.products.find((orderProduct) => orderProduct.pet_plan?.id === pet?.pet_plan.id)
  );
  const nextOrderHasDiscount = Number(nextOrderForPet?.total_discounts) > 0;

  useFocusEffect(
    useCallback(() => {
      window.scrollTo(0, 0); // scroll to top on focus
    }, [])
  );

  if (nextOrderHasDiscount) {
    return <ChangePlanStatusWithDiscount navigation={navigation} route={route} />;
  } else {
    return <ChangePlanStatusNoDiscount navigation={navigation} route={route} />;
  }
};
