/**
 * Defaults: https://github.com/GeekyAnts/NativeBase/blob/v3.4.19/src/theme/components/icon-button.ts
 */
const baseStyle = {
  color: 'black',
  _focus: {
    borderColor: 'black',
  },
  _icon: {
    pointerEvents: 'none',
  },
  _light: {
    _disabled: {
      // disable NativeBase's _disabled partial opacity
      opacity: 1,
    },
  },
};

const defaultProps = {
  size: 'md',
  // NativeBase injects a default `ghost` variant which we need to override
  // see: https://github.com/GeekyAnts/NativeBase/blob/v3.4.19/src/theme/components/icon-button.ts#L273
  variant: '',
};

export const IconButton = {
  baseStyle,
  defaultProps,
};
