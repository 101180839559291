import { axios } from '@/lib/axios';

// do this to remove a discount from a session
export const remove_discount = async (id: string, discount_code: string) =>
  await axios.post(
    `/sessions/${id}/remove_discount`,
    {
      discount_code,
    },
    { noErrorToast: true }
  );
