import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  letterSpacing: 'none',
  fontWeight: 'normal',
  fontFamily: 'primary',

  _light: {
    color: 'black',
  },
};

const variants = {
  dark: {
    _light: {
      color: 'sntGrey.dark',
    },
  },
  accordion: {
    _light: {
      color: 'sntGrey.dark',
    },
  },
  secondary: {
    fontFamily: 'secondary',
    fontWeight: 500,
  },
  titleSmToMd: {
    textAlign: 'center',
    _light: {
      fontWeight: 'bold',
    },
    ...responsiveSizes.titleSmToMd,
  },
  subtitle: {
    fontWeight: 'book',
    textAlign: 'center',
    fontFamily: 'secondary',
    ...responsiveSizes.bodySmToMd,
  },
  extraLargeTitle: {
    ...responsiveSizes.titleLgToXl,
  },
};

// Per Native Base Docs: https://docs.nativebase.io/next/heading
// `Heading` composes `Text` therefore the sizes defined in `theme/components/Text` should translate here.

const defaultProps = {
  size: 'titleMd',
};

export const Heading = {
  baseStyle,
  variants,
  defaultProps,
};
