import * as Linking from 'expo-linking';
import { Heading, Stack } from 'native-base';
import { useEffect } from 'react';

import { LoginForm } from './LoginForm';

import { PortalWrapper, MarketingCard } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { PublicScreenProps } from '@/types';

const GET_STARTED_LINK = 'https://plan.spotandtango.com/';

export const Login = ({ navigation }: PublicScreenProps<'Login'>) => {
  useEffect(() => {
    // reset page scroll after logout
    window.scrollTo(0, 0);
  }, []);

  const handleGetStarted = () => {
    Linking.openURL(GET_STARTED_LINK);
  };

  return (
    <PortalWrapper
      containerProps={{
        maxW: '100%',
        w: { base: '100%' },
        space: { base: 4, lg: 6 },
        px: 0,
        pb: 0,
      }}
    >
      <Heading size="bodyMlToTitleSm" fontWeight="bold" testID="login-home">
        Welcome!
      </Heading>

      <LoginForm navigation={navigation} />

      <Stack
        justifyContent={{ base: 'center' }}
        alignItems="center"
        alignSelf="center"
        w="100%"
        py={{ base: '24px', lg: '48px' }}
        bg="blueBayoux.primary"
      >
        <MarketingCard
          w={{ base: '95%', sm: '492px' }}
          variant="cta"
          ctaText="GET STARTED"
          header="New to Spot & Tango?"
          content="Get Started by taking our short quiz!"
          source={`${ASSETS_URL}/pictos/MarketingCards/new-to-spot-and-tango.png`}
          ctaOnPress={handleGetStarted}
        />
      </Stack>
    </PortalWrapper>
  );
};
