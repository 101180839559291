import { useIsFocused } from '@react-navigation/native';
import { Box, Heading, Text, Stack, VStack, Pressable } from 'native-base';
import { useState, useEffect } from 'react';

import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FunnelScreenProps } from '@/types';
import { getCurrentPet } from '@/utils/getCurrentPet';

export default function HealthConditions({ navigation }: FunnelScreenProps<'HealthConditions'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.HEALTH_CONDITIONS);
  const { session, mutateUpdatePetAnswer } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();
  const [hasHealthConditions, setHasHealthConditions] = useState<string>('');
  const dog = getCurrentPet(session);
  const { addAxiosPromise } = useAxiosStatusContext();

  const onNo = () => {
    setHasHealthConditions('no');
    try {
      addAxiosPromise(
        mutateUpdatePetAnswer({
          health_conditions: [],
        })
      );
    } catch (error) {
      funnelErrorHandler(error, 'Update no health conditions');
    }
    navigation.navigate(nextStep);
  };

  const onYes = () => {
    setHasHealthConditions('yes');
    navigation.navigate(FunnelScreenNames.HEALTH_CONDITIONS_LIST);
  };

  /**
   * When navigating back to a page, sets the currently viewed screen as "true".
   * used to get previously submitted data
   **/
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) return;
    if (dog?.health_conditions?.length) {
      setHasHealthConditions('yes');
    }
  }, [isFocused]);

  return (
    <FunnelWrapper
      containerProps={{
        justifyContent: 'flex-start',
        space: { base: '36px', lg: '48px' },
      }}
    >
      <Heading size="titleSmToMd" textAlign="center" fontWeight="bold">
        Does {dog?.name} have any special health conditions?
      </Heading>
      <VStack alignItems="center" w="100%" space={{ base: '16px', md: '24px' }}>
        <Stack
          w="100%"
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent="center"
          alignItems="center"
        >
          <Pressable
            variant="card"
            _hover={{ bg: 'white' }}
            w={{ base: '100%', md: '324px' }}
            onPress={onYes}
            py={{ base: '42px', md: '36px' }}
            px="16px"
            isPressed={hasHealthConditions === 'yes'}
            alignItems="center"
          >
            <Text size="titleSmToMd" fontWeight="bold">
              Yes
            </Text>
          </Pressable>
          <Box w={{ base: '16px', lg: '24px' }} h={{ base: '16px', lg: '24px' }} />
          <Pressable
            variant="card"
            _hover={{ bg: 'white' }}
            w={{ base: '100%', md: '324px' }}
            onPress={onNo}
            py={{ base: '42px', md: '36px' }}
            px="16px"
            isPressed={hasHealthConditions === 'no'}
            alignItems="center"
          >
            <Text size="titleSmToMd" fontWeight="bold">
              No
            </Text>
          </Pressable>
        </Stack>
        <Text size="bodySmToMd" fontFamily="secondary" textAlign="center" fontWeight="medium">
          This includes things like obesity, arthritis, and digestive issues
        </Text>
      </VStack>
    </FunnelWrapper>
  );
}
