import { IInputProps, Input } from 'native-base';
import ReactPhoneNumberInput, { Props } from 'react-phone-number-input/input';

/**
 * Wraps the input component from react-phone-number-input. `value` must start
 * with +1 to be parsed correctly into the component.
 */
export const PhoneInput = (props: Props<IInputProps>) => {
  return <ReactPhoneNumberInput {...props} country="US" inputComponent={Input} />;
};
