export const isValidImgSrc = async (src?: string): Promise<boolean> => {
  if (!src) return false;
  try {
    const response = await fetch(src, {
      method: 'GET',
    });
    if (response.status === 403) throw new Error(`${response.status}: ${response.statusText}`);
    return true;
  } catch (err) {
    console.log('Error retrieving image src: ', err);
    return false;
  }
};
