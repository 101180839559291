import { datadogLogs } from '@datadog/browser-logs';
import { Heading, Stack } from 'native-base';

import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { BodyTypeCode, usePetFormOptions } from '@/api';
import { Card, ToastType, displayToast } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';

const { logger } = datadogLogs;

export const AddNewDogBodyType = ({ navigation }: AddNewDogScreenProps<'BodyType'>) => {
  const account = useAccount();
  const { petData, savePetData } = useAddNewDogContext();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.BODY_TYPE);

  const { data: petFormData } = usePetFormOptions();
  const bodyTypes = petFormData?.body_types;

  const contentList = bodyTypes
    ? bodyTypes.map((type) => {
        return {
          slug: type.slug,
          picto: `BodyType/${type.slug}.png`,
          heading: type.name,
          content: type.description,
        };
      })
    : [];

  const onSubmit = (bodyType: BodyTypeCode) => {
    try {
      savePetData({ body_type: bodyType });
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's body type`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's body type, account_id=${account.id}`, { error });
    }
    navigation.navigate(nextStep);
  };
  return (
    <AddNewDogWrapper containerProps={{ justifyContent: 'flex-start' }}>
      <Heading
        size="titleSmToMd"
        textAlign="center"
        fontWeight="bold"
        pt={{ base: '0px', md: '24px' }}
        mb={{ base: '36px', lg: '48px' }}
      >
        {`How would you describe ${petData?.name}'s body type?`}
      </Heading>
      <Stack
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="center"
        w="100%"
      >
        {contentList.map((content, key) => {
          const my = key === 1 ? { base: '16px', lg: '0' } : 0;
          const mx = key === 1 ? { base: '0', lg: '24px' } : 0;
          return (
            <Card
              key={key}
              h={{ base: 'auto', md: 'fit-content', lg: '100%' }}
              textData={{
                ...content,
                contentProps: {
                  color: 'black',
                  fontFamily: 'secondary',
                  fontWeight: 'medium',
                  size: 'bodySmToLg',
                },
              }}
              mx={mx}
              my={my}
              isPressed={petData?.body_type === content.slug}
              onPress={() => onSubmit(content.slug)}
            />
          );
        })}
      </Stack>
    </AddNewDogWrapper>
  );
};
