import { axios } from '@/lib/axios';

/**
 *
 * Submits a request to reset the user password
 *
 * @params _Object_ - `{email}`
 *
 * @description
 * This function will request an email to be sent to the provided email addresss in order to complete the Forgot/Reset Password flow.
 *
 * @returns : Promise<AxiosResponse<any, any>>
 */
export const password_reset_request = async (email: string): Promise<any> =>
  await axios.post(`/password_reset_request`, { email }, { noErrorToast: true });
