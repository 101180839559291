import { Text, HStack } from 'native-base';

import { CardBrandLogo } from '../Home/Billing/CardBrandLogo';

import { EditableCard, EditableCardProps, HiddenTextDots } from '@/components/Elements';

export interface BillingCardProps extends EditableCardProps {
  card?: { brand?: string; last4?: string };
  isFree?: boolean;
  paymentError?: 'expired' | 'expiringSoon' | 'error' | string;
}

/**
 * Card component for displaying a payment method for orders pages,
 * and used in the Account tab as navigation
 */
export const BillingCard = ({ card, isFree, paymentError, ...props }: BillingCardProps) => {
  let paymentErrorText: string | undefined;
  if (paymentError === 'expired') {
    paymentErrorText = 'Your card has expired.';
  } else if (paymentError === 'expiringSoon') {
    paymentErrorText = 'Your card is expiring soon.';
  } else if (paymentError) {
    paymentErrorText = 'Your payment has failed';
  }

  return (
    <EditableCard
      w="100%"
      h={{ base: 'fit-content', lg: '100%' }}
      heading="Billing"
      stackProps={{
        direction: { base: 'row', lg: 'column' },
        alignItems: { base: 'center', lg: 'start' },
        justifyContent: { base: 'space-between', lg: 'start' },
        space: { base: '0px', lg: '16px' },
      }}
      borderColor={paymentError ? 'error.default' : undefined}
      _hover={paymentError ? { borderColor: 'error.hover' } : undefined}
      {...props}
    >
      {isFree ? (
        <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
          FREE
        </Text>
      ) : null}
      {!isFree && card ? (
        <>
          <HStack alignItems="center" space={1}>
            <CardBrandLogo brand={card.brand} />
            <HiddenTextDots />
            <Text size="bodySm">{card.last4}</Text>
          </HStack>
          {paymentError && (
            <Text variant="errorMessage" size="bodySmToMd">
              {paymentErrorText}
            </Text>
          )}
        </>
      ) : null}
      {!card && !isFree ? <Text size="bodyMdToLg">Add a payment method</Text> : null}
    </EditableCard>
  );
};
