import { Link as NBLink, ILinkProps as NBLinkProps } from 'native-base';

export interface ILinkProps extends NBLinkProps {
  disabled?: boolean;
}

export const Link = ({
  disabled = false,
  isUnderlined = false,
  isHovered = false,
  ...props
}: ILinkProps) => (
  <NBLink
    accessibilityRole="link"
    accessibilityLabel={props.children}
    variant={disabled && 'disabled'}
    _hover={{
      isUnderlined: !disabled && true,
    }}
    {...props}
  />
);
