import { Heading, Stack, FlatList } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { memo, useState } from 'react';

import { CancelAddonsModal } from '../../CancelAddons';
import { AddonLineItem } from './AddonLineItem';

import { useAddProductToPetPlan } from '@/api';
import { Pet, PetPlan, PetPlanProduct } from '@/api/types';
import { ToastType, displayToast } from '@/components/Elements';
import { ADDONS_CONFIGURATION, AddonsType } from '@/constants';
import { useAuth } from '@/hooks';

export interface AddonsProps extends IHStackProps {
  data: PetPlanProduct[];
  pet: Pet;
  petPlan: PetPlan;
  disabled?: boolean;
  addonsType: AddonsType;
}

export const Addons = memo(({ data, pet, petPlan, disabled, addonsType }: AddonsProps) => {
  const { refetchUser } = useAuth();
  const { type, title } = ADDONS_CONFIGURATION[addonsType];
  const { mutateAsync: updateAddon, isLoading: isUpdateAddonsLoading } = useAddProductToPetPlan();
  const [addonToCancel, setAddonToCancel] = useState<PetPlanProduct>();
  const [showCancelModal, setShowCancelModal] = useState(false);

  const updateAddonAndRefresh = async (
    addon: PetPlanProduct,
    quantity: number,
    changeType: 'inc' | 'dec' = 'inc'
  ) => {
    const cleanAddonName = addon.recipes.map((recipe) => recipe.name).join(', ');
    updateAddon({
      petPlanId: pet.pet_plan.id,
      products: [{ code: addon.code, quantity, recurring: true }],
    })
      .then(() => {
        refetchUser();
        displayToast({
          message: `${cleanAddonName} have been ${
            changeType === 'dec' ? 'removed from' : 'added to'
          } ${pet.name}'s plan.`,
          type: ToastType.Success,
        });
      })
      .catch(() => {}); // to catch the error and avoid page redirect
  };

  const handleUpdateExtra = async (addon: PetPlanProduct, changeType: 'inc' | 'dec' = 'inc') => {
    if (pet) {
      if (changeType === 'dec' && addon.quantity === 0) {
        return;
      }

      const updatedQuantity = changeType === 'dec' ? addon.quantity - 1 : addon.quantity + 1;
      if (updatedQuantity === 0) {
        setAddonToCancel(addon);
        setShowCancelModal(true);
      } else {
        updateAddonAndRefresh(addon, updatedQuantity, changeType);
      }
    }
  };

  return (
    <Stack justifyContent="center" space={{ base: '16px', lg: '24px' }} w="100%">
      <Heading size="bodyMdToLg" fontWeight="bold" color={disabled ? 'sntGrey.primary' : undefined}>
        {title}
      </Heading>
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <AddonLineItem
            product={item}
            assetsDirectory={type}
            onIncExtra={() => handleUpdateExtra(item, 'inc')}
            onDecExtra={() => handleUpdateExtra(item, 'dec')}
            disabled={disabled}
            disabledPlus={disabled || isUpdateAddonsLoading}
            disabledMinus={disabled || isUpdateAddonsLoading || item.quantity === 0}
            showDivider={index < data.length - 1}
          />
        )}
        keyExtractor={(item) => item.product_id}
        w="100%"
      />
      {addonToCancel && (
        <CancelAddonsModal
          petPlan={petPlan}
          product={addonToCancel}
          isOpen={showCancelModal}
          closeModal={() => setShowCancelModal(false)}
          onSubmit={() => updateAddonAndRefresh(addonToCancel, 0, 'dec')}
        />
      )}
    </Stack>
  );
});
