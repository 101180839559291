import * as React from 'react';

// ! Change Label Props type to be div?? or paragraph element type? or? something? else?
export type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  size?: 'mini' | 'small' | 'medium' | 'large';
  content: {
    title?: string;
    text: string;
  };
};

export const Label = ({ size, content }: LabelProps) => {
  const smallText = size === 'mini' || size === 'small';

  return (
    <div className="label-container">
      {content.title && <h5>{content.title}</h5>}
      <p className={smallText ? 'small-text' : ''}>{content.text}</p>
    </div>
  );
};

Label.displayName = 'Label';
