import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';

export interface SetupIntentData {
  client_secret: string;
}

const prepareCardSetup = () => {
  return axios
    .post<SetupIntentData>(`/payment_methods/create_setup_intent`)
    .then((res) => res.data);
};

export const usePrepareCardSetup = () => {
  return useMutation<SetupIntentData>(() => prepareCardSetup());
};
