import { HStack, Heading, Stack, VStack, useBreakpointValue } from 'native-base';

import { Bubble } from './Bubble';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { Dog } from '@/types';

export const WhatsInTheBoxSection = ({
  dog,
  items,
}: {
  dog: Partial<Dog>;
  items: { quantity: number; recipe: string | undefined }[] | undefined;
}) => {
  const bowlImageSrc = `${ASSETS_URL}/pictos/Unkibble/bowls/${
    dog.recipes &&
    (dog.recipes.some((recipe) => recipe === 'U-C')
      ? 'U-C'
      : dog.recipes.some((recipe) => recipe === 'U-B')
      ? 'U-B'
      : 'U-C')
  }.webp`;

  const rotate30OnMobile = useBreakpointValue({ base: [{ rotate: '30deg' }], lg: 'none' });
  const rotate30ReverseOnMobile = useBreakpointValue({ base: [{ rotate: '-30deg' }], lg: 'none' });
  const captionRightToLeft = useBreakpointValue({ base: 'right', lg: 'left' });

  const combineRecipes = items?.reduce((prev, current) => {
    return {
      quantity: prev.quantity + current.quantity,
      recipe: prev.recipe?.split(' ')[0] + ' & ' + current.recipe?.split(' ')[0],
    };
  });

  return (
    <VStack w="100%" pt={{ base: '172px', lg: '364px' }} px="16px" alignItems="center">
      <HStack
        position="absolute"
        w="100%"
        justifyContent="center"
        top={{ base: -125, lg: -308 }}
        left={0}
        right={0}
        margin="auto"
      >
        <Image
          source={{ uri: bowlImageSrc }}
          w={{ base: '250px', lg: '515px' }}
          h={{ base: '250px', lg: '515px' }}
          alt="UnKibble bowl"
        />
      </HStack>
      <Heading
        fontWeight="bold"
        variant="extraLargeTitle"
        pb={{ base: '30px', md: '78px' }}
      >{`What's in ${dog.name}'s Trial Box?`}</Heading>
      <VStack>
        <Stack flexDirection={{ base: 'column', lg: 'row' }} alignItems="center" w="100%">
          <Bubble
            captionSide="right"
            caption={`${combineRecipes?.quantity} bag${
              combineRecipes && combineRecipes?.quantity > 1 ? 's' : ''
            } of ${combineRecipes?.recipe}`}
            position="relative"
            left={{ base: -8, lg: 0 }}
          >
            <Image
              source={{
                uri: `${ASSETS_URL}/pictos/Unkibble/${dog.recipes?.sort().join('')}.png`,
              }}
              size={{ base: '175px', lg: '260px' }}
              alt="UnKibble Product"
            />
          </Bubble>
          <Stack
            position="absolute"
            left={{ base: 220, lg: 375 }}
            top={{ base: 200, lg: -25 }}
            style={{ transform: rotate30OnMobile }}
          >
            <Image
              w={{ base: '58px', lg: '569px' }}
              h={{ base: '30px', lg: '135px' }}
              source={{ uri: `${ASSETS_URL}/pictos/Illustrations/dotted-curve-long.svg` }}
              alt="dotted line"
            />
          </Stack>
          <Bubble
            position="relative"
            top={{ base: 0, lg: 115 }}
            left={{ base: 8, lg: 0 }}
            captionSide="left"
            caption={`FREE personalized scoop for ${dog.name}`}
          >
            <Image
              source={{ uri: `${ASSETS_URL}/heroes/Unkibble/Personalized-Scoop.png` }}
              w="100%"
              h="100%"
              resizeMode="cover"
            />
          </Bubble>
        </Stack>
        <HStack w="100%" alignItems="center" zIndex={-1}>
          <Bubble captionSide={captionRightToLeft} caption="Custom feeding guide">
            <Image
              source={{ uri: `${ASSETS_URL}/pictos/CardIcons/FeedingGuide.png` }}
              resizeMode="contain"
              size="80%"
              alt="feeding guide"
            />
          </Bubble>
          <Stack
            position={{ base: 'absolute', lg: 'relative' }}
            top={{ base: 3, lg: 0 }}
            right={{ base: 100, lg: 0 }}
            style={{ transform: rotate30ReverseOnMobile }}
          >
            <Image
              w={{ base: '95px', lg: '398px' }}
              h={{ base: '61px', lg: '216px' }}
              source={{ uri: `${ASSETS_URL}/pictos/Illustrations/dotted-curve-short.svg` }}
              alt="dotted line"
            />
          </Stack>
        </HStack>
      </VStack>
    </VStack>
  );
};
