import { useQuery } from 'react-query';

import { PetFormOptions } from '../../types';

import { axios } from '@/lib/axios';

const getPetFromOptions = async (): Promise<PetFormOptions> => {
  const response = await axios.get('/pet_form_options');
  return response.data;
};

export const usePetFormOptions = () => {
  return useQuery(['PetFormOptions'], () => getPetFromOptions(), { cacheTime: 1000 * 60 * 60 });
};
