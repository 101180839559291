import { axios } from '@/lib/axios';

/**
 *
 * Sends a logout request to the API.
 *
 * @description Describes the actions of the logout function.
 * This function will invalidate the authentication token on the backend server, as well as remove it from AsyncStorage, preventing the user from accessing any user-level sections of the app.
 *
 * @returns : Promise<AxiosResponse<any, any>>
 */
export const logout = async () => {
  return await axios.post('/logout').then((response) => {
    console.log(response);
  });
};
