import { useMemo } from 'react';

import { UsePlanOptionsQueryData, useSessionPlans } from '@/api';
import { processPlanData } from '@/utils/processPlanData';

/**
 * Helper hook to wrap useSessionPlans and expose common data structures
 */
export const useSessionRecipeOptions = ({
  pet,
  discounts,
  sessionId,
  accountId,
}: UsePlanOptionsQueryData) => {
  const {
    query: { data, isFetching },
    allCallsCompleted,
  } = useSessionPlans({
    pet,
    discounts,
    sessionId,
    accountId,
  });

  const recipeOptionsData = useMemo(() => processPlanData(data), [data]);

  return { data: recipeOptionsData, isFetching, allCallsCompleted };
};
