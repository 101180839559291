import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  w: '100%',
  h: 'fit-content',
  borderRadius: 0,
  borderWidth: '1px',
  borderColor: 'black',
  m: 0,
  p: 0,
  textAlign: 'left',
  fontFamily: 'primary',
  fontWeight: 'medium',
  _light: {
    p: 0,
    color: 'black',
    backgroundColor: 'white',
    _stack: {
      p: '17px',
    },
    _hover: {
      borderWidth: '2px',
      borderColor: 'black',
      _stack: {
        p: '16px',
      },
    },

    _focus: {
      borderWidth: '2px',
      borderColor: 'sntBlue.primary',
      outlineWidth: 0,
      boxShadow: 'none',
      _hover: {
        outlineWidth: 0,
        boxShadow: 'none',
        borderWidth: '2px',
        borderColor: 'sntBlue.primary',
        _stack: {
          p: '16px',
          style: {
            outlineWidth: '0',
            boxShadow: 'none', // removes NB teal shadow
          },
        },
      },
      _stack: {
        p: '16px',
        style: {
          outlineWidth: '0',
          boxShadow: 'none', // removes NB teal shadow
        },
      },
      _invalid: {
        _stack: {
          p: '16px',
          style: {
            margin: '0',
            outlineWidth: '0',
            boxShadow: `none`,
          },
        },
        _hover: {
          _stack: {
            p: '16px',
            style: {
              margin: '0',
              outlineWidth: '0',
              boxShadow: `none`,
            },
          },
        },
      },
    },
  },
  _placeholder: {
    color: 'sntGrey.primary',
    ...responsiveSizes.bodySmToMd,
  },
};

const variants = {
  error: {
    _light: {
      _stack: {
        p: '16px',
      },
      borderColor: 'error.default',
      borderWidth: 2,
      _hover: {
        borderWidth: 2,
        borderColor: 'error.default',
      },
      _focus: {
        borderColor: 'error.default',
        borderWidth: 2,
        _hover: {
          borderWidth: 2,
          borderColor: 'error.default',
        },
      },
    },
  },
};

const defaultProps = {
  size: 'bodySmToMd',
};

export const Input = {
  baseStyle,
  variants,
  sizes: responsiveSizes,
  defaultProps,
};
