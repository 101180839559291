import { Stack } from 'native-base';
import { Control, FormState } from 'react-hook-form';

import { ShippingStateSelect } from '../../../../forms/ShippingStateSelect';
import { AddressFormValues } from './types';

import { LabeledInput } from '@/components/Elements';

interface AddressFormProps {
  control: Control<AddressFormValues>;
  formState: FormState<AddressFormValues>;
}

export const AddressForm = ({ control, formState }: AddressFormProps) => {
  return (
    <Stack direction="column" space={{ base: '16px', lg: '24px' }}>
      <LabeledInput
        name="address1"
        control={control}
        label="Address"
        placeholder="Street address (no PO boxes)"
        required
        error={'address1' in formState.dirtyFields ? formState.errors.address1?.message : undefined}
        // @ts-expect-error - Use a random autoComplete identifier to prevent autofill. (autoComplete="off" is ignored by many browsers.)
        inputProps={{ maxLength: 40, autoComplete: 'random-identifier' }}
      />
      <LabeledInput
        name="address2"
        control={control}
        label="Apt, Suite"
        placeholder="Apartment, suite, unit, etc."
        error={'address2' in formState.dirtyFields ? formState.errors.address2?.message : undefined}
        // @ts-expect-error - Use a random autoComplete identifier to prevent autofill. (autoComplete="off" is ignored by many browsers.)
        inputProps={{ maxLength: 40, autoComplete: 'random-identifier' }}
      />
      <LabeledInput
        name="city"
        label="City"
        control={control}
        placeholder="City"
        required
        // @ts-expect-error - Use a random autoComplete identifier to prevent autofill. (autoComplete="off" is ignored by many browsers.)
        inputProps={{ autoComplete: 'random-identifier' }}
      />
      <Stack direction="row" alignItems="start" space={{ base: '16px', lg: '24px' }}>
        <ShippingStateSelect
          flex={1}
          name="state"
          control={control}
          selectProps={{ textStyling: { lineHeight: '26px' } }}
        />
        <LabeledInput
          flex={1}
          name="zip"
          label="Zip"
          control={control}
          placeholder="Zip"
          required
          error={'zip' in formState.dirtyFields ? formState.errors.zip?.message : undefined}
          // @ts-expect-error - Use a random autoComplete identifier to prevent autofill. (autoComplete="off" is ignored by many browsers.)
          inputProps={{ autoComplete: 'random-identifier' }}
        />
      </Stack>
    </Stack>
  );
};
