import {
  Checkbox as NBCheckbox,
  ICheckboxProps as NBCheckboxProps,
  ICheckboxGroupProps,
} from 'native-base';

export type ICheckboxProps = NBCheckboxProps;

const Checkbox = ({ ...props }: ICheckboxProps) => {
  return (
    <NBCheckbox
      _checked={{ background: 'sntBlue.primary' }}
      _icon={{ color: 'black' }}
      _invalid={{ borderColor: 'error.600' }}
      _text={{
        ml: 0,
        fontSize: { base: 'body.sm', lg: 'body.lg' },
        lineHeight: { base: 'body.sm', lg: 'body.lg' },
      }}
      h={{ base: '18px', lg: '20px' }}
      {...props}
    />
  );
};

const CheckboxGroup = (props: ICheckboxGroupProps) => <NBCheckbox.Group {...props} />;

Checkbox.Group = CheckboxGroup;

export { Checkbox };
