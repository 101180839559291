import { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';

import { Address } from '@/api/types/accountServices';
import { useAuth } from '@/hooks/useAuth';
import { axios } from '@/lib/axios';

const postAddress = ({ data }: { data: Partial<Address> }, axiosConfig?: AxiosRequestConfig) =>
  axios.post(`/addresses/`, data, axiosConfig).then((response) => response.data);

export const useCreateAddress = (axiosConfig?: AxiosRequestConfig) => {
  const { refetchUser } = useAuth();

  return useMutation<Address, Error, Partial<Address>>(
    (data) => postAddress({ data }, axiosConfig),
    {
      onSuccess: () => {
        return refetchUser(); // refresh with new addresses data
      },
    }
  );
};
